import { Icon, Table, TableCellProps, Token, IconProps } from '@revolut/ui-kit'
import { FC } from 'react'

import { OrderState } from '../../../core-api'
import { OrderStateMessage } from '../OrderStateMessage'

interface OrderStatusTableCellProps extends TableCellProps {
  status: OrderState
}

const iconProps: Record<string, IconProps> = {
  [OrderState.Filled]: {
    name: 'Check',
    size: Token.size.s16,
    color: Token.color.up,
  },
  [OrderState.Rejected]: {
    name: 'Cross',
    size: Token.size.s16,
    color: Token.color.down,
  },
  [OrderState.PartiallyFilled]: {
    name: '16/SandWatch',
    size: Token.size.s16,
    color: Token.color.orange,
  },
  [OrderState.Canceled]: {
    name: '16/Canceled',
    size: Token.size.s16,
    color: Token.color.greyTone50,
  },
}

export const OrderStatusTableCell: FC<OrderStatusTableCellProps> = ({ status }) => {
  return (
    <Table.Cell renderStart={() => <Icon {...iconProps[status]} />}>
      <OrderStateMessage state={status} />
    </Table.Cell>
  )
}
