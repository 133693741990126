import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getWatchlist } from '../../api'
import { QueryKey } from '../../constants'
import { WatchlistItem, WatchlistSort } from '../../types'

export const useWatchlist = <T extends unknown = WatchlistItem[]>(
  options: UseQueryOptions<WatchlistItem[], AxiosError, T> = {},
) =>
  useQuery<WatchlistItem[], AxiosError, T>(
    [QueryKey.Watchlist],
    () => getWatchlist({ sort: WatchlistSort.LastAdded }),
    options,
  )
