import { Color, Table, TableFilters } from '@revolut/ui-kit'
import { isNumber } from 'lodash'
import { FC, useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Trade, useInfiniteTrades } from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { mapTableLoadingState } from '../../../core-utils'
import { getTradesFilterParams } from '../../utils'
import { COLUMNS_ADVANCED } from './TradesTable.advanced.columns'
import { COLUMNS_DEFAULT } from './TradesTable.default.columns'

type TradesTableProps = {
  maxRows?: number
  selectedRowId?: string | null
  onSelectRow?: (id: string) => void
  variant?: 'default' | 'advanced'
  initialFilters?: TableFilters<Trade>
  stickyHeaderTop?: number | string
}

export const TradesTable: FC<TradesTableProps> = ({
  maxRows,
  selectedRowId,
  onSelectRow,
  variant,
  initialFilters = [],
  stickyHeaderTop = 0,
}) => {
  const [filters, setFilters] = useState<TableFilters<Trade>>(initialFilters)

  const filterParams = useMemo(() => getTradesFilterParams(filters), [filters])

  const { data, status, fetchNextPage, hasNextPage, refetch } = useInfiniteTrades({
    ...filterParams,
    limit: maxRows,
  })

  const rows = useMemo(
    () => (data?.pages ?? []).flatMap((page) => page.items).slice(0, maxRows),
    [data, maxRows],
  )

  const getRowState = useCallback(
    ({ value }) => {
      return {
        bg: value.execId === selectedRowId ? Color.BLUE_5 : undefined,
      }
    },
    [selectedRowId],
  )

  const handleRowClick = (item: Trade) => {
    onSelectRow && onSelectRow(item.execId)
  }

  if (status === 'error') {
    return <ErrorStatus onRetry={refetch} />
  }

  const hasMore = isNumber(maxRows) ? rows.length < maxRows && hasNextPage : hasNextPage

  return (
    <Table
      rowHeight={variant === 'advanced' ? 'small' : 'large'}
      stickyHeaderTop={stickyHeaderTop}
      columns={variant === 'advanced' ? COLUMNS_ADVANCED : COLUMNS_DEFAULT}
      data={rows}
      loadingState={mapTableLoadingState(status, rows)}
      getRowState={getRowState}
      onRowClick={handleRowClick}
      onRequestMore={fetchNextPage}
      onFilter={setFilters}
      hasMore={hasMore}
      autoResetFilters={false}
      manualFilters
      disableSortBy
      disableResizing
      virtual={variant === 'default'}
      initialState={{ filters }}
      labelEmptyState={
        <FormattedMessage
          id="activity.trades.table.empty.title"
          defaultMessage="No trades"
        />
      }
      labelNoResults={
        <FormattedMessage
          id="activity.trades.table.noResults.title"
          defaultMessage="No trades found"
        />
      }
    />
  )
}
