import { ActionMenu, ActionMenuProps } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { Order, useCancelOrder } from '../../../core-api'
import { useHumanizeCurrencyPair } from '../../../core-shared'
import { useStatusPopup } from '../../../core-ui'
import { getRouteUrl, RoutePath } from '../../../core-utils'

interface OrderActionsProps extends Omit<ActionMenuProps, 'order'> {
  order: Order
}

export const OrderActions: FC<OrderActionsProps> = ({ order, ...rest }) => {
  const history = useHistory()

  const statusPopup = useStatusPopup()

  const cancelOrder = useCancelOrder({
    onMutate: () => {
      statusPopup.showLoading()
    },
    onSuccess: () => {
      statusPopup.showSuccess({
        title: (
          <FormattedMessage
            id="activity.cancelOrder.success.title"
            defaultMessage="Order successfully canceled"
          />
        ),
      })
    },
    onError: () => {
      statusPopup.showError({
        title: (
          <FormattedMessage
            id="activity.cancelOrder.error.title"
            defaultMessage="Something went wrong"
          />
        ),
      })
    },
  })

  const { humanizeCurrencyPair } = useHumanizeCurrencyPair()

  const handleEditOrder = (event: React.MouseEvent) => {
    event.stopPropagation()

    history.push(
      getRouteUrl({
        path: RoutePath.Trade,
        params: {
          currencyPair: humanizeCurrencyPair(order.currencyPair),
        },
        queryParams: { orderId: order.orderId },
      }),
    )
  }

  return (
    <ActionMenu {...rest}>
      <ActionMenu.Item useIcon="Pencil" onClick={handleEditOrder}>
        <FormattedMessage id="actions.editOrder" defaultMessage="Edit order" />
      </ActionMenu.Item>

      <ActionMenu.Item
        color="red"
        useIcon="Cross"
        onClick={() => cancelOrder.mutate(order.orderId)}
      >
        <FormattedMessage id="actions.cancelOrder" defaultMessage="Cancel order" />
      </ActionMenu.Item>
    </ActionMenu>
  )
}
