import { getConfigValue, ConfigKey } from '../../core-config'
import { LocalStorageKey, localStorage } from '../../core-storage'
import { checkRequired } from '../../core-utils'
import { getSupportedLocale } from './getSupportedLocale'

export const getCurrentLocale = () => {
  const locale = localStorage.getItem(LocalStorageKey.Locale)

  const fallbackLocale = checkRequired(
    getConfigValue(ConfigKey.FallbackLocale),
    'Fallback locale should be defined',
  )

  return getSupportedLocale(locale, ...navigator.languages) ?? fallbackLocale
}
