import { HStack, Icon, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { WidgetTabBar } from '../../../core-ui'
import { ChartType, WidgetType } from '../../constants'
import { Chart } from '../Chart/Chart'
import { ChartTypeMessage } from '../ChartTypeMessage'
import { TerminalWidget } from '../TerminalWidget'

interface ChartWidgetProps {
  currencyPair: string
}

export const ChartWidget: FC<ChartWidgetProps> = ({ currencyPair }) => {
  const [selectedChart, setSelectedChart] = useState<ChartType>(ChartType.Price)

  const tooltipRef = useTooltip()

  return (
    <TerminalWidget type={WidgetType.PriceChart}>
      <TerminalWidget.Header>
        <WidgetTabBar>
          <WidgetTabBar.Item
            selected={selectedChart === ChartType.Price}
            onClick={() => setSelectedChart(ChartType.Price)}
          >
            <HStack space="s-8" align="center">
              <ChartTypeMessage type={ChartType.Price} />
              <Icon name="16/InfoOutline" size={16} {...tooltipRef.getAnchorProps()} />
            </HStack>
          </WidgetTabBar.Item>

          <WidgetTabBar.Item
            selected={selectedChart === ChartType.Depth}
            onClick={() => setSelectedChart(ChartType.Depth)}
          >
            <ChartTypeMessage type={ChartType.Depth} />
          </WidgetTabBar.Item>
        </WidgetTabBar>
      </TerminalWidget.Header>

      <Chart type={selectedChart} currencyPair={currencyPair} />

      <Tooltip
        {...tooltipRef.getTargetProps()}
        width={240}
        backgroundColor={Token.color.widgetBackground}
        color={Token.color.foreground}
        placement="bottom-end"
      >
        <FormattedMessage
          id="terminal.widgets.priceChart.tooltip.title"
          defaultMessage="Price chart is built based on the orders available in the order book"
        />
      </Tooltip>
    </TerminalWidget>
  )
}
