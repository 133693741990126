import { Box, Flex, StatusWidget } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

import { FormattedEnumMessage } from '../../../core-intl'
import { Asset3dImageName, getImageAssetSrcProps } from '../../../core-utils'

type MaintenancePlaceholerType = 'global' | 'trading'

const maintenanceSubtitleMessages = defineMessages<MaintenancePlaceholerType>({
  global: {
    id: 'shared.maintenance.placeholder.subtitle',
    defaultMessage:
      'Revolut X is undergoing maintenance. We apologise for any inconvenience this may cause and appreciate your understanding.',
  },
  trading: {
    id: 'shared.maintenance.placeholder.trading.subtitle',
    defaultMessage:
      'Trading and deposits are disabled. You can still cancel your existing open orders and withdraw your assets.',
  },
})

interface MaintenancePlaceholerProps {
  type: MaintenancePlaceholerType
}

export const MaintenancePlaceholer: FC<MaintenancePlaceholerProps> = ({ type }) => (
  <Flex height={type === 'global' ? '100vh' : '100%'}>
    <Box m="auto" px="s-16" maxWidth={350}>
      <StatusWidget>
        <StatusWidget.Image {...getImageAssetSrcProps(Asset3dImageName.Error)} />
        <StatusWidget.Title>
          <FormattedMessage
            id="shared.maintenance.placeholder.title"
            defaultMessage="Maintenance in progress"
          />
        </StatusWidget.Title>
        <StatusWidget.Description>
          <FormattedEnumMessage id={type} messages={maintenanceSubtitleMessages} />
        </StatusWidget.Description>
      </StatusWidget>
    </Box>
  </Flex>
)
