import { QueryStatus } from '@tanstack/react-query'

import { CurrencyAssetType, ExtendedBalance, Pocket } from '../../../core-api'

export enum WithdrawFlowStep {
  CurrencySelection = 'CurrencySelection',
  EnterAmount = 'EnterAmount',
  SourceSelection = 'SourceSelection',
  Confirmation = 'Confirmation',
  Verification = 'Verification',
}

export type WithdrawFlowState = {
  step: WithdrawFlowStep
  assetType: CurrencyAssetType
  balance: ExtendedBalance | undefined
  pocket: Pocket | undefined
  amount: number | null
  sizeRatio: number | null
}

export type WithdrawFlow = {
  state: WithdrawFlowState

  currencyMessage: string | null
  amountMessage: string | null

  verificationLink: string | null

  invalid: boolean
  disabled: boolean
  submitting: boolean
  status: QueryStatus

  handleChangeStep: (step: WithdrawFlowStep) => void
  handleChangeAmount: (nextAmount: number | null) => void
  handleChangeBalance: (balance: ExtendedBalance) => void
  handleChangePocket: (pocket: Pocket) => void
  handleChangeSize: (nextSizeRatio: number) => void
  handleChangeAssetType: (assetType: CurrencyAssetType) => void
  handleCancelVerification: () => void
  handleSubmit: () => void
}
