import { useContext } from 'react'

import { FeedbackContext } from '../providers'

export const useFeedbackContext = () => {
  const ctx = useContext(FeedbackContext)

  if (ctx === null) {
    throw new Error(
      'You can access context only in components inside "FeedbackProvider" component',
    )
  }

  return ctx
}
