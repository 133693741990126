import { Box, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

import { GRID_LAYOUT_RESIZER_GAP, GRID_LAYOUT_RESIZER_THICKNESS } from '../../constants'
import { GridLayoutResizerType } from '../../types'

const GridLayoutResizer = styled(Box)<{ type: GridLayoutResizerType }>`
  flex: 1;
  cursor: pointer;
  z-index: 1000;

  background-clip: content-box;
  box-sizing: content-box;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${Token.color.blue};
    transition-delay: 0.3s;
  }

  ${({ type }) =>
    type === 'row'
      ? {
          paddingTop: GRID_LAYOUT_RESIZER_GAP,
          paddingBottom: GRID_LAYOUT_RESIZER_GAP,
          height: GRID_LAYOUT_RESIZER_THICKNESS,
          cursor: 'ns-resize',
        }
      : {
          paddingLeft: GRID_LAYOUT_RESIZER_GAP,
          paddingRight: GRID_LAYOUT_RESIZER_GAP,
          width: GRID_LAYOUT_RESIZER_THICKNESS,
          cursor: 'ew-resize',
        }}
`

GridLayoutResizer.defaultProps = {
  role: 'separator',
}

export { GridLayoutResizer }
