import { FC } from 'react'
import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

import { OrderSide, OrderType } from '../../../core-api'
import { CurrencyPair, CurrencyPairLink } from '../../../core-shared'

interface OrderSubtitleMessageProps {
  variant: 'link' | 'text'
  side: OrderSide
  orderType: OrderType
  currencyPair: string
}

const orderSubtitleMessages = defineMessages({
  [OrderType.Market + OrderSide.Buy]: {
    id: 'activity.order.details.marketBuy.title',
    defaultMessage: 'Market Buy {currencyPair}',
  },
  [OrderType.Market + OrderSide.Sell]: {
    id: 'activity.order.details.marketSell.title',
    defaultMessage: 'Market Sell {currencyPair}',
  },
  [OrderType.Limit + OrderSide.Buy]: {
    id: 'activity.order.details.limitBuy.title',
    defaultMessage: 'Limit Buy {currencyPair}',
  },
  [OrderType.Limit + OrderSide.Sell]: {
    id: 'activity.order.details.limitSell.title',
    defaultMessage: 'Limit Sell {currencyPair}',
  },
})

export const OrderSubtitleMessage: FC<OrderSubtitleMessageProps> = ({
  variant,
  orderType,
  side,
  currencyPair,
}) => {
  const currencyPairComponent = variant === 'link' ? CurrencyPairLink : CurrencyPair

  return (
    <FormattedMessage
      {...orderSubtitleMessages[orderType + side]}
      values={{
        currencyPair: React.createElement(currencyPairComponent, {
          currencyPair,
          type: 'compact',
          variant: 'primary',
          fontWeight: 500,
          display: 'inline-flex',
        }),
      }}
    />
  )
}
