import Big from 'big.js'

import { ExtendedBalance, ValuePoint } from '../types'

export const calcTotalBalance = (
  balances: Pick<ExtendedBalance, 'value' | 'amountValue' | 'reservedValue'>[],
  previousTotal24h: ValuePoint,
) => {
  const { totalBalanceBig, availableBalanceBig, reservedBalanceBig } = balances.reduce(
    (accumulator, item) => ({
      totalBalanceBig: accumulator.totalBalanceBig.plus(item.value ?? 0),
      availableBalanceBig: accumulator.availableBalanceBig.plus(item.amountValue ?? 0),
      reservedBalanceBig: accumulator.reservedBalanceBig.plus(item.reservedValue ?? 0),
    }),
    {
      totalBalanceBig: new Big(0),
      availableBalanceBig: new Big(0),
      reservedBalanceBig: new Big(0),
    },
  )

  const previousTotalValueBig = new Big(previousTotal24h.balance)

  const totalProfitAndLoss24hBig = totalBalanceBig.minus(previousTotalValueBig)

  const totalPerformance24hBig = previousTotalValueBig.eq(0)
    ? new Big(0)
    : totalBalanceBig.div(previousTotalValueBig).minus(1)

  return {
    total: totalBalanceBig.toNumber(),
    totalProfitAndLoss24h: totalProfitAndLoss24hBig.toNumber(),
    totalPerformance24h: totalPerformance24hBig.toNumber(),
    available: availableBalanceBig.toNumber(),
    reserved: reservedBalanceBig.toNumber(),
  }
}
