import { useTheme } from '@revolut/ui-kit'
import { Suspense, lazy } from 'react'

import { getForm, submitForm } from '../../../core-api'
import { ConfigKey, getConfigValue } from '../../../core-config'

const FormWidget = lazy(() =>
  import(/* webpackChunkName: "form-widget" */ '@revolut-internal/forms-library').then(
    (module) => ({ default: module.FormWidget }),
  ),
)

const FEEDBACK_FORM_ID = getConfigValue(ConfigKey.FeedbackFormId)

const FORM_API = {
  loadFlow: getForm,
  submitFlow: submitForm,
}

type FeedbackFormProps = {
  onComplete: () => void
  onCancel: () => void
}

export const FeedbackForm = ({ onComplete, onCancel }: FeedbackFormProps) => {
  const { mode } = useTheme()

  return (
    <Suspense fallback={null}>
      <FormWidget
        // @ts-expect-error We don't need to define uploadFile, getCards, getTransaction, getTransactions for the feedback form
        api={FORM_API}
        onBackButtonClick={onCancel}
        onFlowAbort={onCancel}
        onFlowComplete={onComplete}
        flowId={FEEDBACK_FORM_ID}
        themeMode={mode}
      />
    </Suspense>
  )
}
