import {
  ChartResolution,
  ChartType,
  LocalizationKey,
  LocalizationMessages,
} from '@revolut-internal/trading-charts'
import { useMemo } from 'react'
import { defineMessages } from 'react-intl'

import { useIntl } from '../../../core-intl'

const chartTypeMessages = defineMessages<ChartType>({
  [ChartType.Area]: {
    id: 'markets.chart.chartTypeSelector.area.label',
    defaultMessage: 'Area',
  },
  [ChartType.Bar]: {
    id: 'markets.chart.chartTypeSelector.bar.label',
    defaultMessage: 'Bars',
  },
  [ChartType.Baseline]: {
    id: 'markets.chart.chartTypeSelector.baseline.label',
    defaultMessage: 'Baseline',
  },
  [ChartType.Candle]: {
    id: 'markets.chart.chartTypeSelector.candle.label',
    defaultMessage: 'Candles',
  },
  [ChartType.HeikinAshi]: {
    id: 'markets.chart.chartTypeSelector.heikinAshi.label',
    defaultMessage: 'Heikin Ashi',
  },
  [ChartType.HollowCandle]: {
    id: 'markets.chart.chartTypeSelector.hollowCandle.label',
    defaultMessage: 'Hollow Candles',
  },
  [ChartType.Line]: {
    id: 'markets.chart.chartTypeSelector.line.label',
    defaultMessage: 'Line',
  },
})

const chartResolutionMessages = defineMessages<ChartResolution>({
  [ChartResolution.FiveMinutes]: {
    id: 'markets.chart.resolutionSelector.fiveMinutes.label',
    defaultMessage: '5m',
  },
  [ChartResolution.TenMinutes]: {
    id: 'markets.chart.resolutionSelector.tenMinutes.label',
    defaultMessage: '10m',
  },
  [ChartResolution.FifteenMinutes]: {
    id: 'markets.chart.resolutionSelector.fifteenMinutes.label',
    defaultMessage: '15m',
  },
  [ChartResolution.ThirtyMinutes]: {
    id: 'markets.chart.resolutionSelector.thirtyMinutes.label',
    defaultMessage: '30m',
  },
  [ChartResolution.OneHour]: {
    id: 'markets.chart.resolutionSelector.oneHour.label',
    defaultMessage: '1H',
  },
  [ChartResolution.FourHours]: {
    id: 'markets.chart.resolutionSelector.fourHours.label',
    defaultMessage: '4H',
  },
  [ChartResolution.OneDay]: {
    id: 'markets.chart.resolutionSelector.oneDay.label',
    defaultMessage: '1D',
  },
  [ChartResolution.TwoDays]: {
    id: 'markets.chart.resolutionSelector.twoDays.label',
    defaultMessage: '2D',
  },
  [ChartResolution.FourDays]: {
    id: 'markets.chart.resolutionSelector.fourDays.label',
    defaultMessage: '4D',
  },
  [ChartResolution.OneWeek]: {
    id: 'markets.chart.resolutionSelector.oneWeek.label',
    defaultMessage: '1W',
  },
  [ChartResolution.FiveWeeks]: {
    id: 'markets.chart.resolutionSelector.fiveWeeks.label',
    defaultMessage: '5W',
  },
})

export const useLocalizationMessages = (): LocalizationMessages => {
  const { formatMessage, formatEnumMessage } = useIntl()

  return useMemo(
    () => ({
      [LocalizationKey.IndicatorTitle]: formatMessage({
        id: 'markets.chart.indicator.title',
        defaultMessage: 'Indicator',
      }),
      [LocalizationKey.ChartTypeSelectorLabel]: (chartType: ChartType) => {
        return formatEnumMessage(chartType, chartTypeMessages)
      },
      [LocalizationKey.ResolutionSelectorLabel]: (chartResolution: ChartResolution) => {
        return formatEnumMessage(chartResolution, chartResolutionMessages)
      },
    }),
    [formatMessage, formatEnumMessage],
  )
}
