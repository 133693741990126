import { HStack, Icon, Text, Token } from '@revolut/ui-kit'
import { FC, useMemo } from 'react'

import { useMoneyFormat } from '../../../core-ui'
import { checkRequired } from '../../../core-utils'
import { OrderBookSteps } from '../../hooks'

interface OrderBookStepCounterProps extends OrderBookSteps {}

export const OrderBookStepCounter: FC<OrderBookStepCounterProps> = ({
  steps,
  currentStep,
  isFirstStep,
  isLastStep,
  quoteStepScale,
  incrementStep,
  decrementStep,
}) => {
  const formatStep = useMoneyFormat({ maximumFractionDigits: quoteStepScale })

  const stepMinWidth = useMemo(
    () => checkRequired(steps[0], 'Step should be defined').toString().length * 10,
    [steps],
  )

  return (
    <HStack align="center" style={{ flexShrink: 0 }}>
      <Icon
        name="16/MinusCircle"
        size={16}
        color={Token.color.accent}
        onClick={decrementStep}
        css={{ cursor: isFirstStep ? 'default' : 'pointer' }}
      />

      <Text color="blue" variant="h6" minWidth={stepMinWidth} textAlign="center">
        {formatStep(checkRequired(currentStep, 'Step should be defined'))}
      </Text>

      <Icon
        name="16/PlusCircle"
        size={16}
        color={Token.color.accent}
        onClick={incrementStep}
        css={{ cursor: isLastStep ? 'default' : 'pointer' }}
      />
    </HStack>
  )
}
