import { Money } from '@revolut/ui-kit'
import Big from 'big.js'
import { IntlShape } from 'react-intl'

import {
  OrderBookExpandedResponse,
  OrderType,
  CurrencyPair,
  OrderSide,
  Currency,
} from '../../../core-api'
import { cropDecimalPart } from '../../../core-utils'
import { calcOrderAmountByOrderBook, calcOrderQuantityByOrderBook } from '../../utils'
import { ExchangeOrderState, OrderValueType } from './types'

const roundQuantity = (quantity: number, currencyPair: CurrencyPair) => {
  const bigQuantity = Big(quantity)

  return bigQuantity
    .div(currencyPair.baseLotSize)
    .round(0, 0)
    .mul(currencyPair.baseLotSize)
    .toNumber()
}

const calcOrderQuantity = (
  state: ExchangeOrderState,
  orderBook: OrderBookExpandedResponse | undefined,
) => {
  let quantity = state.quantity

  if (state.amount !== null && state.valueType === OrderValueType.Amount) {
    if (state.type === OrderType.Limit && state.price !== null && state.price !== 0) {
      const amountBig = new Big(state.amount)
      const priceBig = new Big(state.price)

      quantity = amountBig.div(priceBig).toNumber()
    }

    if (state.type === OrderType.Market && orderBook) {
      quantity = calcOrderQuantityByOrderBook({
        amount: state.amount,
        side: state.side,
        orderBook,
      })
    }
  }

  return quantity
}

const calcOrderAmount = (
  state: ExchangeOrderState,
  orderBook: OrderBookExpandedResponse | undefined,
) => {
  let amount = state.amount

  if (state.quantity !== null && state.valueType === OrderValueType.Quantity) {
    if (state.type === OrderType.Limit && state.price !== null) {
      const quantityBig = new Big(state.quantity)
      const priceBig = new Big(state.price)

      amount = quantityBig.mul(priceBig).toNumber()
    }

    if (state.type === OrderType.Market && orderBook) {
      amount = calcOrderAmountByOrderBook({
        quantity: state.quantity,
        side: state.side,
        orderBook,
      })
    }
  }

  return amount
}

export const fillCalculatedFields = (
  state: ExchangeOrderState,
  orderBook: OrderBookExpandedResponse | undefined,
  quoteCurrency: Currency,
  currencyPair: CurrencyPair,
): ExchangeOrderState => {
  let quantity = calcOrderQuantity(state, orderBook)
  let amount = calcOrderAmount(state, orderBook)

  let roundedQuantity: number | null = null
  let roundedAmount: number | null = null

  if (quantity !== null) {
    roundedQuantity = roundQuantity(quantity, currencyPair)
  }

  if (roundedQuantity !== null) {
    roundedQuantity = cropDecimalPart(roundedQuantity, currencyPair.baseLotSizeScale)

    if (state.type === OrderType.Limit && state.price !== null) {
      roundedAmount = new Big(roundedQuantity).mul(state.price).toNumber()
    }

    if (state.type === OrderType.Market && orderBook) {
      roundedAmount = calcOrderAmountByOrderBook({
        quantity: roundedQuantity,
        side: state.side,
        orderBook,
      })
    }
  }

  if (roundedAmount !== null) {
    roundedAmount = cropDecimalPart(roundedAmount, quoteCurrency.scale)
  }

  if (state.amount !== null || state.quantity !== null) {
    let estimatedPrice: number | null = null

    if (amount !== null && quantity !== null) {
      if (state.type === OrderType.Market) {
        estimatedPrice =
          quantity === 0
            ? 0
            : cropDecimalPart(amount / quantity, currencyPair.quoteStepScale)
      }

      quantity = cropDecimalPart(quantity, currencyPair.baseLotSizeScale)
      amount = cropDecimalPart(amount, quoteCurrency.scale)
    }

    return {
      ...state,
      quantity,
      roundedQuantity,
      amount,
      roundedAmount,
      estimatedPrice,
    }
  }

  return {
    ...state,
    quantity,
    amount,
  }
}

export const getErrorMessages = (params: {
  state: ExchangeOrderState
  baseCurrencyFreeBalanceInMajorUnits: number
  quoteCurrencyFreeBalanceInMajorUnits: number
  currencyPair: CurrencyPair
  formatMessage: IntlShape['formatMessage']
  formatMoney: (value: number | Money) => string
}) => {
  const {
    state,
    baseCurrencyFreeBalanceInMajorUnits,
    quoteCurrencyFreeBalanceInMajorUnits,
    currencyPair,
    formatMessage,
    formatMoney,
  } = params

  let baseCurrencyErrorMessage: string | null = null
  let baseCurrencyErrorTooltipMessage: string | null = null
  let quoteCurrencyErrorMessage: string | null = null

  if (
    state.side === OrderSide.Buy &&
    state.amount !== null &&
    state.amount > quoteCurrencyFreeBalanceInMajorUnits
  ) {
    quoteCurrencyErrorMessage =
      state.valueType === OrderValueType.Amount
        ? formatMessage({
            id: 'trading.orderForm.errors.exceedsBalance.title',
            defaultMessage: 'exceeds balance',
          })
        : formatMessage(
            {
              id: 'trading.orderForm.errors.exceedsCurrencyBalance.title',
              defaultMessage: 'exceeds {currency} balance',
            },
            {
              currency: currencyPair.quoteCurrency,
            },
          )
  }

  if (
    state.side === OrderSide.Sell &&
    state.quantity !== null &&
    state.quantity > baseCurrencyFreeBalanceInMajorUnits
  ) {
    baseCurrencyErrorMessage =
      state.valueType === OrderValueType.Quantity
        ? formatMessage({
            id: 'trading.orderForm.errors.exceedsBalance.title',
            defaultMessage: 'exceeds balance',
          })
        : formatMessage(
            {
              id: 'trading.orderForm.errors.exceedsCurrencyBalance.title',
              defaultMessage: 'exceeds {currency} balance',
            },
            {
              currency: currencyPair.baseCurrency,
            },
          )
  }

  if (
    currencyPair !== undefined &&
    state.quantity !== null &&
    state.quantity < currencyPair.minTradeSize
  ) {
    baseCurrencyErrorMessage = formatMessage({
      id: 'trading.orderForm.errors.belowMinimum.title',
      defaultMessage: 'below minimum',
    })

    baseCurrencyErrorTooltipMessage = formatMessage(
      {
        id: 'trading.orderForm.errors.tooltip.belowMinimum.title',
        defaultMessage: 'The minimum order is {amount} or equivalent',
      },
      {
        amount: formatMoney({
          amount: currencyPair.minTradeSize,
          currency: currencyPair.baseCurrency,
        }),
      },
    )
  }

  if (
    currencyPair !== undefined &&
    state.quantity !== null &&
    state.quantity > currencyPair.maxTradeSize
  ) {
    baseCurrencyErrorMessage = formatMessage({
      id: 'trading.orderForm.errors.exceedsLimit.title',
      defaultMessage: 'exceeds limit',
    })

    baseCurrencyErrorTooltipMessage = formatMessage(
      {
        id: 'trading.orderForm.errors.tooltip.exceedsLimit.title',
        defaultMessage: 'The maximum order is {amount} or equivalent',
      },
      {
        amount: formatMoney({
          amount: currencyPair.maxTradeSize,
          currency: currencyPair.baseCurrency,
        }),
      },
    )
  }

  return {
    baseCurrencyErrorMessage,
    baseCurrencyErrorTooltipMessage,
    quoteCurrencyErrorMessage,
  }
}

export const getValueMessages = (params: {
  state: ExchangeOrderState
  formatMessage: IntlShape['formatMessage']
}) => {
  const { state, formatMessage } = params

  let baseCurrencyValueMessage: string | null = null
  let quoteCurrencyValueMessage: string | null = null

  if (
    state.type === OrderType.Market &&
    state.amount !== null &&
    state.quantity !== null
  ) {
    if (state.valueType === OrderValueType.Amount) {
      baseCurrencyValueMessage = formatMessage({
        id: 'trading.orderForm.messages.estimatedValue.title',
        defaultMessage: 'estimated value',
      })
    } else {
      quoteCurrencyValueMessage = formatMessage({
        id: 'trading.orderForm.messages.estimatedValue.title',
        defaultMessage: 'estimated value',
      })
    }
  }

  return {
    baseCurrencyValueMessage,
    quoteCurrencyValueMessage,
  }
}
