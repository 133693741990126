import { generateCodeChallenge } from './generateCodeChallenge'

export enum PromptType {
  Login = 'login',
  Consent = 'consent',
  Silent = 'silent',
  SelectAccount = 'select_account',
}

export enum FlowType {
  SignIn = 'signin',
  SignInOnly = 'signin_only',
  SignUp = 'signup',
  SignUpOnly = 'signup_only',
  ChangePasscode = 'change_passcode',
}

export type GenerateSsoUrlParams = {
  clientId: string
  url: string
  codeVerifier: string
  redirectUri: string
  flow: FlowType
  prompt: PromptType
  state?: string
  acrValues?: string
  uiColorScheme?: 'light' | 'dark'
}

export const generateSsoUrl = (params: GenerateSsoUrlParams) => {
  const {
    clientId,
    url,
    codeVerifier,
    redirectUri,
    flow,
    prompt,
    state,
    acrValues,
    uiColorScheme,
  } = params

  const codeChallenge = generateCodeChallenge(codeVerifier)

  const searchParams = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'code',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    flow,
    prompt,
  })

  if (state !== undefined) {
    searchParams.append('state', state)
  }

  if (acrValues !== undefined) {
    searchParams.append('acr_values', acrValues)
  }

  if (uiColorScheme !== undefined) {
    searchParams.append('ui_color_scheme', uiColorScheme)
  }

  return `${url}/signin?${searchParams.toString()}`
}
