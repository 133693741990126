import { Ticker, useTicker } from '../../../core-api'

export type TickerPriceType = 'mid' | 'ask' | 'bid' | 'low24h' | 'high24h' | 'change24h'

const calcTickerPrice = (ticker: Ticker, type: TickerPriceType) => {
  switch (type) {
    case 'mid': {
      if (ticker.ask && ticker.bid) {
        return (Number(ticker.ask) + Number(ticker.bid)) / 2
      }
      break
    }
    case 'ask': {
      if (ticker.ask) {
        return Number(ticker.ask)
      }
      break
    }
    case 'bid': {
      if (ticker.bid) {
        return Number(ticker.bid)
      }
      break
    }
    case 'low24h': {
      if (ticker.low24h) {
        return Number(ticker.low24h)
      }
      break
    }
    case 'high24h': {
      if (ticker.high24h) {
        return Number(ticker.high24h)
      }
      break
    }
    case 'change24h': {
      if (ticker.change24h) {
        return Number(ticker.change24h)
      }
      break
    }
  }

  return undefined
}

export const useTickerPrice = (currencyPair: string, type: TickerPriceType = 'mid') => {
  const { data: ticker, status, refetch } = useTicker(currencyPair)

  return { data: ticker ? calcTickerPrice(ticker, type) : undefined, status, refetch }
}
