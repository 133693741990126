import { CurrencyAssetType, DEFAULT_CURRENCY } from '../../../core-api'
import { DepositFlowState, DepositFlowStep } from './types'

export const INITIAL_STATE: DepositFlowState = {
  step: DepositFlowStep.CurrencySelection,
  assetType: CurrencyAssetType.Fiat,
  currency: DEFAULT_CURRENCY,
  pocket: undefined,
  amount: null,
  sizeRatio: null,
}
