import { Table, TableCellProps, Text, Token } from '@revolut/ui-kit'
import { FC } from 'react'

import { formatPercents } from '../../../core-utils'

interface PercentsTableCellProps extends TableCellProps {
  value: number
}

export const PercentsTableCell: FC<PercentsTableCellProps> = ({ value, ...rest }) => {
  const color = value > 0 ? Token.color.orange : Token.color.foreground

  return (
    <Table.Cell {...rest}>
      <Text color={color}>{formatPercents(value, { format: 'rounded' })}</Text>
    </Table.Cell>
  )
}
