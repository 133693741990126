import axios from 'axios'

import { AnalyticsResponse, ProfitAndLossPoint, AnalyticsParams } from '../types'

export const getAnalyticsProfitAndLoss = async ({ timeSpan }: AnalyticsParams) => {
  const { data } = await axios.get<AnalyticsResponse<ProfitAndLossPoint>>(
    `/api/crypto-exchange/trading/pnl/${timeSpan}`,
  )

  return data
}
