const DEFAULT_FRACTION = 2
const DEFAULT_TYPE = 'ratio'
const DEFAULT_VARIANT = 'detailed'

type FormatOptions = Intl.NumberFormatOptions & {
  fraction?: number
  type?: 'ratio' | 'percent'
  format?: 'rounded' | 'detailed'
}

export const formatPercents = (
  value: number,
  {
    fraction = DEFAULT_FRACTION,
    type = DEFAULT_TYPE,
    format = DEFAULT_VARIANT,
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
  }: FormatOptions = {},
) => {
  if (type === 'percent') {
    value /= 100
  }

  if (format === 'rounded' || value >= 1) {
    return value.toLocaleString('en-GB', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      signDisplay,
    })
  }

  return value.toLocaleString('en-GB', {
    style: 'percent',
    minimumFractionDigits: minimumFractionDigits ?? fraction,
    maximumFractionDigits: maximumFractionDigits ?? fraction,
    signDisplay,
  })
}
