import { ExperimentName, isExperimentEnabled } from '../core-api'
import { Feature, Features } from './types'

export const FEATURES: Features = {
  [Feature.Exchange]: ({ experiments }) => {
    return isExperimentEnabled(experiments, ExperimentName.WebExchange)
  },
  [Feature.MobileDevices]: ({ experiments }) => {
    return isExperimentEnabled(experiments, ExperimentName.WebExchangeMobileDevices)
  },
  [Feature.NextPortfolio]: ({ experiments }) => {
    return isExperimentEnabled(experiments, ExperimentName.WebExchangeNextPortfolio)
  },
  [Feature.AmountHero]: ({ experiments }) => {
    return isExperimentEnabled(experiments, ExperimentName.WebExchangeAmountHero)
  },
  [Feature.WebSockets]: ({ experiments }) => {
    return isExperimentEnabled(experiments, ExperimentName.WebExchangeWebSockets)
  },
  [Feature.HideRealisedPnL]: ({ experiments }) => {
    return isExperimentEnabled(experiments, ExperimentName.WebExchangeHideRealisedPnL)
  },
  [Feature.LimitOrderPostOnly]: ({ experiments }) => {
    return isExperimentEnabled(experiments, ExperimentName.WebExchangeLimitOrderPostOnly)
  },
}
