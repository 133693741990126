import { useMemo } from 'react'

import { UserRestrictionKey, findUserRestriction } from '../../utils'
import { useUserRestrictionsRequired } from '../useUserRestrictionsRequired'

export const useUserRestrictionEnabled = (restrictionKey: UserRestrictionKey) => {
  const restrictions = useUserRestrictionsRequired()

  const restriction = useMemo(
    () => findUserRestriction(restrictionKey, restrictions),
    [restrictions, restrictionKey],
  )

  return {
    enabled: restriction !== undefined,
    restriction,
  }
}
