import Big from 'big.js'
import { orderBy } from 'lodash'

import { Ask, Bid, OrderBookExpandedResponse } from '../../core-api'

export type FormattedOrderBookItem = {
  price: number
  amount: number
  total: number
}

type FormattedOrderBookItemBig = {
  price: number
  amount: Big
  total: Big
}

const formatOrders = (
  orders: (Ask | Bid)[],
  priceStep: number,
  type: 'ask' | 'bid',
): FormattedOrderBookItem[] => {
  const aggregatedOrders: Record<number, FormattedOrderBookItemBig> = {}

  for (const order of orders) {
    const priceBig = Big(order.price)

    const formattedPrice = priceBig
      .div(priceStep)
      .round(0, type === 'ask' ? 3 : 0)
      .mul(priceStep)
      .toNumber()

    const aggregatedOrder = aggregatedOrders[formattedPrice] ?? {
      price: formattedPrice,
      amount: Big(0),
      total: Big(0),
    }

    aggregatedOrder.amount = aggregatedOrder.amount.plus(order.amount)
    aggregatedOrder.total = aggregatedOrder.total.plus(priceBig.mul(order.amount))

    aggregatedOrders[formattedPrice] = aggregatedOrder
  }

  const formattedOrders: FormattedOrderBookItem[] = Object.values(aggregatedOrders).map(
    (order) => ({
      price: order.price,
      amount: order.amount.toNumber(),
      total: order.total.toNumber(),
    }),
  )

  return orderBy(formattedOrders, 'price', 'desc')
}

export const formatOrderBook = (
  orderBook: Pick<OrderBookExpandedResponse, 'asks' | 'bids'>,
  priceStep: number,
) => ({
  asks: formatOrders(orderBook.asks, priceStep, 'ask'),
  bids: formatOrders(orderBook.bids, priceStep, 'bid'),
})
