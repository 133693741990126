import {
  ActionButton,
  Calendar,
  Dropdown,
  HStack,
  Table,
  TableData,
  TableFilterProps,
} from '@revolut/ui-kit'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { DateRange, toUTCDateRange } from '../../utils'

export interface TableOptionFilterProps<T extends TableData> {
  column: TableFilterProps<T>['column']
  label?: string
}

export const TableFilterDateRange = <T extends TableData>({
  column,
  label,
  ...rest
}: TableOptionFilterProps<T>) => {
  const [value, setValue] = useState<DateRange | null>(column.filterValue)

  const handleClear = useCallback(
    (onClose?: () => void) => {
      setValue(null)

      column.setFilter?.(null)

      onClose?.()
    },
    [column],
  )

  const handleUpdate = useCallback(
    (nextValue: DateRange | null) => {
      setValue(nextValue)
    },
    [setValue],
  )

  const handleApply = useCallback(
    (onClose?: () => void) => {
      column.setFilter?.(value === null ? null : toUTCDateRange(value))

      onClose?.()
    },
    [column, value],
  )

  return (
    <Table.Filter column={column} aria-haspopup="dialog" {...rest}>
      {(filter) => {
        const filterProps = filter.getDropdownProps({ closeOnEsc: true, maxHeight: null })

        return (
          <Dropdown {...filterProps}>
            <Calendar
              variant="range"
              value={value}
              onChange={handleUpdate}
              disabledDays={{ after: new Date() }}
              labelButtonClear={
                <FormattedMessage id="actions.reset" defaultMessage="Reset" />
              }
              labelEmptyValue={
                <FormattedMessage id="labels.selectRange" defaultMessage="Select range" />
              }
              transparentBg
            />

            <Dropdown.Group sticky="bottom" p="s-16">
              <HStack space="s-16">
                <ActionButton
                  width="100%"
                  onClick={() => handleClear(filterProps.onClose)}
                >
                  <FormattedMessage id="actions.clear" defaultMessage="Clear" />
                </ActionButton>
                <ActionButton
                  variant="accent"
                  width="100%"
                  onClick={() => handleApply(filterProps.onClose)}
                >
                  <FormattedMessage id="actions.apply" defaultMessage="Apply" />
                </ActionButton>
              </HStack>
            </Dropdown.Group>
          </Dropdown>
        )
      }}
    </Table.Filter>
  )
}
