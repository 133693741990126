import { EntityRegion } from '../types'

export const RBUAB_REGIONS: EntityRegion[] = [
  'LT',
  'HU',
  'NL',
  'RO',
  'DE',
  'PT',
  'ES',
  'IE',
  'PL',
  'BE',
  'FR',
  'IT',
]
