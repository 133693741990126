import { Header, VStack, Action, Spacer, Text, Image, Button } from '@revolut/ui-kit'
import { isUndefined } from 'lodash'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useIntl } from '../../../core-intl'
import { Asset3dImageName, getImageAssetSrcProps } from '../../../core-utils'

interface OnboardingPopupCryptoOnboardingContentMobileProps {
  webDeeplink?: string
  refetch: VoidFunction
}

export const OnboardingPopupCryptoOnboardingContentMobile: FC<
  OnboardingPopupCryptoOnboardingContentMobileProps
> = ({ webDeeplink, refetch }) => {
  const { formatMessage } = useIntl()

  const isWebDeeplinkDefined = !isUndefined(webDeeplink)

  return (
    <VStack height="100%">
      <Header variant="item">
        <Header.CloseButton
          aria-label={formatMessage({
            id: 'labels.close',
            defaultMessage: 'Close',
          })}
        />
        <Header.Title>
          <FormattedMessage
            id="onboarding.cryptoOnboarding.title"
            defaultMessage="Action required to enable trading"
          />
        </Header.Title>
        <Header.Subtitle>
          <FormattedMessage
            id="onboarding.cryptoOnboardingMobile.subtitle"
            defaultMessage="We need some information before you can start trading. Continue in Revolut app"
          />
        </Header.Subtitle>
      </Header>

      {isWebDeeplinkDefined ? (
        <VStack align="center" height="100%">
          <Spacer />

          <Image size={250} {...getImageAssetSrcProps(Asset3dImageName.Revolut)} />

          <Spacer />

          <Button use="a" href={webDeeplink} target="_blank">
            {formatMessage({
              defaultMessage: 'Open Revolut App',
              id: 'actions.openRevolutApp',
            })}
          </Button>
        </VStack>
      ) : (
        <VStack space="s-16" align="center" height="100%">
          <Spacer />

          <Text variant="tile" textAlign="center">
            <FormattedMessage
              id="errors.general.title"
              defaultMessage="Something went wrong"
            />
          </Text>

          <Action onClick={refetch}>
            <FormattedMessage id="actions.tryAgain" defaultMessage="Try again" />
          </Action>

          <Spacer />
        </VStack>
      )}
    </VStack>
  )
}
