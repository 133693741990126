import {
  Box,
  FilterButton,
  HStack,
  Icon,
  RadioSelect,
  Spacer,
  Text,
  Token,
  useResizeObserver,
  useToggle,
} from '@revolut/ui-kit'
import { FC, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { OrderType } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { OrderTypeMessage } from '../../../feature-activity'

const StyledFilterButton = styled(FilterButton)`
  & [class*='ButtonLayout__IconWrapper'] {
    margin-left: auto;
  }
`

const TYPE_ORDER = [OrderType.Market, OrderType.Limit]

type OrderTypeDropdownProps = {
  type: OrderType
  onChangeType: (type: OrderType) => void
  disabled?: boolean
}

export const OrderTypeDropdown: FC<OrderTypeDropdownProps> = ({
  type,
  onChangeType,
  disabled,
}) => {
  const { formatMessage } = useIntl()

  const [open, toggleOpen] = useToggle()

  const anchorRef = useRef<HTMLButtonElement>(null)

  const size = useResizeObserver(anchorRef)

  const options = useMemo(
    () =>
      TYPE_ORDER.map((item) => ({
        key: item,
        value: item,
        label: <OrderTypeMessage type={item} />,
      })),
    [],
  )

  return (
    <Box flex={1}>
      <StyledFilterButton
        ref={anchorRef}
        onClick={() => toggleOpen(true)}
        disabled={disabled}
        width="100%"
        useEndIcon="16/ChevronDownSmall"
        aria-label={formatMessage({
          id: 'labels.orderType',
          defaultMessage: 'Order type',
        })}
      >
        <OrderTypeMessage type={type} />
      </StyledFilterButton>

      <RadioSelect
        positionStrategy="fixed"
        anchorRef={anchorRef}
        open={open}
        onClose={() => toggleOpen(false)}
        options={options}
        value={type}
        onChange={(value) => value && onChangeType(value)}
        labelList={formatMessage({
          id: 'labels.orderTypes',
          defaultMessage: 'Order types',
        })}
        indicatorStyle="highlight"
        minWidth={size.width}
        autoClose
      >
        {(option) => (
          <HStack>
            <Text>{option.label}</Text>
            <Spacer />
            {option.value === type && <Icon name="Check" color={Token.color.accent} />}
          </HStack>
        )}
      </RadioSelect>
    </Box>
  )
}
