import { useSsoSignInEffect } from '../../../../packlets/core-auth'
import { GlobalSpinner } from '../../../../packlets/core-ui'
import {
  RoutePath,
  getExternalRouteUrl,
  getRouteUrl,
} from '../../../../packlets/core-utils'

export const SignInPage = () => {
  useSsoSignInEffect({
    redirectUrl: getExternalRouteUrl({ path: RoutePath.AuthSignIn }),
    successRedirectUrl: getRouteUrl({ path: RoutePath.Root }),
    errorRedirectUrl: getRouteUrl({ path: RoutePath.AuthSignInError }),
  })

  return <GlobalSpinner />
}
