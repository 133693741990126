import { Header, Popup, PopupProps } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useIntl } from '../../../core-intl'
import {
  ExchangeBreakdownDetails,
  ExchangeBreakdownDetailsProps,
} from '../ExchangeBreakdownDetails'

type ExchangeBreakdownPopupProps = ExchangeBreakdownDetailsProps &
  Pick<PopupProps, 'open' | 'onClose'>

export const ExchangeBreakdownPopup: FC<ExchangeBreakdownPopupProps> = ({
  toCurrency,
  fromCurrency,
  amount,
  quotes,
  ...rest
}) => {
  const { formatMessage } = useIntl()

  return (
    <Popup {...rest} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.CloseButton
          aria-label={formatMessage({
            id: 'labels.close',
            defaultMessage: 'Close',
          })}
        />
        <Header.Title>
          <FormattedMessage
            id="portfolio.exchangeBreakdown.popup.title"
            defaultMessage="Exchange breakdown"
          />
        </Header.Title>
      </Header>

      <ExchangeBreakdownDetails
        toCurrency={toCurrency}
        fromCurrency={fromCurrency}
        amount={amount}
        quotes={quotes}
      />
    </Popup>
  )
}
