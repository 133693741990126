import {
  Color,
  IconButton as UIIconButton,
  IconButtonProps as UIIconButtonProps,
} from '@revolut/ui-kit'
import styled from 'styled-components'

interface IconButtonProps extends UIIconButtonProps {
  hoverColor?: Color
}

export const IconButton = styled(UIIconButton)<IconButtonProps>`
  &:hover,
  &:focus {
    ${({ hoverColor }) => (hoverColor ? { color: hoverColor } : {})}
  }
`
