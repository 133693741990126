import { AvatarProps, TextProps, HStack, Text, HStackProps } from '@revolut/ui-kit'
import { FC } from 'react'

import { AssetAvatar } from '../AssetAvatar'
import { CurrencyName } from '../CurrencyName'

export interface CurrencyProps extends HStackProps {
  currency: string
  type?: 'default' | 'compact'
  size?: AvatarProps['size']
  variant?: TextProps['variant']
  fontWeight?: TextProps['fontWeight']
}

export const Currency: FC<CurrencyProps> = ({
  currency,
  fontWeight,
  type = 'default',
  variant = 'caption',
  size = 40,
  ...rest
}) => {
  const currencyName = (
    <Text variant={variant} fontWeight={fontWeight}>
      <CurrencyName currency={currency} />
    </Text>
  )

  return (
    <HStack align="center" space="s-8" {...rest}>
      {type === 'default' && <AssetAvatar variant="brand" asset={currency} size={size} />}

      {currencyName}
    </HStack>
  )
}
