import { HStack, DataPoint, Spacer, DataPointSkeleton, Token } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { DEFAULT_CURRENCY, useTotalBalance } from '../../../core-api'
import { WelcomeJourneyTarget } from '../../../core-shared'
import { Money } from '../../../core-ui'
import { useWelcomeJourneyContext } from '../../../core-welcome-journey'
import { DepositButton } from '../DepositButton'
import { WithdrawButton } from '../WithdrawButton'

export const BalancesTableInfo: FC = () => {
  const { total, available, reserved, status } = useTotalBalance()
  const { currentTip } = useWelcomeJourneyContext()

  return (
    <HStack align="start" width="100%">
      <HStack display={{ all: 'none', md: 'flex' }}>
        {status === 'success' ? (
          <>
            <DataPoint px="s-16" py="s-8">
              <DataPoint.Value use="h5">
                <Money type="money" currency={DEFAULT_CURRENCY} amount={total} />
              </DataPoint.Value>
              <DataPoint.Label>
                <FormattedMessage
                  id="portfolio.balances.table.total.title"
                  defaultMessage="Total"
                />
              </DataPoint.Label>
            </DataPoint>
            <DataPoint px="s-16" py="s-8">
              <DataPoint.Value use="h5">
                <Money type="money" currency={DEFAULT_CURRENCY} amount={available} />
              </DataPoint.Value>
              <DataPoint.Label>
                <FormattedMessage
                  id="portfolio.balances.table.available.title"
                  defaultMessage="Available"
                />
              </DataPoint.Label>
            </DataPoint>
            <DataPoint px="s-16" py="s-8">
              <DataPoint.Value use="h5">
                <Money type="money" currency={DEFAULT_CURRENCY} amount={reserved} />
              </DataPoint.Value>
              <DataPoint.Label>
                <FormattedMessage
                  id="portfolio.balances.table.openOrders.title"
                  defaultMessage="Open orders"
                />
              </DataPoint.Label>
            </DataPoint>
          </>
        ) : (
          <>
            <DataPointSkeleton px="s-16" py="s-8" />
            <DataPointSkeleton px="s-16" py="s-8" />
            <DataPointSkeleton px="s-16" py="s-8" />
          </>
        )}
      </HStack>

      <Spacer />

      <HStack
        space="s-8"
        className={WelcomeJourneyTarget.DepositAndWithdraw}
        backgroundColor={
          currentTip?.target === `.${WelcomeJourneyTarget.DepositAndWithdraw}`
            ? Token.color.modalOverlay
            : Token.color.widgetBackground
        }
        borderRadius={Token.radius.round}
      >
        <DepositButton />
        <WithdrawButton />
      </HStack>
    </HStack>
  )
}
