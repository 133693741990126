import { format } from 'date-fns'
import { useEffect, useMemo } from 'react'

import { LocalStorageKey, useLocalStorage } from '../../core-storage'

export const useKeepPreviousSessionDate = () => {
  const [dates, setDates] = useLocalStorage<string[]>(
    LocalStorageKey.FeedbackLastSessionsDates,
    [],
  )

  const today = useMemo(() => format(new Date(), 'yyyy-MM-dd'), [])

  useEffect(() => {
    if (dates.includes(today)) {
      return
    }

    setDates((old) => {
      return [...old.slice(-1), today]
    })
  }, [dates, today, setDates])

  return dates.find((x) => x !== today)
}
