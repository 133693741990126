import { FormattedOrderBookItem } from '../../feature-markets'
import { AccumulatedOrderBookItem } from '../types'

export const getAccumulatedBids = (bids: FormattedOrderBookItem[]) => {
  return bids
    .sort((a, b) => a.price - b.price)
    .reduceRight((acc: AccumulatedOrderBookItem[], current: FormattedOrderBookItem) => {
      const accumulatedAmount = current.amount + (acc[0]?.accumulatedAmount ?? 0)

      acc.unshift({ ...current, accumulatedAmount })

      return acc
    }, [])
}
