import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { deposit } from '../../api'
import { QueryKey, REFETCH_INTERVAL } from '../../constants'
import { DepositData, DepositErrorResponse, DepositResponse } from '../../types'

export const useDeposit = (
  options: UseMutationOptions<
    DepositResponse,
    AxiosError<DepositErrorResponse, DepositData>,
    DepositData
  > = {},
) => {
  const queryClient = useQueryClient()

  return useMutation<
    DepositResponse,
    AxiosError<DepositErrorResponse, DepositData>,
    DepositData
  >(deposit, {
    ...options,
    onSuccess: (...args) => {
      // TODO: Temporary solution until we have a proper WS implementation https://revolut.atlassian.net/browse/PWCE-707
      setTimeout(() => {
        queryClient.invalidateQueries([QueryKey.Balances])
        queryClient.invalidateQueries([QueryKey.Wallet])
        queryClient.invalidateQueries([QueryKey.Transfers])
      }, REFETCH_INTERVAL)

      options.onSuccess?.(...args)
    },
  })
}
