export enum WidgetType {
  Activity = 'ACTIVITY',
  PriceChart = 'PRICE_CHART',
  OrderBook = 'ORDER_BOOK',
  OrderForm = 'ORDER_FORM',
}

export enum ChartType {
  Price = 'Price',
  Depth = 'Depth',
}
