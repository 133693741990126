import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { closeBanner } from '../../api'
import { QueryKey } from '../../constants'
import { BannersResponse } from '../../types'

export const useCloseBanner = (
  options: UseMutationOptions<void, AxiosError, string> = {},
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: closeBanner,
    onMutate: async (id: string) => {
      await queryClient.cancelQueries({ queryKey: [QueryKey.Banners] })

      const previousBanners = queryClient.getQueryData([QueryKey.Banners])

      queryClient.setQueryData(
        [QueryKey.Banners],
        (prevBanners: BannersResponse | undefined) =>
          prevBanners?.filter((item) => item.id !== id),
      )

      return { previousBanners }
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKey.Banners])
    },
  })
}
