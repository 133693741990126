import { RootProvider } from '@revolut/ui-kit'
import { FC, PropsWithChildren } from 'react'

import { EventTracker } from '../../../packlets/core-event-tracker'
import { useIntlContext } from '../../../packlets/core-intl'
import { DisableTransitions, useThemeMode } from '../../../packlets/core-ui'
import { topNav } from './TopNav'

const DISABLE_TRANSITIONS_TIME_MS = 1000

const getUIKitLocale = (locale: string) => {
  // In Lokalise we use 'en' locale for the 'en-GB' locale, so we need to map it to have correct locale in UI Kit
  if (locale === 'en') {
    return 'en-GB'
  }

  return locale.replace('_', '-')
}

export const UIKitProvider: FC<PropsWithChildren> = ({ children }) => {
  const { themeMode } = useThemeMode()

  const { locale } = useIntlContext()

  return (
    <>
      <DisableTransitions time={DISABLE_TRANSITIONS_TIME_MS} disableKey={themeMode} />

      <RootProvider mode={themeMode} topNav={topNav} locale={getUIKitLocale(locale)}>
        {children}
        <EventTracker />
      </RootProvider>
    </>
  )
}
