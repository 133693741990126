import { Color, HStack, Text } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

export const OrderBookEmptyCaption = () => (
  <HStack
    mt="s-4"
    display="table-caption"
    style={{ captionSide: 'bottom', textAlign: 'center' }}
  >
    <Text variant="caption" color={Color.GREY_50}>
      <FormattedMessage
        id="markets.orderBook.empty.title"
        defaultMessage="No open orders"
      />
    </Text>
  </HStack>
)
