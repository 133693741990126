import { FC } from 'react'

import { RoutePath, getRouteUrl } from '../../../core-utils'
import { useHumanizeCurrencyPair } from '../../hooks'
import { CurrencyPair, CurrencyPairProps } from '../CurrencyPair/CurrencyPair'
import { Link } from './styled'

interface CurrencyPairLinkProps extends CurrencyPairProps {}

export const CurrencyPairLink: FC<CurrencyPairLinkProps> = ({
  currencyPair,
  ...rest
}) => {
  const { humanizeCurrencyPair } = useHumanizeCurrencyPair()

  const to = getRouteUrl({
    path: RoutePath.Trade,
    params: {
      currencyPair: humanizeCurrencyPair(currencyPair),
    },
  })

  return (
    <CurrencyPair
      currencyPair={currencyPair}
      use={Link}
      to={to}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    />
  )
}
