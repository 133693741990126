import { add, fromUnixTime, getUnixTime, isAfter } from 'date-fns'
import { useCallback, useMemo } from 'react'

import { LocalStorageKey, useLocalStorage } from '../../core-storage'
import { PROMPT_CLOSE_TIMEOUT } from '../config'

export const usePromptClosureTimer = () => {
  const [unixTime, setUnixTime] = useLocalStorage<number | undefined>(
    LocalStorageKey.FeedbackLastClosedPrompt,
    undefined,
  )

  const onClose = useCallback(() => setUnixTime(getUnixTime(new Date())), [setUnixTime])

  const isTimedOut = useMemo(() => {
    if (!unixTime) {
      return true
    }

    return isAfter(new Date(), add(fromUnixTime(unixTime), PROMPT_CLOSE_TIMEOUT))
  }, [unixTime])

  return { onClose, isClosedRecently: !isTimedOut }
}
