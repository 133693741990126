import * as Sentry from '@sentry/react'
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { IntlProvider as ReactIntlProvider } from '@revolut-internal/event-tracker/react'

import { ConfigKey, getConfigValue } from '../../../core-config'
import { LocalStorageKey, localStorage } from '../../../core-storage'
import { IntlContextType } from '../../types'
import { getCurrentLocale, importMessages } from '../../utils'

export const IntlContext = createContext<IntlContextType | null>(null)

export const IntlProvider: FC<PropsWithChildren> = ({ children }) => {
  const [locale, setLocale] = useState(getCurrentLocale())
  const [messages, setMessages] = useState()

  useEffect(() => {
    if (!messages) {
      importMessages(locale).then(setMessages)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const handleChangeLocale = useCallback((nextLocale: string) => {
    importMessages(nextLocale).then((nextMessages) => {
      setMessages(nextMessages)

      setLocale(nextLocale)

      localStorage.setItem(LocalStorageKey.Locale, nextLocale)
    })
  }, [])

  const value: IntlContextType = useMemo(
    () => ({
      locale,
      setLocale: handleChangeLocale,
    }),
    [locale, handleChangeLocale],
  )

  const defaultLocale = getConfigValue(ConfigKey.DefaultLocale)

  const handleError = useCallback(
    (error) => {
      Sentry.captureException(error, {
        tags: {
          context: 'intl',
          locale,
        },
      })
    },
    [locale],
  )

  return (
    <IntlContext.Provider value={value}>
      <ReactIntlProvider
        messages={messages}
        locale={locale.replace('_', '-')}
        defaultLocale={defaultLocale}
        onError={handleError}
      >
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  )
}
