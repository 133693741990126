import { OrderBookExpandedResponse, OrderBookResponse } from '../types'
import { calcOrderBookMidPrice } from './calcOrderBookMidPrice'
import { calcOrderBookSpread } from './calcOrderBookSpread'
import { getOrderBookMaxAmount } from './getOrderBookMaxAmount'

export const selectOrderBookExpandedResponse = ({
  asks,
  bids,
}: OrderBookResponse): OrderBookExpandedResponse => ({
  asks,
  bids,
  maxAmount: getOrderBookMaxAmount({ asks, bids }),
  askPrice: asks[0]?.price,
  midPrice: calcOrderBookMidPrice({ asks, bids }),
  bidPrice: bids[0]?.price,
  empty: asks.length === 0 && bids.length === 0,
  spread: calcOrderBookSpread({ asks, bids }),
})
