import { AvatarSkeleton, DualAvatar, DualAvatarProps } from '@revolut/ui-kit'
import { FC } from 'react'

import { useCurrencyDetailsRequired } from '../../../core-api'
import { getCurrencyAssetUrl } from '../../utils'

interface AssetDualAvatarProps extends Omit<DualAvatarProps, 'topImage' | 'bottomImage'> {
  primaryAsset: string
  secondaryAsset: string
}

export const AssetDualAvatar: FC<AssetDualAvatarProps> = ({
  primaryAsset,
  secondaryAsset,
  ...rest
}) => {
  const primaryCurrencyDetails = useCurrencyDetailsRequired(primaryAsset)
  const secondaryCurrencyDetails = useCurrencyDetailsRequired(secondaryAsset)

  const primaryImage = primaryCurrencyDetails
    ? getCurrencyAssetUrl(primaryCurrencyDetails.ticker, primaryCurrencyDetails.type)
    : undefined

  const secondaryImage = secondaryCurrencyDetails
    ? getCurrencyAssetUrl(secondaryCurrencyDetails.ticker, secondaryCurrencyDetails.type)
    : undefined

  if (!primaryImage || !secondaryImage) return <AvatarSkeleton size={rest.size} />

  return <DualAvatar topImage={primaryImage} bottomImage={secondaryImage} {...rest} />
}
