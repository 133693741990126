import { Icon, IconProps, Table, TableCellProps, Token } from '@revolut/ui-kit'
import { FC } from 'react'

import { TransferState } from '../../../core-api'
import { TransferStateMessage } from '../TransferStateMessage'

interface TransferStatusTableCellProps extends TableCellProps {
  status: TransferState
}

const iconProps: Record<string, IconProps> = {
  [TransferState.PROCESSED]: {
    name: 'Check',
    size: Token.size.s16,
    color: Token.color.up,
  },
  [TransferState.REJECTED]: {
    name: 'Cross',
    size: Token.size.s16,
    color: Token.color.down,
  },
  [TransferState.PENDING]: {
    name: '16/Time',
    size: Token.size.s16,
    color: Token.color.accent,
  },
}

export const TransferStatusTableCell: FC<TransferStatusTableCellProps> = ({ status }) => {
  return (
    <Table.Cell renderStart={() => <Icon {...iconProps[status]} />}>
      <TransferStateMessage state={status} />
    </Table.Cell>
  )
}
