import { ActionButton, Bar, usePercentFormat } from '@revolut/ui-kit'
import { FC } from 'react'
import styled from 'styled-components'

type OrderSizeSuggestionsProps = {
  sizeRatio?: number | null
  onChangeSize: (sizeRatio: number) => void
}

const StyledBar = styled(Bar)`
  grid-auto-columns: auto;
  padding: 0;
`

const ORDER_SIZE_SUGGESTIONS = [0.25, 0.5, 0.75, 1]

export const OrderSizeSuggestions: FC<OrderSizeSuggestionsProps> = ({
  sizeRatio,
  onChangeSize,
}) => {
  const percentFormat = usePercentFormat()

  return (
    <StyledBar variant="suggestions">
      {ORDER_SIZE_SUGGESTIONS.map((ratio) => (
        /* We can't use ToogleButton, because it impossible to change width for it */
        <ActionButton
          key={ratio}
          width="100%"
          variant={ratio === sizeRatio ? 'accent' : 'primary'}
          size="xs"
          onClick={() => {
            onChangeSize(ratio)
          }}
        >
          {percentFormat(ratio)}
        </ActionButton>
      ))}
    </StyledBar>
  )
}
