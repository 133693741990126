import { useCallback, useEffect, useState } from 'react'

import { Time, formatCountdownTime } from '../../../core-utils'

interface CountdownProps {
  endDate?: number
}

export const useCountdown = ({ endDate = new Date().getTime() }: CountdownProps) => {
  const getTimer = useCallback(
    () => Math.max(endDate - new Date().getTime(), 0),
    [endDate],
  )

  const [timer, setTimer] = useState(getTimer())

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer(getTimer())
    }, Time.Second)

    return () => clearInterval(timerInterval)
  }, [endDate, getTimer])

  return formatCountdownTime(timer)
}
