import {
  HStack,
  HStackProps,
  Icon,
  Spacer,
  Text,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { FC, ReactNode } from 'react'

interface WidgetHeaderProps extends Omit<HStackProps, 'title' | 'action'> {
  title?: ReactNode
  action?: ReactNode
  color?: string
  tooltip?: ReactNode
}

export const WidgetHeader: FC<WidgetHeaderProps> = ({
  title,
  action,
  tooltip,
  color = Token.color.foreground,
  children,
  ...rest
}) => {
  const tooltipRef = useTooltip()

  return (
    <HStack px="s-16" height={44} minHeight={44} align="center" {...rest}>
      {title && (
        <>
          <HStack align="center" gap="s-8">
            <Text variant="h6" color={color}>
              {title}
            </Text>
            {tooltip && (
              <Icon name="16/InfoOutline" size={16} {...tooltipRef.getAnchorProps()} />
            )}
          </HStack>
          <Tooltip
            {...tooltipRef.getTargetProps()}
            width={240}
            backgroundColor={Token.color.widgetBackground}
            color={Token.color.foreground}
            placement="bottom-end"
          >
            {tooltip}
          </Tooltip>
        </>
      )}
      {children}

      {action && (
        <>
          <Spacer />
          {action}
        </>
      )}
    </HStack>
  )
}
