import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getTransfers } from '../../api'
import { QueryKey } from '../../constants'
import { TransfersParams, TransfersReponse } from '../../types'

export const useTransfers = <T extends unknown = TransfersReponse>(
  params: TransfersParams = {},
  options: UseQueryOptions<TransfersReponse, AxiosError, T> = {},
) =>
  useQuery<TransfersReponse, AxiosError, T>(
    [QueryKey.Transfers, params],
    () => getTransfers(params),
    options,
  )
