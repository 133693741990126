export type EventMap = Record<string, any>

type Listener<T> = (arg: T) => void

export class EventEmitter<E extends EventMap> {
  private eventListeners: {
    [K in keyof E]?: Set<Listener<E[K]>>
  } = {}

  on<K extends keyof E>(eventName: K, listener: Listener<E[K]>) {
    const listeners = this.eventListeners[eventName] ?? new Set()
    listeners.add(listener)
    this.eventListeners[eventName] = listeners
  }

  emit<K extends keyof E>(eventName: K, arg: E[K]) {
    const listeners = this.eventListeners[eventName]
    if (listeners) {
      for (const listener of listeners) {
        listener(arg)
      }
    }
  }

  off<K extends keyof E>(eventName: K, listener: Listener<E[K]>) {
    const listeners = this.eventListeners[eventName]
    if (listeners) {
      listeners.delete(listener)
    }
  }
}
