import axios from 'axios'

import { UserRestriction } from '../types'

export const getUserRestrictions = async () => {
  const { data } = await axios.get<UserRestriction[]>(
    '/api/crypto-exchange/user/restrictions',
  )

  return data
}
