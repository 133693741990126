import { Ask, Bid } from '../types'

export const calcOrderBookMidPrice = ({
  bids,
  asks,
}: {
  bids: Array<Bid>
  asks: Array<Ask>
}): number | undefined => {
  const firstBid = bids[0]
  const lastAsk = asks[0]

  if (firstBid && lastAsk) {
    return (firstBid.price + lastAsk.price) / 2
  }

  if (firstBid) {
    return firstBid.price
  }

  if (lastAsk) {
    return lastAsk.price
  }

  return undefined
}
