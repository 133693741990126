import { ActionButton, Bar } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { OrderState, useCancelOrder, useOrder } from '../../../core-api'
import { ErrorStatus, useHumanizeCurrencyPair } from '../../../core-shared'
import { SideHeaderSkeleton, useStatusPopup } from '../../../core-ui'
import { RoutePath, getRouteUrl } from '../../../core-utils'
import { ActivityType } from '../../constants'
import { OpenOrderDetailsSideContent } from './OpenOrderDetailsSideContent'
import { OrderDetailsSideHeader } from './OrderDetailsSideHeader'
import { OrderHistoryDetailsSideContent } from './OrderHistoryDetailsSideContent'

interface OrderDetailsSideProps {
  type: ActivityType
  orderId: string
  onClose?: (nextState?: boolean | undefined) => void
}

export const OrderDetailsSide: FC<OrderDetailsSideProps> = ({
  type,
  orderId,
  onClose,
}) => {
  const { data: order, status, refetch } = useOrder(orderId)

  const statusPopup = useStatusPopup()

  const cancelOrder = useCancelOrder({
    onMutate: () => {
      statusPopup.showLoading()
    },
    onSuccess: () => {
      statusPopup.showSuccess({
        title: (
          <FormattedMessage
            id="activity.cancelOrder.success.title"
            defaultMessage="Order successfully canceled"
          />
        ),
        onClose,
      })
    },
    onError: () => {
      statusPopup.showError({
        title: (
          <FormattedMessage
            id="activity.cancelOrder.error.title"
            defaultMessage="Something went wrong"
          />
        ),
      })
    },
  })

  const history = useHistory()

  const { humanizeCurrencyPair } = useHumanizeCurrencyPair()

  if (status === 'loading') {
    return <SideHeaderSkeleton />
  }

  if (status === 'error') {
    return <ErrorStatus onRetry={refetch} />
  }

  const { currencyPair, side, orderType, createdDate, state, qty } = order

  const isOpenOrders = type === ActivityType.OpenOrders
  const isOrdersHistory = type === ActivityType.OrdersHistory

  const canEdit = state === OrderState.New

  const canCancel = [OrderState.New, OrderState.PartiallyFilled].includes(state)

  const hasActions = canEdit || canCancel

  const handleEditOrder = () => {
    onClose?.()

    history.push(
      getRouteUrl({
        path: RoutePath.Trade,
        params: {
          currencyPair: humanizeCurrencyPair(order.currencyPair),
        },
        queryParams: { orderId: order.orderId },
      }),
    )
  }

  return (
    <>
      <OrderDetailsSideHeader
        side={side}
        qty={qty}
        orderType={orderType}
        date={createdDate}
        currencyPair={currencyPair}
        state={state}
      />

      {hasActions && (
        <Bar mb="s-16">
          {canEdit && (
            <ActionButton useIcon="Pencil" onClick={handleEditOrder}>
              <FormattedMessage id="actions.edit" defaultMessage="Edit" />
            </ActionButton>
          )}

          {canCancel && (
            <ActionButton
              useIcon="Cross"
              variant="negative"
              onClick={() => cancelOrder.mutate(orderId)}
            >
              <FormattedMessage id="actions.cancel" defaultMessage="Cancel" />
            </ActionButton>
          )}
        </Bar>
      )}

      {isOpenOrders && <OpenOrderDetailsSideContent order={order} />}
      {isOrdersHistory && <OrderHistoryDetailsSideContent order={order} />}
    </>
  )
}
