export enum ConfigKey {
  AuthRefreshTokenInBackground = 'AUTH_REFRESH_TOKEN_IN_BACKGROUND',
  AuthRefreshTokenThresholdMilliseconds = 'AUTH_REFRESH_TOKEN_THRESHOLD_MILLISECONDS',
  AuthRefreshTokenTimeoutMilliseconds = 'AUTH_REFRESH_TOKEN_TIMEOUT_MILLISECONDS',
  CdnUrl = 'CDN_URL',
  ClientVersion = 'CLIENT_VERSION',
  CookiesPolicyLink = 'COOKIES_POLICY_LINK',
  DefaultLocale = 'DEFAULT_LOCALE',
  EventTrackerDebuggerEnabled = 'EVENT_TRACKER_DEBUGGER_ENABLED',
  EventTrackerLoggerEnabled = 'EVENT_TRACKER_LOGGER_ENABLED',
  EventTrackerMode = 'EVENT_TRACKER_MODE',
  FallbackLocale = 'FALLBACK_LOCALE',
  FCADisclaimerUrl = 'FCA_DISCLAIMER_URL',
  FeedbackFormId = 'FEEDBACK_FORM_ID',
  FormsUrl = 'FORMS_URL',
  HelpCentreUrl = 'HELP_CENTRE_URL',
  SentryDsn = 'SENTRY_DSN',
  SharedCookieDomain = 'SHARED_COOKIE_DOMAIN',
  SsoClientId = 'SSO_CLIENT_ID',
  SsoBusinessClientId = 'SSO_BUSINESS_CLIENT_ID',
  SsoUrl = 'SSO_URL',
  SupportChatHostname = 'SUPPORT_CHAT_HOSTNAME',
  SupportChatQueueTag = 'SUPPORT_CHAT_QUEUE_TAG',
  SupportChatVersion = 'SUPPORT_CHAT_VERSION',
  SupportedLocales = 'SUPPORTED_LOCALES',
  TerminalRefetchIntervalMilliseconds = 'TERMINAL_REFETCH_INTERVAL_MILLISECONDS',
}
