import {
  Bullet,
  Button,
  Group,
  Header,
  Link,
  List,
  Popup,
  Text,
  VStack,
} from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useAcceptOnboardingTerms } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { useStatusPopup } from '../../../core-ui'

interface OnboardingPopupTermsAndConditionsContentProps {
  termsLink?: string
  tradingRulesLink?: string
  onClose: VoidFunction
}

export const OnboardingPopupTermsAndConditionsContent: FC<
  OnboardingPopupTermsAndConditionsContentProps
> = ({ termsLink, tradingRulesLink, onClose }) => {
  const { formatMessage } = useIntl()

  const statusPopup = useStatusPopup()

  const { mutate, isLoading } = useAcceptOnboardingTerms({
    onSuccess: onClose,
    onError: () => {
      statusPopup.showError({
        title: (
          <FormattedMessage
            id="errors.general.title"
            defaultMessage="Something went wrong"
          />
        ),
      })
    },
  })

  return (
    <>
      <Header variant="item">
        <Header.CloseButton
          aria-label={formatMessage({
            id: 'labels.close',
            defaultMessage: 'Close',
          })}
        />
        <Header.Title>
          <FormattedMessage
            id="onboarding.termsAndConditions.title"
            defaultMessage="Terms & Conditions"
          />
        </Header.Title>
        <Header.Subtitle>
          <FormattedMessage
            id="onboarding.termsAndConditions.subtitle"
            defaultMessage="The cryptocurrency service is provided to you by Revolut Ltd."
          />
        </Header.Subtitle>
      </Header>

      <VStack space="s-16">
        <Group>
          <List use="ol" px="s-16">
            <List.Item useIcon={<Bullet variant="filled">1</Bullet>}>
              <Text variant="caption">
                <FormattedMessage
                  id="onboarding.termsAndConditions.regulation.title"
                  defaultMessage="Crypto is not regulated. Only invest if you understand the risks. There is no investor protection compensation scheme."
                />
              </Text>
            </List.Item>
            <List.Item useIcon={<Bullet variant="filled">2</Bullet>}>
              <Text variant="caption">
                <FormattedMessage
                  id="onboarding.termsAndConditions.volatility.title"
                  defaultMessage="Crypto is highly volatile. You could lose all the money you put into crypto."
                />
              </Text>
            </List.Item>
            <List.Item useIcon={<Bullet variant="filled">3</Bullet>}>
              <Text variant="caption">
                <FormattedMessage
                  id="onboarding.termsAndConditions.restrictions.title"
                  defaultMessage="Trading restrictions/delays are possible due to events outside of our control."
                />
              </Text>
            </List.Item>
          </List>
        </Group>
      </VStack>

      <Popup.Actions>
        <Text variant="small" color="grey-tone-50" textAlign="center">
          <FormattedMessage
            id="onboarding.termsAndConditions.note"
            defaultMessage="By tapping “Accept”, you are agreeing to the {TermsAndConditionsLink} and {TradingRulesLink}."
            values={{
              TermsAndConditionsLink: (
                <Link href={termsLink}>
                  <FormattedMessage
                    id="onboarding.termsAndConditions.termsLink.title"
                    defaultMessage="Terms and Conditions"
                  />
                </Link>
              ),
              TradingRulesLink: (
                <Link href={tradingRulesLink}>
                  <FormattedMessage
                    id="onboarding.termsAndConditions.rulesLink.title"
                    defaultMessage="Trading Rules"
                  />
                </Link>
              ),
            }}
          />
        </Text>

        <Button onClick={() => mutate()} pending={isLoading} elevated>
          <FormattedMessage id="actions.accept" defaultMessage="Accept" />
        </Button>
      </Popup.Actions>
    </>
  )
}
