import { Box, DepthChart as UIDepthChart } from '@revolut/ui-kit'
import { FC, useMemo } from 'react'

import { useCurrencyPairRequired, useOrderBook } from '../../../core-api'
import { Feature, useFeatures } from '../../../core-features'
import { CurrencyPairProvider } from '../../../core-shared'
import { formatOrderBook, useOrderBookSteps } from '../../../feature-markets'
import {
  getAccumulatedAsks,
  getAccumulatedBids,
  getOrderBookPriceRangeByPercentile,
} from '../../utils'

interface DepthChartProps {
  currencyPair: string
  visible: boolean
}

const DEPTH_CHART_MAX_PRICE_RANGE_RATIO = 0.1
const DEPTH_CHART_PRICE_RANGE_RATIO_VOLUME_PERCENTILE = 0.8

export const DepthChart: FC<DepthChartProps> = ({ currencyPair, visible }) => {
  const { baseCurrency, quoteCurrency } = useCurrencyPairRequired(currencyPair)

  const orderBookSteps = useOrderBookSteps({ currencyPair })

  const { isFeatureEnabled } = useFeatures()
  const ws = isFeatureEnabled(Feature.WebSockets)

  const orderBook = useOrderBook(
    {
      currencyPair,
    },
    {
      ws,
    },
  )

  const { accumulatedAsks, accumulatedBids, priceRangeRatio } = useMemo(() => {
    if (orderBook.status === 'success' && orderBookSteps.currentStep) {
      const { asks, bids } = formatOrderBook(orderBook.data, orderBookSteps.currentStep)

      return {
        accumulatedAsks: getAccumulatedAsks(asks),
        accumulatedBids: getAccumulatedBids(bids),
        priceRangeRatio: getOrderBookPriceRangeByPercentile(
          orderBook.data,
          DEPTH_CHART_PRICE_RANGE_RATIO_VOLUME_PERCENTILE,
          {
            max: DEPTH_CHART_MAX_PRICE_RANGE_RATIO,
          },
        ),
      }
    }

    return { accumulatedAsks: [], accumulatedBids: [] }
  }, [orderBook.data, orderBook.status, orderBookSteps.currentStep])

  return (
    <Box
      height="100%"
      width="100%"
      pb="s-12"
      css={
        visible
          ? { visibility: 'visible' }
          : { position: 'absolute', width: '100%', visibility: 'hidden' }
      }
    >
      <CurrencyPairProvider currencyPair={currencyPair}>
        <UIDepthChart
          mirrorTooltip
          orderBook={{
            baseCurrency: quoteCurrency,
            quoteCurrency: baseCurrency,
            bids: accumulatedBids,
            asks: accumulatedAsks,
          }}
          priceRangeRatio={priceRangeRatio}
        />
      </CurrencyPairProvider>
    </Box>
  )
}
