import {
  Bar,
  Icon,
  Table,
  TableColumn,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import {
  CurrencyPairStatus,
  Order,
  OrderSide,
  useCancelOrder,
  useCurrencyPairRequired,
} from '../../../core-api'
import { useIntl } from '../../../core-intl'
import {
  CurrencyPairLink,
  CurrencyPairMoney,
  TableFilterDateRange,
  useHumanizeCurrencyPair,
} from '../../../core-shared'
import { DateTimeTableCell, Money, useStatusPopup } from '../../../core-ui'
import { RoutePath, getRouteUrl } from '../../../core-utils'
import { OrderSideTableCell } from '../OrderSideTableCell'
import { OrderTypeMessage } from '../OrderTypeMessage'
import { PercentsTableCell } from '../PercentsTableCell'
import { TableFilterCurrencyPair } from '../TableFilterCurrencyPair'
import { TableFilterOrderSide } from '../TableFilterOrderSide'
import { TableFilterOrderType } from '../TableFilterOrderType'

export const COLUMNS_ADVANCED: TableColumn<Order>[] = [
  {
    Header: <FormattedMessage id="activity.table.pair.caption" defaultMessage="Pair" />,
    accessor: 'currencyPair',
    Cell: ({ value }) => (
      <Table.Cell>
        <CurrencyPairLink currencyPair={value} size={24} variant="small" />
      </Table.Cell>
    ),
    Filter: ({ column }) => (
      <TableFilterCurrencyPair
        column={column}
        filterCurrencyPairStatus={CurrencyPairStatus.Active}
      />
    ),
  },
  {
    Header: (
      <FormattedMessage
        id="activity.table.timePlaced.caption"
        defaultMessage="Time placed"
      />
    ),
    accessor: 'createdDate',
    Cell: ({ value }) => <DateTimeTableCell date={new Date(value)} />,
    Filter: ({ column }) => <TableFilterDateRange column={column} />,
  },
  {
    Header: (
      <FormattedMessage
        id="activity.table.direction.caption"
        defaultMessage="Direction"
      />
    ),
    accessor: 'side',
    Cell: ({ value }) => <OrderSideTableCell side={value} />,
    Filter: ({ column }) => <TableFilterOrderSide column={column} />,
  },
  {
    id: 'type',
    Header: <FormattedMessage id="activity.table.type.caption" defaultMessage="Type" />,
    accessor: 'orderType',
    Cell: ({ value }) => (
      <Table.Cell>
        <OrderTypeMessage type={value} />
      </Table.Cell>
    ),
    Filter: ({ column }) => <TableFilterOrderType column={column} />,
  },
  {
    Header: (
      <FormattedMessage id="shared.order.quantity.label" defaultMessage="Quantity" />
    ),
    accessor: 'qty',
    align: 'end',
    Cell: ({ value, row }) => (
      <Table.Cell>
        <CurrencyPairMoney
          variant="base"
          currencyPair={row.original.currencyPair}
          amount={value}
        />
      </Table.Cell>
    ),
  },
  {
    Header: <FormattedMessage id="activity.table.price.caption" defaultMessage="Price" />,
    accessor: 'price',
    align: 'end',
    Cell: ({ value, row }) => (
      <Table.Cell>
        <CurrencyPairMoney
          kind="price"
          variant="quote"
          currencyPair={row.original.currencyPair}
          amount={value}
        />
      </Table.Cell>
    ),
  },
  {
    Header: (
      <FormattedMessage id="activity.table.filled.caption" defaultMessage="Filled" />
    ),
    id: 'filled',
    align: 'end',
    accessor: ({ qty, leavesQty }) => (qty === 0 ? 0 : (qty - leavesQty) / qty),
    Cell: ({ value }) => <PercentsTableCell value={value} />,
  },
  {
    Header: <FormattedMessage id="shared.order.value.label" defaultMessage="Value" />,
    id: 'total',
    align: 'end',
    accessor: ({ qty, price, side }) => {
      const total = qty * price

      return side === OrderSide.Buy ? -total : total
    },
    Cell: ({ value, row }) => {
      const pair = useCurrencyPairRequired(row.original.currencyPair)

      return (
        <Table.Cell>
          <Money kind="total" currency={pair.quoteCurrency} amount={value} />
        </Table.Cell>
      )
    },
  },
  {
    id: 'actions',
    accessor: 'orderId',
    align: 'end',
    width: 68,
    Cell: ({ value, row }) => {
      const { formatMessage } = useIntl()

      const history = useHistory()

      const editOrderTooltip = useTooltip()
      const cancelOrderTooltip = useTooltip()

      const statusPopup = useStatusPopup()

      const cancelOrder = useCancelOrder({
        onMutate: () => {
          statusPopup.showLoading()
        },
        onSuccess: () => {
          statusPopup.showSuccess({
            title: (
              <FormattedMessage
                id="activity.cancelOrder.success.title"
                defaultMessage="Order successfully canceled"
              />
            ),
          })
        },
        onError: () => {
          statusPopup.showError({
            title: (
              <FormattedMessage
                id="activity.cancelOrder.error.title"
                defaultMessage="Something went wrong"
              />
            ),
          })
        },
      })

      const { humanizeCurrencyPair } = useHumanizeCurrencyPair()

      const handleCancel = (event: React.MouseEvent) => {
        event.stopPropagation()

        cancelOrder.mutate(value)
      }

      const handleEditOrder = (event: React.MouseEvent) => {
        event.stopPropagation()

        history.push(
          getRouteUrl({
            path: RoutePath.Trade,
            params: {
              currencyPair: humanizeCurrencyPair(row.original.currencyPair),
            },
            queryParams: { orderId: row.original.orderId },
          }),
        )
      }

      return (
        <Table.Cell>
          <Bar>
            <Icon
              type="button"
              name="Pencil"
              aria-label={formatMessage({
                id: 'labels.editOrder',
                defaultMessage: 'Edit order',
              })}
              size={16}
              color={Token.color.greyTone50}
              css={{ '&:hover': { color: Token.color.accent } }}
              onClick={handleEditOrder}
              {...editOrderTooltip.getAnchorProps()}
            />

            <Icon
              type="button"
              name="Cross"
              aria-label={formatMessage({
                id: 'labels.cancelOrder',
                defaultMessage: 'Cancel order',
              })}
              size={16}
              color={Token.color.greyTone50}
              css={{ '&:hover': { color: Token.color.red } }}
              onClick={handleCancel}
              {...cancelOrderTooltip.getAnchorProps()}
            />
          </Bar>

          <Tooltip {...editOrderTooltip.getTargetProps({ placement: 'bottom-end' })}>
            <FormattedMessage id="actions.editOrder" defaultMessage="Edit order" />
          </Tooltip>

          <Tooltip {...cancelOrderTooltip.getTargetProps({ placement: 'bottom-end' })}>
            <FormattedMessage id="actions.cancelOrder" defaultMessage="Cancel order" />
          </Tooltip>
        </Table.Cell>
      )
    },
  },
]
