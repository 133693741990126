import { QueryStatus } from '@tanstack/react-query'
import Big from 'big.js'

import {
  DEFAULT_CURRENCY,
  OrderType,
  formatCurrencyPair,
  useFees,
  useTickers,
} from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { useMoneyFormat } from '../../../core-ui'
import { formatPercents } from '../../../core-utils'

export type OrderEstimatedFeeParams = {
  quoteCurrency: string
  type: OrderType
  amount: number | null
}

export interface OrderEstimatedFeeReturn {
  feeMessage: string | null
  feeTooltipMessage: string | null
  status: QueryStatus
}

export const useOrderEstimatedFee = (
  params: OrderEstimatedFeeParams,
): OrderEstimatedFeeReturn => {
  const { quoteCurrency, type } = params

  const amount = params.amount ?? 0

  const { formatMessage } = useIntl()

  const fees = useFees()

  const tickers = useTickers()

  const formatMoney = useMoneyFormat({
    kind: 'total',
  })

  let feeMessage: string | null = null
  let feeTooltipMessage: string | null = null

  if (fees.status === 'success') {
    const takerFeeBig = Big(fees.data.takerFeePerc).div(100)
    const makerFeeBig = Big(fees.data.makerFeePerc).div(100)

    const formattedTakerFee = formatPercents(takerFeeBig.toNumber(), {
      minimumFractionDigits: 0,
    })

    const formattedMakerFee = formatPercents(makerFeeBig.toNumber(), {
      minimumFractionDigits: 0,
    })

    if (type === OrderType.Market) {
      feeMessage = formattedTakerFee
    }

    if (type === OrderType.Limit) {
      feeMessage = formatMessage(
        {
          id: 'trading.orderForm.estimatedFee.content',
          defaultMessage: '{makerFee} - {takerFee}',
        },
        {
          takerFee: formattedTakerFee,
          makerFee: formattedMakerFee,
        },
      )
    }

    feeTooltipMessage = formatMessage(
      {
        id: 'trading.orderForm.estimatedFee.description',
        defaultMessage:
          'Fees are {makerFee} for maker orders and {takerFee} taker orders',
      },
      {
        takerFee: formattedTakerFee,
        makerFee: formattedMakerFee,
      },
    )

    if (tickers.status === 'success') {
      const quoteCurrencyPair = formatCurrencyPair(
        {
          baseCurrency: quoteCurrency,
          quoteCurrency: DEFAULT_CURRENCY,
        },
        '/',
      )

      const ticker = tickers.data[quoteCurrencyPair]

      let midPriceBig = ticker?.mid ? Big(ticker.mid) : undefined

      if (quoteCurrency === DEFAULT_CURRENCY) {
        midPriceBig = Big(1)
      }

      const takerFeeTotalBig = takerFeeBig.times(amount)
      const makerFeeTotalBig = makerFeeBig.times(amount)

      if (midPriceBig !== undefined) {
        const takerFeeTotalInDefaultCurrency = midPriceBig
          .times(takerFeeTotalBig)
          .toNumber()

        const makerFeeTotalInDefaultCurrency = midPriceBig
          .times(makerFeeTotalBig)
          .toNumber()

        const formattedTakerFeeTotal = formatMoney({
          amount: takerFeeTotalInDefaultCurrency,
          currency: DEFAULT_CURRENCY,
        })

        const formattedMakerFeeTotal = formatMoney({
          amount: makerFeeTotalInDefaultCurrency,
          currency: DEFAULT_CURRENCY,
        })

        if (
          type === OrderType.Market ||
          formattedTakerFeeTotal === formattedMakerFeeTotal
        ) {
          feeMessage = formattedTakerFeeTotal
        } else if (type === OrderType.Limit) {
          feeMessage = formatMessage(
            {
              id: 'trading.orderForm.estimatedFee.content',
              defaultMessage: '{makerFee} - {takerFee}',
            },
            {
              takerFee: formattedTakerFeeTotal,
              makerFee: formattedMakerFeeTotal,
            },
          )
        }
      } else {
        const formattedTakerFeeTotal = formatMoney({
          amount: takerFeeTotalBig.toNumber(),
          currency: quoteCurrency,
        })

        const formattedMakerFeeTotal = formatMoney({
          amount: makerFeeTotalBig.toNumber(),
          currency: quoteCurrency,
        })

        if (
          type === OrderType.Market ||
          formattedTakerFeeTotal === formattedMakerFeeTotal
        ) {
          feeMessage = formattedTakerFeeTotal
        } else if (type === OrderType.Limit) {
          feeMessage = formatMessage(
            {
              id: 'trading.orderForm.estimatedFee.content',
              defaultMessage: '{makerFee} - {takerFee}',
            },
            {
              takerFee: formattedTakerFeeTotal,
              makerFee: formattedMakerFeeTotal,
            },
          )
        }
      }
    }
  }

  const status = fees.status

  return { feeMessage, feeTooltipMessage, status }
}
