import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { FormattedEnumMessage } from '../../../core-intl'
import { ActivityType } from '../../constants'

interface ActivityTypeMessageProps {
  type: ActivityType
}

const activityTypeMessages = defineMessages<ActivityType>({
  [ActivityType.OpenOrders]: {
    id: 'activity.type.openOrders.title',
    defaultMessage: 'Open orders',
  },
  [ActivityType.OrdersHistory]: {
    id: 'activity.type.orderHistory.title',
    defaultMessage: 'Order history',
  },
  [ActivityType.Trades]: {
    id: 'activity.type.trades.title',
    defaultMessage: 'Trades',
  },
  [ActivityType.Transfers]: {
    id: 'activity.type.transfers.title',
    defaultMessage: 'Transfers',
  },
})

export const ActivityTypeMessage: FC<ActivityTypeMessageProps> = ({ type }) => (
  <FormattedEnumMessage id={type} messages={activityTypeMessages} />
)
