import axios from 'axios'

import { BalancesResponse, CurrencyAssetType } from '../types'

const INITIAL_BALANCES: BalancesResponse = {
  USD: {
    amount: 0,
    reserved: 0,
    assetType: CurrencyAssetType.Fiat,
  },
}

export const getBalances = async (currency?: string | string[]) => {
  const { data } = await axios.get<BalancesResponse>(
    '/api/crypto-exchange/balances/values',
    {
      params: {
        currency,
      },
    },
  )

  // API returns empty object if there are no balances, we want to show USD zero balance for this case
  return { ...INITIAL_BALANCES, ...data }
}
