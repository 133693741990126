import { Token } from '@revolut/ui-kit'
import {
  ColorType,
  DeepPartial,
  IChartApi,
  ISeriesApi,
  TimeChartOptions,
} from 'lightweight-charts'

import { ComputedStyleUtils, ThemePalette } from '../../hooks'
import { BARS_LIMIT, VISIBLE_LOGICAL_RANGE_CHANGE_TIMEOUT } from './constants'
import { LightweightChartData, LightweightChartType } from './types'

export const getChartOptions = (
  { getComputedStyleProperty }: ComputedStyleUtils,
  computedPalette: ThemePalette,
): DeepPartial<TimeChartOptions> => {
  return {
    handleScale: {
      axisPressedMouseMove: false,
    },
    handleScroll: false,
    rightPriceScale: {
      borderColor: 'transparent',
      autoScale: true,
      scaleMargins: {
        bottom: 0.05,
      },
      entireTextOnly: true,
    },
    timeScale: {
      borderColor: 'transparent',
      timeVisible: true,
    },
    layout: {
      background: {
        type: ColorType.Solid,
        color: computedPalette.backgroundColor,
      },
      textColor: computedPalette.foregroundColor,
      fontFamily: getComputedStyleProperty(Token.font.default, 'fontFamily'),
      fontSize: 10,
    },
    grid: {
      vertLines: {
        color: 'transparent',
      },
      horzLines: {
        color: 'transparent',
      },
    },
  }
}

export const updateChart = (
  chart: IChartApi,
  series: (ISeriesApi<'Area'> | ISeriesApi<'Histogram'>)[],
  data: LightweightChartData[],
  computedPalette: ThemePalette,
) => {
  chart.applyOptions({
    layout: {
      background: {
        type: ColorType.Solid,
        color: computedPalette.backgroundColor,
      },
    },
  })

  data.forEach((item, index) => {
    let currentSeries = series[index]

    if (!currentSeries) {
      if (item.type === LightweightChartType.Area) {
        currentSeries = chart.addAreaSeries({
          topColor: 'transparent',
          lineColor: computedPalette.upColor,
          bottomColor: 'transparent',
          lineWidth: 1,
          baseLineVisible: false,
          ...item.options,
        })
      } else {
        currentSeries = chart.addHistogramSeries(item.options)
      }

      series[index] = currentSeries
    }

    currentSeries.setData(item.data)

    const timeScale = chart.timeScale()

    let timer: number | undefined

    timeScale.subscribeVisibleLogicalRangeChange(() => {
      if (timer !== undefined) {
        return
      }

      timer = window.setTimeout(() => {
        const logicalRange = timeScale.getVisibleLogicalRange()

        if (currentSeries && logicalRange !== null) {
          const barsInfo = currentSeries.barsInLogicalRange(logicalRange)

          if (barsInfo !== null && barsInfo.barsBefore < BARS_LIMIT) {
            if (item.onFetchMore) {
              item.onFetchMore()
            }
          }
        }

        timer = undefined
      }, VISIBLE_LOGICAL_RANGE_CHANGE_TIMEOUT)
    })
  })
}
