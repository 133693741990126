import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

type OrderExecutionMessageProps = {
  postOnly: boolean
}

export const OrderExecutionMessage: FC<OrderExecutionMessageProps> = ({ postOnly }) => {
  if (postOnly) {
    return (
      <FormattedMessage
        id="activity.order.execution.postOnly.title"
        defaultMessage="Post-Only"
      />
    )
  }

  return (
    <FormattedMessage
      id="activity.order.execution.allowTaker.title"
      defaultMessage="Allow-Taker"
    />
  )
}
