import { Popup, PopupProps } from '@revolut/ui-kit'
import { FC, useCallback } from 'react'

import { OnboardingStep } from '../../../core-api'
import { useCompatibilityCheck } from '../../../core-shared'
import { useOnboardingContext } from '../../hooks'
import { OnboardingPopupCoolDownPeriodContent } from '../OnboardingPopupCoolDownPeriodContent'
import {
  OnboardingPopupCryptoOnboardingContent,
  OnboardingPopupCryptoOnboardingContentMobile,
} from '../OnboardingPopupCryptoOnboardingContent'
import { OnboardingPopupKycDocumentCheckContent } from '../OnboardingPopupKycDocumentCheckContent'
import { OnboardingPopupMarketingConsentContent } from '../OnboardingPopupMarketingConsentContent'
import { OnboardingPopupTermsAndConditionsContent } from '../OnboardingPopupTermsAndConditionsContent'

export type OnboardingPopupProps = Omit<PopupProps, 'variant'>

export const OnboardingPopup: FC<OnboardingPopupProps> = (props) => {
  const { open, data, toggleOpen, refetch } = useOnboardingContext()

  const handleClose = useCallback(() => {
    toggleOpen(false)
  }, [toggleOpen])

  const { platformType } = useCompatibilityCheck()

  return (
    <Popup
      open={open}
      variant="modal-view"
      onClose={handleClose}
      closeOnOverlayClick={data?.nextStep === OnboardingStep.MarketingConsent}
      {...props}
    >
      {data?.nextStep === OnboardingStep.CryptoOnboarding &&
        (platformType === 'mobile' ? (
          <OnboardingPopupCryptoOnboardingContentMobile
            webDeeplink={data.context.webDeeplink}
            refetch={refetch}
          />
        ) : (
          <OnboardingPopupCryptoOnboardingContent
            deeplink={data.context.deeplink}
            refetch={refetch}
          />
        ))}

      {data?.nextStep === OnboardingStep.CoolDownPeriod && (
        <OnboardingPopupCoolDownPeriodContent
          coolDownEndDate={data.context.coolDownEndDate}
          onClose={handleClose}
        />
      )}

      {data?.nextStep === OnboardingStep.KycDocumentCheck && (
        <OnboardingPopupKycDocumentCheckContent />
      )}

      {data?.nextStep === OnboardingStep.TermsAndConditions && (
        <OnboardingPopupTermsAndConditionsContent
          termsLink={data.context.termsLink}
          tradingRulesLink={data.context.tradingRulesLink}
          onClose={handleClose}
        />
      )}

      {data?.nextStep === OnboardingStep.MarketingConsent && (
        <OnboardingPopupMarketingConsentContent onClose={handleClose} />
      )}
    </Popup>
  )
}
