import { Table, useDateTimeFormat } from '@revolut/ui-kit'
import { FC } from 'react'

type DateTimeTableCellProps = {
  date: Date
}

export const DateTimeTableCell: FC<DateTimeTableCellProps> = ({ date }) => {
  const dateTimeFormat = useDateTimeFormat({ now: new Date() })

  return <Table.Cell>{dateTimeFormat(date)}</Table.Cell>
}
