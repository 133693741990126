import { OrderSide, OrderType } from '../../../core-api'
import { ExchangeOrderState, OrderValueType } from './types'

export enum ActionType {
  UpdateSide = 'UpdateSide',
  UpdateType = 'UpdateType',
  ToggleValueType = 'ToggleValueType',
  UpdateQuantity = 'UpdateQuantity',
  UpdateAmount = 'UpdateAmount',
  UpdatePostOnly = 'UpdatePostOnly',
  SetPrice = 'SetPrice',
  UpdatePrice = 'UpdatePrice',
  SetSizeRatio = 'SetSizeRatio',
  Reset = 'Reset',
  Clear = 'Clear',
  Prefill = 'Prefill',
}

type Action =
  | {
      type: ActionType.UpdateSide
      payload: OrderSide
    }
  | {
      type: ActionType.UpdateQuantity
      payload: number | null
    }
  | {
      type: ActionType.UpdateAmount
      payload: number | null
    }
  | {
      type: ActionType.UpdateType
      payload: OrderType
    }
  | {
      type: ActionType.ToggleValueType
      payload: number | null
    }
  | {
      type: ActionType.UpdatePostOnly
      payload: boolean
    }
  | {
      type: ActionType.SetPrice
      payload: number | null
    }
  | {
      type: ActionType.UpdatePrice
      payload: number | null
    }
  | {
      type: ActionType.SetSizeRatio
      payload: number | null
    }
  | {
      type: ActionType.Reset
    }
  | {
      type: ActionType.Clear
    }
  | {
      type: ActionType.Prefill
      payload: Partial<
        Omit<
          ExchangeOrderState,
          'touched' | 'sizeRatio' | 'estimatedPrice' | 'roundedQuantity' | 'roundedAmount'
        >
      >
    }

export const exchangeOrderReducer = (
  state: ExchangeOrderState,
  action: Action,
): ExchangeOrderState => {
  switch (action.type) {
    case ActionType.UpdateSide:
      return {
        ...state,
        side: action.payload,
        valueType:
          action.payload === OrderSide.Buy
            ? OrderValueType.Amount
            : OrderValueType.Quantity,
        quantity: null,
        amount: null,
        sizeRatio: null,
      }
    case ActionType.UpdateQuantity:
      return {
        ...state,
        valueType: OrderValueType.Quantity,
        quantity: action.payload,
        sizeRatio: null,
        touched: true,
      }
    case ActionType.UpdateAmount:
      return {
        ...state,
        valueType: OrderValueType.Amount,
        amount: action.payload,
        sizeRatio: null,
        touched: true,
      }
    case ActionType.UpdateType:
      return {
        ...state,
        type: action.payload,
        quantity: null,
        amount: null,
        postOnly: false,
        price: null,
        sizeRatio: null,
        touched: false,
      }
    case ActionType.ToggleValueType:
      return state.valueType === OrderValueType.Amount
        ? {
            ...state,
            valueType: OrderValueType.Quantity,
            quantity: action.payload,
            amount: null,
          }
        : {
            ...state,
            valueType: OrderValueType.Amount,
            quantity: null,
            amount: action.payload,
          }
    case ActionType.UpdatePostOnly:
      return {
        ...state,
        postOnly: action.payload,
      }
    case ActionType.SetPrice:
      return {
        ...state,
        price: action.payload,
      }
    case ActionType.UpdatePrice:
      return {
        ...state,
        price: action.payload,
        touched: true,
      }
    case ActionType.SetSizeRatio:
      return {
        ...state,
        sizeRatio: action.payload,
      }
    case ActionType.Reset:
      return {
        ...state,
        orderId: null,
        quantity: null,
        amount: null,
        postOnly: false,
        price: null,
        sizeRatio: null,
        touched: false,
      }
    case ActionType.Clear:
      return {
        ...state,
        orderId: null,
        quantity: null,
        amount: null,
        postOnly: false,
        sizeRatio: null,
        touched: state.type === OrderType.Limit,
      }
    case ActionType.Prefill:
      return {
        ...state,
        ...action.payload,
        touched: true,
      }

    default:
      return state
  }
}
