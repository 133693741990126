import { CurrencyPair } from '../types'

export const formatCurrencyPair = (
  pair: Pick<CurrencyPair, 'baseCurrency' | 'quoteCurrency'> | string,
  delimiter: '-' | '/',
): string => {
  if (typeof pair === 'string') {
    return pair.replace(/(-|\/)/, delimiter)
  }

  return `${pair.baseCurrency}${delimiter}${pair.quoteCurrency}`
}
