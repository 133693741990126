export enum ExperimentName {
  WebExchange = 'webExchange',
  WebExchangeMobileDevices = 'webExchangeMobileDevices',
  WebExchangeNextPortfolio = 'webExchangeNextPortfolio',
  WebExchangeAmountHero = 'webExchangeAmountHero',
  WebExchangeWebSockets = 'webExchangeWebSockets',
  WebExchangeHideRealisedPnL = 'webExchangeHideRealisedPnL',
  WebExchangeLimitOrderPostOnly = 'webExchangeLimitOrderPostOnly',
}

export type Experiment = {
  experiment: ExperimentName | string
  variant: 'CONTROL' | 'VARIANT'
}
