import { Status, StatusProps, TextSkeleton } from '@revolut/ui-kit'
import { isNil } from 'lodash'
import { FC } from 'react'

import { formatPercents } from '../../../core-utils'
import { useThemePalette } from '../../hooks'
import { EmptyValue } from '../EmptyValue'

export type PercentsProps = {
  value: number | undefined | null
  loading?: boolean
  type?: 'ratio' | 'percent'
} & Omit<StatusProps, 'color'>

export const Percents: FC<PercentsProps> = ({
  value,
  loading,
  type = 'percent',
  ...rest
}) => {
  const { palette } = useThemePalette()

  const isNegative = value !== undefined && value < 0
  const isZero = value === 0

  const icon = isNegative ? '10/StocksArrowDown' : '10/StocksArrowUp'
  const color = isNegative ? palette.downColor : palette.upColor

  if (!loading && isNil(value)) {
    return <EmptyValue variant="caption" />
  }

  return (
    <Status
      display="inline-flex"
      color={isZero ? palette.zeroColor : color}
      variant="caption"
      iconSize={10}
      useIcon={isZero || loading ? undefined : icon}
      {...rest}
    >
      {loading || value === undefined ? (
        <TextSkeleton width={30} />
      ) : (
        formatPercents(Math.abs(value), { type })
      )}
    </Status>
  )
}
