import { useCallback } from 'react'

import { checkRequired } from '../../../core-utils'
import { useThemeMode } from '../useThemeMode'

export type ComputedStyleUtils = {
  getComputedStyleProperty: <T extends keyof CSSStyleDeclaration>(
    variable: string,
    prop: T,
  ) => CSSStyleDeclaration[T]
  getColor: (variable: string) => string
}

export const useComputedStyleUtils = (): ComputedStyleUtils => {
  const { resolvedThemeMode: themeMode } = useThemeMode()

  const getComputedStyleProperty: ComputedStyleUtils['getComputedStyleProperty'] =
    useCallback(
      (variable, prop) => {
        const themeRoot = checkRequired(
          document.getElementById(themeMode),
          'Theme root should exist',
        )

        const tempEl = document.createElement('div')

        tempEl.style.display = 'none'

        themeRoot.appendChild(tempEl)

        tempEl.style.color = variable

        const value = getComputedStyle(tempEl)[prop]

        themeRoot.removeChild(tempEl)

        return value
      },
      [themeMode],
    )

  const getColor: ComputedStyleUtils['getColor'] = useCallback(
    (variable: string): string => getComputedStyleProperty(variable, 'color'),
    [getComputedStyleProperty],
  )

  return { getColor, getComputedStyleProperty }
}
