import { useCallback } from 'react'

import { formatCurrencyPair, useCurrencyPairsRequired } from '../../../core-api'
import { useHumanizeCurrency } from '../useHumanizeCurrency'

const HUMAN_CURRENCY_PAIR_SEPARATOR = '-'

const HUMAN_CURRENCY_PAIR_SEPARATOR_REXEP = new RegExp(
  `([^${HUMAN_CURRENCY_PAIR_SEPARATOR}]+)${HUMAN_CURRENCY_PAIR_SEPARATOR}([^${HUMAN_CURRENCY_PAIR_SEPARATOR}]+)`,
)

export const useHumanizeCurrencyPair = () => {
  const currencyPairs = useCurrencyPairsRequired()

  const { unhumanizeCurrency, humanizeCurrency } = useHumanizeCurrency()

  const unhumanizeCurrencyPair = useCallback(
    (pair: string) => {
      const [, baseCurrency, quoteCurrency] =
        pair.match(HUMAN_CURRENCY_PAIR_SEPARATOR_REXEP) ?? []

      if (baseCurrency && quoteCurrency) {
        const unhumanizedBaseCurrency = unhumanizeCurrency(baseCurrency)
        const unhumanizedQuoteCurrency = unhumanizeCurrency(quoteCurrency)

        if (unhumanizedBaseCurrency && unhumanizedQuoteCurrency) {
          return formatCurrencyPair(
            {
              baseCurrency: unhumanizedBaseCurrency,
              quoteCurrency: unhumanizedQuoteCurrency,
            },
            '/',
          )
        }
      }

      return undefined
    },
    [unhumanizeCurrency],
  )

  const humanizeCurrencyPair = useCallback(
    (pair: string) => {
      const currencyPair = currencyPairs[pair]

      if (currencyPair) {
        const { baseCurrency, quoteCurrency } = currencyPair

        const humanizedBaseCurrency = humanizeCurrency(baseCurrency)
        const humanizedQuoteCurrency = humanizeCurrency(quoteCurrency)

        if (humanizedBaseCurrency && humanizedQuoteCurrency) {
          return formatCurrencyPair(
            {
              baseCurrency: humanizedBaseCurrency,
              quoteCurrency: humanizedQuoteCurrency,
            },
            HUMAN_CURRENCY_PAIR_SEPARATOR,
          )
        }
      }

      return undefined
    },
    [humanizeCurrency, currencyPairs],
  )

  return { humanizeCurrencyPair, unhumanizeCurrencyPair }
}
