import { useContext } from 'react'

import { IntlContext } from '../../providers'

export const useIntlContext = () => {
  const ctx = useContext(IntlContext)

  if (ctx === null) {
    throw new Error(
      'You can access context only in components inside "IntlProvider" component',
    )
  }

  return ctx
}
