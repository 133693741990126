import { startCase } from 'lodash'

import { parseHotkey } from './parseHotkey'

const MACOS_FORMATTED_KEYS: Record<string, string> = {
  command: '⌘',
  ctrl: '⌃',
  shift: '⇧',
  alt: '⌥',
  option: '⌥',
  enter: '⏎',
  tab: '⇥',
}

export const formatHotkey = (hotkey: string, os: string) => {
  const parts = parseHotkey(hotkey)

  if (os === 'macos') {
    const formattedParts = parts.map(
      (part) => MACOS_FORMATTED_KEYS[part.toLowerCase()] ?? startCase(part),
    )

    return formattedParts.join('')
  }

  const formattedParts = parts.map((part) => startCase(part))

  return formattedParts.join(' + ')
}
