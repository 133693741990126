import { Placeholder, PlaceholderProps } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Asset3dImageName, getImageAssetProps } from '../../../core-utils'

interface NoResultsPlaceholderProps extends PlaceholderProps {
  search: string
  onClearSearch: VoidFunction
}

export const NoResultsPlaceholder: FC<NoResultsPlaceholderProps> = ({
  search,
  onClearSearch,
}) => (
  <Placeholder>
    <Placeholder.Image image={getImageAssetProps(Asset3dImageName.NotFound)} />
    <Placeholder.Title>
      <FormattedMessage id="shared.noResults.title" defaultMessage="No results found" />
    </Placeholder.Title>
    <Placeholder.Description>
      <FormattedMessage
        id="shared.noResults.description"
        defaultMessage={`No results found for "{search}"`}
        values={{ search }}
      />
    </Placeholder.Description>
    <Placeholder.Action onClick={onClearSearch}>
      <FormattedMessage id="shared.noResults.clearSearch" defaultMessage="Clear search" />
    </Placeholder.Action>
  </Placeholder>
)
