import {
  AvatarProps,
  HStack,
  HStackProps,
  Text,
  TextProps,
  createChain,
} from '@revolut/ui-kit'
import { FC } from 'react'

import { useCurrencyPairRequired } from '../../../core-api'
import { useHumanizeCurrency } from '../../hooks'
import { AssetAvatar } from '../AssetAvatar'

export interface CurrencyPairProps extends HStackProps {
  currencyPair: string
  type?: 'default' | 'compact'
  size?: AvatarProps['size']
  variant?: TextProps['variant']
  fontWeight?: TextProps['fontWeight']
  separator?: string
  swapOrder?: boolean
  baseCurrencyColor?: TextProps['color']
  quoteCurrencyColor?: TextProps['color']
}

export const CurrencyPair: FC<CurrencyPairProps> = ({
  currencyPair,
  fontWeight,
  size = 40,
  variant = 'caption',
  type = 'default',
  separator = '-',
  swapOrder = false,
  baseCurrencyColor = 'foreground',
  quoteCurrencyColor = 'grey-tone-50',
  ...rest
}) => {
  const pair = useCurrencyPairRequired(currencyPair)

  const { humanizeCurrency } = useHumanizeCurrency()

  const humanizedBaseCurrency = humanizeCurrency(pair.baseCurrency)
  const humanizedQuoteCurrency = humanizeCurrency(pair.quoteCurrency)

  const currencyPairName = (
    <Text variant={variant} fontWeight={fontWeight}>
      {swapOrder
        ? createChain(separator)(
            <Text color={quoteCurrencyColor}>{humanizedQuoteCurrency}</Text>,
            <Text color={baseCurrencyColor}>{humanizedBaseCurrency}</Text>,
          )
        : createChain(separator)(
            <Text color={baseCurrencyColor}>{humanizedBaseCurrency}</Text>,
            <Text color={quoteCurrencyColor}>{humanizedQuoteCurrency}</Text>,
          )}
    </Text>
  )

  return (
    <HStack align="center" space="s-8" {...rest}>
      {type === 'default' && (
        <AssetAvatar variant="brand" asset={pair.baseCurrency} size={size} />
      )}

      {currencyPairName}
    </HStack>
  )
}
