import { FC } from 'react'

import { useMoneyFormat } from '../../../core-ui'

interface FormattedRateProps {
  fromCurrency: string
  toCurrency: string
  rate: number
}

export const FormattedRate: FC<FormattedRateProps> = ({
  toCurrency,
  fromCurrency,
  rate,
}) => {
  const moneyFormat = useMoneyFormat()

  const formattedFromAmount = moneyFormat({
    amount: 1,
    currency: fromCurrency,
  })

  const formattedToAmount = moneyFormat({
    amount: rate,
    currency: toCurrency,
  })

  return (
    <>{`
      ${formattedFromAmount} = 
      ${formattedToAmount}
    `}</>
  )
}
