import { IChatLocaleDictionary } from '@revolut-internal/chat-widget'
import { useIntl } from 'react-intl'

export const useChatMessages = (): IChatLocaleDictionary => {
  const { formatMessage } = useIntl()

  return {
    'supportChat.ticket.header.title': formatMessage({
      id: 'supportChat.ticket.header.title',
      defaultMessage: 'Support',
    }),
    'supportChat.ticket.header.description': formatMessage({
      id: 'supportChat.ticket.header.description',
      defaultMessage: 'Helping you 24/7',
    }),
    'supportChat.ticket.action.viewProfile': formatMessage({
      id: 'supportChat.ticket.action.viewProfile',
      defaultMessage: 'View profile',
    }),
    'supportChat.ticket.gotcha': formatMessage({
      id: 'supportChat.ticket.gotcha',
      defaultMessage: 'Got it!',
    }),
    'supportChat.message.rated': formatMessage({
      id: 'supportChat.message.rated',
      defaultMessage: 'Rated {rating}',
    }),
    'supportChat.message.booleanTrue': formatMessage({
      id: 'supportChat.message.booleanTrue',
      defaultMessage: 'True',
    }),
    'supportChat.message.booleanFalse': formatMessage({
      id: 'supportChat.message.booleanFalse',
      defaultMessage: 'False',
    }),
    'supportChat.message.requestIsProcessing': formatMessage({
      id: 'supportChat.message.requestIsProcessing',
      defaultMessage:
        'Your request is currently being processed. We we will notify you once there is an update.',
    }),
    'supportChat.tickets.chatWithAgent': formatMessage({
      id: 'supportChat.tickets.chatWithAgent',
      defaultMessage: 'Chat with {agentName}',
    }),
    'supportChat.tickets.inProgress': formatMessage({
      id: 'supportChat.tickets.inProgress',
      defaultMessage: 'In progress',
    }),
    'supportChat.tickets.pendingRating': formatMessage({
      id: 'supportChat.tickets.pendingRating',
      defaultMessage: 'Pending rating',
    }),
    'supportChat.banner.dismiss': formatMessage({
      id: 'supportChat.banner.dismiss',
      defaultMessage: 'Dismiss',
    }),
    'supportChat.agentInfo.chats': formatMessage({
      id: 'supportChat.agentInfo.chats',
      defaultMessage: 'Chats',
    }),
    'supportChat.message.translatorToolActivated': formatMessage({
      id: 'supportChat.message.translatorToolActivated',
      defaultMessage: 'Translator tool activated',
    }),
    'supportChat.message.translatorToolDeactivated': formatMessage({
      id: 'supportChat.message.translatorToolDeactivated',
      defaultMessage: 'Translator tool deactivated',
    }),
    'supportChat.survey.skip': formatMessage({
      id: 'supportChat.survey.skip',
      defaultMessage: 'Skip',
    }),
    'supportChat.survey.liveAgent': formatMessage({
      id: 'supportChat.survey.liveAgent',
      defaultMessage: 'Chat with a live agent',
    }),
    'supportChat.survey.needMoreHelp': formatMessage({
      id: 'supportChat.survey.needMoreHelp',
      defaultMessage: 'I need more help',
    }),
    'supportChat.ratings.additionalComments': formatMessage({
      id: 'supportChat.ratings.additionalComments',
      defaultMessage: 'Additional information',
    }),
    'supportChat.agentInfo.onboarding': formatMessage({
      id: 'supportChat.agentInfo.onboarding',
      defaultMessage: 'Onboarding',
    }),
    'supportChat.agentInfo.timeCompanyYears': formatMessage({
      id: 'supportChat.agentInfo.timeCompanyYears',
      defaultMessage: '{count, plural, =0 {none} one {# year} other {# years}}',
    }),
    'supportChat.agentInfo.timeCompanyMonths': formatMessage({
      id: 'supportChat.agentInfo.timeCompanyMonths',
      defaultMessage: '{count, plural, =0 {none} one {# month} other {# months}}',
    }),
    'supportChat.agentInfo.compliments': formatMessage({
      id: 'supportChat.agentInfo.compliments',
      defaultMessage: 'My compliments',
    }),
    'supportChat.agentInfo.headerAgent': formatMessage({
      id: 'supportChat.agentInfo.headerAgent',
      defaultMessage: "Hi, I'm {name}",
    }),
    'supportChat.agentInfo.chatbot': formatMessage({
      id: 'supportChat.agentInfo.chatbot',
      defaultMessage: 'Chatbot',
    }),
    'supportChat.agentInfo.ratings.notEnough': formatMessage({
      id: 'supportChat.agentInfo.ratings.notEnough',
      defaultMessage: "I don't have enough reviews yet",
    }),
    'supportChat.agentInfo.ratings.feedback': formatMessage({
      id: 'supportChat.agentInfo.ratings.feedback',
      defaultMessage:
        'Your feedback at the ending of the chat would be very helpful to improve my work.',
    }),
    'supportChat.agentInfo.reviews': formatMessage({
      id: 'supportChat.agentInfo.reviews',
      defaultMessage: 'From {count} reviews',
    }),
    'supportChat.agentInfo.atRevolut': formatMessage({
      id: 'supportChat.agentInfo.atRevolut',
      defaultMessage: 'At Revolut',
    }),
    'supportChat.greetingMessagePrimary': formatMessage({
      id: 'supportChat.greetingMessagePrimary',
      defaultMessage: 'How can we help you?',
    }),
    'supportChat.faq.featureIsNotAvailable': formatMessage({
      id: 'supportChat.faq.featureIsNotAvailable',
      defaultMessage: 'Available in Revolut mobile only',
    }),
    'supportChat.input.placeholder': formatMessage({
      id: 'supportChat.input.placeholder',
      defaultMessage: 'Type a message',
    }),
    'supportChat.message.pdfSendingFailed': formatMessage({
      id: 'supportChat.message.pdfSendingFailed',
      defaultMessage: 'File failed to send',
    }),
    'supportChat.message.imageSendingFailed': formatMessage({
      id: 'supportChat.message.imageSendingFailed',
      defaultMessage: 'Image failed to send',
    }),
    'supportChat.message.messageSendingFailed': formatMessage({
      id: 'supportChat.message.messageSendingFailed',
      defaultMessage: 'Message failed to send',
    }),
    'supportChat.message.sending': formatMessage({
      id: 'supportChat.message.sending',
      defaultMessage: 'Sending...',
    }),
    'supportChat.message.deleteFailed': formatMessage({
      id: 'supportChat.message.deleteFailed',
      defaultMessage: 'Delete',
    }),
    'supportChat.message.resendFailed': formatMessage({
      id: 'supportChat.message.resendFailed',
      defaultMessage: 'Resend',
    }),
    'supportChat.survey.send': formatMessage({
      id: 'supportChat.survey.send',
      defaultMessage: 'Send',
    }),
    'supportChat.tickets.resolved': formatMessage({
      id: 'supportChat.tickets.resolved',
      defaultMessage: 'Resolved',
    }),
    'supportChat.banners.wrongFileSizeTitle': formatMessage({
      id: 'supportChat.banners.wrongFileSizeTitle',
      defaultMessage: 'File size should not exceed 10Mb',
    }),
    'supportChat.banners.somethingWentWrongTitle': formatMessage({
      id: 'supportChat.banners.somethingWentWrongTitle',
      defaultMessage: 'Something went wrong',
    }),
    'supportChat.tickets.actions.end': formatMessage({
      id: 'supportChat.tickets.actions.end',
      defaultMessage: 'End',
    }),
    'supportChat.tickets.actions.continue': formatMessage({
      id: 'supportChat.tickets.actions.continue',
      defaultMessage: 'Continue',
    }),
    'supportChat.tickets.actions.rateOurSupport': formatMessage({
      id: 'supportChat.tickets.actions.rateOurSupport',
      defaultMessage: 'Rate our support',
    }),
    'supportChat.survey.titleExperience': formatMessage({
      id: 'supportChat.survey.titleExperience',
      defaultMessage: 'How was your chat?',
    }),
  }
}
