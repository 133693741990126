import { Item, chain } from '@revolut/ui-kit'
import { FC } from 'react'

import { CurrencyAssetType, DEFAULT_CURRENCY, ExtendedBalance } from '../../../core-api'
import { AssetAvatar, CurrencyName, useHumanizeCurrency } from '../../../core-shared'
import { Money, Percents } from '../../../core-ui'

interface BalancesGroupItemProps {
  balance: ExtendedBalance
  onClick: (balance: ExtendedBalance) => void
  selected?: boolean
  variant?: 'default' | 'performance'
}

export const BalancesGroupItem: FC<BalancesGroupItemProps> = ({
  balance,
  onClick,
  selected,
  variant = 'default',
}) => {
  const { currency, assetType, amount, amountValue, price, performance } = balance

  const { humanizeCurrency } = useHumanizeCurrency()

  const humanizedCurrency = humanizeCurrency(currency)

  const isFiat = assetType === CurrencyAssetType.Fiat

  if (variant === 'performance') {
    return (
      <Item
        key={currency}
        use="button"
        selected={selected}
        onClick={() => onClick(balance)}
      >
        <Item.Avatar>
          <AssetAvatar variant="brand" asset={currency} />
        </Item.Avatar>

        <Item.Content>
          <Item.Title>
            <CurrencyName currency={currency} />
          </Item.Title>
          {isFiat ? (
            <Item.Description>{humanizedCurrency}</Item.Description>
          ) : (
            <Item.Description>
              {chain(
                <Item.Value>
                  <Money
                    amount={amount}
                    currency={currency}
                    type="money-fractional"
                    maximumFractionDigits={2}
                    kind="total"
                  />
                </Item.Value>,
                <Item.Description>
                  <Money currency={DEFAULT_CURRENCY} amount={price} />
                </Item.Description>,
              )}
            </Item.Description>
          )}
        </Item.Content>

        <Item.Side>
          <Item.Value>
            <Money currency={DEFAULT_CURRENCY} amount={amountValue} />
          </Item.Value>
          {performance !== undefined && !isFiat && (
            <Item.Description>
              <Percents value={balance.performance} />
            </Item.Description>
          )}
        </Item.Side>
      </Item>
    )
  }

  return (
    <Item
      key={currency}
      use="button"
      selected={selected}
      onClick={() => onClick(balance)}
    >
      <Item.Avatar>
        <AssetAvatar variant="brand" asset={currency} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{humanizedCurrency}</Item.Title>
        <Item.Description>
          <CurrencyName currency={currency} />
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <Money amount={balance.amount} currency={currency} type="money-fractional" />
        </Item.Value>

        {balance.currency !== DEFAULT_CURRENCY && (
          <Item.Value variant="secondary">
            <Money currency={DEFAULT_CURRENCY} amount={balance.amountValue} />
          </Item.Value>
        )}
      </Item.Side>
    </Item>
  )
}
