import { Token } from '@revolut/ui-kit'
import { useMemo } from 'react'

import { LocalStorageKey, useLocalStorage } from '../../../core-storage'
import { CustomToken } from '../../constants'
import { useComputedStyleUtils } from '../useComputedStyleUtils'

export enum ThemePaletteType {
  Default = 'Default',
  Revolut = 'Revolut',
}

export type ThemePalette = {
  zeroColor: string
  upColor: string
  upColor10: string
  upColor5: string
  downColor: string
  downColor10: string
  downColor5: string
  backgroundColor: string
  foregroundColor: string
  gridColor: string
  wickColor: string
  separatorColor: string
}

const PALETTES: Record<ThemePaletteType, ThemePalette> = {
  [ThemePaletteType.Default]: {
    zeroColor: Token.color.greyTone50,
    upColor: Token.color.up,
    upColor10: CustomToken.color.up_10,
    upColor5: CustomToken.color.up_5,
    downColor: Token.color.down,
    downColor10: CustomToken.color.down_10,
    downColor5: CustomToken.color.down_5,
    backgroundColor: Token.color.widgetBackground,
    foregroundColor: Token.color.foreground,
    gridColor: Token.color.greyTone8,
    wickColor: Token.color.greyTone50,
    separatorColor: Token.color.greyTone8,
  },
  [ThemePaletteType.Revolut]: {
    zeroColor: Token.color.greyTone50,
    upColor: Token.color.blue,
    upColor10: Token.color.blue_10,
    upColor5: Token.color.blue_5,
    downColor: Token.color.pink,
    downColor10: Token.color.pink_10,
    downColor5: Token.color.pink_5,
    backgroundColor: Token.color.widgetBackground,
    foregroundColor: Token.color.foreground,
    gridColor: Token.color.greyTone8,
    wickColor: Token.color.greyTone50,
    separatorColor: Token.color.greyTone8,
  },
}

export const useThemePalette = () => {
  const { getColor } = useComputedStyleUtils()

  const [paletteType, setPaletteType] = useLocalStorage<ThemePaletteType>(
    LocalStorageKey.ThemePalette,
    ThemePaletteType.Default,
  )

  const palette = PALETTES[paletteType]

  const computedPalette = useMemo(
    () =>
      Object.entries(palette).reduce(
        (result, [name, value]) => ({
          ...result,
          [name]: getColor(value),
        }),
        {},
      ) as ThemePalette,
    [palette, getColor],
  )

  return { palette: PALETTES[paletteType], computedPalette, setPaletteType, paletteType }
}
