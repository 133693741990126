import { Color, Table, TableFilters } from '@revolut/ui-kit'
import { isNumber } from 'lodash'
import { FC, useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Transfer, useInfiniteTransfers } from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { mapTableLoadingState } from '../../../core-utils'
import { getTransfersFilterParams } from '../../utils'
import { COLUMNS_ADVANCED } from './TransfersTable.advanced.columns'
import { COLUMNS_DEFAULT } from './TransfersTable.default.columns'

interface TransfersTableProps {
  selectedRowId?: string | null
  onSelectRow?: (id: string) => void
  maxRows?: number
  variant?: 'default' | 'advanced'
  initialFilters?: TableFilters<Transfer>
  stickyHeaderTop?: number | string
}

export const TransfersTable: FC<TransfersTableProps> = ({
  maxRows,
  selectedRowId,
  onSelectRow,
  variant,
  initialFilters = [],
  stickyHeaderTop = 0,
}) => {
  const [filters, setFilters] = useState<TableFilters<Transfer>>(initialFilters)

  const filterParams = useMemo(() => getTransfersFilterParams(filters), [filters])

  const { data, status, fetchNextPage, hasNextPage, refetch } = useInfiniteTransfers({
    ...filterParams,
    limit: maxRows,
  })

  const rows = useMemo(() => (data?.pages ?? []).flatMap((page) => page.items), [data])

  const getRowState = useCallback(
    ({ value }) => ({
      bg: value.id === selectedRowId ? Color.BLUE_5 : undefined,
    }),
    [selectedRowId],
  )

  const handleRowClick = (item: Transfer) => {
    onSelectRow && onSelectRow(item.id)
  }

  if (status === 'error') {
    return <ErrorStatus onRetry={refetch} />
  }

  const hasMore = isNumber(maxRows) ? rows.length < maxRows && hasNextPage : hasNextPage

  return (
    <Table
      rowHeight={variant === 'advanced' ? 'small' : 'large'}
      stickyHeaderTop={stickyHeaderTop}
      columns={variant === 'advanced' ? COLUMNS_ADVANCED : COLUMNS_DEFAULT}
      data={rows}
      getRowState={getRowState}
      onRowClick={handleRowClick}
      loadingState={mapTableLoadingState(status, rows)}
      onRequestMore={fetchNextPage}
      onFilter={setFilters}
      hasMore={hasMore}
      autoResetFilters={false}
      manualFilters
      disableSortBy
      disableResizing
      virtual={variant === 'default'}
      initialState={{ filters }}
      labelEmptyState={
        <FormattedMessage
          id="activity.transfers.table.empty.title"
          defaultMessage="No transfers"
        />
      }
      labelNoResults={
        <FormattedMessage
          id="activity.transfers.table.noResults.title"
          defaultMessage="No transfers found"
        />
      }
    />
  )
}
