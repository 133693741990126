import { ReactNode, useMemo, useState, createContext, memo, useEffect } from 'react'

import { FeedbackPrompt, FeedbackSide } from '../components'
import {
  useFeedbackPrompt,
  useFormCompletionTimer,
  usePromptClosureTimer,
} from '../hooks'

export type FeedbackContextState = {
  open: () => void
  close: () => void
}

export type FeedbackProviderProps = { children: ReactNode }

const notInitialized = () => {
  throw new Error('Feedback context not initialized properly')
}

export const FeedbackContext = createContext<FeedbackContextState>({
  open: notInitialized,
  close: notInitialized,
})

enum FeedbackState {
  Initial = 'INITIAL',
  Prompt = 'PROMPT',
  Side = 'SIDE',
  Idle = 'IDLE',
}

export const FeedbackProvider = memo(({ children }: FeedbackProviderProps) => {
  const [state, setState] = useState(FeedbackState.Initial)

  const feedbackPrompt = useFeedbackPrompt()

  useEffect(() => {
    if (state === FeedbackState.Initial && feedbackPrompt) {
      setState(FeedbackState.Prompt)
    }
  }, [state, feedbackPrompt])

  const { onComplete } = useFormCompletionTimer()
  const { onClose } = usePromptClosureTimer()

  const handleCompleteForm = () => {
    setState(FeedbackState.Idle)
    onComplete()
  }
  const handleClosePrompt = () => {
    setState(FeedbackState.Idle)
    onClose()
  }
  const handleClickAway = () => {
    setState(FeedbackState.Idle)
  }

  const handleCloseSide = () => setState(FeedbackState.Idle)
  const handleOpenSide = () => setState(FeedbackState.Side)

  const value = useMemo<FeedbackContextState>(
    () => ({
      open: handleOpenSide,
      close: handleCloseSide,
    }),
    [],
  )

  return (
    <FeedbackContext.Provider value={value}>
      {children}
      <FeedbackPrompt
        isOpen={state === FeedbackState.Prompt}
        onConfirm={handleOpenSide}
        onClose={handleClosePrompt}
        onClickAway={handleClickAway}
      />
      <FeedbackSide
        isOpen={state === FeedbackState.Side}
        onClose={handleCloseSide}
        onComplete={handleCompleteForm}
      />
    </FeedbackContext.Provider>
  )
})
