import { Box, Fixed, Overlay, Popup, Token, TransitionSlide } from '@revolut/ui-kit'
import { FC, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { LocalStorageKey, localStorage } from '../../../core-storage'
import { notReachable } from '../../../core-utils'
import { useCookiesPreferences, useLayoutSize } from '../../hooks'
import { ConsentScreen } from './ConsentScreen'
import { PreferencesScreen } from './PreferencesScreen'

const CONTENT_MAX_HEIGHT = '500px'
const CONTENT_WIDTH = '400px'

export const CookieConsentModalBase = styled(Box)`
  background-color: ${Token.color.widgetBackground};
  border-radius: ${Token.radius.r12};
  bottom: ${Token.size.s40};
  max-height: ${CONTENT_MAX_HEIGHT};
  width: ${CONTENT_WIDTH};
  overflow: auto;
  padding: ${Token.size.s24};
  position: fixed;
  right: ${Token.size.s40};
`

enum CookieBannerScreen {
  Consent = 'CONSENT',
  Preferences = 'PREFERENCES',
}

export const CookiesModal: FC = () => {
  const [open, setOpen] = useState(
    !(localStorage.getItem(LocalStorageKey.CookiesBannerIsClosed) ?? false),
  )
  const [screen, setScreen] = useState(CookieBannerScreen.Consent)

  const { cookiesPreferences, saveAndApplyNewPreferences } = useCookiesPreferences()

  const layoutSize = useLayoutSize()

  const handleSaveCookies = useCallback(
    (nextAnalyticsValue: boolean) => {
      localStorage.setItem(LocalStorageKey.CookiesBannerIsClosed, true)
      setOpen(false)
      saveAndApplyNewPreferences({
        analyticsTargetingEnabled: nextAnalyticsValue,
      })
    },
    [saveAndApplyNewPreferences],
  )

  const screenComponent = useMemo(() => {
    switch (screen) {
      case CookieBannerScreen.Consent:
        return (
          <ConsentScreen
            onClickAllow={() => handleSaveCookies(true)}
            onClickReject={() => handleSaveCookies(false)}
            onClickManage={() => setScreen(CookieBannerScreen.Preferences)}
          />
        )
      case CookieBannerScreen.Preferences:
        return (
          <PreferencesScreen
            isAnalyticsEnabled={cookiesPreferences?.analyticsTargetingEnabled}
            onClickBack={() => setScreen(CookieBannerScreen.Consent)}
            onClickSave={handleSaveCookies}
          />
        )
      default:
        return notReachable(screen)
    }
  }, [cookiesPreferences?.analyticsTargetingEnabled, handleSaveCookies, screen])

  if (layoutSize === 'narrow') {
    return (
      <Popup open={open} variant="bottom-sheet">
        {screenComponent}
      </Popup>
    )
  }

  return (
    <Overlay open={open}>
      <Fixed right="s-32" bottom="s-32">
        <TransitionSlide in={open} offsetX={-24}>
          <CookieConsentModalBase>{screenComponent}</CookieConsentModalBase>
        </TransitionSlide>
      </Fixed>
    </Overlay>
  )
}
