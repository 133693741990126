import { CurrencyAssetType } from './currency'

export interface WatchlistResponse {
  items: WatchlistItem[]
}

export interface WatchlistItem {
  currencyPair: string
  assetType: CurrencyAssetType
  createdDate: string
}

export enum WatchlistSort {
  LastAdded = 'RANK_OVER_ASSET_TYPE_LAST_ADDED',
  Symbol = 'RANK_OVER_ASSET_TYPE_SYMBOL',
}

export interface WatchlistParams {
  sort: WatchlistSort
  limit?: number
}

export enum WatchlistOperationType {
  Add = 'add',
  Remove = 'remove',
}

export interface WatchlistData {
  operations: {
    type: WatchlistOperationType
    currencyPair: string
  }[]
}
