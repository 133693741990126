import { useLocation } from 'react-router-dom'

import { useAppInitTimer } from './useAppInitTimer'
import { useFormCompletionTimer } from './useFormCompletionTimer'
import { useIsUserFrequent } from './useIsUserFrequent'
import { usePromptClosureTimer } from './usePromptClosureTimer'

export const useFeedbackPrompt = () => {
  const { pathname } = useLocation()

  const { isTimedOut } = useAppInitTimer()
  const { isCompletedRecently } = useFormCompletionTimer()
  const { isClosedRecently } = usePromptClosureTimer()
  const isFrequent = useIsUserFrequent()
  const isLegacyTerminal = pathname.startsWith('/terminal')

  return (
    isTimedOut &&
    !isClosedRecently &&
    !isCompletedRecently &&
    isFrequent &&
    isLegacyTerminal
  )
}
