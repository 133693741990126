import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { FormattedEnumMessage } from '../../../core-intl'
import { EmptyStatusType } from '../../constants'

interface ErrorStatusDescriptionMessageProps {
  type: EmptyStatusType
}

const errorStatusDescriptionMessages = defineMessages<EmptyStatusType>({
  [EmptyStatusType.Orders]: {
    id: 'shared.orders.state.error.description',
    defaultMessage: 'Your open orders will appear here',
  },
  [EmptyStatusType.OrderHistory]: {
    id: 'shared.orderHistory.state.error.description',
    defaultMessage: 'Your order history will appear here',
  },
  [EmptyStatusType.Trades]: {
    id: 'shared.trades.state.error.description',
    defaultMessage: 'Your trades will appear here',
  },
  [EmptyStatusType.Transfers]: {
    id: 'shared.transfers.state.error.description',
    defaultMessage: 'Your transfers will appear here',
  },
  [EmptyStatusType.Balances]: {
    id: 'shared.balances.state.error.description',
    defaultMessage: 'Your balances will appear here',
  },
  [EmptyStatusType.CurrencyPairs]: {
    id: 'shared.currencyPairs.state.error.description',
    defaultMessage: 'Try to use another search term',
  },
})

export const ErrorStatusDescriptionMessage: FC<ErrorStatusDescriptionMessageProps> = ({
  type,
}) => {
  return <FormattedEnumMessage id={type} messages={errorStatusDescriptionMessages} />
}
