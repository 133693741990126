import { Text, Token } from '@revolut/ui-kit'
import { FC, PropsWithChildren } from 'react'

interface WidgetTabBarItemProps {
  selected?: boolean
  onClick: VoidFunction
}

export const WidgetTabBarItem: FC<PropsWithChildren<WidgetTabBarItemProps>> = ({
  selected,
  onClick,
  children,
}) => {
  if (selected) {
    return <Text variant="h6">{children}</Text>
  }

  return (
    <Text
      variant="caption"
      color={Token.color.greyTone50}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </Text>
  )
}
