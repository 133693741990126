import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getUserRestrictions } from '../../api'
import { QueryKey } from '../../constants'
import { UserRestriction } from '../../types'

export const useUserRestrictions = <T extends unknown = UserRestriction[]>(
  options: UseQueryOptions<UserRestriction[], AxiosError, T> = {},
) =>
  useQuery<UserRestriction[], AxiosError, T>(
    [QueryKey.UserRestrictions],
    () => getUserRestrictions(),
    options,
  )
