import { opacity } from './utils'

export const TRANSITION_OFFSET_Y = -100

export const Z_INDICES = {
  CONTENT: 1,
  SIDE: 5,
  OVERLAY: 10,
  TIP: 15,
} as const

const CustomColorChannel = {
  up: 'var(--rui-color-channel-up)',
  down: 'var(--rui-color-channel-down)',
} as const

const CustomColor = {
  up_5: opacity(CustomColorChannel.up, 0.05),
  up_10: opacity(CustomColorChannel.up, 0.1),
  down_5: opacity(CustomColorChannel.down, 0.05),
  down_10: opacity(CustomColorChannel.down, 0.1),
} as const

export const CustomToken = {
  color: CustomColor,
  colorChannel: CustomColorChannel,
} as const
