import { add, fromUnixTime, getUnixTime, isAfter } from 'date-fns'
import { useCallback, useMemo } from 'react'

import { LocalStorageKey, useLocalStorage } from '../../core-storage'
import { PROMPT_COMPLETION_TIMEOUT } from '../config'

export const useFormCompletionTimer = () => {
  const [unixTime, setUnixTime] = useLocalStorage<number | undefined>(
    LocalStorageKey.FeedbackLastCompletedForm,
    undefined,
  )
  const onComplete = useCallback(
    () => setUnixTime(getUnixTime(new Date())),
    [setUnixTime],
  )

  const isTimedOut = useMemo(() => {
    if (!unixTime) {
      return true
    }

    return isAfter(new Date(), add(fromUnixTime(unixTime), PROMPT_COMPLETION_TIMEOUT))
  }, [unixTime])

  return { onComplete, isCompletedRecently: !isTimedOut }
}
