import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { OrderState } from '../../../core-api'
import { FormattedEnumMessage } from '../../../core-intl'

interface OrderStateMessageProps {
  state: OrderState
}

const orderStateMessages = defineMessages<OrderState>({
  [OrderState.New]: {
    id: 'activity.order.state.new.title',
    defaultMessage: 'New',
  },
  [OrderState.PartiallyFilled]: {
    id: 'activity.order.state.partiallyFilled.title',
    defaultMessage: 'Partially Filled',
  },
  [OrderState.Filled]: {
    id: 'activity.order.state.filled.title',
    defaultMessage: 'Filled',
  },
  [OrderState.Canceled]: {
    id: 'activity.order.state.canceled.title',
    defaultMessage: 'Canceled',
  },
  [OrderState.Replaced]: {
    id: 'activity.order.state.replaced.title',
    defaultMessage: 'Replaced',
  },
  [OrderState.Rejected]: {
    id: 'activity.order.state.rejected.title',
    defaultMessage: 'Rejected',
  },
})

export const OrderStateMessage: FC<OrderStateMessageProps> = ({ state }) => {
  return <FormattedEnumMessage id={state} messages={orderStateMessages} />
}
