import { add, differenceInMilliseconds } from 'date-fns'
import { useEffect, useState } from 'react'

import { PROMPT_REQUIRED_SESSION_TIME } from '../config'

const INIT_TIME = new Date()

export const useAppInitTimer = (initTime = INIT_TIME) => {
  const [isTimedOut, setIsTimedOut] = useState(false)

  useEffect(() => {
    const diff = differenceInMilliseconds(
      add(initTime, PROMPT_REQUIRED_SESSION_TIME),
      new Date(),
    )

    const timeout = setTimeout(() => setIsTimedOut(true), diff)

    return () => clearTimeout(timeout)
  }, [initTime])

  return { isTimedOut }
}
