import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { OrderDeclineReason } from '../../../core-api'
import { FormattedEnumMessage } from '../../../core-intl'

type OrderDeclineReasonMessageProps = {
  declineReason: OrderDeclineReason
}

const orderDeclineReasonMessages = defineMessages<OrderDeclineReason>({
  [OrderDeclineReason.None]: {
    id: 'activity.order.declineReason.none.title',
    defaultMessage: 'No reason provided',
  },
  [OrderDeclineReason.InvalidQty]: {
    id: 'activity.order.declineReason.invalidQty.title',
    defaultMessage: 'Invalid quantity',
  },
  [OrderDeclineReason.InvalidPrice]: {
    id: 'activity.order.declineReason.invalidPrice.title',
    defaultMessage: 'Invalid price',
  },
  [OrderDeclineReason.InvalidLockedFunds]: {
    id: 'activity.order.declineReason.invalidLockedFunds.title',
    defaultMessage: 'Invalid locked funds',
  },
  [OrderDeclineReason.NotEnoughLiquidity]: {
    id: 'activity.order.declineReason.notEnoughLiquidity.title',
    defaultMessage: 'Insufficient liquidity',
  },
  [OrderDeclineReason.NotEnoughLockedFunds]: {
    id: 'activity.order.declineReason.notEnoughLockedFunds.title',
    defaultMessage: 'Insufficient locked funds',
  },
  [OrderDeclineReason.NotFound]: {
    id: 'activity.order.declineReason.notFound.title',
    defaultMessage: 'Order not found',
  },
  [OrderDeclineReason.NotReplaceable]: {
    id: 'activity.order.declineReason.notReplaceable.title',
    defaultMessage: 'Order not replaceable',
  },
  [OrderDeclineReason.InvalidCurrencyPair]: {
    id: 'activity.order.declineReason.invalidCurrencyPair.title',
    defaultMessage: 'Invalid currency pair',
  },
  [OrderDeclineReason.DisabledCurrencyPair]: {
    id: 'activity.order.declineReason.disabledCurrencyPair.title',
    defaultMessage: 'Disabled currency pair',
  },
  [OrderDeclineReason.NotEnoughBalance]: {
    id: 'activity.order.declineReason.notEnoughBalance.title',
    defaultMessage: 'Insufficient balance',
  },
  [OrderDeclineReason.EmptyReserveAmount]: {
    id: 'activity.order.declineReason.emptyReserveAmount.title',
    defaultMessage: 'Empty reserve amount',
  },
  [OrderDeclineReason.SelfMatchingProtection]: {
    id: 'activity.order.declineReason.selfMatchingProtection.title',
    defaultMessage: 'Self-matching trade protection',
  },
  [OrderDeclineReason.PriceLimitProtection]: {
    id: 'activity.order.declineReason.priceLimitProtection.title',
    defaultMessage: 'Limit price protection',
  },
  [OrderDeclineReason.InvalidOrderType]: {
    id: 'activity.order.declineReason.invalidOrderType.title',
    defaultMessage: 'Invalid order type',
  },
  [OrderDeclineReason.PrevOrderNotFound]: {
    id: 'activity.order.declineReason.prevOrderNotFound.title',
    defaultMessage: 'Previous order not found',
  },
  [OrderDeclineReason.PrevOrderAnotherSide]: {
    id: 'activity.order.declineReason.prevOrderAnotherSide.title',
    defaultMessage: 'Previous order on another side',
  },
  [OrderDeclineReason.ExchangeClosed]: {
    id: 'activity.order.declineReason.exchangeClosed.title',
    defaultMessage: 'Exchange closed',
  },
})

export const OrderDeclineReasonMessage: FC<OrderDeclineReasonMessageProps> = ({
  declineReason,
}) => {
  if (declineReason === OrderDeclineReason.None) {
    return null
  }

  return <FormattedEnumMessage id={declineReason} messages={orderDeclineReasonMessages} />
}
