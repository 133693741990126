import { HStack, Text, VStack } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { TickerPerformance, TickerPrice } from '../../../core-shared'

interface TickersProps {
  currencyPair: string
}

export const Tickers: FC<TickersProps> = ({ currencyPair }) => (
  <HStack
    space="s-40"
    align="center"
    pr="s-12"
    style={{ whiteSpace: 'nowrap', overflowY: 'auto' }}
  >
    <VStack>
      <TickerPrice variant="h5" currencyPair={currencyPair} />

      <Text variant="caption" color="grey-tone-50">
        <FormattedMessage
          id="terminal.header.lastPrice.label"
          defaultMessage="Last price"
        />
      </Text>
    </VStack>

    <VStack alignSelf="flex-end">
      <TickerPerformance variant="h5" currencyPair={currencyPair} />

      <Text variant="caption" color="grey-tone-50">
        <FormattedMessage
          id="terminal.header.dayChange.label"
          defaultMessage="24h change"
        />
      </Text>
    </VStack>

    <VStack alignSelf="flex-end">
      <TickerPrice
        variant="h5"
        color="grey-tone-50"
        currencyPair={currencyPair}
        type="high24h"
      />

      <Text variant="caption" color="grey-tone-50">
        <FormattedMessage id="terminal.header.dayHigh.label" defaultMessage="24h high" />
      </Text>
    </VStack>

    <VStack alignSelf="flex-end">
      <TickerPrice
        variant="h5"
        color="grey-tone-50"
        currencyPair={currencyPair}
        type="low24h"
      />

      <Text variant="caption" color="grey-tone-50">
        <FormattedMessage id="terminal.header.dayLow.label" defaultMessage="24h low" />
      </Text>
    </VStack>
  </HStack>
)
