import { Icon, Table, TableColumn } from '@revolut/ui-kit'
import { isNumber } from 'lodash'
import { FormattedMessage } from 'react-intl'

import { ExtendedCurrencyPair } from '../../../core-api'
import { CurrencyPair, CurrencyPairMoney } from '../../../core-shared'
import { Percents } from '../../../core-ui'
import { WatchlistStar } from '../WatchlistStar'

export const COLUMNS: TableColumn<ExtendedCurrencyPair>[] = [
  {
    accessor: 'watchlisted',
    Header: ({ column }) => {
      const watchlisted = column.filterValue

      const handleClick = () => {
        column.setFilter?.(watchlisted === undefined ? true : !watchlisted)
      }

      return (
        <Icon
          css={{ cursor: 'pointer' }}
          color={watchlisted ? 'accent' : 'grey-tone-50'}
          name={watchlisted ? 'StarFilled' : 'StarEmpty'}
          size={16}
          onClick={handleClick}
        />
      )
    },
    disableSortBy: true,
    Cell: ({ row, value }) => (
      <Table.Cell>
        <WatchlistStar currencyPair={row.original.pair} checked={value} />
      </Table.Cell>
    ),
    width: 38,
  },
  {
    accessor: 'pair',
    Header: <FormattedMessage id="markets.table.pair.caption" defaultMessage="Pair" />,
    Cell: ({ row }) => (
      <Table.Cell>
        <CurrencyPair currencyPair={row.original.pair} variant="small" size={24} />
      </Table.Cell>
    ),
  },
  {
    accessor: 'midPrice',
    Header: <FormattedMessage id="markets.table.price.caption" defaultMessage="Price" />,
    Cell: ({ row }) => (
      <Table.Cell>
        {isNumber(row.original.midPrice) ? (
          <CurrencyPairMoney
            amount={row.original.midPrice ?? 0}
            currencyPair={row.original.pair}
            variant="quote"
          />
        ) : (
          <Table.EmptyCell />
        )}
      </Table.Cell>
    ),
  },
  {
    accessor: 'percentageChange24h',
    Header: (
      <FormattedMessage
        id="markets.table.performance.caption"
        defaultMessage="24h Change"
      />
    ),
    Cell: ({ row }) => (
      <Table.Cell>
        {isNumber(row.original.percentageChange24h) ? (
          <Percents variant="small" value={row.original.percentageChange24h} />
        ) : (
          <Table.EmptyCell />
        )}
      </Table.Cell>
    ),
  },
]
