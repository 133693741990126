import { Flex, Placeholder } from '@revolut/ui-kit'
import * as Sentry from '@sentry/react'
import { Component, ErrorInfo, PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'

export class WidgetErrorBoundary extends Component<
  PropsWithChildren,
  { error?: Error; errorId?: string }
> {
  constructor(props) {
    super(props)

    this.state = {}
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorId = Sentry.captureException(error, {
      extra: {
        componentStack: errorInfo.componentStack,
      },
    })

    this.setState((prevState) => ({ ...prevState, errorId }))
  }

  handleTryAgain = () => {
    this.setState({ error: undefined })
  }

  render() {
    const { children } = this.props

    const { error, errorId } = this.state

    if (!error) {
      return children
    }

    return (
      <Flex flex={1} alignItems="center" justifyContent="center" pt="s-16">
        <Placeholder>
          <Placeholder.Title>
            <FormattedMessage
              id="errors.general.title"
              defaultMessage="Something went wrong"
            />
          </Placeholder.Title>

          {errorId && <Placeholder.Description>{errorId}</Placeholder.Description>}

          <Placeholder.Action onClick={this.handleTryAgain}>
            <FormattedMessage id="actions.tryAgain" defaultMessage="Try again" />
          </Placeholder.Action>
        </Placeholder>
      </Flex>
    )
  }
}
