import { ConfigKey, getConfigValue } from '../../core-config'

// TODO: Temporary solution until we implement WebSockets https://revolut.atlassian.net/browse/PWCE-707
export const REFETCH_INTERVAL = getConfigValue<number>(
  ConfigKey.TerminalRefetchIntervalMilliseconds,
)

export const DEFAULT_CURRENCY = 'USD'
export const MARKETING_NOTIFICATION_SETTINGS_KEY = 'crypto_x_marketing'

export const DEFAULT_PAGE_SIZE = 100

export const ACTIVE_ORDERS_LIMIT = 100
