import { generatePath } from 'react-router-dom'

import { RestrictedAccessType, RoutePath } from '../constants'
import { getRoutePath } from './getRoutePath'
import { notReachable } from './notReachable'
import { stringifyQueryParams } from './queryParams'

export type GetRouteUrlParams =
  | {
      path:
        | RoutePath.Root
        | RoutePath.Auth
        | RoutePath.AuthSignIn
        | RoutePath.AuthSignInError
        | RoutePath.AuthSignOut
        | RoutePath.AuthSignedOut
        | RoutePath.AuthLimitedAccess
        | RoutePath.AuthVerification
        | RoutePath.Error
        | RoutePath.Activity
        | RoutePath.Home
        | RoutePath.Profile
        | RoutePath.NotificationSettings
        | RoutePath.ProfileAppearance
    }
  | {
      path: RoutePath.Portfolio

      params?: {
        currency?: string
      }
    }
  | {
      path: RoutePath.Trade
      params?: {
        currencyPair?: string
      }
      queryParams?: {
        orderId: string
      }
    }
  | {
      path:
        | RoutePath.ActivityOpenOrders
        | RoutePath.ActivityOrderHistory
        | RoutePath.ActivityTrades
      params?: {
        id?: string
      }
      queryParams?: {
        currencyPair?: string
      }
    }
  | {
      path: RoutePath.ActivityTransfers
      params?: {
        id?: string
      }
      queryParams?: {
        currency?: string
      }
    }
  | {
      path: RoutePath.AuthRestrictedAccess
      params: {
        type: RestrictedAccessType
      }
    }

export const getRouteUrl = (params: GetRouteUrlParams): string => {
  const routePath = getRoutePath(params.path)

  switch (params.path) {
    case RoutePath.Root:
    case RoutePath.Auth:
    case RoutePath.AuthSignIn:
    case RoutePath.AuthSignInError:
    case RoutePath.AuthSignOut:
    case RoutePath.AuthSignedOut:
    case RoutePath.AuthLimitedAccess:
    case RoutePath.AuthVerification:
    case RoutePath.Error:
    case RoutePath.Activity:
    case RoutePath.Home:
    case RoutePath.Profile:
    case RoutePath.NotificationSettings:
    case RoutePath.ProfileAppearance:
      return routePath
    case RoutePath.Portfolio:
    case RoutePath.AuthRestrictedAccess:
      return generatePath(routePath, params.params)
    case RoutePath.ActivityOpenOrders:
    case RoutePath.ActivityOrderHistory:
    case RoutePath.ActivityTrades:
    case RoutePath.ActivityTransfers:
    case RoutePath.Trade: {
      const path = generatePath(routePath, params.params)

      if (params.queryParams) {
        return `${path}?${stringifyQueryParams(params.queryParams)}`
      }

      return generatePath(routePath, params.params)
    }
    default:
      return notReachable(params)
  }
}
