import axios from 'axios'

import { CurrentUserResponse } from '../types'

export const getCurrentUser = async () => {
  const { data } = await axios.get<CurrentUserResponse>(
    '/api/crypto-exchange/user/current',
  )

  return data.user
}
