import * as Sentry from '@sentry/react'
import { useCallback, useEffect, useRef } from 'react'

const NAME_PREFIX = `REVOLUT_EXCHANGE_`

export enum BroadcastChannelMessageType {
  Verification,
}

export const useBroadcastChannel = <T>(type: BroadcastChannelMessageType) => {
  const channelRef = useRef<BroadcastChannel | null>(null)

  useEffect(() => {
    try {
      channelRef.current = new BroadcastChannel(`${NAME_PREFIX}${type}`)
    } catch (e) {
      Sentry.captureException(e)
    }

    return () => {
      if (channelRef.current) {
        channelRef.current.close()
        channelRef.current = null
      }
    }
  }, [type])

  const sendMessage = useCallback((message: T) => {
    channelRef.current?.postMessage(message)
  }, [])

  const receiveMessage = useCallback(
    (): Promise<T> =>
      new Promise((resolve) => {
        const handleMessageEvent = (event: MessageEvent<T>) => {
          resolve(event.data)

          channelRef.current?.removeEventListener('message', handleMessageEvent)
        }

        channelRef.current?.addEventListener('message', handleMessageEvent)
      }),
    [],
  )

  return { sendMessage, receiveMessage }
}
