export enum HttpHeader {
  ClientVersion = 'x-client-version',
  DeviceId = 'x-device-id',
  XAuthCode = 'x-auth-code',
  XAuthCodeVerifier = 'x-auth-code-verifier',
  XIdempotencyKey = 'x-idempotency-key',
}

export enum HttpCode {
  Ok = 200,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  UnavailableLegalReasons = 451,
  InternalServerError = 500,
  BadGateway = 502,
}

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Patch = 'PATCH',
}
