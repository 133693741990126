import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { keyBy } from 'lodash'

import { getTickers } from '../../api'
import { QueryKey, REFETCH_INTERVAL } from '../../constants'
import { Ticker } from '../../types'

export const useTickers = () =>
  useQuery<Ticker[], AxiosError, Record<string, Ticker>>(
    [QueryKey.Tickers],
    () => getTickers(),
    {
      select: (data) => keyBy(data, 'pair'),
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
    },
  )
