import axios from 'axios'

import { CreateOrderData, CreateOrderResponse } from '../types'

export const createOrder = async (data: CreateOrderData) => {
  const { data: response } = await axios.post<CreateOrderResponse>(
    '/api/crypto-exchange/orders/',
    data,
  )

  return response
}
