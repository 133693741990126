import { useEffect, useRef } from 'react'

import { ConfigKey, getConfigValue } from '../../../core-config'
import { TokenInfo } from '../../api'
import { useRefreshToken } from '../useRefreshToken'
import { useTokenInfo } from '../useTokenInfo'

const REFRESH_TOKEN_THRESHOLD_MS = getConfigValue<number>(
  ConfigKey.AuthRefreshTokenThresholdMilliseconds,
)

const REFRESH_TOKEN_TIMEOUT_MS = getConfigValue<number>(
  ConfigKey.AuthRefreshTokenTimeoutMilliseconds,
)

const REFRESH_TOKEN_IN_BACKGROUND = getConfigValue<boolean>(
  ConfigKey.AuthRefreshTokenInBackground,
)

const shouldRefreshToken = (tokenInfo: TokenInfo) => {
  const { lifetime } = tokenInfo.token

  return lifetime < REFRESH_TOKEN_THRESHOLD_MS
}

export const useRefreshTokenEffect = ({ enabled }: { enabled: boolean }) => {
  const isRefreshingRef = useRef(false)

  const { mutate: refreshToken } = useRefreshToken({
    onMutate: () => {
      isRefreshingRef.current = true
    },
    onSettled: () => {
      isRefreshingRef.current = false
    },
  })

  const tokenInfo = useTokenInfo({
    refetchInterval: REFRESH_TOKEN_TIMEOUT_MS,
    refetchIntervalInBackground: REFRESH_TOKEN_IN_BACKGROUND,
    enabled,
  })

  useEffect(() => {
    if (
      tokenInfo.status === 'success' &&
      shouldRefreshToken(tokenInfo.data) &&
      !isRefreshingRef.current
    ) {
      refreshToken()
    }
  }, [tokenInfo, refreshToken])
}
