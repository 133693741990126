import { Box, Flex, StatusWidget } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Asset3dImageName, getImageAssetSrcProps } from '../../../core-utils'

export const CompatibilityCheckerScreenSizePlaceholder: FC = () => (
  <Flex height="100vh">
    <Box m="auto" px="s-16" maxWidth={350}>
      <StatusWidget>
        <StatusWidget.Image {...getImageAssetSrcProps(Asset3dImageName.Trading)} />
        <StatusWidget.Title>
          <FormattedMessage
            id="shared.compatibility.screenSize.placeholder.title"
            defaultMessage="Screen size not supported"
          />
        </StatusWidget.Title>
        <StatusWidget.Description>
          <FormattedMessage
            id="shared.compatibility.screenSize.placeholder.subtitle"
            defaultMessage="Switch to a larger screen size to access the exchange."
          />
        </StatusWidget.Description>
      </StatusWidget>
    </Box>
  </Flex>
)
