export enum Environment {
  Development = 'development',
  Test = 'test',
  Production = 'production',
}

export const ENV_BY_ORIGIN: Record<Environment, string | RegExp> = {
  [Environment.Development]: 'https://local.exchange.revolut.codes',
  [Environment.Test]: /^https:\/\/([a-z0-9]+\.)?exchange.revolut.codes/,
  [Environment.Production]: 'https://exchange.revolut.com',
}

export const DEFAULT_ENV = Symbol.for('default')
