import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getCurrencyPairs } from '../../api'
import { QueryKey } from '../../constants'
import { CurrencyPairsResponse } from '../../types'

export const useCurrencyPairs = <T extends unknown = CurrencyPairsResponse>(
  options: UseQueryOptions<CurrencyPairsResponse, AxiosError, T> = {},
) =>
  useQuery<CurrencyPairsResponse, AxiosError, T>(
    [QueryKey.CurrencyPairs],
    () => getCurrencyPairs(),
    { staleTime: Infinity, ...options },
  )
