import { addSeconds } from 'date-fns'
import Cookies from 'js-cookie'

type CookieStorageOptions = {
  secure: boolean
  expires?: number
}

export class CookieStorage<T extends string> implements Storage {
  secure: boolean

  expires?: number

  constructor(options: CookieStorageOptions) {
    this.secure = options.secure
    this.expires = options.expires
  }

  getItemValue<V = string>(): { ts: number; payload: V } | null {
    throw new Error('Not implemented')
  }

  getItem(key: T) {
    return Cookies.get(key) ?? null
  }

  setItem(key: T, value: string, options: { domain?: string; expires?: number } = {}) {
    const expires = options.expires ?? this.expires

    Cookies.set(key, value, {
      domain: options.domain,
      secure: this.secure,
      sameSite: 'Strict',
      expires: expires ? addSeconds(new Date(), expires) : undefined,
    })
  }

  removeItem(key: T) {
    Cookies.remove(key)
  }

  getAll() {
    return Cookies.get()
  }

  clear(): void {
    throw new Error('Not implemented')
  }

  key(): T | null {
    throw new Error('Not implemented')
  }

  get length(): number {
    throw new Error('Not implemented')
  }
}
