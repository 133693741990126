import { Avatar, Group, Header, Item, Popup, PopupProps } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

import { ConfigKey, getConfigValue, Locale } from '../../../core-config'
import { useIntlContext } from '../../../core-intl'
import { getAssetUrl } from '../../../core-utils'

type LanguagePopupProps = Omit<PopupProps, 'variant'> & {
  onClose: () => void
}

const SUPPORTED_LOCALES = getConfigValue<Locale[]>(ConfigKey.SupportedLocales)

export const LanguagePopup = ({ onClose, ...rest }: LanguagePopupProps) => {
  const { locale, setLocale } = useIntlContext()

  return (
    <Popup variant="bottom-sheet" onClose={onClose} {...rest}>
      <Header variant="bottom-sheet">
        <Header.Title>
          <FormattedMessage id="actions.language" defaultMessage="Language" />
        </Header.Title>
      </Header>

      <Group>
        {SUPPORTED_LOCALES.map((option) => (
          <Item
            key={option.countryId}
            use="button"
            aria-pressed={locale === option.locale}
            onClick={() => {
              setLocale(option.locale)
              onClose()
            }}
          >
            <Item.Avatar>
              <Avatar image={getAssetUrl(`flags/${option.countryId}.svg`)} size={40}>
                {locale === option.locale && <Avatar.Badge useIcon="Check" />}
              </Avatar>
            </Item.Avatar>

            <Item.Content>
              <Item.Title>{option.nativeName}</Item.Title>
            </Item.Content>
          </Item>
        ))}
      </Group>
    </Popup>
  )
}
