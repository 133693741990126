import * as Sentry from '@sentry/react'
import { MessageDescriptor } from 'react-intl'

export const isKnownMessageKey = <
  K extends keyof any,
  T = MessageDescriptor,
  U extends Record<K, T> = Record<K, T>,
>(
  key: K,
  messages: U,
  message?: string,
): boolean => {
  if (key in messages) {
    return true
  }

  Sentry.captureException(new Error(message ?? `Message key ${String(key)} is unknown`))

  return false
}
