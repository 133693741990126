import {
  Amount,
  AmountSkeleton,
  Avatar,
  Button,
  Flex,
  Group,
  HStack,
  Header,
  Icon,
  Item,
  Popup,
  Subheader,
  TabBar,
  Text,
  Token,
  VStack,
  useToggle,
  useTooltip,
} from '@revolut/ui-kit'
import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  CurrencyAssetType,
  CurrencyStatus,
  Pocket,
  UserRestrictionType,
  useCurrenciesDetailsRequired,
  useCurrenciesRequired,
  useUserRestrictionEnabled,
} from '../../../core-api'
import { Feature, useFeatures } from '../../../core-features'
import { useIntl } from '../../../core-intl'
import { AssetAvatar, OrderSizeSuggestions } from '../../../core-shared'
import { AccountSwitcher, InfoText, useMoneyFormat, AmountHero } from '../../../core-ui'
import {
  Asset3dImageName,
  checkRequired,
  getImageAssetSrcProps,
  notReachable,
} from '../../../core-utils'
import { DepositFlowStep, useDepositFlow } from '../../hooks'
import { CurrencyBar } from '../CurrencyBar'
import { ExchangeBreakdownDetails } from '../ExchangeBreakdownDetails'
import { ExchangeBreakdownPopup } from '../ExchangeBreakdownPopup'
import { PocketsGroup } from '../PocketsGroup'
import { TransferDisclaimer } from '../TransferDisclaimer'
import { VerificationPopupContent } from '../VerificationPopupContent'

export type DepositPopupContentProps = {
  currency?: string
  onClose: VoidFunction
}

export const DepositPopupContent = ({ onClose, currency }: DepositPopupContentProps) => {
  const { formatMessage } = useIntl()

  const moneyFormat = useMoneyFormat({ type: 'money-fractional' })

  const currencies = useCurrenciesRequired()

  const currenciesDetails = useCurrenciesDetailsRequired()

  const { restriction } = useUserRestrictionEnabled({
    type: UserRestrictionType.DEPOSIT_CURRENCY,
  })

  const filterRestrictedCurrency = useCallback(
    (code: string) => {
      if (restriction && restriction.type === UserRestrictionType.DEPOSIT_CURRENCY) {
        return !restriction.currencies.includes(code)
      }

      return true
    },
    [restriction],
  )

  const filterRestrictedPockets = useCallback(
    (pocket: Pocket) => filterRestrictedCurrency(pocket.currency),
    [filterRestrictedCurrency],
  )

  const activeFiatCurrencies = useMemo(
    () =>
      Object.values(currencies).filter(
        (item) =>
          item.status === CurrencyStatus.Active &&
          item.assetType === CurrencyAssetType.Fiat &&
          filterRestrictedCurrency(item.code),
      ),
    [currencies, filterRestrictedCurrency],
  )

  const [isExchangeBreakdownOpen, toggleExchangeBreakdownOpen] = useToggle()

  const tooltip = useTooltip()

  const {
    state,

    currencyMessage,
    amountMessage,

    verificationLink,

    quotes,

    invalid,
    submitting,
    disabled,
    status,

    handleChangeStep,
    handleChangeCurrency,
    handleChangePocket,
    handleChangeAmount,
    handleChangeSize,
    handleChangeAssetType,
    handleCancelVerification,
    handleSubmit,
  } = useDepositFlow({
    currency,
    onSuccess: onClose,
  })

  const { step } = state

  const features = useFeatures()

  const isAmountHeroEnabled = features.isFeatureEnabled(Feature.AmountHero)

  switch (step) {
    case DepositFlowStep.CurrencySelection: {
      const isCryptoCurrency = state.assetType === CurrencyAssetType.Crypto

      return (
        <>
          <Header variant="item" displayMode="inline">
            <Header.CloseButton
              aria-label={formatMessage({
                id: 'labels.close',
                defaultMessage: 'Close',
              })}
              onClick={onClose}
            />
            <Header.Title>
              <FormattedMessage
                id="portfolio.deposit.currencySelection.title.v2"
                defaultMessage="Select currency"
              />
            </Header.Title>
          </Header>

          <VStack space="s-16">
            <TabBar variant="segmented fit" width="100%">
              <TabBar.Item
                use="button"
                onClick={() => handleChangeAssetType(CurrencyAssetType.Fiat)}
                aria-selected={state.assetType === CurrencyAssetType.Fiat}
              >
                <FormattedMessage
                  id="portfolio.currencies.fiat.title"
                  defaultMessage="Fiat"
                />
              </TabBar.Item>
              <TabBar.Item
                use="button"
                onClick={() => handleChangeAssetType(CurrencyAssetType.Crypto)}
                aria-selected={state.assetType === CurrencyAssetType.Crypto}
              >
                <FormattedMessage
                  id="portfolio.currencies.crypto.title"
                  defaultMessage="Crypto"
                />
              </TabBar.Item>
            </TabBar>

            {!isCryptoCurrency && (
              <VStack>
                <Subheader>
                  <Subheader.Title {...tooltip.getAnchorProps()}>
                    <InfoText
                      text={
                        <FormattedMessage
                          id="portfolio.deposit.currencySelection.assetType.title"
                          defaultMessage="Add money to Revolut X in"
                        />
                      }
                      description={
                        activeFiatCurrencies.length === 1 ? (
                          <FormattedMessage
                            id="portfolio.deposit.currencySelection.fiat.convertion.subtitle"
                            defaultMessage="All fiat transfers will be converted to {currency}. Select which Revolut balance you want to transfer from"
                            values={{
                              currency: state.currency,
                            }}
                          />
                        ) : undefined
                      }
                      iconSize={16}
                      iconPosition="right"
                      iconColor={Token.color.greyTone50}
                    />
                  </Subheader.Title>
                </Subheader>

                <CurrencyBar
                  currencies={activeFiatCurrencies}
                  currency={state.currency}
                  onChange={handleChangeCurrency}
                />
              </VStack>
            )}

            <VStack>
              <Subheader>
                <Subheader.Title>
                  {isCryptoCurrency ? (
                    <FormattedMessage
                      id="portfolio.deposit.currencySelection.crypto.title"
                      defaultMessage="Deposit crypto from"
                    />
                  ) : (
                    <FormattedMessage
                      id="portfolio.deposit.currencySelection.fiat.title"
                      defaultMessage="Add money from"
                    />
                  )}
                </Subheader.Title>
              </Subheader>

              <PocketsGroup
                subheader={
                  <Subheader>
                    <Subheader.Title>
                      <InfoText
                        text="Revolut"
                        description={
                          state.assetType === CurrencyAssetType.Fiat ? undefined : (
                            <FormattedMessage
                              id="portfolio.deposit.sourceSelection.crypto.description"
                              defaultMessage="These are the balances available to transfer from Revolut. Staked and locked balances are not available."
                            />
                          )
                        }
                        iconSize={16}
                        iconPosition="right"
                        tooltipPlacement="bottom-start"
                      />
                    </Subheader.Title>
                  </Subheader>
                }
                assetType={state.assetType}
                filter={filterRestrictedPockets}
                onItemClick={handleChangePocket}
              />
            </VStack>

            {isCryptoCurrency && (
              <Group>
                <Subheader>
                  <Subheader.Title>
                    <FormattedMessage
                      id="portfolio.deposit.sourceSelection.externalWallet.title"
                      defaultMessage="External wallet"
                    />
                  </Subheader.Title>
                </Subheader>
                <Item>
                  <Item.Avatar>
                    <Avatar
                      variant="brand"
                      bg={Token.color.widgetBackground}
                      size={40}
                      image={getImageAssetSrcProps(Asset3dImageName.Pending).src}
                    />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>
                      <FormattedMessage
                        id="portfolio.deposit.sourceSelection.externalWallet.pending.title"
                        defaultMessage="External deposits coming soon"
                      />
                    </Item.Title>
                  </Item.Content>
                </Item>
              </Group>
            )}
          </VStack>
        </>
      )
    }

    case DepositFlowStep.EnterAmount: {
      const requiredCurrency = checkRequired(
        state.currency,
        `Currency should be defined: ${state.currency}`,
      )

      const { ticker, type } = checkRequired(
        currenciesDetails[requiredCurrency],
        `Currency details data should be defined: ${requiredCurrency}`,
      )

      const showCurrency = requiredCurrency ? type !== CurrencyAssetType.Crypto : false

      return (
        <>
          {isAmountHeroEnabled ? (
            <Header variant="item" displayMode="inline">
              {currency === undefined ? (
                <Header.BackButton
                  aria-label={formatMessage({
                    id: 'labels.back',
                    defaultMessage: 'Back',
                  })}
                  onClick={() => handleChangeStep(DepositFlowStep.CurrencySelection)}
                />
              ) : (
                <Header.CloseButton
                  aria-label={formatMessage({
                    id: 'labels.close',
                    defaultMessage: 'Close',
                  })}
                  onClick={onClose}
                />
              )}
              <Header.Title>
                {type === CurrencyAssetType.Fiat ? (
                  <FormattedMessage
                    id="portfolio.deposit.enterAmount.add.title"
                    defaultMessage="Add {currency}"
                    values={{ currency: ticker }}
                  />
                ) : (
                  <FormattedMessage
                    id="portfolio.deposit.enterAmount.title"
                    defaultMessage="Deposit {currency}"
                    values={{ currency: ticker }}
                  />
                )}
              </Header.Title>
              <Header.Actions>
                <AssetAvatar asset={requiredCurrency} />
              </Header.Actions>
            </Header>
          ) : (
            <Header variant="item">
              {currency === undefined ? (
                <Header.BackButton
                  aria-label={formatMessage({
                    id: 'labels.back',
                    defaultMessage: 'Back',
                  })}
                  onClick={() => handleChangeStep(DepositFlowStep.CurrencySelection)}
                />
              ) : (
                <Header.CloseButton
                  aria-label={formatMessage({
                    id: 'labels.close',
                    defaultMessage: 'Close',
                  })}
                  onClick={onClose}
                />
              )}
              <Header.Title>
                {type === CurrencyAssetType.Fiat ? (
                  <FormattedMessage
                    id="portfolio.deposit.enterAmount.add.title"
                    defaultMessage="Add {currency}"
                    values={{ currency: ticker }}
                  />
                ) : (
                  <FormattedMessage
                    id="portfolio.deposit.enterAmount.title"
                    defaultMessage="Deposit {currency}"
                    values={{ currency: ticker }}
                  />
                )}
              </Header.Title>

              <Header.Avatar>
                <AssetAvatar asset={requiredCurrency} />
              </Header.Avatar>
            </Header>
          )}

          {status === 'success' ? (
            <>
              {isAmountHeroEnabled ? (
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ flex: 1 }}
                >
                  <VStack align="center" space="s-16" mb="s-8">
                    <AmountHero
                      autoFocus
                      name="amount"
                      type="money-fractional"
                      currency={requiredCurrency}
                      value={state.amount}
                      onChange={handleChangeAmount}
                      invalid={invalid}
                      description={
                        quotes?.fromAmount && amountMessage === null ? (
                          <HStack
                            align="center"
                            space="s-8"
                            onClick={() => toggleExchangeBreakdownOpen(true)}
                            css={{ cursor: 'pointer' }}
                            aria-label={formatMessage({
                              id: 'portfolio.deposit.enterAmount.openExchangeBreakdown.label',
                              defaultMessage: 'Open exchange breakdown',
                            })}
                          >
                            <Icon name="16/InfoOutline" size={12} />
                            <Text>
                              {quotes.fee.total.value > 0 ? (
                                <FormattedMessage
                                  id="portfolio.deposit.enterAmount.total.title"
                                  defaultMessage="{amount} inc. fees"
                                  values={{
                                    amount: moneyFormat({
                                      amount:
                                        quotes.fromAmount.value + quotes.fee.total.value,
                                      currency: quotes.fromAmount.symbol,
                                    }),
                                  }}
                                />
                              ) : (
                                moneyFormat({
                                  amount:
                                    quotes.fromAmount.value + quotes.fee.total.value,
                                  currency: quotes.fromAmount.symbol,
                                })
                              )}
                            </Text>
                          </HStack>
                        ) : (
                          amountMessage
                        )
                      }
                    />

                    <AccountSwitcher
                      code={currencyMessage?.replace('Balance: ', '')}
                      image="https://assets.revolut.com/assets/banks/Revolut.svg"
                    >
                      <FormattedMessage
                        id="portfolio.deposit.enterAmount.fromRevolut.label"
                        defaultMessage="From Revolut"
                      />
                    </AccountSwitcher>
                  </VStack>
                </Flex>
              ) : (
                <Amount use="label" mb="s-8" aria-invalid={invalid}>
                  <Amount.Currency
                    value={ticker}
                    description={currencyMessage}
                    invalid={invalid}
                  />
                  <Amount.Input
                    name="amount"
                    type="money-fractional"
                    value={state.amount}
                    currency={requiredCurrency}
                    description={
                      quotes?.fromAmount && amountMessage === null ? (
                        <HStack
                          align="center"
                          space="s-8"
                          onClick={() => toggleExchangeBreakdownOpen(true)}
                          css={{ cursor: 'pointer' }}
                          aria-label={formatMessage({
                            id: 'portfolio.deposit.enterAmount.openExchangeBreakdown.label',
                            defaultMessage: 'Open exchange breakdown',
                          })}
                        >
                          <Icon name="16/InfoOutline" size={12} />
                          <Text>
                            {quotes.fee.total.value > 0 ? (
                              <FormattedMessage
                                id="portfolio.deposit.enterAmount.total.title"
                                defaultMessage="{amount} inc. fees"
                                values={{
                                  amount: moneyFormat({
                                    amount:
                                      quotes.fromAmount.value + quotes.fee.total.value,
                                    currency: quotes.fromAmount.symbol,
                                  }),
                                }}
                              />
                            ) : (
                              moneyFormat({
                                amount: quotes.fromAmount.value + quotes.fee.total.value,
                                currency: quotes.fromAmount.symbol,
                              })
                            )}
                          </Text>
                        </HStack>
                      ) : (
                        amountMessage
                      )
                    }
                    invalid={invalid}
                    showCurrency={showCurrency}
                    onChange={handleChangeAmount}
                  />
                </Amount>
              )}
            </>
          ) : (
            <AmountSkeleton />
          )}

          {(state.pocket?.currency === undefined ||
            state.pocket?.currency === state.currency) && (
            <OrderSizeSuggestions
              sizeRatio={state.sizeRatio}
              onChangeSize={handleChangeSize}
            />
          )}

          <ExchangeBreakdownPopup
            open={isExchangeBreakdownOpen}
            onClose={() => toggleExchangeBreakdownOpen(false)}
            toCurrency={requiredCurrency}
            fromCurrency={state.pocket?.currency}
            amount={state.amount}
            quotes={quotes}
          />

          <Popup.Actions>
            <Button
              elevated
              disabled={disabled}
              pending={submitting}
              onClick={() => handleChangeStep(DepositFlowStep.Confirmation)}
            >
              <FormattedMessage id="actions.review" defaultMessage="Review" />
            </Button>
          </Popup.Actions>
        </>
      )
    }

    case DepositFlowStep.Confirmation: {
      const requiredCurrency = checkRequired(
        state.currency,
        `Currency should be defined: ${state.currency}`,
      )

      const { assetType } = checkRequired(
        currencies?.[requiredCurrency],
        `Currency data should be defined: ${requiredCurrency}`,
      )

      const requiredAmount = checkRequired(state.amount, 'Amount should be defined')
      const requiredPocket = checkRequired(state.pocket, 'Pocket should be defined')

      return (
        <>
          <Header variant="item">
            <Header.BackButton
              aria-label={formatMessage({
                id: 'labels.back',
                defaultMessage: 'Back',
              })}
              onClick={() => handleChangeStep(DepositFlowStep.EnterAmount)}
            />
            <Header.Title>
              <FormattedMessage
                id="portfolio.transfer.confirmation.title"
                defaultMessage="Review transfer"
              />
            </Header.Title>
          </Header>

          <ExchangeBreakdownDetails
            toCurrency={requiredCurrency}
            fromCurrency={requiredPocket.currency}
            amount={requiredAmount}
            quotes={quotes}
          />

          <Popup.Actions>
            <TransferDisclaimer assetType={assetType} />

            <Button elevated pending={submitting} onClick={handleSubmit}>
              <FormattedMessage id="actions.confirm" defaultMessage="Confirm" />
            </Button>
          </Popup.Actions>
        </>
      )
    }

    case DepositFlowStep.Verification: {
      return (
        <VerificationPopupContent
          title={
            <FormattedMessage
              id="portfolio.transfer.verification.title"
              defaultMessage="Approve transfer"
            />
          }
          subtitle={
            <FormattedMessage
              id="portfolio.transfer.verification.subtitle"
              defaultMessage="You’re being redirected to a new page to approve this transfer. If you’re not redirected automatically, click on the link below."
            />
          }
          verificationLink={verificationLink}
          onClose={handleCancelVerification}
        />
      )
    }
    default:
      return notReachable(step)
  }
}
