import { Text, TextSkeleton, VStack } from '@revolut/ui-kit'
import { LineData } from 'lightweight-charts'
import { last } from 'lodash'
import { FC, useMemo, useState } from 'react'

import {
  DEFAULT_CURRENCY,
  TimeSpan,
  useAnalyticsTotalValue,
  useTotalBalance,
} from '../../../core-api'
import { ErrorStatus, ResolutionMessage } from '../../../core-shared'
import {
  LightweightChart,
  LightweightChartData,
  LightweightChartType,
  useMoneyFormat,
} from '../../../core-ui'
import { getTotalValueChartData, getVisibleRange } from '../../utils'
import { TimeSpanTabs } from '../TimeSpanTabs'

export const AnalyticsTotalValue: FC = () => {
  const [timeSpan, setTimeSpan] = useState(TimeSpan['1m'])

  const formatMoney = useMoneyFormat({ currency: DEFAULT_CURRENCY })

  const totalBalance = useTotalBalance()

  const {
    data: value,
    refetch,
    status,
  } = useAnalyticsTotalValue({
    timeSpan,
  })

  const chartData: LightweightChartData[] = useMemo(() => {
    let result: LineData[] = []

    if (status === 'success' && totalBalance.status === 'success') {
      result = getTotalValueChartData(value, totalBalance.total)
    }

    return [
      {
        type: LightweightChartType.Area,
        data: result,
      },
    ]
  }, [status, value, totalBalance])

  const chartOptions = useMemo(
    () => ({
      localization: {
        priceFormatter: (price: number) => formatMoney(price),
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
      },
    }),
    [formatMoney],
  )

  if (status === 'error') {
    return <ErrorStatus onRetry={refetch} />
  }

  const visibleRange = getVisibleRange(chartData)

  const isLoading = status === 'loading' || totalBalance.status === 'loading'

  return (
    <VStack space="s-16" height="100%" px="s-16" pb="s-16" pt={{ all: 's-16', md: '0' }}>
      <VStack>
        {isLoading ? (
          <TextSkeleton variant="h2" size={8} />
        ) : (
          <Text variant="h2">{formatMoney(last(chartData[0]?.data)?.value ?? 0)}</Text>
        )}

        <Text variant="caption" color="grey-tone-50" mb="s-16">
          <ResolutionMessage timeSpan={timeSpan} />
        </Text>
      </VStack>

      <LightweightChart
        data={chartData}
        options={chartOptions}
        loading={isLoading}
        visibleRange={visibleRange}
      />

      <TimeSpanTabs value={timeSpan} onChange={setTimeSpan} />
    </VStack>
  )
}
