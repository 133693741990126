import { EventTracker, EventTrackerOptions } from '@revolut-internal/event-tracker'

import { ConfigKey, getConfigValue } from '../../core-config'
import { getDeviceId } from '../../core-utils'
import { pathResolver } from './pathResolver'
import { productResolver } from './productResolver'

const { REACT_APP_RELEASE_TAG: appVersion } = process.env

const EVENT_TRACKER_OPTIONS: EventTrackerOptions<'cryptoExchange'> = {
  productType: 'cryptoExchange',
  appId: window.location.hostname,
  appVersion,
  deviceId: getDeviceId(),
  pathResolver,
  productResolver,
  mode: getConfigValue(ConfigKey.EventTrackerMode),
  loggerEnabled: getConfigValue(ConfigKey.EventTrackerLoggerEnabled),
}

export const eventTracker = new EventTracker(EVENT_TRACKER_OPTIONS)
