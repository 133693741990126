import { CurrencyAssetType, DEFAULT_CURRENCY, Pocket } from '../../../core-api'
import { DepositFlowState, DepositFlowStep } from './types'

export enum DepositFlowActionType {
  SetStep = 'SetStep',
  SetSizeRatio = 'SetSizeRatio',
  SetPocket = 'SetPocket',
  UpdateCurrency = 'UpdateCurrency',
  UpdateAmount = 'UpdateAmount',
  UpdatePocket = 'UpdatePocket',
  UpdateAssetType = 'UpdateAssetType',
}

type DepositFlowAction =
  | {
      type: DepositFlowActionType.SetStep
      step: DepositFlowStep
    }
  | {
      type: DepositFlowActionType.SetSizeRatio
      sizeRatio: number
    }
  | {
      type: DepositFlowActionType.SetPocket
      pocket: Pocket
    }
  | {
      type: DepositFlowActionType.UpdateCurrency
      currency: string
    }
  | {
      type: DepositFlowActionType.UpdateAmount
      payload: number | null
    }
  | {
      type: DepositFlowActionType.UpdatePocket
      pocket: Pocket
    }
  | {
      type: DepositFlowActionType.UpdateAssetType
      assetType: CurrencyAssetType
    }

export const depositFlowReducer = (
  state: DepositFlowState,
  action: DepositFlowAction,
): DepositFlowState => {
  switch (action.type) {
    case DepositFlowActionType.SetStep:
      return {
        ...state,
        step: action.step,
      }
    case DepositFlowActionType.SetSizeRatio:
      return {
        ...state,
        sizeRatio: action.sizeRatio,
      }
    case DepositFlowActionType.SetPocket:
      return {
        ...state,
        pocket: action.pocket,
      }
    case DepositFlowActionType.UpdateCurrency:
      return {
        ...state,
        amount: null,
        sizeRatio: null,
        pocket: undefined,
        currency: action.currency,
      }
    case DepositFlowActionType.UpdateAmount:
      return {
        ...state,
        amount: action.payload,
        sizeRatio: null,
      }
    case DepositFlowActionType.UpdatePocket:
      return {
        ...state,
        step: DepositFlowStep.EnterAmount,
        currency:
          action.pocket.assetType === CurrencyAssetType.Fiat
            ? state.currency
            : action.pocket.currency,
        amount: null,
        sizeRatio: null,
        pocket: action.pocket,
      }
    case DepositFlowActionType.UpdateAssetType: {
      const prevAssetType = state.assetType

      return {
        ...state,
        assetType: action.assetType,
        currency:
          prevAssetType === CurrencyAssetType.Crypto ? DEFAULT_CURRENCY : state.currency,
      }
    }

    default:
      return state
  }
}
