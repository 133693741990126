import { FC } from 'react'

import { notReachable } from '../../../core-utils'
import { ActivityType } from '../../constants'
import { OpenOrdersTable } from '../OpenOrdersTable'
import { OrdersHistoryTable } from '../OrdersHistoryTable'
import { TradesTable } from '../TradesTable'
import { TransfersTable } from '../TransfersTable'

interface ActivityTableProps {
  type: ActivityType

  selectedRowId: string | null
  onSelectRow: (id: string) => void
}

export const ActivityTable: FC<ActivityTableProps> = ({
  type,

  selectedRowId,
  onSelectRow,
}) => {
  switch (type) {
    case ActivityType.OpenOrders:
      return (
        <OpenOrdersTable
          selectedRowId={selectedRowId}
          onSelectRow={onSelectRow}
          variant="advanced"
        />
      )
    case ActivityType.OrdersHistory:
      return (
        <OrdersHistoryTable selectedRowId={selectedRowId} onSelectRow={onSelectRow} />
      )
    case ActivityType.Trades:
      return (
        <TradesTable
          selectedRowId={selectedRowId}
          onSelectRow={onSelectRow}
          variant="advanced"
        />
      )
    case ActivityType.Transfers:
      return (
        <TransfersTable
          selectedRowId={selectedRowId}
          onSelectRow={onSelectRow}
          variant="advanced"
        />
      )
    default:
      return notReachable(type)
  }
}
