import { ChartResolution, ChartType } from '@revolut-internal/trading-charts'
import { FC } from 'react'

import { LocalStorageKey, useLocalStorage } from '../../../core-storage'
import { PriceChart } from '../../../feature-markets'

const CHART_ID = 'TRADE_PRICE_CHART'

interface PriceChartWidgetProps {
  currencyPair: string
  visible: boolean
}

export const PriceChartWidget: FC<PriceChartWidgetProps> = ({
  currencyPair,
  visible,
}) => {
  const [chartType, setChartType] = useLocalStorage<ChartType>(
    LocalStorageKey.TradeSelectedChartType,
    ChartType.Candle,
  )

  const [resolution, setResolution] = useLocalStorage<ChartResolution>(
    LocalStorageKey.TradeSelectedResolution,
    ChartResolution.FiveMinutes,
  )

  return (
    <PriceChart
      px="s-16"
      pb="s-16"
      currencyPair={currencyPair}
      chartId={CHART_ID}
      type={chartType}
      onChangeType={setChartType}
      resolution={resolution}
      onChangeResolution={setResolution}
      style={
        visible
          ? { display: 'flex' }
          : { position: 'absolute', width: '100%', visibility: 'hidden' }
      }
    />
  )
}
