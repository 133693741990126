import {
  ColorMode,
  ColorSelector,
  HStack,
  Image,
  Page,
  Radio,
  RadioGroup,
  Subheader,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { useIntl } from '../../../core-intl'
import { useGoBack } from '../../../core-shared'
import {
  ThemeMode,
  ThemePaletteType,
  useThemeMode,
  useThemePalette,
} from '../../../core-ui'
import { getPigeonAssetUrl, getRouteUrl, RoutePath } from '../../../core-utils'
import { ThemePaletteTypeMessage } from '../ThemePaletteTypeMessage'

// Temporary solution until UI kit catches up actual design
const PaletteSelector = styled.div<{
  leftColor: string
  rightColor: string
}>`
  & [class*='Circle'] > * {
    background: linear-gradient(
      90deg,
      ${({ leftColor }) => leftColor} 50%,
      ${({ rightColor }) => rightColor} 50%
    );
  }
`

const THEME_THUBMNAIL_BY_PALLETE: Record<ThemePaletteType, Record<ThemeMode, string>> = {
  [ThemePaletteType.Default]: {
    [ColorMode.LIGHT]: getPigeonAssetUrl(
      'cd14919c-a7ed-4f79-a593-4a7c9d3cff62/c84274c0-8816-4967-a332-0e621414f600.png',
    ),
    [ColorMode.DARK]: getPigeonAssetUrl(
      '9da281cb-b2ab-47e1-be71-b39b9997e783/567598ca-6acd-4998-a23f-be73c521b5a2.png',
    ),
    auto: getPigeonAssetUrl(
      '0d688660-4c4a-4f4d-b590-1ca1a060ee93/32281aa6-b9a5-4f7b-823d-c53f0f973a4b.png',
    ),
  },
  [ThemePaletteType.Revolut]: {
    [ColorMode.LIGHT]: getPigeonAssetUrl(
      '1108122d-746b-4ebe-84bc-74eadb3a0edb/7f7b318a-57b4-498f-8ca9-dfe97d108fb3.png',
    ),
    [ColorMode.DARK]: getPigeonAssetUrl(
      'ec331d6a-56d8-4bd8-aa27-7edcdfc75810/996dea83-32fe-4ef9-8250-b9e0e832b04c.png',
    ),
    auto: getPigeonAssetUrl(
      '55cf82bc-4ddc-4879-ac05-664195e549f6/21997e21-986e-4366-9b1b-e91bbdd8f368.png',
    ),
  },
}

const getThemeThumbnail = (paletteType: ThemePaletteType, themeMode: ThemeMode) =>
  THEME_THUBMNAIL_BY_PALLETE[paletteType][themeMode]

export const Appearance: FC = () => {
  const { formatMessage } = useIntl()

  const handleGoBack = useGoBack(getRouteUrl({ path: RoutePath.Profile }))

  const { paletteType, setPaletteType } = useThemePalette()

  const { themeMode, setThemeMode } = useThemeMode()

  const handleChangePaletteType = (value: ThemePaletteType | null) => {
    if (value !== null) {
      setPaletteType(value)
    }
  }

  const handleChangeThemeMode = (value: ThemeMode | null) => {
    if (value !== null) {
      setThemeMode(value)
    }
  }

  return (
    <>
      <Page.Header onBack={handleGoBack}>
        <FormattedMessage id="profile.appearance.title" defaultMessage="Appearance" />
      </Page.Header>

      <Page.Main>
        <Subheader>
          <Subheader.Title>
            <FormattedMessage
              id="profile.appearance.colour.title"
              defaultMessage="Colour"
            />
          </Subheader.Title>

          <Subheader.Side>
            <ThemePaletteTypeMessage type={paletteType} />
          </Subheader.Side>
        </Subheader>

        <Widget p="s-16">
          <RadioGroup value={paletteType} onChange={handleChangePaletteType}>
            {(group) => (
              <HStack space="s-8" css={{ justifyContent: 'center' }}>
                <PaletteSelector leftColor={Token.color.up} rightColor={Token.color.down}>
                  <ColorSelector
                    aria-label={formatMessage({
                      id: 'labels.theme.palette.default',
                      defaultMessage: 'Default',
                    })}
                    {...group.getInputProps({ value: ThemePaletteType.Default })}
                  />
                </PaletteSelector>

                <PaletteSelector
                  leftColor={Token.color.blue}
                  rightColor={Token.color.pink}
                >
                  <ColorSelector
                    aria-label={formatMessage({
                      id: 'labels.theme.palette.revolut',
                      defaultMessage: 'Revolut',
                    })}
                    {...group.getInputProps({ value: ThemePaletteType.Revolut })}
                  />
                </PaletteSelector>
              </HStack>
            )}
          </RadioGroup>
        </Widget>

        <Subheader>
          <Subheader.Title>
            <FormattedMessage
              id="profile.appearance.theme.title"
              defaultMessage="Theme"
            />
          </Subheader.Title>
        </Subheader>

        <Widget p="s-16">
          <RadioGroup value={themeMode} onChange={handleChangeThemeMode}>
            {(group) => (
              <HStack space="s-32" css={{ justifyContent: 'center', userSelect: 'none' }}>
                <VStack
                  use="label"
                  space="s-12"
                  align="center"
                  style={{ cursor: 'pointer' }}
                >
                  <Image
                    variant="round"
                    width={129}
                    src={getThemeThumbnail(paletteType, ColorMode.LIGHT)}
                    alt="Light"
                  />
                  <Text>
                    <FormattedMessage
                      id="profile.appearance.theme.light"
                      defaultMessage="Light"
                    />
                  </Text>
                  <Radio
                    aria-label={formatMessage({
                      id: 'labels.theme.light',
                      defaultMessage: 'Light',
                    })}
                    {...group.getInputProps({ value: ColorMode.LIGHT })}
                  />
                </VStack>
                <VStack
                  use="label"
                  space="s-12"
                  align="center"
                  style={{ cursor: 'pointer' }}
                >
                  <Image
                    variant="round"
                    width={129}
                    src={getThemeThumbnail(paletteType, ColorMode.DARK)}
                    alt="Dark"
                  />
                  <Text>
                    <FormattedMessage
                      id="profile.appearance.theme.dark"
                      defaultMessage="Dark"
                    />
                  </Text>
                  <Radio
                    aria-label={formatMessage({
                      id: 'labels.theme.dark',
                      defaultMessage: 'Dark',
                    })}
                    {...group.getInputProps({ value: ColorMode.DARK })}
                  />
                </VStack>
                <VStack
                  use="label"
                  space="s-12"
                  align="center"
                  style={{ cursor: 'pointer' }}
                >
                  <Image
                    variant="round"
                    width={129}
                    src={getThemeThumbnail(paletteType, 'auto')}
                    alt="System"
                  />
                  <Text>
                    <FormattedMessage
                      id="profile.appearance.theme.auto"
                      defaultMessage="System"
                    />
                  </Text>
                  <Radio
                    aria-label={formatMessage({
                      id: 'labels.theme.system',
                      defaultMessage: 'System',
                    })}
                    {...group.getInputProps({ value: 'auto' })}
                  />
                </VStack>
              </HStack>
            )}
          </RadioGroup>
        </Widget>
      </Page.Main>
    </>
  )
}
