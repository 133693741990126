import { Box, ErrorWidget, Layout } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { EventTrackerHiddenHeader } from '../../../../packlets/core-event-tracker'
import {
  Asset3dImageName,
  getImageAssetSrcProps,
  getRouteUrl,
  RoutePath,
} from '../../../../packlets/core-utils'

export const SignInErrorPage = () => {
  const history = useHistory()

  return (
    <Layout>
      <Layout.Main>
        <EventTrackerHiddenHeader screen="authSignInError" />

        <Box my="auto">
          <ErrorWidget>
            <ErrorWidget.Image {...getImageAssetSrcProps(Asset3dImageName.Error)} />
            <ErrorWidget.Title>
              <FormattedMessage
                id="errors.general.title"
                defaultMessage="Something went wrong"
              />
            </ErrorWidget.Title>
            <ErrorWidget.Description>
              <FormattedMessage
                id="auth.signInError.subtitle"
                defaultMessage="Try again in a few minutes"
              />
            </ErrorWidget.Description>

            <ErrorWidget.Action
              onClick={() => history.replace(getRouteUrl({ path: RoutePath.AuthSignIn }))}
            >
              <FormattedMessage id="actions.retry" defaultMessage="Retry" />
            </ErrorWidget.Action>
          </ErrorWidget>
        </Box>
      </Layout.Main>
    </Layout>
  )
}
