import { Details, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

const CondensedDetailsRoot = styled(Details)`
  padding-top: ${Token.space.s8};
  padding-bottom: ${Token.space.s8};s
`

export const CondensedDetails = Object.assign(CondensedDetailsRoot, {
  Title: Details.Title,
  Content: Details.Content,
  Note: Details.Note,
})
