import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getCurrencies } from '../../api'
import { QueryKey } from '../../constants'
import { CurrenciesResponse } from '../../types'

export const useCurrencies = <T extends unknown = CurrenciesResponse>(
  options: UseQueryOptions<CurrenciesResponse, AxiosError, T> = {},
) =>
  useQuery<CurrenciesResponse, AxiosError, T>(
    [QueryKey.Currencies],
    () => getCurrencies(),
    { staleTime: Infinity, ...options },
  )
