import { Group, Placeholder } from '@revolut/ui-kit'
import { isEmpty } from 'lodash'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useOrders } from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { GroupSkeleton } from '../../../core-ui'
import { getImageAssetSrcProps, Asset3dImageName } from '../../../core-utils'
import { OrdersGroupItem } from './OrdersGroupItem'

interface OrdersGroupProps {
  limit: number
  currencyPair?: string
  onItemClick?: (id: string) => void
}

export const OrdersGroup: FC<OrdersGroupProps> = ({
  currencyPair,
  limit,
  onItemClick,
}) => {
  const orders = useOrders({
    currencyPair,
  })

  if (orders.status === 'loading') {
    return <GroupSkeleton />
  }

  if (orders.status === 'error') {
    return <ErrorStatus onRetry={orders.refetch} />
  }

  if (isEmpty(orders.data.items)) {
    return (
      <Placeholder>
        <Placeholder.Image {...getImageAssetSrcProps(Asset3dImageName.Orders)} />
        <Placeholder.Title>
          <FormattedMessage
            id="activity.openOrders.group.empty.title"
            defaultMessage="No open orders"
          />
        </Placeholder.Title>
        <Placeholder.Description>
          <FormattedMessage
            id="activity.openOrders.group.empty.description"
            defaultMessage="Your open orders will appear here"
          />
        </Placeholder.Description>
      </Placeholder>
    )
  }

  const limitedOrders = orders.data.items.slice(0, limit)

  return (
    <Group>
      {limitedOrders.map((item) => (
        <OrdersGroupItem key={item.orderId} order={item} onClick={onItemClick} />
      ))}
    </Group>
  )
}
