import { Avatar, Group, Item, Search, Token, VStack } from '@revolut/ui-kit'
import { FC, ReactNode, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  CurrencyAssetType,
  ExtendedBalance,
  useStableSortedBalances,
} from '../../../core-api'
import { ErrorStatus, NoResultsPlaceholder } from '../../../core-shared'
import { GroupSkeleton } from '../../../core-ui'
import {
  Asset3dImageName,
  getImageAssetProps,
  matchFilterValue,
} from '../../../core-utils'
import { BalancesGroupItem } from './BalancesGroupItem'

const ITEMS_SEARCH_LIMIT = 10

interface BalancesGroupProps {
  subheader?: ReactNode
  balance: ExtendedBalance | undefined
  assetType: CurrencyAssetType
  onClickItem: (balance: ExtendedBalance) => void
  filter?: (balance: ExtendedBalance) => boolean
}

export const BalancesGroup: FC<BalancesGroupProps> = ({
  subheader,
  balance,
  assetType,
  onClickItem,
  filter,
}) => {
  const { formatMessage } = useIntl()

  const balances = useStableSortedBalances()

  const [search, setSearch] = useState('')

  const items = useMemo(() => {
    if (balances.data) {
      let result = balances.data

      if (assetType) {
        result = result.filter((pocket) => pocket.assetType === assetType)
      }

      if (filter) {
        result = result.filter(filter)
      }

      return result
    }

    return []
  }, [balances.data, assetType, filter])

  const shouldShowSearch = items.length >= ITEMS_SEARCH_LIMIT

  const filteredItems = useMemo(
    () => items.filter((item) => matchFilterValue(item.currency, search)),
    [items, search],
  )

  if (balances.status === 'loading') {
    return <GroupSkeleton />
  }

  if (balances.status === 'error') {
    return <ErrorStatus onRetry={balances.refetch} />
  }

  return (
    <VStack space="s-16">
      {shouldShowSearch && (
        <Search
          placeholder={formatMessage({
            id: 'labels.search',
            defaultMessage: 'Search',
          })}
          value={search}
          onChange={setSearch}
        />
      )}

      <Group>
        {subheader}

        {filteredItems.length === 0 && !search && (
          <Item>
            <Item.Avatar>
              <Avatar
                variant="brand"
                bg={Token.color.widgetBackground}
                size={40}
                image={getImageAssetProps(Asset3dImageName.Trades)}
              />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <FormattedMessage
                  id="portfolio.balances.group.empty.title"
                  defaultMessage="No balances available"
                />
              </Item.Title>
            </Item.Content>
          </Item>
        )}

        {filteredItems.length === 0 && search && (
          <NoResultsPlaceholder search={search} onClearSearch={() => setSearch('')} />
        )}

        {filteredItems.map((item) => (
          <BalancesGroupItem
            key={item.currency}
            selected={item.currency === balance?.currency}
            balance={item}
            onClick={onClickItem}
          />
        ))}
      </Group>
    </VStack>
  )
}
