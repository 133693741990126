import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getCurrenciesDetails } from '../../api'
import { QueryKey } from '../../constants'
import { CurrencyDetails } from '../../types'

export const useCurrenciesDetails = <T extends unknown = Record<string, CurrencyDetails>>(
  options: UseQueryOptions<Record<string, CurrencyDetails>, AxiosError, T> = {},
) =>
  useQuery<Record<string, CurrencyDetails>, AxiosError, T>(
    [QueryKey.CurrenciesDetails],
    () => getCurrenciesDetails(),
    { staleTime: Infinity, ...options },
  )
