import { cloneDeep, fill } from 'lodash'

import { GRID_LAYOUT_RESIZER_SIZE } from '../constants'
import {
  GridLayoutTemplate,
  GridLayoutTemplateArea,
  GridLayoutTemplateColumn,
  GridLayoutTemplateRow,
} from '../types'

const addGridLayoutRowResizers = (
  columns: GridLayoutTemplateRow[],
): GridLayoutTemplateRow[] =>
  columns.flatMap((item, index) =>
    index === 0
      ? [item]
      : [
          {
            type: 'ROW_RESIZER',
            width: GRID_LAYOUT_RESIZER_SIZE,
          },
          item,
        ],
  )

const addGridLayoutColumnResizers = (
  rows: GridLayoutTemplateColumn[],
): GridLayoutTemplateColumn[] =>
  rows.flatMap((item, index) =>
    index === 0
      ? [item]
      : [
          {
            type: 'COLUMN_RESIZER',
            height: GRID_LAYOUT_RESIZER_SIZE,
          },
          item,
        ],
  )
const addGridLayoutAreaResizers = <T extends string>(
  areas: GridLayoutTemplateArea<T>[][],
): GridLayoutTemplateArea<T>[][] => {
  let nextAreas = areas.map((row) =>
    row.reduce<GridLayoutTemplateArea<T>[]>((result, item, index, arr) => {
      const prevItem = arr[index - 1]

      if (!prevItem) {
        return [...result, item]
      }

      if (prevItem.name === item.name) {
        return [...result, item, item]
      }

      return [
        ...result,
        {
          name: 'COLUMN_RESIZER',
          targetIndex: index - 1,
          index: result.length,
        },
        item,
      ]
    }, []),
  )

  nextAreas = nextAreas.reduce<GridLayoutTemplateArea<T>[][]>((result, row, rowIndex) => {
    if (rowIndex === 0) {
      return [...result, row]
    }

    let resizers: GridLayoutTemplateArea<T>[] = fill(Array(row.length), {
      name: 'ROW_RESIZER',
      targetIndex: rowIndex - 1,
      index: result.length,
    })

    const prevRow = nextAreas[rowIndex - 1]
    const nextRow = nextAreas[rowIndex]

    if (prevRow && nextRow) {
      resizers = resizers.map((item, columnIndex) => {
        const prevItem = prevRow[columnIndex]
        const nextItem = nextRow[columnIndex]

        if (nextItem && prevItem?.name === nextItem?.name) {
          return nextItem
        }

        return item
      })
    }

    return [...result, resizers, row]
  }, [])

  return nextAreas
}

export const addGridLayoutResizers = <T extends string>(
  template: GridLayoutTemplate<T>,
): GridLayoutTemplate<T> => {
  const { rows, columns, areas } = cloneDeep(template)

  const nextRows = addGridLayoutRowResizers(rows)

  const nextColumns = addGridLayoutColumnResizers(columns)

  const nextAreas = addGridLayoutAreaResizers(areas)

  return {
    rows: nextRows,
    columns: nextColumns,
    areas: nextAreas,
  }
}
