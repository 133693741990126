import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getTradesByOrderId } from '../../api'
import { QueryKey } from '../../constants'
import { TradesReponse } from '../../types'

export const useInfiniteTradesByOrderId = <T extends unknown = TradesReponse>(
  id: string,
  options: UseInfiniteQueryOptions<TradesReponse, AxiosError, T> = {},
) =>
  useInfiniteQuery<TradesReponse, AxiosError, T>(
    [QueryKey.TradesByOrderId, id],
    ({ pageParam }) =>
      getTradesByOrderId(id, {
        cursor: pageParam,
      }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.cursor !== '' ? lastPage.cursor : undefined,
      ...options,
    },
  )
