import { encodeDeviceId, isEncodeDeviceIdSupported } from '@revolut-internal/web-commons'
import axios, { AxiosError, InternalAxiosRequestConfig, isAxiosError } from 'axios'

import {
  REFRESH_TOKEN_URL,
  TOKEN_INFO_URL,
  SIGN_IN_URL,
  refreshToken,
} from '../../core-auth'
import { ConfigKey, getConfigValue } from '../../core-config'
import { browser, getDeviceId } from '../../core-utils'
import { ErrorCode, HttpCode, HttpHeader, HttpMethod } from '../constants'
import { ErrorResponse } from '../types'
import { delay } from './delay'

const MAX_RETRIES = 5
const RETRY_DELAY_MS = 1000

interface AxiosRequestConfig extends InternalAxiosRequestConfig {
  retry?: number
}

const setAxiosCommonHeader = (header: HttpHeader, value: string | boolean | number) => {
  axios.defaults.headers.common[header] = value
}

const shouldRetryRequest = (config: AxiosRequestConfig, error: AxiosError) => {
  const method = config.method?.toUpperCase()

  if (error.response && error.response.status <= HttpCode.InternalServerError) {
    return false
  }

  return (
    method === HttpMethod.Get &&
    (config.retry === undefined || config.retry < MAX_RETRIES)
  )
}

export const setupAxios = ({
  signInUrl,
  signedOutUrl,
}: {
  signInUrl: string
  signedOutUrl: string
}) => {
  const deviceId = getDeviceId()

  let encodedDeviceId = deviceId

  // TODO: Throw Sentry exeception if encoding is not supported
  if (isEncodeDeviceIdSupported()) {
    encodedDeviceId = encodeDeviceId({
      deviceIdVersion: 3,
      deviceId,
    }).toString()
  }

  setAxiosCommonHeader(HttpHeader.DeviceId, encodedDeviceId)
  setAxiosCommonHeader(HttpHeader.ClientVersion, getConfigValue(ConfigKey.ClientVersion))

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (isAxiosError(error) && error.config) {
        const config = error.config as AxiosRequestConfig

        if (error.response?.status === HttpCode.Unauthorized) {
          const data = error.response?.data as ErrorResponse | undefined

          if (data?.code === ErrorCode.AccessTokenExpired) {
            return refreshToken().then(() => axios.request(config))
          }

          if (
            data?.code !== ErrorCode.SSOVerificationRequired &&
            config.url !== SIGN_IN_URL
          ) {
            browser.navigateTo(signInUrl)
          }
        } else if (shouldRetryRequest(config, error)) {
          config.retry = config.retry ?? 0

          const retryDelay = RETRY_DELAY_MS * Math.pow(2, config.retry)

          config.retry += 1

          return delay(retryDelay).then(() => axios.request(config))
        } else if (
          config?.url &&
          [TOKEN_INFO_URL, REFRESH_TOKEN_URL].includes(config?.url)
        ) {
          browser.navigateTo(signedOutUrl)
        }
      }

      return Promise.reject(error)
    },
  )
}
