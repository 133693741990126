import { CurrenciesResponse } from '../../types'
import { useCurrencies } from '../useCurrencies'

const selectCurrency = (currency: string) => (currencies: CurrenciesResponse) =>
  currencies[currency]

export const useCurrency = (currency: string) => {
  const { data } = useCurrencies({
    select: selectCurrency(currency),
  })

  return data
}
