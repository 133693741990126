export enum ChartInterval {
  FiveMinutes = '5m',
  FifteenMinutes = '15m',
  ThirtyMinutes = '30m',
  OneHour = '1h',
  NinetyMinutes = '90m',
  FourHours = '4h',
  OneDay = '1d',
  TwoDays = '2d',
  FourDays = '4d',
  OneWeek = '1w',
  SevenDays = '7d',
}

export interface CurrencyPairChartResponse {
  t: number[]
  h: number[]
  l: number[]
  o: number[]
  c: number[]
}

export interface CurrencyPairChartParams {
  pair: string
  interval: ChartInterval
  countBack: number
  to?: number
}
