import axios from 'axios'

import { AnalyticsResponse, PerformancePoint, AnalyticsParams } from '../types'

export const getAnalyticsPerformance = async ({ timeSpan }: AnalyticsParams) => {
  const { data } = await axios.get<AnalyticsResponse<PerformancePoint>>(
    `/api/crypto-exchange/trading/performance/${timeSpan}`,
  )

  return data
}
