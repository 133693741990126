import axios from 'axios'

import { WatchlistParams, WatchlistResponse } from '../types'

export const getWatchlist = async (params: WatchlistParams) => {
  const { data } = await axios.get<WatchlistResponse>(
    '/api/crypto-exchange/watchlist/items',
    { params },
  )

  return data.items
}
