import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { withdraw } from '../../api'
import { QueryKey, REFETCH_INTERVAL } from '../../constants'
import { WithdrawData, WithdrawErrorResponse, WithdrawResponse } from '../../types'

export const useWithdraw = (
  options: UseMutationOptions<
    WithdrawResponse,
    AxiosError<WithdrawErrorResponse, WithdrawData>,
    WithdrawData
  > = {},
) => {
  const queryClient = useQueryClient()

  return useMutation<
    WithdrawResponse,
    AxiosError<WithdrawErrorResponse, WithdrawData>,
    WithdrawData
  >(withdraw, {
    ...options,
    onSuccess: (...args) => {
      // TODO: Temporary solution until we have a proper WS implementation https://revolut.atlassian.net/browse/PWCE-707
      setTimeout(() => {
        queryClient.invalidateQueries([QueryKey.Balances])
        queryClient.invalidateQueries([QueryKey.Wallet])
        queryClient.invalidateQueries([QueryKey.Transfers])
      }, REFETCH_INTERVAL)

      options.onSuccess?.(...args)
    },
  })
}
