import { Button, Flex, Header, Popup, Image } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useIntl } from '../../../core-intl'
import { useCountdown } from '../../../core-ui'
import { getImageAssetSrcProps, Asset3dImageName } from '../../../core-utils'

interface OnboardingPopupCoolDownPeriodContentProps {
  coolDownEndDate?: number
  onClose: VoidFunction
}

export const OnboardingPopupCoolDownPeriodContent: FC<
  OnboardingPopupCoolDownPeriodContentProps
> = ({ coolDownEndDate, onClose }) => {
  const { formatMessage } = useIntl()

  const timer = useCountdown({ endDate: coolDownEndDate })

  return (
    <>
      <Header variant="item">
        <Header.CloseButton
          aria-label={formatMessage({
            id: 'labels.close',
            defaultMessage: 'Close',
          })}
        />
        <Header.Title>
          <FormattedMessage
            id="onboarding.coolDownPeriod.title"
            defaultMessage="Trade crypto in {value}"
            values={{
              value: timer,
            }}
          />
        </Header.Title>
        <Header.Subtitle>
          <FormattedMessage
            id="onboarding.coolDownPeriod.subtitle"
            defaultMessage="Due to regulations, newly created accounts will need to wait for 24 hours before trading crypto"
          />
        </Header.Subtitle>
      </Header>

      <Flex justifyContent="center">
        <Image size={250} {...getImageAssetSrcProps(Asset3dImageName.Pending)} />
      </Flex>

      <Popup.Actions>
        <Button elevated onClick={onClose}>
          <FormattedMessage id="actions.gotIt" defaultMessage="Got it" />
        </Button>
      </Popup.Actions>
    </>
  )
}
