import {
  FilterButton,
  HStack,
  Text,
  Token,
  Tooltip,
  useToggle,
  useTooltip,
} from '@revolut/ui-kit'
import { FC, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { formatCurrencyPair, useCurrencyPairRequired } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import {
  AssetAvatar,
  WelcomeJourneyTarget,
  useCompatibilityCheck,
  useHotkeyEffect,
  useHumanizeCurrency,
  useHumanizeCurrencyPair,
  useLayoutSize,
} from '../../../core-shared'
import { RoutePath, getRouteUrl, useQueryParams } from '../../../core-utils'
import { CurrencyPairSelectorPopup } from '../../../feature-markets'
import { useOnboardingContext } from '../../../feature-onboarding'
import { Tickers } from '../Tickers'

interface TerminalHeaderProps {
  currencyPair: string
}

export const TerminalHeader: FC<TerminalHeaderProps> = ({ currencyPair }) => {
  const { platformType } = useCompatibilityCheck()

  const layoutSize = useLayoutSize()

  const { formatMessage } = useIntl()

  const history = useHistory()

  const { baseCurrency, quoteCurrency } = useCurrencyPairRequired(currencyPair)

  const { humanizeCurrency } = useHumanizeCurrency()

  const humanizedBaseCurrency = humanizeCurrency(baseCurrency)
  const humanizedQuoteCurrency = humanizeCurrency(quoteCurrency)

  const formattedCurrencyPair = formatCurrencyPair(
    {
      baseCurrency: humanizedBaseCurrency,
      quoteCurrency: humanizedQuoteCurrency,
    },
    '-',
  )

  const { open: onboardingOpen } = useOnboardingContext()

  const [queryParams, setQueryParams] = useQueryParams<{ open?: 'pair-selector' }>()

  const currencyPairSelectorDefaultOpen =
    queryParams.open === 'pair-selector' && !onboardingOpen

  const [isCurrencyPairSelectorOpen, toggleCurrencyPairSelectorOpen] = useToggle({
    defaultState: currencyPairSelectorDefaultOpen,
  })

  const { humanizeCurrencyPair } = useHumanizeCurrencyPair()

  const handleCurrencyPairChange = useCallback(
    (nextCurrencyPair: string) => {
      toggleCurrencyPairSelectorOpen(false)

      history.replace(
        getRouteUrl({
          path: RoutePath.Trade,
          params: { currencyPair: humanizeCurrencyPair(nextCurrencyPair) },
        }),
      )
    },
    [history, toggleCurrencyPairSelectorOpen, humanizeCurrencyPair],
  )

  const tooltip = useTooltip()

  const { hotkey } = useHotkeyEffect({
    hotkey: 'command+k',
    onHotkey: () => {
      toggleCurrencyPairSelectorOpen()
    },
  })

  return (
    <HStack space="s-40" align="center" pr="s-12">
      <FilterButton
        {...tooltip.getAnchorProps()}
        useIcon={() => <AssetAvatar variant="brand" asset={baseCurrency} size={20} />}
        useEndIcon="16/ChevronDownSmall"
        onClick={() => {
          toggleCurrencyPairSelectorOpen(true)
        }}
        aria-label={formatMessage({
          id: 'labels.currencyPairSelector',
          defaultMessage: 'Currency pair selector',
        })}
        className={WelcomeJourneyTarget.SelectCurrencyPair}
      >
        {formattedCurrencyPair}
      </FilterButton>

      {platformType === 'desktop' && (
        <Tooltip
          {...tooltip.getTargetProps({ placement: 'bottom-start' })}
          maxWidth={240}
        >
          <FormattedMessage
            id="terminal.hotkeys.currencyPairSelector.tooltip"
            defaultMessage="Browse pairs {hotkey}"
            values={{ hotkey: <Text color={Token.color.greyTone50}>{hotkey}</Text> }}
          />
        </Tooltip>
      )}

      <CurrencyPairSelectorPopup
        open={isCurrencyPairSelectorOpen}
        currencyPair={currencyPair}
        onSelectCurrencyPair={handleCurrencyPairChange}
        onClose={() => {
          toggleCurrencyPairSelectorOpen(false)
          currencyPairSelectorDefaultOpen && setQueryParams({ open: undefined })
        }}
      />

      {layoutSize === 'wide' && <Tickers currencyPair={currencyPair} />}
    </HStack>
  )
}
