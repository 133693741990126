import { Group, Item, Subheader, Switch, VStack } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  useUpdateUserMarketingNotificationSettings,
  useUserNotificationSettings,
} from '../../../core-api'
import { getMarketingNotificationSettings } from '../../utils'

export const Marketing: FC = () => {
  const { data: notificationSettings } = useUserNotificationSettings({
    select: (settings) => getMarketingNotificationSettings(settings),
  })

  const { mutate: updateUserMarketingNotificationSettings } =
    useUpdateUserMarketingNotificationSettings()

  return (
    <VStack>
      <Subheader>
        <Subheader.Title>
          <FormattedMessage
            id="profile.notificationSettings.marketing.title"
            defaultMessage="Marketing"
          />
        </Subheader.Title>
      </Subheader>

      <Group>
        <Item useIcon="StarFilled">
          <Item.Content>
            <Item.Title>
              <FormattedMessage
                id="profile.notificationSettings.marketing.personalisedRecommendations.title"
                defaultMessage="Personalised recommendations"
              />
            </Item.Title>
            <Item.Description>
              <FormattedMessage
                id="profile.notificationSettings.marketing.personalisedRecommendations.description"
                defaultMessage="Get notifications about Revolut X products, services and offers that may
                interest you"
              />
            </Item.Description>
          </Item.Content>
        </Item>

        <Item use="label" useIcon="Envelope">
          <Item.Content>
            <Item.Title>
              <FormattedMessage
                id="profile.notificationSettings.marketing.email.label"
                defaultMessage="Email"
              />
            </Item.Title>
          </Item.Content>
          <Item.Side>
            <Switch
              checked={notificationSettings?.email}
              onChange={(e) =>
                updateUserMarketingNotificationSettings({
                  email: e.target.checked,
                  push: notificationSettings?.push,
                })
              }
            />
          </Item.Side>
        </Item>

        <Item use="label" useIcon="Smartphone">
          <Item.Content>
            <Item.Title>
              <FormattedMessage
                id="profile.notificationSettings.marketing.push.label"
                defaultMessage="Push"
              />
            </Item.Title>
          </Item.Content>
          <Item.Side>
            <Switch
              checked={notificationSettings?.push}
              onChange={(e) =>
                updateUserMarketingNotificationSettings({
                  email: notificationSettings?.email,
                  push: e.target.checked,
                })
              }
            />
          </Item.Side>
        </Item>
      </Group>
    </VStack>
  )
}
