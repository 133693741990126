import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getAnalyticsValue } from '../../api'
import { QueryKey } from '../../constants'
import { AnalyticsResponse, AnalyticsParams, ValuePoint } from '../../types'

export const useAnalyticsTotalValue = <T extends unknown = AnalyticsResponse<ValuePoint>>(
  params: AnalyticsParams,
  options: UseQueryOptions<AnalyticsResponse<ValuePoint>, AxiosError, T> = {},
) =>
  useQuery<AnalyticsResponse<ValuePoint>, AxiosError, T>(
    [QueryKey.AnalyticsValue, params],
    () => getAnalyticsValue(params),
    options,
  )
