import { Flex, Text, useTooltip } from '@revolut/ui-kit'
import { FC } from 'react'

import { useCurrencyPairRequired } from '../../../core-api'
import { CurrencyPairMoney } from '../../../core-shared'
import { Money, useThemePalette } from '../../../core-ui'
import { OrderBookProgressSide, OrderBookSide } from '../../constants'
import { useAccumulatedVolume } from '../../hooks'
import { FormattedOrderBookItem } from '../../utils'
import { OrderBookProgress } from './OrderBookProgress'
import { OrderBookTooltip } from './OrderBookTooltip'

const PROGRESS_MAX_WIDTH = 40

interface OrderBookListItemProps {
  currencyPair: string
  side: OrderBookSide
  price: number
  amount: number
  total: number
  maxAmount: number
  currentIndex: number
  backgroundColor: string | null
  accumulatedData: FormattedOrderBookItem[]
  onHover: (index: number | null) => void
  isScrolling: boolean
  onSelectPrice?: (price: number) => void
}

export const OrderBookListItem: FC<OrderBookListItemProps> = ({
  currencyPair,
  side,
  price,
  amount,
  total,
  maxAmount,
  onHover,
  currentIndex,
  backgroundColor,
  accumulatedData,
  isScrolling,
  onSelectPrice,
}) => {
  const { baseCurrency, quoteCurrency } = useCurrencyPairRequired(currencyPair)

  const accumulatedVolumeTooltip = useTooltip()

  const { averagePrice, totalAmount, totalPrice } = useAccumulatedVolume({
    accumulatedData,
  })

  const handleItemClick = () => {
    onSelectPrice?.(price)
  }

  const { palette } = useThemePalette()

  return (
    <>
      <Flex
        onClick={handleItemClick}
        style={{
          position: 'relative',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
        {...accumulatedVolumeTooltip.getAnchorProps()}
        onMouseEnter={() => {
          accumulatedVolumeTooltip.getAnchorProps().onMouseEnter()
          onHover(currentIndex)
        }}
        onMouseLeave={() => {
          accumulatedVolumeTooltip.getAnchorProps().onMouseLeave()
          onHover(null)
        }}
        bg={backgroundColor}
      >
        <Text
          variant="small"
          color={side === OrderBookSide.Ask ? palette.downColor : palette.upColor}
          py="s-4"
          pl="s-16"
          pr="s-12"
          flex="1 1 0"
        >
          <CurrencyPairMoney
            kind="price"
            variant="quote"
            currencyPair={currencyPair}
            amount={price}
            clearSymbol
          />
        </Text>
        <Text variant="small" py="s-4" px="s-12" textAlign="right" flex="1 1 0">
          <CurrencyPairMoney
            kind="amount"
            variant="base"
            currencyPair={currencyPair}
            amount={amount}
            clearSymbol
            compactNotation
            showTrailingZeros
          />
        </Text>
        <Text variant="small" py="s-4" pl="s-12" pr="s-16" textAlign="right" flex="1 1 0">
          <Money
            kind="total"
            amount={total}
            currency={quoteCurrency}
            clearSymbol
            compactNotation
          />
        </Text>
        <OrderBookProgress
          side={OrderBookProgressSide.Left}
          amount={amount}
          maxAmount={maxAmount}
          progressRange={PROGRESS_MAX_WIDTH}
          color={side === OrderBookSide.Ask ? palette.downColor10 : palette.upColor10}
        />
      </Flex>
      {!isScrolling && (
        <OrderBookTooltip
          currencyPair={currencyPair}
          averagePrice={averagePrice}
          totalAmount={totalAmount}
          totalPrice={totalPrice}
          baseCurrency={baseCurrency}
          quoteCurrency={quoteCurrency}
          tooltip={accumulatedVolumeTooltip}
        />
      )}
    </>
  )
}
