import { RefObject, useEffect, useState } from 'react'

export const useScrollDetect = ({
  elementRef,
  scrollTimeoutDuration = 800,
}: {
  elementRef: RefObject<HTMLElement>
  scrollTimeoutDuration?: number
}) => {
  const [isScrolling, setIsScrolling] = useState(false)

  useEffect(() => {
    let scrollingTimer: NodeJS.Timeout

    const handleScroll = () => {
      setIsScrolling(true)

      clearTimeout(scrollingTimer)

      scrollingTimer = setTimeout(() => {
        setIsScrolling(false)
      }, scrollTimeoutDuration)
    }

    const element = elementRef.current

    if (element) {
      element.addEventListener('scroll', handleScroll)

      return () => {
        element.removeEventListener('scroll', handleScroll)
        clearTimeout(scrollingTimer)
      }
    }

    return undefined
  }, [elementRef, scrollTimeoutDuration])

  return isScrolling
}
