import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { cancelOrder } from '../../api'
import { QueryKey } from '../../constants'

export const useCancelOrder = (
  options: UseMutationOptions<void, AxiosError, string> = {},
) => {
  const queryClient = useQueryClient()

  return useMutation(cancelOrder, {
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries([QueryKey.Balances])
      queryClient.invalidateQueries([QueryKey.Orders])
      queryClient.invalidateQueries([QueryKey.OrdersHistory])

      options.onSuccess?.(...args)
    },
  })
}
