import { ErrorCode, HttpCode } from '../constants'
import { DepositErrorResponse, WithdrawErrorResponse } from '../types'

export const transferRequiresSSOVerification = (
  error: Pick<DepositErrorResponse | WithdrawErrorResponse, 'code'>,
  status: number,
) => {
  if (
    status === HttpCode.Unauthorized &&
    error.code === ErrorCode.SSOVerificationRequired
  ) {
    return true
  }

  return false
}
