import axios from 'axios'

import { TransferQuotes, TransferQuotesParams } from '../types'

export const getTransferQuotes = async (params: TransferQuotesParams) => {
  const { data } = await axios.get<TransferQuotes>(
    '/api/crypto-exchange/transfer-quotes',
    {
      params,
    },
  )

  return data
}
