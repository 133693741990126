import { UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Pocket, Wallet } from '../../types'
import { selectPockets } from '../../utils'
import { useCurrenciesDetailsRequired } from '../useCurrenciesDetailsRequired'
import { useCurrenciesRequired } from '../useCurrenciesRequired'
import { useWallet } from '../useWallet'

export const usePockets = (
  options: UseQueryOptions<Wallet, AxiosError, Pocket[]> = {},
) => {
  const currencies = useCurrenciesRequired()
  const currenciesDetails = useCurrenciesDetailsRequired()

  return useWallet({
    ...options,
    select: (wallet) => selectPockets(wallet.pockets, currencies, currenciesDetails),
  })
}
