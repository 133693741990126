import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getUserNotificationSettings } from '../../api'
import { QueryKey } from '../../constants'
import { NotificationSettingsResponse } from '../../types'

export const useUserNotificationSettings = <
  T extends unknown = NotificationSettingsResponse,
>(
  options: UseQueryOptions<NotificationSettingsResponse, AxiosError, T> = {},
) =>
  useQuery<NotificationSettingsResponse, AxiosError, T>(
    [QueryKey.UserNotificationSettings],
    () => getUserNotificationSettings(),
    options,
  )
