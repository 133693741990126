import { createEventKeys } from '@revolut-internal/event-tracker'

export const EVENT_TRACKER_KEYS = {
  screens: createEventKeys({
    activity: 'screens.activity',
    authLimitedAccess: 'screens.authLimitedAccess',
    authRestrictedAccess: 'screens.authRestrictedAccess',
    authSignedOut: 'screens.authSignedOut',
    authSignInError: 'screens.authSignInError',
    error: 'screens.error',
    home: 'screens.home',
    portfolio: 'screens.portfolio',
    profile: 'screens.profile',
    trade: 'screens.trade',
  }),
}
