import { parseHotkey } from './parseHotkey'

const KEYS_TRANSLATION_MAP: Record<string, string> = {
  command: 'ctrl',
  option: 'alt',
}

export const translateMacOsHotkey = (macHotkey: string, os: string) => {
  if (os === 'macos') {
    return macHotkey
  }

  const parts = parseHotkey(macHotkey)

  const translatedParts = parts.map(
    (part) => KEYS_TRANSLATION_MAP[part.toLowerCase()] ?? part,
  )

  return translatedParts.join('+')
}
