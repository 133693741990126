import axios from 'axios'

import { CurrencyPairsResponse } from '../types'

export const getCurrencyPairs = async () => {
  const { data } = await axios.get<CurrencyPairsResponse>(
    '/api/crypto-exchange/config/pairs',
  )

  return data
}
