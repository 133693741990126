import {
  ChartResolution,
  ChartType,
  DatafeedError,
  GetChartHistoryCallback,
  TradingChartWidget,
} from '@revolut-internal/trading-charts'
import {
  BoxProps,
  Text,
  Token,
  VStack,
  useResizeObserver,
  useToggle,
} from '@revolut/ui-kit'
import * as Sentry from '@sentry/react'
import { FC, useCallback, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { createGlobalStyle } from 'styled-components'

import {
  CurrencyPairStatus,
  getChartHistory,
  useCurrencyPairsRequired,
} from '../../../core-api'
import { useLayoutSize, useTickerPrice } from '../../../core-shared'
import { useThemePalette } from '../../../core-ui'
import { checkRequired } from '../../../core-utils'
import {
  CHART_CUSTOM_OVERRIDES,
  CHART_DISABLED_FEATURES,
  CHART_ENABLED_FEATURES,
  CHART_SUPPORTED_RESOLUTIONS,
  CHART_TIMEFRAMES,
  CONDENSED_WIDTH_LIMIT_PX,
  NARROW_MAIN_RESOLUTIONS,
  RESOLUTION_TO_FX_INTERVAL_MAP,
} from '../../constants'
import { useChartState, useLocalizationMessages } from '../../hooks'

// We need it for the fullscreen, because position: fixed doesn't work correctly with transform
// We can't use Portal, because it will trigger re-render of the chart and we will lose state
const FullscreenGlobalStyle = createGlobalStyle`
    .react-grid-item {
      transform: none !important;
    }
  `

const getFxChartHistoryCallback: GetChartHistoryCallback = (
  symbol,
  resolution,
  { countBack, to },
) =>
  getChartHistory({
    pair: symbol,
    interval: checkRequired(
      RESOLUTION_TO_FX_INTERVAL_MAP[resolution as ChartResolution],
      'interval',
    ),
    countBack,
    to,
  })

const customizeOptions = (options) => ({
  ...options,
  disabled_features: CHART_DISABLED_FEATURES,
  enabled_features: CHART_ENABLED_FEATURES,
})

interface PriceChartProps extends Omit<BoxProps, 'type'> {
  currencyPair: string
  chartId: string

  type: ChartType
  onChangeType: (type: ChartType) => void

  resolution: ChartResolution
  onChangeResolution: (resolution: ChartResolution) => void
}

export const PriceChart: FC<PriceChartProps> = ({
  currencyPair,
  chartId,

  type,
  onChangeType,

  resolution,
  onChangeResolution,

  ...rest
}) => {
  const layoutSize = useLayoutSize()

  const currencyPairs = useCurrencyPairsRequired({ status: CurrencyPairStatus.Active })

  const { data: midPrice } = useTickerPrice(currencyPair, 'mid')

  const getSymbolInfo = useCallback(
    (symbol: string) => {
      const pair = currencyPairs[symbol]

      let symbolInfo = {
        pricescale: 100,
        minmov: 1,
      }

      if (pair !== undefined) {
        const pricescale = Math.pow(10, pair.quoteStepScale)

        symbolInfo = {
          pricescale,
          minmov: pricescale * pair.quoteStep,
        }
      }

      return Promise.resolve(symbolInfo)
    },
    [currencyPairs],
  )

  const localizationMessages = useLocalizationMessages()

  const [fullscreen, toggleFullscreen] = useToggle()

  useEffect(() => {
    const handleCloseFullscreen = (event) => {
      if (event.key === 'Escape') {
        toggleFullscreen(false)
      }
    }

    document.addEventListener('keydown', handleCloseFullscreen)

    return () => {
      document.removeEventListener('keydown', handleCloseFullscreen)
    }
  }, [toggleFullscreen])

  const { chartState, loading, onChartStateSave } = useChartState(chartId)

  const wrapperElRef = useRef<HTMLDivElement>(null)

  const size = useResizeObserver(wrapperElRef)

  const condensed = size.width < CONDENSED_WIDTH_LIMIT_PX

  const { computedPalette } = useThemePalette()

  const onDatafeedError = useCallback((error: DatafeedError) => {
    Sentry.captureException(new Error(`Datafeed error: ${error.type}`), {
      extra: {
        time: error.time,
        ticker: error.ticker,
        resolution: error.resolution,
      },
    })
  }, [])

  return (
    <VStack space="s-16" ref={wrapperElRef} flex={1} {...rest}>
      {fullscreen && <FullscreenGlobalStyle />}

      <TradingChartWidget
        symbol={currencyPair}
        colors={computedPalette}
        condensed={condensed}
        lastPrice={midPrice}
        supportedResolutions={CHART_SUPPORTED_RESOLUTIONS}
        mainResolutions={layoutSize === 'wide' ? undefined : NARROW_MAIN_RESOLUTIONS}
        timeframes={CHART_TIMEFRAMES}
        customizeOptions={customizeOptions}
        customOverrides={CHART_CUSTOM_OVERRIDES}
        getChartHistory={getFxChartHistoryCallback}
        localizationMessages={localizationMessages}
        resolution={resolution}
        chartType={type}
        onChangeResolution={onChangeResolution}
        onChangeChartType={onChangeType}
        getSymbolInfo={getSymbolInfo}
        fullscreen={layoutSize === 'wide' ? fullscreen : undefined}
        toggleFullscreen={layoutSize === 'wide' ? toggleFullscreen : undefined}
        loading={loading}
        chartState={chartState}
        onChartStateSave={onChartStateSave}
        onDatafeedError={onDatafeedError}
      />

      <Text variant="small" textAlign="center" color={Token.color.greyTone50}>
        <FormattedMessage
          id="markets.priceChart.disclaimer.title"
          defaultMessage="Past performance is not a reliable indicator of future results"
        />
      </Text>
    </VStack>
  )
}
