import { AxiosError } from 'axios'
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { v4 as uuid } from 'uuid'

import {
  CurrencyAssetType,
  DepositData,
  DepositErrorResponse,
  DepositType,
  Pocket,
  PocketState,
  transferRequiresSSOVerification,
  useCurrenciesRequired,
  useDeposit,
  usePockets,
  useTransferQuotes,
} from '../../../core-api'
import { useSsoVerification } from '../../../core-auth'
import { useIntl } from '../../../core-intl'
import { useMoneyFormat, useStatusPopup } from '../../../core-ui'
import { checkRequired } from '../../../core-utils'
import { INITIAL_STATE } from './constants'
import { DepositFlowActionType, depositFlowReducer } from './depositFlowReducer'
import { DepositFlow, DepositFlowStep } from './types'

interface DepositSubmitParams {
  currency?: string
  onSuccess: VoidFunction
}

export const useDepositFlow = ({
  currency,
  onSuccess,
}: DepositSubmitParams): DepositFlow => {
  const { formatMessage } = useIntl()

  const moneyFormat = useMoneyFormat({ type: 'money-fractional' })

  const { verify, verificationLink } = useSsoVerification()

  const operationRef = useRef(uuid())

  const [state, dispatch] = useReducer(depositFlowReducer, {
    ...INITIAL_STATE,
    step: currency ? DepositFlowStep.EnterAmount : DepositFlowStep.CurrencySelection,
    currency: currency ?? INITIAL_STATE.currency,
    pocket: undefined,
    amount: null,
    sizeRatio: null,
  })

  const quotes = useTransferQuotes({
    fromCurrency: state.pocket?.currency,
    toCurrency: state.currency,
    amount: state.amount,
  })

  const handleChangeStep = (step: DepositFlowStep) => {
    dispatch({ type: DepositFlowActionType.SetStep, step })
  }

  const handleChangeAmount = (nextAmount: number | null) => {
    dispatch({ type: DepositFlowActionType.UpdateAmount, payload: nextAmount })
  }

  const handleChangeCurrency = (nextCurrency: string) => {
    dispatch({ type: DepositFlowActionType.UpdateCurrency, currency: nextCurrency })
  }

  const handleChangePocket = useCallback(
    (pocket: Pocket) => {
      dispatch({ type: DepositFlowActionType.UpdatePocket, pocket })
    },
    [dispatch],
  )

  const handleChangeSize = (nextSizeRatio: number) => {
    const orderSizeAmount = nextSizeRatio * (state.pocket?.balance ?? 0)

    handleChangeAmount(orderSizeAmount)

    dispatch({ type: DepositFlowActionType.SetSizeRatio, sizeRatio: nextSizeRatio })
  }

  const handleChangeAssetType = (assetType: CurrencyAssetType) => {
    dispatch({ type: DepositFlowActionType.UpdateAssetType, assetType })
  }

  const handleCancelVerification = () => {
    handleChangeStep(DepositFlowStep.Confirmation)
  }

  const pockets = usePockets()

  const currencies = useCurrenciesRequired()

  useEffect(() => {
    if (
      state.step === DepositFlowStep.EnterAmount &&
      state.pocket === undefined &&
      state.currency &&
      pockets.data
    ) {
      let pocket = pockets.data.find((item) => item.currency === state.currency)

      const { assetType } = currencies[state.currency] ?? {}

      if (
        assetType === CurrencyAssetType.Fiat &&
        (pocket === undefined || pocket.balance === 0)
      ) {
        pocket = pockets.data.find(
          (item) => item.assetType === CurrencyAssetType.Fiat && item.balance > 0,
        )
      }

      if (pocket) {
        dispatch({ type: DepositFlowActionType.SetPocket, pocket })
      }
    }
  }, [state.step, state.pocket, state.currency, currencies, pockets, dispatch])

  const errorMessage: string | null = useMemo(() => {
    if (state.amount !== null && state.pocket) {
      const isSameCurrency = state.currency === state.pocket.currency

      const exceedsBalance = isSameCurrency
        ? state.amount > state.pocket.balance
        : quotes.data &&
          quotes.data.fromAmount.value + quotes.data.fee.total.value >
            state.pocket.balance

      if (exceedsBalance) {
        return formatMessage({
          id: 'portfolio.deposit.errors.exceedsBalance.title',
          defaultMessage: 'exceeds balance',
        })
      }
    }

    return null
  }, [state.amount, state.currency, state.pocket, formatMessage, quotes.data])

  const invalid = errorMessage !== null

  const quotesFetched =
    state.currency !== state.pocket?.currency ? quotes.status === 'success' : true

  const disabled =
    state.amount === null ||
    state.amount === 0 ||
    !state.pocket ||
    state.pocket.state !== PocketState.Active ||
    !quotesFetched ||
    invalid

  const status = pockets.status

  const statusPopup = useStatusPopup()

  const {
    mutate,
    mutateAsync,
    isLoading: submitting,
  } = useDeposit({
    onSuccess: () => {
      if (state.amount !== null && state.currency !== undefined) {
        statusPopup.showSuccess({
          title: (
            <FormattedMessage
              id="portfolio.deposit.success.title"
              defaultMessage="You have funded your investment account with {amount}"
              values={{
                amount: moneyFormat({
                  amount: state.amount,
                  currency: state.currency,
                }),
              }}
            />
          ),
          onClose: onSuccess,
        })
      }

      operationRef.current = uuid()
    },
    onError: (error) => {
      const { response } = error

      if (!response || !transferRequiresSSOVerification(response.data, response.status)) {
        statusPopup.showError({
          title: (
            <FormattedMessage
              id="errors.general.title"
              defaultMessage="Something went wrong"
            />
          ),
        })
      }
    },
  })

  const handleSubmit = () => {
    if (disabled || state.amount === null) {
      statusPopup.showError({
        title: (
          <FormattedMessage
            id="errors.general.title"
            defaultMessage="Something went wrong"
          />
        ),
      })

      return
    }

    const depositAmount = state.amount

    const sourcePocket = checkRequired(state.pocket, 'Source pocket is required')

    const depositData: DepositData = {
      type: DepositType.Amount,
      amount: depositAmount,
      sourcePocketId: sourcePocket.id,
      op: operationRef.current,
      currency: sourcePocket.currency === state.currency ? undefined : state.currency,
    }

    mutateAsync(depositData).catch(
      (error: AxiosError<DepositErrorResponse, DepositData>) => {
        const { response } = error

        if (response && transferRequiresSSOVerification(response.data, response.status)) {
          handleChangeStep(DepositFlowStep.Verification)

          const { state: verificationState, acceptedLevel } = response.data

          verify({
            state: verificationState,
            context: acceptedLevel,
            onClose: () => {
              handleChangeStep(DepositFlowStep.Confirmation)
            },
          }).then(async ({ code, codeVerifier }) => {
            statusPopup.showLoading()

            mutate({
              ...depositData,
              code,
              codeVerifier,
            })
          })
        }
      },
    )
  }

  const balanceMessage = useMemo(() => {
    if (state.pocket) {
      return moneyFormat({
        amount: state.pocket.balance,
        currency: state.pocket.currency,
      })
    }

    if (state.currency) {
      return moneyFormat({
        amount: 0,
        currency: state.currency,
      })
    }

    return null
  }, [state.pocket, state.currency, moneyFormat])

  const currencyMessage =
    balanceMessage === null
      ? null
      : formatMessage(
          {
            id: 'portfolio.balance.title',
            defaultMessage: 'Balance: {balance}',
          },
          {
            balance: balanceMessage,
          },
        )

  return {
    state,

    balanceMessage,
    currencyMessage,
    amountMessage: errorMessage,

    verificationLink,

    quotes: quotes.data,

    invalid,
    disabled,
    submitting,
    status,

    handleChangeStep,
    handleChangeCurrency,
    handleChangePocket,
    handleChangeAmount,
    handleChangeSize,
    handleChangeAssetType,
    handleCancelVerification,
    handleSubmit,
  }
}
