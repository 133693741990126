import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Scope } from '../constants'
import { useRefreshTokenEffect, useSignOut, useTokenInfo } from '../hooks'
import { AuthContextType, AuthSignOutCause, AuthStatus } from '../types'

export const AuthContext = createContext<AuthContextType | null>(null)

type AuthProviderProps = PropsWithChildren<{
  ssoUrl: string
  ssoClientId: string
  ssoBusinessClientId: string

  signInUrl: string
  signedOutUrl: string
  limitedAccessUrl: string
  verificationUrl: string
}>

export const AuthProvider: FC<AuthProviderProps> = ({
  ssoUrl,
  ssoClientId,
  ssoBusinessClientId,

  signInUrl,
  signedOutUrl,
  limitedAccessUrl,
  verificationUrl,
  children,
}) => {
  const [status, setStatus] = useState<AuthStatus>('unknown')
  const [signOutCause, setSignOutCause] = useState<AuthSignOutCause>()

  const { status: tokenInfoStatus, data: tokenInfo } = useTokenInfo({
    enabled: status === 'requested',
  })

  const { mutateAsync: signOut } = useSignOut()

  const handleSignOut = useCallback(
    (cause: AuthSignOutCause) =>
      signOut().then(() => {
        setSignOutCause(cause)

        setStatus('unauthorized')
      }),
    [signOut],
  )

  useEffect(() => {
    if (tokenInfoStatus === 'success') {
      if (tokenInfo.token.scopes.includes(Scope.CryptoExchangeAll)) {
        setStatus('authorized')
      } else {
        setStatus('limited')
      }
    } else if (tokenInfoStatus === 'error') {
      setStatus('unauthorized')
    }
  }, [tokenInfoStatus, tokenInfo])

  useRefreshTokenEffect({
    enabled: status === 'authorized',
  })

  const requestAuthorization = useCallback(() => {
    setStatus('requested')
  }, [])

  const userId = tokenInfo?.user?.userId

  const value: AuthContextType = useMemo(
    () => ({
      userId,
      status,
      signOutCause,
      ssoUrl,
      ssoClientId,
      ssoBusinessClientId,
      signInUrl,
      signedOutUrl,
      limitedAccessUrl,
      verificationUrl,
      setStatus,
      signOut: handleSignOut,
      requestAuthorization,
    }),
    [
      userId,
      status,
      signOutCause,
      ssoUrl,
      ssoClientId,
      ssoBusinessClientId,
      signInUrl,
      signedOutUrl,
      limitedAccessUrl,
      verificationUrl,
      requestAuthorization,
      handleSignOut,
    ],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
