import { isNumber } from 'lodash'

import { GridLayoutTemplateColumn } from '../types'

export const getGridLayoutColumnsStyle = (columns: GridLayoutTemplateColumn[]) =>
  columns
    .map((row) => {
      if (row.type === 'FIXED') {
        if (isNumber(row.range)) {
          return `minmax(${row.width - row.range}px, ${row.width + row.range}px)`
        }

        return `${row.width}px`
      }

      if (row.type === 'FLUID') {
        return `minmax(${row.minWidth}px, ${row.ratio}fr)`
      }

      return `${row.height}px`
    })
    .join(' ')
