import {
  getScrollParent,
  hasCustomScrollParent,
  hasPosition,
  scrollDocument,
} from '../../utils'
import { useClientRect } from '../useClientRect'

export const useElementPosition = ({
  element,
  offset = 0,
}: {
  element: HTMLElement | null
  offset?: number
}) => {
  const elementRect = useClientRect(element)
  const parent = getScrollParent(element)
  const hasScrollParent = hasCustomScrollParent(element)

  let parentTop = 0
  let top = elementRect?.top ?? 0

  if (parent instanceof HTMLElement) {
    parentTop = parent.scrollTop

    if (!hasScrollParent && !hasPosition(element)) {
      top += parentTop
    }

    if (!parent.isSameNode(scrollDocument())) {
      top += scrollDocument().scrollTop
    }
  }

  return Math.floor(top - offset)
}
