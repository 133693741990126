import { LocalStorageKey, localStorage, useLocalStorage } from '../../../core-storage'
import { browser } from '../../../core-utils'

type CookiesPreferences = {
  analyticsTargetingEnabled: boolean
}

const getDefaultCookiesPreferences = () => {
  const isBannerClosed = localStorage.getItem<boolean>(
    LocalStorageKey.CookiesBannerIsClosed,
  )

  if (isBannerClosed) {
    return {
      analyticsTargetingEnabled: true,
    }
  }

  return null
}

export const useCookiesPreferences = () => {
  const [cookiesPreferences, setCookiesPreferences] = useLocalStorage(
    LocalStorageKey.CookiesPreferences,
    getDefaultCookiesPreferences(),
  )

  const saveAndApplyNewPreferences = (newCookiePreferences: CookiesPreferences) => {
    localStorage.setItem(LocalStorageKey.CookiesPreferences, newCookiePreferences)
    setCookiesPreferences(newCookiePreferences)
    browser.reloadLocation()
  }

  return {
    cookiesPreferences,
    saveAndApplyNewPreferences,
  }
}
