import { some } from 'lodash'
import { FC, ReactNode } from 'react'
import { Redirect } from 'react-router-dom'

import { UserRestrictionType, useUserRestrictions } from '../../../core-api'
import { GlobalSpinner } from '../../../core-ui'
import { RoutePath, getRouteUrl } from '../../../core-utils'
import { MaintenancePlaceholer } from '../MaintenancePlaceholer'

interface MaintenanceCheckerProps {
  children: ReactNode
}

export const MaintenanceChecker: FC<MaintenanceCheckerProps> = ({ children }) => {
  const restrictions = useUserRestrictions()

  if (restrictions.status === 'loading') {
    return <GlobalSpinner />
  }

  if (restrictions.status === 'error') {
    return <Redirect to={getRouteUrl({ path: RoutePath.Error })} />
  }

  const cantRead = some(restrictions.data, { type: UserRestrictionType.READ })

  if (cantRead) {
    return <MaintenancePlaceholer type="global" />
  }

  return <>{children}</>
}
