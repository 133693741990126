import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { OrderType } from '../../../core-api'
import { FormattedEnumMessage } from '../../../core-intl'

type OrderTypeMessageProps = {
  type: OrderType
}

const orderTypeMessages = defineMessages<OrderType>({
  [OrderType.Market]: {
    id: 'activity.order.type.market.title',
    defaultMessage: 'Market',
  },
  [OrderType.Limit]: {
    id: 'activity.order.type.limit.title',
    defaultMessage: 'Limit',
  },
})

export const OrderTypeMessage: FC<OrderTypeMessageProps> = ({ type }) => {
  return <FormattedEnumMessage id={type} messages={orderTypeMessages} />
}
