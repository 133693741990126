import {
  ActionButton,
  ActionButtonProps,
  MoreBarActionProps,
  useToggle,
} from '@revolut/ui-kit'
import { ComponentType, FC } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  UserRestrictionKey,
  UserRestrictionType,
  useUserRestrictionEnabled,
} from '../../../core-api'
import { UserRestrictionTooltip } from '../../../core-shared'
import { useOnboardingButtonProps } from '../../../feature-onboarding'
import { WithdrawPopup } from '../WithdrawPopup'

const RESTRICTION_KEY: UserRestrictionKey = {
  type: UserRestrictionType.WITHDRAW,
}

interface WithdrawButtonProps {
  use?: ComponentType<ActionButtonProps | MoreBarActionProps>
  currency?: string
}

export const WithdrawButton: FC<WithdrawButtonProps> = ({
  use: Button = ActionButton,
  currency,
}) => {
  const [isPopupOpen, toogleIsPopupOpen] = useToggle()

  const { enabled: withdrawDisabled } = useUserRestrictionEnabled(
    currency
      ? {
          type: UserRestrictionType.WITHDRAW_CURRENCY,
          currency,
        }
      : RESTRICTION_KEY,
  )

  const buttonProps = useOnboardingButtonProps<ActionButtonProps>({
    variant: 'primary',
    useIcon: 'ArrowUp',
    onClick: () => toogleIsPopupOpen(true),
    disabled: withdrawDisabled,
    children: <FormattedMessage id="actions.withdraw" defaultMessage="Withdraw" />,
  })

  return (
    <>
      <UserRestrictionTooltip restrictionKey={RESTRICTION_KEY}>
        <Button {...buttonProps} />
      </UserRestrictionTooltip>

      <WithdrawPopup
        currency={currency}
        open={isPopupOpen}
        onClose={() => toogleIsPopupOpen(false)}
      />
    </>
  )
}
