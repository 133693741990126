export enum OnboardingStep {
  KycDocumentCheck = 'KYC_DOCUMENT_CHECK',
  CryptoOnboarding = 'CRYPTO_ONBOARDING',
  CoolDownPeriod = 'COOL_DOWN_PERIOD',
  TermsAndConditions = 'TERMS_AND_CONDITIONS',
  MarketingConsent = 'MARKETING_CONSENT',
  None = 'NONE',
}

export type OnboardingStepReponse = {
  status: number
  blocking: boolean
} & (
  | {
      nextStep: OnboardingStep.KycDocumentCheck
      context: undefined
    }
  | {
      nextStep: OnboardingStep.CryptoOnboarding
      context: {
        deeplink: string
        webDeeplink?: string
      }
    }
  | {
      nextStep: OnboardingStep.CoolDownPeriod
      context: {
        coolDownEndDate?: number
      }
    }
  | {
      nextStep: OnboardingStep.TermsAndConditions
      context: {
        termsLink?: string
        tradingRulesLink?: string
      }
    }
  | {
      nextStep: OnboardingStep.MarketingConsent
      context: undefined
    }
  | {
      nextStep: OnboardingStep.None
      context: undefined
    }
)
