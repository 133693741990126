import { CurrencyAssetType, ExtendedBalance, Pocket } from '../../../core-api'
import { WithdrawFlowState, WithdrawFlowStep } from './types'

export enum WithdrawFlowActionType {
  SetStep = 'SetStep',
  SetSizeRatio = 'SetSizeRatio',
  SetPocket = 'SetPocket',
  UpdateBalance = 'UpdateBalance',
  UpdateAmount = 'UpdateAmount',
  UpdatePocket = 'UpdatePocket',
  UpdateAssetType = 'UpdateAssetType',
}

type WithdrawFlowAction =
  | {
      type: WithdrawFlowActionType.SetStep
      step: WithdrawFlowStep
    }
  | {
      type: WithdrawFlowActionType.SetSizeRatio
      sizeRatio: number
    }
  | {
      type: WithdrawFlowActionType.SetPocket
      pocket: Pocket
    }
  | {
      type: WithdrawFlowActionType.UpdateBalance
      balance: ExtendedBalance
    }
  | {
      type: WithdrawFlowActionType.UpdateAmount
      payload: number | null
    }
  | {
      type: WithdrawFlowActionType.UpdatePocket
      pocket: Pocket
    }
  | {
      type: WithdrawFlowActionType.UpdateAssetType
      assetType: CurrencyAssetType
    }

export const withdrawFlowReducer = (
  state: WithdrawFlowState,
  action: WithdrawFlowAction,
): WithdrawFlowState => {
  switch (action.type) {
    case WithdrawFlowActionType.SetStep:
      return {
        ...state,
        step: action.step,
      }
    case WithdrawFlowActionType.SetSizeRatio:
      return {
        ...state,
        sizeRatio: action.sizeRatio,
      }
    case WithdrawFlowActionType.SetPocket:
      return {
        ...state,
        pocket: action.pocket,
      }
    case WithdrawFlowActionType.UpdateBalance:
      return {
        ...state,
        step: WithdrawFlowStep.EnterAmount,
        amount: null,
        sizeRatio: null,
        pocket: undefined,
        balance: action.balance,
      }
    case WithdrawFlowActionType.UpdateAmount:
      return {
        ...state,
        amount: action.payload,
        sizeRatio: null,
      }
    case WithdrawFlowActionType.UpdatePocket:
      return {
        ...state,
        step: WithdrawFlowStep.EnterAmount,
        amount: null,
        sizeRatio: null,
        pocket: action.pocket,
      }
    case WithdrawFlowActionType.UpdateAssetType: {
      return {
        ...state,
        assetType: action.assetType,
      }
    }

    default:
      return state
  }
}
