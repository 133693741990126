import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { checkRequired } from '../../../core-utils'
import { getTransferQuotes } from '../../api'
import { QueryKey } from '../../constants'
import { TransferQuotes } from '../../types'

export const useTransferQuotes = <T extends unknown = TransferQuotes>(
  params: {
    fromCurrency: string | undefined
    toCurrency: string | undefined
    amount: number | null
  },
  options: UseQueryOptions<TransferQuotes, AxiosError, T> = {},
) =>
  useQuery<TransferQuotes, AxiosError, T>(
    [QueryKey.TransferQuotes, params],
    () =>
      getTransferQuotes({
        fromCurrency: checkRequired(params.fromCurrency, 'From currency is required'),
        toCurrency: checkRequired(params.toCurrency, 'To currency is required'),
        amount: checkRequired(params.amount, 'Amount is required'),
      }),
    {
      enabled:
        params.amount !== null &&
        params.toCurrency !== undefined &&
        params.fromCurrency !== undefined &&
        params.toCurrency !== params.fromCurrency,
      ...options,
    },
  )
