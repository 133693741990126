import findKey from 'lodash/findKey'
import isRegExp from 'lodash/isRegExp'

import { Environment, ENV_BY_ORIGIN } from '../constants'

const getOrigin = () => window.location.origin

export const getEnvByOrigin = () => {
  const origin = getOrigin()

  const env = findKey(ENV_BY_ORIGIN, (url) =>
    isRegExp(url) ? url.test(origin) : url === origin,
  )

  if (!env) {
    throw new Error(`Can not find env for origin: ${origin}`)
  }

  return env as Environment
}
