import { FC, useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { INTERMEDIATE_AUTH_STATUSES, REQUIRE_AUTH_REQUEST_STATUSES } from '../constants'
import { useAuthContext } from '../hooks'

type AuthRouteProps = RouteProps

export const AuthRoute: FC<AuthRouteProps> = (props) => {
  const { status, signInUrl, limitedAccessUrl, requestAuthorization } = useAuthContext()

  useEffect(() => {
    if (REQUIRE_AUTH_REQUEST_STATUSES.includes(status)) {
      requestAuthorization()
    }
  }, [status, requestAuthorization])

  if (INTERMEDIATE_AUTH_STATUSES.includes(status)) {
    return null
  }

  if (status === 'limited') {
    return <Redirect to={limitedAccessUrl} />
  }

  if (status === 'unauthorized') {
    return <Redirect to={signInUrl} />
  }

  return <Route {...props} />
}
