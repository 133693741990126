import { Flex, Text, Token } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { useCurrencyPairRequired } from '../../../core-api'
import { useHumanizeCurrency } from '../../../core-shared'

interface OrderBookHeaderProps {
  currencyPair: string
}

const StyledFlex = styled(Flex)`
  box-shadow: inset 0px -1px 0px ${Token.color.greyTone8};
`

export const OrderBookHeader: FC<OrderBookHeaderProps> = ({ currencyPair }) => {
  const { baseCurrency, quoteCurrency } = useCurrencyPairRequired(currencyPair)

  const { humanizeCurrency } = useHumanizeCurrency()

  const humanizedBaseCurrency = humanizeCurrency(baseCurrency)
  const humanizedQuoteCurrency = humanizeCurrency(quoteCurrency)

  return (
    <StyledFlex alignItems="center">
      <Text
        variant="small-tile"
        py="s-4"
        pl="s-16"
        pr="s-12"
        color="grey-tone-50"
        flex="1 1 0%"
      >
        <FormattedMessage
          id="markets.orderBook.header.quoteCurrencyPrice.title"
          defaultMessage="Price ({quoteCurrency})"
          values={{
            quoteCurrency: humanizedQuoteCurrency,
          }}
        />
      </Text>
      <Text
        variant="small-tile"
        py="s-4"
        px="s-12"
        textAlign="right"
        color="grey-tone-50"
        flex="1 1 0%"
      >
        <FormattedMessage
          id="markets.orderBook.header.amount.title"
          defaultMessage="Amount ({baseCurrency})"
          values={{
            baseCurrency: humanizedBaseCurrency,
          }}
        />
      </Text>
      <Text
        variant="small-tile"
        py="s-4"
        pl="s-12"
        pr="s-16"
        textAlign="right"
        color="grey-tone-50"
        flex="1 1 0%"
      >
        <FormattedMessage
          id="markets.orderBook.header.total.title"
          defaultMessage="Total ({quoteCurrency})"
          values={{
            quoteCurrency: humanizedQuoteCurrency,
          }}
        />
      </Text>
    </StyledFlex>
  )
}
