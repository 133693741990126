import { TabBar } from '@revolut/ui-kit'
import { FC } from 'react'

import { TimeSpan } from '../../../core-api'
import { TimeSpanTabsMessage } from './TimeSpanTabsMessage'

type TimeSpanTabsProps = {
  value: TimeSpan
  onChange: (value: TimeSpan) => void
}

export const TimeSpanTabs: FC<TimeSpanTabsProps> = ({ value, onChange }) => {
  return (
    <TabBar variant="segmented">
      {Object.entries(TimeSpan).map(([key, timeSpan]) => (
        <TabBar.Item
          key={key}
          use="button"
          aria-selected={value === timeSpan}
          onClick={() => onChange(timeSpan)}
        >
          <TimeSpanTabsMessage timeSpan={timeSpan} />
        </TabBar.Item>
      ))}
    </TabBar>
  )
}
