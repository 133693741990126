import Big from 'big.js'
import { useMemo } from 'react'

import { FormattedOrderBookItem } from '../utils'

export const useAccumulatedVolume = ({
  accumulatedData,
}: {
  accumulatedData: FormattedOrderBookItem[]
}) => {
  const totalAmountBig = useMemo(
    () => accumulatedData.reduce((acc, item) => acc.plus(item.amount), Big(0)),
    [accumulatedData],
  )

  const totalPriceBig = useMemo(
    () => accumulatedData.reduce((acc, item) => acc.plus(item.total), Big(0)),
    [accumulatedData],
  )

  const averagePriceBig = useMemo(
    () => (totalAmountBig.eq(0) ? Big(0) : totalPriceBig.div(totalAmountBig)),
    [totalAmountBig, totalPriceBig],
  )

  return {
    averagePrice: averagePriceBig.toNumber(),
    totalAmount: totalAmountBig.toNumber(),
    totalPrice: totalPriceBig.toNumber(),
  }
}
