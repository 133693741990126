import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { FormattedEnumMessage } from '../../../../packlets/core-intl'
import { RestrictedAccessType } from '../../../../packlets/core-utils'

interface RestrictedAccessTitleMessageProps {
  restrictedAccessType: RestrictedAccessType
}

const restrictedAccessTitleMessages = defineMessages<RestrictedAccessType>({
  [RestrictedAccessType.Account]: {
    id: 'auth.restrictedAccess.account.title',
    defaultMessage: 'Revolut X is not available for this account',
  },
  [RestrictedAccessType.Region]: {
    id: 'auth.restrictedAccess.region.title',
    defaultMessage: 'Revolut X is not available in your region yet',
  },
})

export const RestrictedAccessTitleMessage: FC<RestrictedAccessTitleMessageProps> = ({
  restrictedAccessType,
}) => {
  return (
    <FormattedEnumMessage
      id={restrictedAccessType}
      messages={restrictedAccessTitleMessages}
    />
  )
}
