import { Spacer, Text, VStack, VStackProps } from '@revolut/ui-kit'
import { FC } from 'react'

import { EmptyStatusType } from '../../constants'
import { ErrorStatusDescriptionMessage } from '../ErrorStatusDescriptionMessage'
import { ErrorStatusTitleMessage } from '../ErrorStatusTitleMessage'

interface EmptyStatusProps extends VStackProps {
  type: EmptyStatusType
}

export const EmptyStatus: FC<EmptyStatusProps> = ({ type, ...rest }) => {
  return (
    <VStack space="s-16" align="center" height="100%" {...rest}>
      <Spacer />

      <VStack space="s-8">
        <Text variant="tile" textAlign="center">
          <ErrorStatusTitleMessage type={type} />
        </Text>
        <Text variant="caption" textAlign="center">
          <ErrorStatusDescriptionMessage type={type} />
        </Text>
      </VStack>

      <Spacer />
    </VStack>
  )
}
