import { Header, Group, Item, Token, Image, Flex } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useIntl } from '../../../core-intl'
import { Asset3dImageName, getImageAssetSrcProps } from '../../../core-utils'

export const OnboardingPopupKycDocumentCheckContent: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <>
      <Header variant="item">
        <Header.CloseButton
          aria-label={formatMessage({
            id: 'labels.close',
            defaultMessage: 'Close',
          })}
        />
        <Header.Title>
          <FormattedMessage
            id="onboarding.kycDocumentCheck.title"
            defaultMessage="We're verifying your identity"
          />
        </Header.Title>
        <Header.Subtitle>
          <FormattedMessage
            id="onboarding.kycDocumentCheck.subtitle"
            defaultMessage="This typically takes under an hour, but can sometimes take a little longer"
          />
        </Header.Subtitle>
      </Header>

      <Flex justifyContent="center">
        <Image size={250} {...getImageAssetSrcProps(Asset3dImageName.UserSearch)} />
      </Flex>

      <Group>
        <Item useIcon="Bell" iconColor={Token.color.foreground}>
          <Item.Content>
            <Item.Title>
              <FormattedMessage
                id="onboarding.kycDocumentCheck.weWillNotifyYou.label"
                defaultMessage="We'll notify you as soon as you're verified and ready to go!"
              />
            </Item.Title>
          </Item.Content>
        </Item>
        <Item useIcon="Shield" iconColor={Token.color.foreground}>
          <Item.Content>
            <Item.Title>
              <FormattedMessage
                id="onboarding.kycDocumentCheck.yourPersonalInfoIsProtected.label"
                defaultMessage="Your personal info is protected with multiple layers of security"
              />
            </Item.Title>
          </Item.Content>
        </Item>
      </Group>
    </>
  )
}
