import { checkRequired } from '../../../core-utils'
import { CurrencyPairStatus, CurrencyPairsResponse } from '../../types'
import { useCurrencyPairs } from '../useCurrencyPairs'

const selectCurrencyPairsByStatus = (
  currencyPairs: CurrencyPairsResponse,
  status?: CurrencyPairStatus,
) =>
  Object.entries(currencyPairs).reduce<CurrencyPairsResponse>(
    (acc, [pair, currencyPair]) => {
      if (!status || currencyPair.status === status) {
        acc[pair] = currencyPair
      }
      return acc
    },
    {},
  )

export const useCurrencyPairsRequired = ({
  status,
}: { status?: CurrencyPairStatus } = {}) => {
  const currencyPairs = useCurrencyPairs({
    select: (currencyPairsResponse) =>
      selectCurrencyPairsByStatus(currencyPairsResponse, status),
  })

  return checkRequired(currencyPairs.data, 'Currency pairs should be defined')
}
