import { createContext, useContext } from 'react'

import { EventEmitter, EventMap } from '../../utils'

export interface EventEmitterContextType<E extends EventMap = EventMap> {
  eventEmitter: EventEmitter<E>
}

export const EventEmitterContext = createContext<EventEmitterContextType | null>(null)

export const useEventEmitterContext = <E extends EventMap = EventMap>() => {
  const ctx = useContext<EventEmitterContextType<E> | null>(EventEmitterContext)

  if (ctx === null) {
    throw new Error(
      'You can access context only in components inside "EventEmitterProvider" component',
    )
  }

  return ctx
}
