import { OrderBookExpandedResponse, OrderSide } from '../../core-api'
import { checkRequired } from '../../core-utils'

export type CalcOrderAmountByOrderBookParams = {
  quantity: number
  side: OrderSide
  orderBook: Pick<OrderBookExpandedResponse, 'asks' | 'bids'>
}

export const calcOrderAmountByOrderBook = (params: CalcOrderAmountByOrderBookParams) => {
  const { quantity, side, orderBook } = params

  const { asks, bids } = orderBook

  let amount: number | null = null

  const orders = side === OrderSide.Buy ? asks : bids

  if (orders.length > 0) {
    let restQuantity = quantity
    let orderIndex = 0

    amount = 0

    while (restQuantity > 0 && orderIndex < orders.length) {
      const order = checkRequired(orders[orderIndex], 'Order should be defined')

      if (order.amount >= restQuantity || orderIndex === orders.length - 1) {
        amount += restQuantity * order.price
      } else {
        amount += order.amount * order.price
      }

      restQuantity -= order.amount
      orderIndex += 1
    }
  }

  return amount
}
