import { FC } from 'react'

import { useCurrencyPairRequired } from '../../../core-api'
import { Money, MoneyProps } from '../../../core-ui'
import { CurrencyPairProvider } from '../../providers'

type CurrencyPairMoneyProps = {
  currencyPair: string
  variant: 'base' | 'quote'
} & Omit<MoneyProps, 'currency'>

export const CurrencyPairMoney: FC<CurrencyPairMoneyProps> = ({
  currencyPair,
  variant,
  ...rest
}) => {
  const pair = useCurrencyPairRequired(currencyPair)

  const currency = variant === 'base' ? pair.baseCurrency : pair.quoteCurrency

  return (
    <CurrencyPairProvider currencyPair={currencyPair}>
      <Money {...rest} currency={currency} />
    </CurrencyPairProvider>
  )
}
