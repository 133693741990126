import { Link, Text, Token } from '@revolut/ui-kit'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CurrencyAssetType, RBUAB_REGIONS, useCurrentUser } from '../../../core-api'

interface TransferDisclaimerProps {
  assetType: CurrencyAssetType
}

export const TransferDisclaimer: React.FC<TransferDisclaimerProps> = ({ assetType }) => {
  const { data: user } = useCurrentUser()

  if (assetType === CurrencyAssetType.Fiat && user) {
    const { region, name } = user.entity

    if (RBUAB_REGIONS.includes(region)) {
      return (
        <Text variant="body3" color={Token.color.greyTone50} textAlign="center">
          <FormattedMessage
            id="portfolio.transfer.confirmation.disclaimer"
            defaultMessage="Your money balance available for crypto assets exchange in Revolut X is held in your payment account, which is a payment service provided by {entityName}. Revolut X is a crypto service provided by RT Digital Securities Cyprus Ltd. View <Link>Revolut X terms</Link>."
            values={{
              entityName: name,
              Link: (text) => (
                <Link
                  href="https://www.revolut.com/legal/crypto-exchange-terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text}
                </Link>
              ),
            }}
          />
        </Text>
      )
    }
  }

  return null
}
