import { has } from 'lodash'
import { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { CurrencyPairStatus, useCurrencyPairsRequired } from '../../packlets/core-api'
import { EventTrackerHiddenHeader } from '../../packlets/core-event-tracker'
import { useHumanizeCurrencyPair } from '../../packlets/core-shared'
import { LocalStorageKey, useLocalStorage } from '../../packlets/core-storage'
import { RoutePath, getRouteUrl } from '../../packlets/core-utils'
import { CurrencyPairHelmet } from '../../packlets/feature-markets'
import { Terminal } from '../../packlets/feature-terminal'

const DEFAULT_CURENCY_PAIR = 'BTC-USD'

export const TradePage = () => {
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useLocalStorage<string>(
    LocalStorageKey.TradeSelectedCurrencyPair,
    DEFAULT_CURENCY_PAIR,
  )

  const params = useParams<{ currencyPair?: string }>()

  const { humanizeCurrencyPair, unhumanizeCurrencyPair } = useHumanizeCurrencyPair()

  const currencyPair = params.currencyPair
    ? unhumanizeCurrencyPair(params.currencyPair)
    : undefined

  useEffect(() => {
    if (currencyPair) {
      setSelectedCurrencyPair(currencyPair)
    }
  }, [currencyPair, setSelectedCurrencyPair])

  const currencyPairs = useCurrencyPairsRequired({ status: CurrencyPairStatus.Active })

  if (!currencyPair) {
    return (
      <Redirect
        to={getRouteUrl({
          path: RoutePath.Trade,
          params: {
            currencyPair:
              humanizeCurrencyPair(selectedCurrencyPair) ?? DEFAULT_CURENCY_PAIR,
          },
        })}
      />
    )
  }

  if (!has(currencyPairs, currencyPair)) {
    return (
      <Redirect
        to={getRouteUrl({
          path: RoutePath.Trade,
          params: { currencyPair: DEFAULT_CURENCY_PAIR },
        })}
      />
    )
  }

  return (
    <>
      <CurrencyPairHelmet currencyPair={currencyPair} />

      <EventTrackerHiddenHeader screen="trade" />

      <Terminal currencyPair={currencyPair} />
    </>
  )
}
