import { Box, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

import { Placement } from '@floating-ui/react'

export const TipArrow = styled(Box)<{ placement: Placement }>`
  &::before {
    content: '';
    display: block;
    width: ${Token.size.s24};
    height: ${Token.size.s8};
    background-color: ${Token.color.widgetBackground};
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='8' fill='none'%3E%3Cpath fill='currentColor' d='M10.55 7.053c.93 1.263 2.97 1.263 3.9 0l1.723-2.34C18.076 2.128 21.109.411 24.5 0H.5c3.39.411 6.424 2.128 8.327 4.713l1.723 2.34Z'/%3E%3C/svg%3E");
    mask-size: ${Token.size.s24} ${Token.size.s8};
  }

  position: absolute;

  ${({ placement }) =>
    placement.includes('right-start') &&
    `
  left: ${Token.space.n16};
  top: ${Token.space.s24};

  &::before {
    rotate: 90deg;
  }
  `}

  ${({ placement }) =>
    placement.includes('bottom-start') &&
    `
  left: ${Token.space.s24};
  top: ${Token.space.n8};

  &::before {
    transform: rotate(180deg);
  }
  `}

  ${({ placement }) =>
    placement.includes('left-start') &&
    `
  right: ${Token.space.n16};
  top: ${Token.space.s24};

  &::before {
    rotate: -90deg;    }
  `}

  ${({ placement }) =>
    placement.includes('top-start') &&
    `
  left: ${Token.space.s24};
  bottom: ${Token.space.n8};

  &::before {
    rotate: 0;   
  }
  `}
`
