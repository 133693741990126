import { Button, Grid, HStack, Sticky, VStack } from '@revolut/ui-kit'
import { FC, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { OrderSide, OrderType } from '../../../core-api'
import { useHumanizeCurrencyPair } from '../../../core-shared'
import { useThemePalette } from '../../../core-ui'
import { RoutePath, getRouteUrl, useQueryParams } from '../../../core-utils'
import { InitialOrderState, OrderFormPopup } from '../../../feature-trading'
import { ActivityWidget } from '../ActivityWidget'
import { ChartWidget } from '../ChartWidget'
import { OrderBookWidget } from '../OrderBookWidget'
import { Tickers } from '../Tickers'
import { TerminalProps } from './types'

const GRID_TEMPLATE = `
  "PRICE_CHART" 50vh
  "ORDER_BOOK" 55vh
  "ACTIVITY" 35vh
`

const STICKY_BOTTOM = 70

export const TerminalNarrow: FC<TerminalProps> = ({ currencyPair }) => {
  const [initialOrderState, setInitialOrderState] = useState<InitialOrderState>()

  const { palette } = useThemePalette()

  const [isOrderFormOpen, toggleOrderFormOpen] = useState<boolean>(false)

  const history = useHistory()

  const [queryParams] = useQueryParams<{ orderId?: string }>()

  const { humanizeCurrencyPair } = useHumanizeCurrencyPair()

  const handleResetOrderReplace = useCallback(() => {
    history.replace(
      getRouteUrl({
        path: RoutePath.Trade,
        params: { currencyPair: humanizeCurrencyPair(currencyPair) },
      }),
    )
  }, [history, currencyPair, humanizeCurrencyPair])

  const handleCloseOrderForm = useCallback(() => {
    toggleOrderFormOpen(false)
  }, [toggleOrderFormOpen])

  const handleSelectPrice = useCallback(
    (price: number) => {
      setInitialOrderState({ price, type: OrderType.Limit })

      toggleOrderFormOpen(true)
    },
    [toggleOrderFormOpen],
  )

  const handleBuy = useCallback(() => {
    setInitialOrderState({ side: OrderSide.Buy })

    toggleOrderFormOpen(true)
  }, [toggleOrderFormOpen])

  const handleSell = useCallback(() => {
    setInitialOrderState({ side: OrderSide.Sell })

    toggleOrderFormOpen(true)
  }, [toggleOrderFormOpen])

  return (
    <>
      <VStack space="s-16" flex={1} overflow="auto" mb="s-16">
        <Tickers currencyPair={currencyPair} />

        <Grid template={GRID_TEMPLATE} rowGap="s-16">
          <ChartWidget currencyPair={currencyPair} />

          <OrderBookWidget
            currencyPair={currencyPair}
            onSelectPrice={handleSelectPrice}
          />

          <ActivityWidget />
        </Grid>

        <OrderFormPopup
          orderId={queryParams.orderId}
          initialState={initialOrderState}
          currencyPair={currencyPair}
          onResetOrderReplace={handleResetOrderReplace}
          open={Boolean(isOrderFormOpen || queryParams.orderId)}
          onClose={handleCloseOrderForm}
        />
      </VStack>

      <Sticky bottom={STICKY_BOTTOM}>
        <HStack space="s-16">
          <Button bg={palette.upColor} onClick={handleBuy}>
            <FormattedMessage id="actions.buy" defaultMessage="Buy" />
          </Button>
          <Button bg={palette.downColor} onClick={handleSell}>
            <FormattedMessage id="actions.sell" defaultMessage="Sell" />
          </Button>
        </HStack>
      </Sticky>
    </>
  )
}
