import { GridLayoutTemplate } from '../types'
import { getGridLayoutAreasStyle } from './getGridLayoutAreasStyle'
import { getGridLayoutColumnsStyle } from './getGridLayoutColumnsStyle'
import { getGridLayoutRowsStyle } from './getGridLayoutRowsStyle'

export const getGridLayoutStyle = <T extends string>(
  template: GridLayoutTemplate<T>,
): Pick<
  CSSStyleDeclaration,
  'gridTemplateAreas' | 'gridTemplateRows' | 'gridTemplateColumns'
> => {
  const { areas, rows, columns } = template

  return {
    gridTemplateAreas: getGridLayoutAreasStyle(areas),
    gridTemplateRows: getGridLayoutRowsStyle(rows),
    gridTemplateColumns: getGridLayoutColumnsStyle(columns),
  }
}
