import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { createOrder } from '../../api'
import { QueryKey, REFETCH_INTERVAL } from '../../constants'
import {
  CreateOrderData,
  CreateOrderErrorResponse,
  CreateOrderResponse,
} from '../../types'

export const useCreateOrder = (
  options: UseMutationOptions<
    CreateOrderResponse,
    AxiosError<CreateOrderErrorResponse, CreateOrderData>,
    CreateOrderData
  > = {},
) => {
  const queryClient = useQueryClient()

  return useMutation<
    CreateOrderResponse,
    AxiosError<CreateOrderErrorResponse, CreateOrderData>,
    CreateOrderData
  >(createOrder, {
    ...options,
    onSuccess: (...args) => {
      // TODO: Temporary solution until we have a proper WS implementation https://revolut.atlassian.net/browse/PWCE-707
      setTimeout(() => {
        queryClient.invalidateQueries([QueryKey.Balances])
        queryClient.invalidateQueries([QueryKey.Orders])
        queryClient.invalidateQueries([QueryKey.OrdersHistory])
      }, REFETCH_INTERVAL)

      options.onSuccess?.(...args)
    },
  })
}
