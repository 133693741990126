import { Avatar, Group, Item, Token } from '@revolut/ui-kit'
import { isEmpty } from 'lodash'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useTransfers } from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { GroupSkeleton } from '../../../core-ui'
import { getImageAssetSrcProps, Asset3dImageName } from '../../../core-utils'
import { TransfersGroupItem } from './TransfersGroupItem'

interface TransfersGroupProps {
  currency?: string
  limit?: number
  onItemClick?: (id: string) => void
}

export const TransfersGroup: FC<TransfersGroupProps> = ({
  currency,
  limit,
  onItemClick,
}) => {
  const transfers = useTransfers({
    currency,
    limit,
  })

  if (transfers.status === 'loading') {
    return <GroupSkeleton />
  }

  if (transfers.status === 'error') {
    return <ErrorStatus onRetry={transfers.refetch} />
  }

  if (isEmpty(transfers.data.items)) {
    return (
      <Item>
        <Item.Avatar>
          <Avatar
            variant="brand"
            bg={Token.color.widgetBackground}
            size={40}
            image={getImageAssetSrcProps(Asset3dImageName.Transfers).src}
          />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="activity.transfers.group.empty.title"
              defaultMessage="Your transfers will appear here"
            />
          </Item.Title>
        </Item.Content>
      </Item>
    )
  }

  return (
    <Group>
      {transfers.data.items.map((item) => (
        <TransfersGroupItem key={item.id} transfer={item} onClick={onItemClick} />
      ))}
    </Group>
  )
}
