import { DEFAULT_LOCALE } from '@revolut/ui-kit'

export const formatCountdownTime = (input: number) =>
  new Intl.DateTimeFormat(DEFAULT_LOCALE, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'UTC',
  })
    .format(Math.ceil(input / 1000) * 1000)
    .replace(/^(0)(\d:)/, (_match, _p1, p2) => p2)
