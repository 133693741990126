import { Header, Token, useDateTimeFormat, Box } from '@revolut/ui-kit'
import { FC } from 'react'

import { OrderSide, useCurrencyPairRequired } from '../../../core-api'
import {
  AssetDualAvatar,
  CurrencyPairLink,
  CurrencyPairMoney,
} from '../../../core-shared'

interface TradeDetailsSideHeaderProps {
  currencyPair: string
  side: OrderSide
  date: number
  qty: number
}

export const TradeDetailsSideHeader: FC<TradeDetailsSideHeaderProps> = ({
  side,
  date,
  currencyPair,
  qty,
}) => {
  const pair = useCurrencyPairRequired(currencyPair)

  const dateTimeFormat = useDateTimeFormat({
    style: 'precise',
  })

  const isBuyOrder = side === OrderSide.Buy

  const baseCurrencySign = side === OrderSide.Sell ? -1 : 1

  const primaryCurrencyAsset = isBuyOrder ? pair.baseCurrency : pair.quoteCurrency
  const secondaryCurrencyAsset = isBuyOrder ? pair.quoteCurrency : pair.baseCurrency

  return (
    <Header variant="item">
      <Header.CloseButton />

      <Header.Avatar>
        <Box marginTop={Token.size.s2}>
          <AssetDualAvatar
            primaryAsset={primaryCurrencyAsset}
            secondaryAsset={secondaryCurrencyAsset}
            size={56}
          />
        </Box>
      </Header.Avatar>

      <Header.Title>
        <CurrencyPairMoney
          variant="base"
          currencyPair={currencyPair}
          amount={qty * baseCurrencySign}
          showSign
        />
      </Header.Title>

      <Header.Subtitle>
        <CurrencyPairLink
          currencyPair={currencyPair}
          type="compact"
          variant="primary"
          fontWeight={500}
          display="inline-flex"
          separator=" → "
          swapOrder={isBuyOrder}
          quoteCurrencyColor="foreground"
        />
      </Header.Subtitle>

      <Header.Description>{dateTimeFormat(date)}</Header.Description>
    </Header>
  )
}
