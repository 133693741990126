import axios from 'axios'
import { keyBy } from 'lodash'

import { CurrenciesDetailsResponse } from '../types'

export const getCurrenciesDetails = async () => {
  const { data } = await axios.get<CurrenciesDetailsResponse>(
    '/api/crypto-exchange/config/currencies/details',
  )

  return keyBy(data.currencies, 'code')
}
