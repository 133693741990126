import { useChatWidget } from '@revolut-internal/chat-widget'
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { getChatAccessToken, useAuthContext } from '../../../core-auth'
import { getDeviceId } from '../../../core-utils'
import { CHAT_CONFIG, NEW_CHAT_PAYLOAD } from '../constants'
import { ChatContextType } from '../types'

export const ChatContext = createContext<ChatContextType | null>(null)

export const ChatProvider: FC<PropsWithChildren> = ({ children }) => {
  const { status } = useAuthContext()

  const { chatProviderService, chatSignIn, chatSignOut } = useChatWidget(
    getDeviceId(),
    CHAT_CONFIG,
    getChatAccessToken,
  )

  useEffect(() => {
    if (status === 'authorized') {
      chatSignIn()
    } else {
      chatSignOut()
    }
  }, [chatSignIn, chatSignOut, status])

  const openChat = useCallback(() => {
    chatProviderService.chatApi.openNewChat(NEW_CHAT_PAYLOAD)
  }, [chatProviderService.chatApi])

  const closeChat = useCallback(() => {
    chatProviderService.chatApi.closeChat()
  }, [chatProviderService.chatApi])

  const value: ChatContextType = useMemo(
    () => ({
      chatProviderService,
      openChat,
      closeChat,
    }),
    [chatProviderService, closeChat, openChat],
  )

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>
}
