import axios from 'axios'
import qs from 'qs'

import { Quote } from '../types'

export const getQuote = async (currencyPairs: string[]) => {
  const { data } = await axios.get<Quote[]>('/api/crypto-exchange/quote', {
    params: {
      symbol: currencyPairs,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })

  return data
}
