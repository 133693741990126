import { CreateOrderData, OrderType, ReplaceOrderData } from '../../../core-api'
import { checkRequired } from '../../../core-utils'
import { ExchangeOrderState, OrderValueType } from '../useExchangeOrderInputs'

export const getPlaceOrderData = ({
  clientOrderId,
  currencyPair,
  state,
}: {
  clientOrderId: string
  currencyPair: string
  state: ExchangeOrderState
}): CreateOrderData => {
  const { side, type: orderType, quantity, price, amount, valueType, postOnly } = state

  const orderData: CreateOrderData = {
    clientOrderId,
    currencyPair,
    side,
    orderType,
  }

  if (orderType === OrderType.Limit || valueType === OrderValueType.Amount) {
    orderData.price = checkRequired(price, 'Price is required')
  }

  if (orderType === OrderType.Limit) {
    orderData.postOnly = postOnly
  }

  if (valueType === OrderValueType.Amount) {
    orderData.amount = checkRequired(amount, 'Amount is required')
  } else {
    orderData.qty = checkRequired(quantity, 'Quantity is required')
  }

  return orderData
}

export const getReplaceOrderData = ({
  clientOrderId,
  currencyPair,
  state,
}: {
  clientOrderId: string
  currencyPair: string
  state: ExchangeOrderState
}): ReplaceOrderData => {
  const { orderId, side, quantity, price, amount, valueType, postOnly } = state

  const orderData: ReplaceOrderData = {
    id: checkRequired(orderId, 'Order ID is required'),
    clientOrderId,
    currencyPair,
    side,
    orderType: OrderType.Limit,
    price: checkRequired(price, 'Price is required'),
    postOnly,
  }

  if (valueType === OrderValueType.Amount) {
    orderData.amount = checkRequired(amount, 'Amount is required')
  } else {
    orderData.qty = checkRequired(quantity, 'Quantity is required')
  }

  return orderData
}
