import qs from 'qs'
import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export const useQueryParams = <T>() => {
  const location = useLocation()
  const history = useHistory()

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as T

  const setQueryParams = useCallback(
    (newParams: Partial<T>) => {
      history.replace({
        ...location,
        search: qs.stringify({ ...queryParams, ...newParams }, { addQueryPrefix: true }),
      })
    },
    [history, location, queryParams],
  )

  return [queryParams, setQueryParams] as const
}
