import { Popup } from '@revolut/ui-kit'

import { FeedbackForm } from '../FeedbackForm'

export type FeedbackSideProps = {
  isOpen: boolean
  onClose: VoidFunction
  onComplete: VoidFunction
}
export const FeedbackSide = ({ isOpen, onClose, onComplete }: FeedbackSideProps) => {
  return (
    <Popup open={isOpen} onClose={onClose}>
      <FeedbackForm onComplete={onComplete} onCancel={onClose} />
    </Popup>
  )
}
