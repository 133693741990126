import { Item, useDateTimeFormat } from '@revolut/ui-kit'
import { forwardRef } from 'react'
import { Link } from 'react-router-dom'

import { OrderSide, Trade, useCurrencyPairRequired } from '../../../core-api'
import {
  AssetDualAvatar,
  CurrencyPairMoney,
  useHumanizeCurrency,
} from '../../../core-shared'
import { Money } from '../../../core-ui'
import { RoutePath, getRouteUrl } from '../../../core-utils'
import { getTradesTotal } from '../../utils'

interface TradesGroupItemProps {
  trade: Trade
  onClick?: (id: string) => void
}

export const TradesGroupItem = forwardRef<HTMLDivElement, TradesGroupItemProps>(
  ({ trade, onClick }, ref) => {
    const { side, currencyPair, execDate, qty, price, execId, fee, feeCurrency, amount } =
      trade

    const dateTimeFormat = useDateTimeFormat({ now: new Date() })

    const { baseCurrency, quoteCurrency } = useCurrencyPairRequired(currencyPair)

    const { humanizeCurrency } = useHumanizeCurrency()

    const humanizedBaseCurrency = humanizeCurrency(baseCurrency)
    const humanizedQuoteCurrency = humanizeCurrency(quoteCurrency)

    const isBuy = side === OrderSide.Buy

    const quoteCurrencySign = isBuy ? -1 : 1
    const baseCurrencySign = quoteCurrencySign * -1

    const title = isBuy
      ? `${humanizedQuoteCurrency} → ${humanizedBaseCurrency}`
      : `${humanizedBaseCurrency} → ${humanizedQuoteCurrency}`

    const total = getTradesTotal({ quoteCurrency, feeCurrency, fee, amount, price })

    const tradeLink = getRouteUrl({
      path: RoutePath.ActivityTrades,
      params: { id: execId },
    })

    const handleItemClick = () => {
      if (onClick) {
        onClick(execId)
      }
    }

    const itemProps = onClick
      ? { onClick: handleItemClick }
      : { use: Link, to: tradeLink, ref }

    return (
      <Item use="button" {...itemProps}>
        <Item.Avatar>
          <AssetDualAvatar
            primaryAsset={isBuy ? baseCurrency : quoteCurrency}
            secondaryAsset={isBuy ? quoteCurrency : baseCurrency}
          />
        </Item.Avatar>

        <Item.Content>
          <Item.Title>{title}</Item.Title>
          <Item.Description>{dateTimeFormat(new Date(execDate))}</Item.Description>
        </Item.Content>
        <Item.Side>
          <Item.Value>
            <CurrencyPairMoney
              variant="base"
              currencyPair={currencyPair}
              amount={qty * baseCurrencySign}
              showSign
            />
          </Item.Value>
          <Item.Value variant="secondary">
            <Money
              kind="total"
              currency={quoteCurrency}
              amount={total * quoteCurrencySign}
              showSign
            />
          </Item.Value>
        </Item.Side>
      </Item>
    )
  },
)
