import { FC } from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

import { TransferState } from '../../../core-api'

interface TransferStateMessageProps {
  state: TransferState
}

export const transferStateMessages = defineMessages({
  [TransferState.PROCESSED]: {
    id: 'activity.transfer.state.processed.title',
    defaultMessage: 'Completed',
  },
  [TransferState.DELETED]: {
    id: 'activity.transfer.state.deleted.title',
    defaultMessage: 'Deleted',
  },
  [TransferState.REJECTED]: {
    id: 'activity.transfer.state.rejected.title',
    defaultMessage: 'Failed',
  },
})

export const TransferStateMessage: FC<TransferStateMessageProps> = ({ state }) => {
  return <FormattedMessage {...transferStateMessages[state]} />
}
