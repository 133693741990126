import { FC, useCallback } from 'react'

import { OrderBook } from '../../../feature-markets'
import { WidgetType } from '../../constants'
import { useWidgetEvent } from '../../hooks'
import { TerminalWidget } from '../TerminalWidget'
import { WidgetTypeMessage } from '../WidgetTypeMessage'

interface OrderBookWidgetProps {
  currencyPair: string
  onSelectPrice?: (price: number) => void
}

export const OrderBookWidget: FC<OrderBookWidgetProps> = ({
  currencyPair,
  onSelectPrice,
}) => {
  const { dispatch } = useWidgetEvent()

  const handleSelectPrice = useCallback(
    (price: number) => {
      dispatch('prefillOrder', { price })
    },
    [dispatch],
  )

  return (
    <TerminalWidget type={WidgetType.OrderBook} overflow="auto">
      <TerminalWidget.Header title={<WidgetTypeMessage type={WidgetType.OrderBook} />} />

      <OrderBook
        currencyPair={currencyPair}
        onSelectPrice={onSelectPrice ?? handleSelectPrice}
      />
    </TerminalWidget>
  )
}
