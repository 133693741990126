import { useCallback, useEffect, useMemo, useState } from 'react'

import { useCurrencyPairRequired } from '../../core-api'
import { ORDER_BOOK_STEPS } from '../constants'

export interface OrderBookSteps {
  steps: number[]
  currentStep: number | undefined
  defaultStep: number | undefined
  isFirstStep: boolean
  isLastStep: boolean
  quoteStepScale: number
  decrementStep: () => void
  incrementStep: () => void
  setCurrentStep: (step: number) => void
}

export const useOrderBookSteps = ({
  currencyPair,
}: {
  currencyPair: string
}): OrderBookSteps => {
  const { quoteStep, quoteStepScale } = useCurrencyPairRequired(currencyPair)

  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const steps = useMemo(
    () => ORDER_BOOK_STEPS.map((step) => step * quoteStep),
    [quoteStep],
  )

  const currentStep = steps[currentStepIndex]
  const defaultStep = steps[0]

  const isFirstStep = currentStepIndex === 0
  const isLastStep = currentStepIndex === steps.length - 1

  const decrementStep = useCallback(() => {
    if (!isFirstStep) {
      setCurrentStepIndex((prevIndex) => prevIndex - 1)
    }
  }, [isFirstStep])

  const incrementStep = useCallback(() => {
    if (!isLastStep) {
      setCurrentStepIndex((prevIndex) => prevIndex + 1)
    }
  }, [isLastStep])

  const setCurrentStep = useCallback(
    (step: number) => {
      const newIndex = steps.indexOf(step)
      if (newIndex !== -1) {
        setCurrentStepIndex(newIndex)
      }
    },
    [steps],
  )

  useEffect(() => {
    setCurrentStepIndex(0)
  }, [currencyPair])

  return {
    steps,
    currentStep,
    defaultStep,
    isFirstStep,
    isLastStep,
    quoteStepScale,
    decrementStep,
    incrementStep,
    setCurrentStep,
  }
}
