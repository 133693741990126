export type AnalyticsResponse<T> = {
  from: number
  to: number
  points: T[]
}

export type ValuePoint = {
  timestamp: number
  balance: string
}

export type PerformancePoint = {
  timestamp: number
  change: string
}

export type ProfitAndLossPoint = {
  timestamp: number
  pnl: string
}

export enum TimeSpan {
  '1d' = '1d',
  '1w' = '1w',
  '1m' = '1m',
  '6m' = '6m',
  '1y' = '1y',
  All = 'max',
}

export interface AnalyticsParams {
  timeSpan: TimeSpan
}
