import { ButtonProps } from '@revolut/ui-kit'
import { ReactNode } from 'react'

import { OnboardingStep } from '../../../core-api'
import { useOnboardingContext } from '../useOnboardingContext'

export const useOnboardingButtonProps = <T extends object = ButtonProps>(
  props: T & { children: ReactNode },
) => {
  const { data, toggleOpen } = useOnboardingContext()

  const commonProps = {
    disabled: false,
    pending: false,
    onClick: () => toggleOpen(true),
  }

  return data?.nextStep === undefined || data.nextStep === OnboardingStep.None
    ? props
    : { ...props, ...commonProps }
}
