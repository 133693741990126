import { Flex, ColorMode } from '@revolut/ui-kit'
import { QRCodeSVG } from 'qrcode.react'

import { useThemeMode } from '../../../core-ui'
import { getAssetUrl } from '../../../core-utils'

type QrCodeProps = Omit<React.SVGProps<SVGSVGElement>, 'onChange'> & {
  deeplink: string
  size?: number
}

export const QrCode = ({ deeplink, size = 220, ...rest }: QrCodeProps) => {
  const { resolvedThemeMode: themeMode } = useThemeMode()

  const isDarkMode = themeMode === ColorMode.DARK

  const revolutLogoSrc = getAssetUrl(
    `brand/Revolut-Symbol-${isDarkMode ? 'White' : 'Black'}.svg`,
  )

  return (
    <Flex justifyContent="center" mb="s-24">
      <QRCodeSVG
        {...rest}
        role="img"
        display="block"
        size={size}
        value={deeplink}
        fgColor="currentColor"
        bgColor="transparent"
        level="M"
        imageSettings={{
          src: revolutLogoSrc,
          excavate: true,
          width: 60,
          height: 60,
        }}
      />
    </Flex>
  )
}
