import { useSignOutEffect } from '../../../../packlets/core-auth'
import { GlobalSpinner } from '../../../../packlets/core-ui'

export const SignOutPage = () => {
  useSignOutEffect({
    cause: 'user',
  })

  return <GlobalSpinner />
}
