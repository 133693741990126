import { getStyleComputedProperty } from './getStyleComputedProperty'

export function hasPosition(
  el: HTMLElement | Node | null,
  type: string = 'fixed',
): boolean {
  if (!el || !(el instanceof HTMLElement)) {
    return false
  }

  const { nodeName } = el
  const styles = getStyleComputedProperty(el)

  if (nodeName === 'BODY' || nodeName === 'HTML') {
    return false
  }

  if (styles && styles.position === type) {
    return true
  }

  if (!el.parentNode) {
    return false
  }

  return hasPosition(el.parentNode, type)
}
