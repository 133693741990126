import { getScrollParent } from './getScrollParent'
import { scrollDocument } from './scrollDocument'

export function hasCustomScrollParent(element: HTMLElement | null): boolean {
  if (!element) {
    return false
  }

  const parent = getScrollParent(element)

  return parent ? !parent.isSameNode(scrollDocument()) : false
}
