import { FC } from 'react'

import { Transfer } from '../../../core-api'
import { DetailsSideContent } from '../../../core-ui'
import { useTransferDetails } from '../../hooks'

interface TransferDetailsSideContentProps {
  transfer: Transfer
}

export const TransferDetailsSideContent: FC<TransferDetailsSideContentProps> = ({
  transfer,
}) => {
  const items = useTransferDetails({ transfer })

  return <DetailsSideContent items={items} />
}
