import { Popup, PopupProps } from '@revolut/ui-kit'

import { DepositPopupContent } from './DepositPopupContent'

export type DepositPopupProps = Omit<PopupProps, 'variant'> & {
  currency?: string
  onClose: VoidFunction
}

export const DepositPopup = ({
  open,
  onClose,
  currency,
  ...props
}: DepositPopupProps) => (
  <Popup
    open={open}
    variant="modal-view"
    onClose={onClose}
    shouldKeepMaxHeight
    {...props}
  >
    <DepositPopupContent onClose={onClose} currency={currency} />
  </Popup>
)
