import axios from 'axios'

import { TokenInfo } from './getTokenInfo'

export const REFRESH_TOKEN_URL = '/api/crypto-exchange/token/info'

export const refreshToken = async () => {
  const { data } = await axios.put<TokenInfo>('/api/crypto-exchange/token/refresh')

  return data
}
