import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Time } from '../../../core-utils'
import { getOnboardingStep } from '../../api'
import { HttpCode, QueryKey } from '../../constants'
import { OnboardingStep, ErrorResponse, OnboardingStepReponse } from '../../types'

export const useOnboardingStep = (
  options: UseQueryOptions<
    OnboardingStepReponse,
    AxiosError<ErrorResponse, OnboardingStepReponse>,
    OnboardingStepReponse
  > = {},
) =>
  useQuery<
    OnboardingStepReponse,
    AxiosError<ErrorResponse, OnboardingStepReponse>,
    OnboardingStepReponse
  >([QueryKey.OnboardingStep], () => getOnboardingStep(), {
    ...options,
    refetchInterval: (data, query) => {
      if (query.state.error?.response?.status === HttpCode.UnprocessableEntity) {
        return false
      }

      if (data?.status === HttpCode.NoContent || data?.nextStep === OnboardingStep.None) {
        return false
      }

      if (data?.nextStep === OnboardingStep.CoolDownPeriod) {
        return Time.Second
      }

      return 5 * Time.Second
    },
  })
