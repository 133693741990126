import { Balance, BoxProps, Details, HStack, Text, VStack, Widget } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { CurrencyAssetType, DEFAULT_CURRENCY, ExtendedBalance } from '../../../core-api'
import { EmptyValue, Money, Percents } from '../../../core-ui'

interface BalanceDetailsProps extends BoxProps {
  balance: ExtendedBalance
}

export const BalanceDetails: FC<BalanceDetailsProps> = ({ balance, ...rest }) => {
  const isCrypto = balance.assetType === CurrencyAssetType.Crypto

  return (
    <Widget {...rest}>
      <VStack py="s-16">
        {balance.value !== undefined ? (
          <Balance variant="h2" currency={DEFAULT_CURRENCY} value={balance.value} />
        ) : (
          <Text variant="h2">
            <EmptyValue />
          </Text>
        )}

        <Text variant="body2">
          <FormattedMessage
            id="portfolio.balance.side.value.title"
            defaultMessage="Value"
          />
        </Text>
      </VStack>

      <Details>
        <Details.Title>
          <FormattedMessage
            id="portfolio.balance.side.total.title"
            defaultMessage="Total"
          />
        </Details.Title>
        <Details.Content>
          <Money
            type="money-fractional"
            currency={balance.currency}
            amount={balance.total}
          />
        </Details.Content>
      </Details>

      <Details>
        <Details.Title>
          <FormattedMessage
            id="portfolio.balance.side.available.title"
            defaultMessage="Available"
          />
        </Details.Title>
        <Details.Content>
          <Money
            type="money-fractional"
            currency={balance.currency}
            amount={balance.total - balance.reserved}
          />
        </Details.Content>
      </Details>

      {isCrypto && (
        <Details>
          <Details.Title>
            <FormattedMessage
              id="portfolio.balance.side.unrealisedReturn.title"
              defaultMessage="Unrealised return"
            />
          </Details.Title>
          <Details.Content>
            <HStack space="s-8">
              <Money amount={balance.return} currency={DEFAULT_CURRENCY} showSign />
              <Percents value={balance.performance} />
            </HStack>
          </Details.Content>
        </Details>
      )}
    </Widget>
  )
}
