import { Text, TextProps, TextSkeleton } from '@revolut/ui-kit'
import { FC } from 'react'

import { TickerPriceType, useTickerPrice } from '../../hooks'
import { CurrencyPairMoney } from '../CurrencyPairMoney'

interface TickerPriceProps extends TextProps {
  currencyPair: string
  type?: TickerPriceType
}

export const TickerPrice: FC<TickerPriceProps> = ({
  currencyPair,
  type = 'mid',
  ...rest
}) => {
  const { data, status } = useTickerPrice(currencyPair, type)

  return status === 'success' ? (
    <Text {...rest}>
      <CurrencyPairMoney
        kind="price"
        variant="quote"
        amount={data}
        currencyPair={currencyPair}
      />
    </Text>
  ) : (
    <Text {...rest}>
      <TextSkeleton width="s-48" />
    </Text>
  )
}
