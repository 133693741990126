export const KEY_PREFIX = 'exchange_'

export enum CookieStorageKey {
  DeviceId = 'revo_device_id',
}

export enum LocalStorageKey {
  CookiesPreferences = 'cookiesPreferences',
  CookiesBannerIsClosed = 'cookiesBannerIsClosed',
  FeedbackLastClosedPrompt = 'feedbackLastClosedPrompt',
  FeedbackLastCompletedForm = 'feedbackLastCompletedForm',
  FeedbackLastSessionsDates = 'feedbackSessionDates',
  Locale = 'locale',
  PortfolioBalancesTableSortingRules = 'portfolioBalancesTableSortingRules',
  PortfolioFiatBalancesTableSortingRules = 'portfolioFiatBalancesTableSortingRules',
  PortfolioCryptoBalancesTableSortingRules = 'portfolioCryptoBalancesTableSortingRules',
  SsoCodeVerifier = 'ssoCodeVerifier',
  SsoType = 'ssoType',
  ThemeMode = 'themeMode',
  ThemePalette = 'themePalette',
  TradeGridLayoutTemplate = 'tradeGridLayoutTemplate',
  TradeSelectedActivityType = 'tradeSelectedActivityType',
  TradeSelectedChartType = 'tradeSelectedChartType',
  TradeSelectedResolution = 'tradeSelectedResolution',
  TradeSelectedCurrencyPair = 'tradeSelectedCurrencyPair',
  TradeMarketsTableFilters = 'tradeMarketsTableFilters',
  TradeMarketsQuoteCurrencyFilter = 'tradeMarketQuoteCurrencyFilter',
  WelcomeJourneyBanner = 'welcomeJourneyBanner',
  OnboardingMarketingConsentBanner = 'onboardingMarketingConsentBanner',
  RegisteredIdentityId = 'registeredIdentityId',
}
