import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { TransferType } from '../../../core-api'
import { FormattedEnumMessage } from '../../../core-intl'
import { CurrencyLink } from '../../../core-shared'

interface TransferSubtitleMessageProps {
  type: TransferType
  currency: string
}

const transferSubtitleMessages = defineMessages<TransferType>({
  [TransferType.DEPOSIT]: {
    id: 'activity.details.deposit.title',
    defaultMessage: 'Deposit {currency}',
  },
  [TransferType.WITHDRAWAL]: {
    id: 'activity.details.withdraw.title',
    defaultMessage: 'Withdraw {currency}',
  },
})

export const TransferSubtitleMessage: FC<TransferSubtitleMessageProps> = ({
  type,
  currency,
}) => {
  return (
    <FormattedEnumMessage
      id={type}
      messages={transferSubtitleMessages}
      values={{
        currency: (
          <CurrencyLink
            currency={currency}
            type="compact"
            variant="primary"
            fontWeight={500}
            display="inline-flex"
          />
        ),
      }}
    />
  )
}
