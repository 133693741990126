import { Group } from '@revolut/ui-kit'
import { FC } from 'react'

import { DetailsCellGroupItem, DetailsCellGroupItemProps } from './DetailsCellGroupItem'

interface DetailsCellGroupProps {
  items: DetailsCellGroupItemProps[]
}

export type DetailsCellGroupItems = (
  | DetailsCellGroupItemProps
  | DetailsCellGroupItemProps[]
)[]

export const DetailsCellGroup: FC<DetailsCellGroupProps> = ({ items }) => {
  return (
    <Group>
      {items.map((item) => (
        <DetailsCellGroupItem {...item} />
      ))}
    </Group>
  )
}
