import { FC, PropsWithChildren } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from '../../../packlets/core-auth'
import { ConfigKey, getConfigValue } from '../../../packlets/core-config'
import { EventTracker } from '../../../packlets/core-event-tracker'
import { IntlProvider } from '../../../packlets/core-intl'
import { RoutePath, getExternalRouteUrl, getRouteUrl } from '../../../packlets/core-utils'
import { ReactQueryProvider } from '../ReactQueryProvider'
import { UIKitProvider } from '../UIKitProvider'

const SSO_URL = getConfigValue(ConfigKey.SsoUrl)
const SSO_CLIENT_ID = getConfigValue(ConfigKey.SsoClientId)
const SSO_BUSINESS_CLIENT_ID = getConfigValue(ConfigKey.SsoBusinessClientId)

const SIGN_IN_URL = getRouteUrl({ path: RoutePath.AuthSignIn })
const SIGNED_OUT_URL = getRouteUrl({ path: RoutePath.AuthSignedOut })
const LIMITED_ACCESS_URL = getRouteUrl({ path: RoutePath.AuthLimitedAccess })
const VERIFICATION_URL = getExternalRouteUrl({ path: RoutePath.AuthVerification })

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <IntlProvider>
      <BrowserRouter>
        <ReactQueryProvider>
          <AuthProvider
            ssoUrl={SSO_URL}
            ssoClientId={SSO_CLIENT_ID}
            ssoBusinessClientId={SSO_BUSINESS_CLIENT_ID}
            signInUrl={SIGN_IN_URL}
            signedOutUrl={SIGNED_OUT_URL}
            limitedAccessUrl={LIMITED_ACCESS_URL}
            verificationUrl={VERIFICATION_URL}
          >
            <UIKitProvider>
              {children}
              <EventTracker />
            </UIKitProvider>
          </AuthProvider>
        </ReactQueryProvider>
      </BrowserRouter>
    </IntlProvider>
  )
}
