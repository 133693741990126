import '@revolut/ui-kit/features/newBreakpoints'
import '@revolut/ui-kit/styles.css'

import { Pages } from '../pages'
import { AppProvider } from './providers'

export const App = () => {
  return (
    <AppProvider>
      <Pages />
    </AppProvider>
  )
}
