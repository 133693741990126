import { useEffect, useReducer, useRef } from 'react'
import { useIntl } from 'react-intl'

import {
  OrderSide,
  OrderType,
  useBalance,
  useCurrencyPairRequired,
  useCurrencyRequired,
  useCurrencyUnitsConvert,
  useOrder,
  useOrderBook,
} from '../../../core-api'
import { Feature, useFeatures } from '../../../core-features'
import { useMoneyFormat } from '../../../core-ui'
import { cropDecimalPart } from '../../../core-utils'
import { INITIAL_STATE } from './constants'
import { ActionType, exchangeOrderReducer } from './exchangeOrderReducer'
import { ExchangeOrderInputsReturn, InitialOrderState, OrderValueType } from './types'
import { fillCalculatedFields, getErrorMessages, getValueMessages } from './utils'

export const useExchangeOrderInputs = ({
  initialState,
  currencyPair,
  orderId,
}: {
  initialState?: InitialOrderState
  currencyPair: string
  orderId?: string
}): ExchangeOrderInputsReturn => {
  const { formatMessage } = useIntl()

  const moneyFormat = useMoneyFormat()

  const [rawState, dispatch] = useReducer(exchangeOrderReducer, {
    ...INITIAL_STATE,
    ...initialState,
    valueType:
      (initialState?.side ?? INITIAL_STATE.side) === OrderSide.Buy
        ? OrderValueType.Amount
        : OrderValueType.Quantity,
    touched: initialState !== undefined,
  })

  const { isFeatureEnabled } = useFeatures()
  const ws = isFeatureEnabled(Feature.WebSockets)

  const orderBook = useOrderBook(
    {
      currencyPair,
    },
    {
      ws,
    },
  )

  const pair = useCurrencyPairRequired(currencyPair)

  const quoteCurrency = useCurrencyRequired(pair.quoteCurrency)

  const baseCurrencyBalance = useBalance(pair.baseCurrency)
  const quoteCurrencyBalance = useBalance(pair.quoteCurrency)

  const order = useOrder(orderId)

  useEffect(() => {
    if (order.status === 'success') {
      const payload = {
        orderId: order.data.orderId,
        price: order.data.price,
        type: order.data.orderType,
        side: order.data.side,
        quantity: order.data.qty,
        valueType: OrderValueType.Quantity,
        postOnly: order.data.postOnly,
      }

      dispatch({
        type: ActionType.Prefill,
        payload,
      })
    }
  }, [order.status, order.data, currencyPair])

  const convertTo = useCurrencyUnitsConvert()

  let baseCurrencyFreeBalanceInMajorUnits = convertTo(
    baseCurrencyBalance.data?.amount ?? 0,
    pair.baseCurrency,
    'major',
  )

  if (order.data && order.data.side === OrderSide.Sell) {
    baseCurrencyFreeBalanceInMajorUnits += order.data.leavesQty
  }

  let quoteCurrencyFreeBalanceInMajorUnits = convertTo(
    quoteCurrencyBalance.data?.amount ?? 0,
    pair.quoteCurrency,
    'major',
  )

  if (order.data && order.data.side === OrderSide.Buy) {
    quoteCurrencyFreeBalanceInMajorUnits += order.data.leavesQty * order.data.price
  }

  const handleChangeSide = (nextSide: OrderSide) => {
    dispatch({ type: ActionType.UpdateSide, payload: nextSide })
  }

  const handleChangeType = (nextType: OrderType) => {
    dispatch({ type: ActionType.UpdateType, payload: nextType })
  }

  const handleChangeQuantity = (nextQuantity: number | null) => {
    dispatch({ type: ActionType.UpdateQuantity, payload: nextQuantity })
  }

  const handleChangePostOnly = (nextValue: boolean) => {
    dispatch({ type: ActionType.UpdatePostOnly, payload: nextValue })
  }

  const handleChangeAmount = (nextAmount: number | null) => {
    dispatch({ type: ActionType.UpdateAmount, payload: nextAmount })
  }

  const handleChangePrice = (nextPrice: number | null) => {
    dispatch({ type: ActionType.UpdatePrice, payload: nextPrice })
  }

  const handleChangeSize = (sizeRatio: number) => {
    if (rawState.side === OrderSide.Buy) {
      handleChangeAmount(
        cropDecimalPart(
          quoteCurrencyFreeBalanceInMajorUnits * sizeRatio,
          quoteCurrency.scale,
        ),
      )
    } else {
      handleChangeQuantity(
        cropDecimalPart(
          baseCurrencyFreeBalanceInMajorUnits * sizeRatio,
          pair.baseLotSizeScale,
        ),
      )
    }

    dispatch({ type: ActionType.SetSizeRatio, payload: sizeRatio })
  }

  const handleReset = () => {
    dispatch({ type: ActionType.Reset })
  }

  const handleClear = () => {
    dispatch({ type: ActionType.Clear })
  }

  const handlePrefill = (price: number | null) => {
    dispatch({ type: ActionType.Prefill, payload: { price, type: OrderType.Limit } })
  }

  const prevOrderIdRef = useRef<string | undefined>(orderId)

  useEffect(() => {
    if (prevOrderIdRef.current !== orderId) {
      prevOrderIdRef.current = orderId

      if (orderId === undefined) {
        handleReset()
      }
    }
  }, [currencyPair, orderId])

  useEffect(() => {
    if (orderId !== undefined) {
      return
    }

    if (
      !rawState.touched ||
      (rawState.type === OrderType.Market && rawState.price === null)
    ) {
      const askPrice = orderBook.data?.askPrice
      const bidPrice = orderBook.data?.bidPrice

      if (rawState.side === OrderSide.Buy && askPrice !== undefined) {
        dispatch({ type: ActionType.SetPrice, payload: askPrice })
      } else if (rawState.side === OrderSide.Sell && bidPrice !== undefined) {
        dispatch({ type: ActionType.SetPrice, payload: bidPrice })
      }
    }
  }, [
    orderId,
    orderBook.data,
    rawState.type,
    rawState.side,
    rawState.touched,
    rawState.price,
  ])

  const state = fillCalculatedFields(rawState, orderBook.data, quoteCurrency, pair)

  const handleToggleValueType = () => {
    dispatch({
      type: ActionType.ToggleValueType,
      payload: state.valueType === OrderValueType.Amount ? state.quantity : state.amount,
    })
  }

  const baseCurrencyBalanceMessage = moneyFormat({
    amount: baseCurrencyFreeBalanceInMajorUnits,
    currency: pair.baseCurrency,
  })

  const quoteCurrencyBalanceMessage = moneyFormat({
    amount: quoteCurrencyFreeBalanceInMajorUnits,
    currency: pair.quoteCurrency,
  })

  const {
    baseCurrencyErrorMessage,
    baseCurrencyErrorTooltipMessage,
    quoteCurrencyErrorMessage,
  } = getErrorMessages({
    state,
    baseCurrencyFreeBalanceInMajorUnits,
    quoteCurrencyFreeBalanceInMajorUnits,
    currencyPair: pair,
    formatMessage,
    formatMoney: moneyFormat,
  })

  const { baseCurrencyValueMessage, quoteCurrencyValueMessage } = getValueMessages({
    state,
    formatMessage,
  })

  const baseCurrencyInvalid = baseCurrencyErrorMessage !== null
  const baseCurrencyMessage = baseCurrencyErrorMessage ?? baseCurrencyValueMessage

  const quoteCurrencyInvalid = quoteCurrencyErrorMessage !== null
  const quoteCurrencyMessage = quoteCurrencyErrorMessage ?? quoteCurrencyValueMessage

  const isInvalid = baseCurrencyInvalid || quoteCurrencyInvalid

  const isFetched =
    orderBook.status === 'success' &&
    baseCurrencyBalance.status === 'success' &&
    quoteCurrencyBalance.status === 'success'

  const isEmptyValues = state.amount === null && state.quantity === null

  const isPriceMissing =
    !(state.type === OrderType.Market && state.valueType === OrderValueType.Quantity) &&
    state.price === null

  const isWrongCurrencyPair =
    Boolean(orderId) && order.data?.currencyPair !== currencyPair

  const disabled =
    !isFetched || isInvalid || isEmptyValues || isPriceMissing || isWrongCurrencyPair

  const depositRequired =
    isFetched &&
    (state.side === OrderSide.Buy
      ? quoteCurrencyFreeBalanceInMajorUnits === 0
      : baseCurrencyFreeBalanceInMajorUnits === 0)

  const depositCurrency =
    state.side === OrderSide.Buy ? pair.quoteCurrency : pair.baseCurrency

  return {
    state,

    baseCurrencyInvalid,
    baseCurrencyMessage,
    baseCurrencyBalanceMessage,
    baseCurrencyErrorMessage,
    baseCurrencyErrorTooltipMessage,

    quoteCurrencyInvalid,
    quoteCurrencyMessage,
    quoteCurrencyBalanceMessage,
    quoteCurrencyErrorMessage,

    disabled,
    loading: !isFetched,

    depositRequired,
    depositCurrency,

    handleChangeSide,
    handleChangeType,
    handleToggleValueType,
    handleChangeQuantity,
    handleChangeAmount,
    handleChangePostOnly,
    handleChangePrice,
    handleChangeSize,
    handleReset,
    handleClear,
    handlePrefill,
  }
}
