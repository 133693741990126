import CryptoJS from 'crypto-js'

const base64URL = (string: CryptoJS.lib.WordArray) =>
  string
    .toString(CryptoJS.enc.Base64)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')

export const generateCodeChallenge = (codeVerifier: string) =>
  base64URL(CryptoJS.SHA256(codeVerifier))
