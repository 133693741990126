import { Page, PageProps } from '@revolut/ui-kit'
import { lazy, Suspense, useEffect, useRef } from 'react'
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'

import { AuthRoute } from '../packlets/core-auth'
import { Feature, useFeatures } from '../packlets/core-features'
import {
  CompatibilityChecker,
  CookiesModal,
  CurrencyProvider,
  MaintenanceChecker,
  useWelcomeJourney,
} from '../packlets/core-shared'
import { LocalStorageKey, useLocalStorage } from '../packlets/core-storage'
import { GlobalSpinner } from '../packlets/core-ui'
import {
  browser,
  getRoutePath,
  getRouteUrl,
  RestrictedAccessType,
  ROUTE_PATHS,
  RoutePath,
} from '../packlets/core-utils'
import { WelcomeJourneyProvider } from '../packlets/core-welcome-journey'
import { FeedbackProvider } from '../packlets/feature-feedback'
import { OnboardingProvider } from '../packlets/feature-onboarding'
import { Chat, ChatProvider } from '../packlets/feature-support'
import { AuthPages } from './auth'
import { ErrorPage } from './error'
import { TradePage } from './trade'

const ProfilePage = lazy(() =>
  import(/* webpackChunkName: "profile" */ './profile').then((module) => ({
    default: module.ProfilePage,
  })),
)

const HomePage = lazy(() =>
  import(/* webpackChunkName: "home" */ './home').then((module) => ({
    default: module.HomePage,
  })),
)

const PortfolioPage = lazy(() =>
  import(/* webpackChunkName: "portfolio" */ './portfolio').then((module) => ({
    default: module.PortfolioPage,
  })),
)

const ActivityPages = lazy(() =>
  import(/* webpackChunkName: "activity" */ './activity').then((module) => ({
    default: module.ActivityPages,
  })),
)

const getAppLayoutVariant = (pathname: string): PageProps['variant'] | undefined => {
  if (matchPath(pathname, { path: getRoutePath(RoutePath.Profile) })) {
    return 'focused'
  }

  return 'default'
}

const MAIN_ROUTE_PATHS: RoutePath[] = [
  RoutePath.Home,
  RoutePath.Portfolio,
  RoutePath.Trade,
  RoutePath.ActivityOpenOrders,
  RoutePath.ActivityOrderHistory,
  RoutePath.ActivityTrades,
  RoutePath.ActivityTransfers,
  RoutePath.ProfileAppearance,
  RoutePath.NotificationSettings,
]

const AuthRoutes = () => {
  const location = useLocation()
  const history = useHistory()

  const features = useFeatures()

  const { tips, messages } = useWelcomeJourney()

  const exchangeEnabled = features.isFeatureEnabled(Feature.Exchange)

  const [cookiesBannerIsClosed] = useLocalStorage(
    LocalStorageKey.CookiesBannerIsClosed,
    false,
  )

  useEffect(() => {
    if (features.status === 'success' && !exchangeEnabled) {
      history.replace(
        getRouteUrl({
          path: RoutePath.AuthRestrictedAccess,
          params: { type: RestrictedAccessType.Region },
        }),
      )
    }
  }, [features.status, exchangeEnabled, history])

  const lastRoutePathRef = useRef<RoutePath | undefined>()

  useEffect(() => {
    const currentRoutePath = MAIN_ROUTE_PATHS.find((path) =>
      matchPath(location.pathname, { path: ROUTE_PATHS[path] }),
    )

    if (
      currentRoutePath &&
      currentRoutePath !== lastRoutePathRef.current &&
      MAIN_ROUTE_PATHS.includes(currentRoutePath)
    ) {
      browser.scrollTo({ top: 0 })
    }

    lastRoutePathRef.current = currentRoutePath
  }, [location.pathname])

  if (features.status === 'error') {
    return <Redirect to={getRouteUrl({ path: RoutePath.Error })} />
  }

  if (features.status === 'loading' || !exchangeEnabled) {
    return <GlobalSpinner />
  }

  return (
    <CompatibilityChecker>
      <MaintenanceChecker>
        <OnboardingProvider enabled={cookiesBannerIsClosed}>
          <CurrencyProvider>
            <Suspense fallback={<GlobalSpinner />}>
              <ChatProvider>
                <FeedbackProvider>
                  <WelcomeJourneyProvider tips={tips} messages={messages}>
                    <Switch>
                      <AuthRoute
                        path={[
                          getRoutePath(RoutePath.Profile),
                          getRoutePath(RoutePath.Home),
                          getRoutePath(RoutePath.Trade),
                          getRoutePath(RoutePath.Portfolio),
                          getRoutePath(RoutePath.Activity),
                        ]}
                      >
                        <Page variant={getAppLayoutVariant(location.pathname)}>
                          <Switch>
                            <AuthRoute
                              path={getRoutePath(RoutePath.Profile)}
                              component={ProfilePage}
                            />
                            <AuthRoute
                              path={getRoutePath(RoutePath.Home)}
                              component={HomePage}
                            />
                            <AuthRoute
                              path={getRoutePath(RoutePath.Trade)}
                              component={TradePage}
                            />
                            <AuthRoute
                              path={getRoutePath(RoutePath.Portfolio)}
                              component={PortfolioPage}
                            />
                            <AuthRoute
                              path={getRoutePath(RoutePath.Activity)}
                              component={ActivityPages}
                            />
                          </Switch>

                          <Chat />
                        </Page>
                      </AuthRoute>

                      <Redirect
                        to={getRouteUrl({
                          path: RoutePath.Home,
                        })}
                      />
                    </Switch>

                    <CookiesModal />
                  </WelcomeJourneyProvider>
                </FeedbackProvider>
              </ChatProvider>
            </Suspense>
          </CurrencyProvider>
        </OnboardingProvider>
      </MaintenanceChecker>
    </CompatibilityChecker>
  )
}

export const Pages = () => (
  <Switch>
    <Route path={getRoutePath(RoutePath.Auth)} component={AuthPages} />
    <Route path={getRoutePath(RoutePath.Error)} component={ErrorPage} exact />

    <AuthRoute
      path={[
        getRoutePath(RoutePath.Profile),
        getRoutePath(RoutePath.Home),
        getRoutePath(RoutePath.Trade),
        getRoutePath(RoutePath.Portfolio),
        getRoutePath(RoutePath.Activity),
      ]}
    >
      <AuthRoutes />
    </AuthRoute>

    <Redirect
      to={getRouteUrl({
        path: RoutePath.Home,
      })}
    />
  </Switch>
)
