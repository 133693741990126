import { DetailsCell, Group } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { TransferQuotesFeeBreakdown } from '../../../core-api'
import { FeeBreakdownDetailsItem } from './FeeBreakdownDetailsItem'

type FeeBreakdownDetailsProps = {
  breakdown: TransferQuotesFeeBreakdown[]
}

export const FeeBreakdownDetails: FC<FeeBreakdownDetailsProps> = ({ breakdown }) => (
  <Group>
    {breakdown.length === 0 ? (
      <DetailsCell>
        <DetailsCell.Title>
          <FormattedMessage
            id="portfolio.feeBreakdown.details.fees.title"
            defaultMessage="Fees"
          />
        </DetailsCell.Title>
        <DetailsCell.Content>
          <FormattedMessage id="shared.orders.noFees.label" defaultMessage="No fees" />
        </DetailsCell.Content>
      </DetailsCell>
    ) : (
      breakdown.map((fee) => (
        <FeeBreakdownDetailsItem
          key={fee.title}
          title={fee.title}
          amount={fee.amount.value}
          currency={fee.amount.symbol}
          description={fee.description}
        />
      ))
    )}
  </Group>
)
