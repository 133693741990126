import axios from 'axios'

import { OnboardingStepReponse } from '../types'

export const getOnboardingStep = async () => {
  const { data, status } = await axios.get<OnboardingStepReponse>(
    '/api/crypto-exchange/onboarding/step',
  )

  return { ...data, status }
}
