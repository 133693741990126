import { TabBar } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { OrderSide } from '../../../core-api'
import { useIntl } from '../../../core-intl'

type OrderSideTabsProps = {
  side: OrderSide
  onChangeSide: (side: OrderSide) => void
  disabled?: boolean
}

export const OrderSideTabs: FC<OrderSideTabsProps> = ({
  side,
  onChangeSide,
  disabled,
}) => {
  const { formatMessage } = useIntl()

  return (
    <TabBar
      variant="segmented fit"
      width="100%"
      aria-label={formatMessage({
        id: 'labels.orderSides',
        defaultMessage: 'Order sides',
      })}
    >
      <TabBar.Item
        use="button"
        onClick={() => onChangeSide(OrderSide.Buy)}
        aria-label={formatMessage({
          id: 'labels.orderSide.buy',
          defaultMessage: 'Buy',
        })}
        aria-selected={side === OrderSide.Buy}
        disabled={disabled}
      >
        <FormattedMessage id="trading.orderForm.tabs.buy.title" defaultMessage="Buy" />
      </TabBar.Item>
      <TabBar.Item
        use="button"
        onClick={() => onChangeSide(OrderSide.Sell)}
        aria-label={formatMessage({
          id: 'labels.orderSide.sell',
          defaultMessage: 'Sell',
        })}
        aria-selected={side === OrderSide.Sell}
        disabled={disabled}
      >
        <FormattedMessage id="trading.orderForm.tabs.sell.title" defaultMessage="Sell" />
      </TabBar.Item>
    </TabBar>
  )
}
