import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getFees } from '../../api'
import { QueryKey } from '../../constants'
import { FeesResponse } from '../../types'

export const useFees = <T extends unknown = FeesResponse>(
  options: UseQueryOptions<FeesResponse, AxiosError, T> = {},
) => useQuery<FeesResponse, AxiosError, T>([QueryKey.Fees], () => getFees(), options)
