import { Avatar, AvatarProps } from '@revolut/ui-kit'
import { FC, memo } from 'react'

import { useCurrenciesDetailsRequired } from '../../../core-api'
import { getCurrencyAssetUrl } from '../../utils'

interface AssetAvatarProps extends AvatarProps {
  asset: string
  badgeAsset?: string
}

export const AssetAvatar: FC<AssetAvatarProps> = memo(
  ({ asset, badgeAsset, children, ...rest }) => {
    const currenciesDetails = useCurrenciesDetailsRequired()

    const currency = currenciesDetails[asset]
    const badgeCurrency = badgeAsset ? currenciesDetails[badgeAsset] : undefined

    const image = currency
      ? getCurrencyAssetUrl(currency.ticker, currency.type)
      : undefined
    const badgeImage = badgeCurrency
      ? getCurrencyAssetUrl(badgeCurrency.ticker, badgeCurrency.type)
      : undefined

    return (
      <Avatar image={image} {...rest}>
        {badgeImage ? <Avatar.Badge image={badgeImage} /> : children}
      </Avatar>
    )
  },
)
