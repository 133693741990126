import { Item, useDateTimeFormat } from '@revolut/ui-kit'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Transfer } from '../../../core-api'
import { AssetAvatar, useHumanizeCurrency } from '../../../core-shared'
import { Money } from '../../../core-ui'
import { RoutePath, getRouteUrl } from '../../../core-utils'
import { TransferTypeMessage } from '../TransferTypeMessage'

interface TransfersGroupItemProps {
  transfer: Transfer
  onClick?: (id: string) => void
}

export const TransfersGroupItem: FC<TransfersGroupItemProps> = ({
  transfer,
  onClick,
}) => {
  const dateTimeFormat = useDateTimeFormat({ now: new Date() })

  const { humanizeCurrency } = useHumanizeCurrency()

  const humanizedCurrency = humanizeCurrency(transfer.assetId)

  const transferLink = getRouteUrl({
    path: RoutePath.ActivityTransfers,
    params: { id: transfer.id },
  })

  const handleItemClick = () => {
    if (onClick) {
      onClick(transfer.id)
    }
  }

  const itemProps = onClick
    ? { onClick: handleItemClick }
    : { use: Link, to: transferLink }

  return (
    <Item use="button" {...itemProps}>
      <Item.Avatar>
        <AssetAvatar asset={transfer.assetId} />
      </Item.Avatar>

      <Item.Content>
        <Item.Title>
          <TransferTypeMessage
            variant="currency"
            type={transfer.type}
            currency={humanizedCurrency}
          />
        </Item.Title>
        <Item.Description>
          {dateTimeFormat(new Date(transfer.updatedDate))}
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <Money
            type="money-fractional"
            currency={transfer.assetId}
            amount={transfer.amount}
            showSign
          />
        </Item.Value>
      </Item.Side>
    </Item>
  )
}
