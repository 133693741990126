import {
  AmountHero as UIAmountHero,
  AmountHeroProps as UIAmountHeroProps,
  useMoneyFormat,
} from '@revolut/ui-kit'
import { FC } from 'react'

import { AmountHeroWrapper } from './styled'

interface AmountHeroProps extends UIAmountHeroProps {
  value: number | null
  currency: string
}

const AMOUNT_LENGTH_M = 8
const AMOUNT_LENGTH_L = 12
const FIAT_AMOUNT_ID = 'fiat-amount-input'

const getLength = (length: number) => {
  if (length > AMOUNT_LENGTH_L) {
    return 'l'
  }

  if (length > AMOUNT_LENGTH_M) {
    return 'm'
  }

  return undefined
}

// TODO: PWCE-2163 Temporary wrapper with fixes from Ramp (https://bitbucket.org/revolut/ramp-ui/src/master/widgets/AmountHero/styled.ts) until things will be fixed in UI kit
export const AmountHero: FC<AmountHeroProps> = ({ value, type, currency, ...rest }) => {
  const moneyFormat = useMoneyFormat({ type, minimumFractionDigits: 0 })

  const amountLength = moneyFormat({ currency, amount: value ?? 0 }).length

  return (
    <AmountHeroWrapper length={getLength(amountLength)}>
      <label htmlFor={FIAT_AMOUNT_ID}>
        <UIAmountHero
          id={FIAT_AMOUNT_ID}
          type={type}
          currency={currency}
          value={value}
          {...rest}
        />
      </label>
    </AmountHeroWrapper>
  )
}
