import { CONFIG, Config, ConfigKey, DEFAULT_ENV } from '../constants'
import { getEnvByOrigin } from './getEnvByOrigin'

type GetConfigValueOptions = {
  config?: Config
}

export const getConfigValue = <T = string>(
  key: ConfigKey,
  options?: GetConfigValueOptions,
): T => {
  const config = options?.config ?? CONFIG

  const env = getEnvByOrigin()

  return config[env][key] ?? config[DEFAULT_ENV][key]
}
