import { Avatar, Group, Item, Search, Token, VStack } from '@revolut/ui-kit'
import { FC, ReactNode, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { CurrencyAssetType, Pocket, useStableSortedPockets } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { ErrorStatus, NoResultsPlaceholder } from '../../../core-shared'
import { GroupSkeleton } from '../../../core-ui'
import {
  Asset3dImageName,
  getImageAssetSrcProps,
  matchFilterValue,
} from '../../../core-utils'
import { PocketsGroupItem } from './PocketsGroupItem'

const ITEMS_SEARCH_LIMIT = 10

type PocketsGroupProps = {
  subheader?: ReactNode
  assetType?: CurrencyAssetType
  currency?: string
  filter?: (pocket: Pocket) => boolean
  onItemClick?: (pocket: Pocket) => void
}

export const PocketsGroup: FC<PocketsGroupProps> = ({
  subheader,
  assetType,
  currency,
  filter,
  onItemClick,
}) => {
  const { formatMessage } = useIntl()

  const pockets = useStableSortedPockets()

  const [search, setSearch] = useState('')

  const items = useMemo(() => {
    if (pockets.data) {
      let result = pockets.data

      if (assetType) {
        result = result.filter((pocket) => pocket.assetType === assetType)
      }

      if (currency) {
        result = result.filter((pocket) => pocket.currency === currency)
      }

      if (filter) {
        result = result.filter(filter)
      }

      return result
    }

    return []
  }, [pockets.data, assetType, currency, filter])

  const shouldShowSearch = items.length >= ITEMS_SEARCH_LIMIT

  const filteredItems = useMemo(
    () => items.filter((item) => matchFilterValue(item.currency, search)),
    [items, search],
  )

  if (pockets.status === 'loading') {
    return <GroupSkeleton />
  }

  if (pockets.status === 'error') {
    return <ErrorStatus onRetry={pockets.refetch} />
  }

  return (
    <VStack space="s-16">
      {shouldShowSearch && (
        <Search
          placeholder={formatMessage({
            id: 'labels.search',
            defaultMessage: 'Search',
          })}
          value={search}
          onChange={setSearch}
        />
      )}

      <Group>
        {subheader}

        {filteredItems.length === 0 && !search && (
          <Item>
            <Item.Avatar>
              <Avatar
                variant="brand"
                bg={Token.color.widgetBackground}
                size={40}
                image={getImageAssetSrcProps(Asset3dImageName.Trades).src}
              />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <FormattedMessage
                  id="portfolio.pockets.group.empty.title"
                  defaultMessage="No balances available"
                />
              </Item.Title>
            </Item.Content>
          </Item>
        )}

        {filteredItems.length === 0 && search && (
          <NoResultsPlaceholder search={search} onClearSearch={() => setSearch('')} />
        )}

        {filteredItems.map((pocket) => (
          <PocketsGroupItem
            key={pocket.currency}
            pocket={pocket}
            onClick={() => onItemClick?.(pocket)}
          />
        ))}
      </Group>
    </VStack>
  )
}
