import { Banner, Image } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Asset3dImageName, getImageAssetSrcProps } from '../../../core-utils'
import { useOnboardingContext } from '../../hooks'

export const OnboardingMarketingConsentBanner: FC = () => {
  const { toggleOpen } = useOnboardingContext()

  return (
    <Banner onClick={() => toggleOpen(true)} style={{ cursor: 'pointer' }}>
      <Banner.Avatar>
        <Image
          size={56}
          minWidth={56}
          {...getImageAssetSrcProps(Asset3dImageName.Gift)}
        />
      </Banner.Avatar>
      <Banner.Content>
        <Banner.Title>
          <FormattedMessage
            id="onboarding.banner.marketingConsent.title"
            defaultMessage="Make the most of Revolut X"
          />
        </Banner.Title>
        <Banner.Description>
          <FormattedMessage
            id="onboarding.banner.marketingConsent.description"
            defaultMessage="Allow us to send you updates about exciting offers, products and services."
          />
        </Banner.Description>
      </Banner.Content>
    </Banner>
  )
}
