import { useCallback } from 'react'

import { useExperiments } from '../../../core-api'
import { FEATURES } from '../../constants'
import { Feature } from '../../types'

export const useFeatures = () => {
  const experiments = useExperiments()

  const isFeatureEnabled = useCallback(
    (feature: Feature) => {
      if (experiments.status === 'success') {
        return FEATURES[feature]({ experiments: experiments.data })
      }

      return false
    },
    [experiments],
  )

  return { isFeatureEnabled, status: experiments.status }
}
