import { SelectOptionItemType, TableData, TableFilterProps } from '@revolut/ui-kit'

import { OrderType } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { TableFilterRadioSelect } from '../../../core-shared'
import { OrderTypeMessage } from '../OrderTypeMessage'

interface TableFilterOrderTypeProps<T extends TableData> {
  column: TableFilterProps<T>['column']
}

const OPTIONS_ORDER: OrderType[] = [OrderType.Market, OrderType.Limit]

const OPTIONS: SelectOptionItemType<OrderType>[] = OPTIONS_ORDER.map((type) => ({
  key: type,
  label: <OrderTypeMessage type={type} />,
  value: type,
}))

export const TableFilterOrderType = <T extends TableData>({
  column,
}: TableFilterOrderTypeProps<T>) => {
  const { formatMessage } = useIntl()

  return (
    <TableFilterRadioSelect
      aria-label={formatMessage({
        id: 'labels.filterByOrderType',
        defaultMessage: 'Filter by order type',
      })}
      column={column}
      options={OPTIONS}
    />
  )
}
