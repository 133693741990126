import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getAnalyticsPerformance } from '../../api'
import { QueryKey } from '../../constants'
import { AnalyticsResponse, AnalyticsParams, PerformancePoint } from '../../types'

export const useAnalyticsPerformance = <
  T extends unknown = AnalyticsResponse<PerformancePoint>,
>(
  params: AnalyticsParams,
) =>
  useQuery<AnalyticsResponse<PerformancePoint>, AxiosError, T>(
    [QueryKey.AnalyticsPerformance, params],
    () => getAnalyticsPerformance(params),
  )
