import { AxiosError } from 'axios'
import { ReactNode, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { v4 as uuid } from 'uuid'

import {
  ACTIVE_ORDERS_LIMIT,
  CreateOrderData,
  CreateOrderErrorResponse,
  ErrorCode,
  ReplaceOrderData,
  ReplaceOrderErrorResponse,
  useCreateOrder,
  useReplaceOrder,
} from '../../../core-api'
import { useStatusPopup } from '../../../core-ui'
import { InitialOrderState, useExchangeOrderInputs } from '../useExchangeOrderInputs'
import { getPlaceOrderData, getReplaceOrderData } from './utils'

export const useExchangeOrderSubmit = ({
  initialState,
  currencyPair,
  orderId,
  onCreateSuccess,
  onReplaceSuccess,
}: {
  initialState?: InitialOrderState
  currencyPair: string
  orderId?: string
  onCreateSuccess?: VoidFunction
  onReplaceSuccess: VoidFunction
}) => {
  const clientOrderId = useRef(uuid())

  const { state, disabled, handleClear, ...rest } = useExchangeOrderInputs({
    initialState,
    currencyPair,
    orderId,
  })

  const statusPopup = useStatusPopup()

  const handleError = (
    error?:
      | AxiosError<CreateOrderErrorResponse, CreateOrderData>
      | AxiosError<ReplaceOrderErrorResponse, ReplaceOrderData>,
  ) => {
    let errorTitle = (
      <FormattedMessage
        id="trading.order.error.title"
        defaultMessage="Something went wrong"
      />
    )

    let errorDescriptionMessage: ReactNode

    let errorButtonText: ReactNode

    if (error && error.response?.data.code === ErrorCode.ActiveOrdersLimitExceeded) {
      errorTitle = (
        <FormattedMessage
          id="trading.order.error.activeOrdersLimitExceeded.title"
          defaultMessage="Order can't be placed"
        />
      )

      errorDescriptionMessage = (
        <FormattedMessage
          id="trading.order.error.activeOrdersLimitExceeded.subtitle"
          defaultMessage="You can't have more than {limit} active orders"
          values={{ limit: ACTIVE_ORDERS_LIMIT }}
        />
      )

      errorButtonText = <FormattedMessage id="actions.gotIt" defaultMessage="Got it" />
    }

    statusPopup.showError({
      title: errorTitle,
      description: errorDescriptionMessage,
      buttonText: errorButtonText,
    })
  }

  const createOrder = useCreateOrder({
    onSuccess: () => {
      statusPopup.showSuccess({
        title: (
          <FormattedMessage
            id="trading.order.success.title"
            defaultMessage="Order placed"
          />
        ),
        description: (
          <FormattedMessage
            id="trading.order.success.subtitle"
            defaultMessage="Your order has been placed"
          />
        ),
      })

      clientOrderId.current = uuid()

      handleClear()

      onCreateSuccess?.()
    },
    onError: handleError,
  })

  const replaceOrder = useReplaceOrder({
    onSuccess: () => {
      statusPopup.showSuccess({
        title: (
          <FormattedMessage
            id="trading.replaceOrder.success.title"
            defaultMessage="Order saved"
          />
        ),
      })

      clientOrderId.current = uuid()

      handleClear()

      onReplaceSuccess()
    },
    onError: handleError,
  })

  const handleSubmit = () => {
    if (disabled) {
      handleError()

      return
    }

    statusPopup.showLoading()

    if (state.orderId === null) {
      createOrder.mutate(
        getPlaceOrderData({
          currencyPair,
          state,
          clientOrderId: clientOrderId.current,
        }),
      )
    } else {
      replaceOrder.mutate(
        getReplaceOrderData({
          currencyPair,
          state,
          clientOrderId: clientOrderId.current,
        }),
      )
    }
  }

  return {
    state,
    disabled,
    submitting: createOrder.isLoading || replaceOrder.isLoading,
    handleClear,
    handleSubmit,
    ...rest,
  }
}
