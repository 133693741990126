import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getOrdersHistory } from '../../api'
import { DEFAULT_PAGE_SIZE, INFINITE_QUERY_KEY, QueryKey } from '../../constants'
import { OrdersParams, OrdersReponse } from '../../types'

export const useInfiniteOrdersHistory = <T extends unknown = OrdersReponse>(
  params: Omit<OrdersParams, 'cursor'>,
  options: UseInfiniteQueryOptions<OrdersReponse, AxiosError, T> = {},
) =>
  useInfiniteQuery<OrdersReponse, AxiosError, T>(
    [QueryKey.OrdersHistory, INFINITE_QUERY_KEY, params],
    ({ pageParam }) =>
      getOrdersHistory({
        ...params,
        cursor: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.items.length < DEFAULT_PAGE_SIZE) {
          return undefined
        }
        return lastPage.cursor !== '' ? lastPage.cursor : undefined
      },
      ...options,
    },
  )
