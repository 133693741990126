import { UserRestriction, UserRestrictionType } from '../types'

export type UserRestrictionKey =
  | {
      type:
        | UserRestrictionType.READ
        | UserRestrictionType.DEPOSIT
        | UserRestrictionType.WITHDRAW
        | UserRestrictionType.CREATE_ORDER
        | UserRestrictionType.REPLACE_ORDER
        | UserRestrictionType.CANCEL_ORDER
    }
  | {
      type: UserRestrictionType.DEPOSIT_CURRENCY | UserRestrictionType.WITHDRAW_CURRENCY
      currency?: string
    }
  | {
      type:
        | UserRestrictionType.CREATE_ORDER_CURRENCY_PAIR
        | UserRestrictionType.REPLACE_ORDER_CURRENCY_PAIR
        | UserRestrictionType.CANCEL_ORDER_CURRENCY_PAIR
      currencyPair: string
    }

const USER_RESTRICTION_MAP = {
  [UserRestrictionType.DEPOSIT_CURRENCY]: UserRestrictionType.DEPOSIT,
  [UserRestrictionType.WITHDRAW_CURRENCY]: UserRestrictionType.WITHDRAW,
  [UserRestrictionType.CREATE_ORDER_CURRENCY_PAIR]: UserRestrictionType.CREATE_ORDER,
  [UserRestrictionType.REPLACE_ORDER_CURRENCY_PAIR]: UserRestrictionType.REPLACE_ORDER,
  [UserRestrictionType.CANCEL_ORDER_CURRENCY_PAIR]: UserRestrictionType.CANCEL_ORDER,
}

export const findUserRestriction = (
  restrictionKey: UserRestrictionKey,
  restrictions: UserRestriction[],
) => {
  const generalRestriction = restrictions.find((item) =>
    restrictionKey.type in USER_RESTRICTION_MAP
      ? item.type === USER_RESTRICTION_MAP[restrictionKey.type]
      : item.type === restrictionKey.type,
  )

  if (generalRestriction !== undefined) {
    return generalRestriction
  }

  return restrictions.find((item) => {
    switch (restrictionKey.type) {
      case UserRestrictionType.DEPOSIT_CURRENCY:
      case UserRestrictionType.WITHDRAW_CURRENCY:
        return (
          item.type === restrictionKey.type &&
          (restrictionKey.currency
            ? item.currencies.includes(restrictionKey.currency)
            : true)
        )
      case UserRestrictionType.CREATE_ORDER_CURRENCY_PAIR:
      case UserRestrictionType.REPLACE_ORDER_CURRENCY_PAIR:
      case UserRestrictionType.CANCEL_ORDER_CURRENCY_PAIR:
        return (
          item.type === restrictionKey.type &&
          item.currencyPairs.includes(restrictionKey.currencyPair)
        )
      default:
        return item.type === restrictionKey.type
    }
  })
}
