import axios from 'axios'

import { OrderBookParams, OrderBookResponse } from '../types'

export const getOrderBook = async (params: OrderBookParams) => {
  const { data } = await axios.get<OrderBookResponse>(
    '/api/crypto-exchange/orders/book/',
    {
      params,
    },
  )

  return data
}
