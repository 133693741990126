import { isDevelopmentEnv } from '../core-config'
import {
  CookieStorageKey,
  KEY_PREFIX,
  LocalStorageKey,
  ONE_YEAR_IN_SECONDS,
} from './constants'
import { CookieStorage, BaseStorage } from './utils'

export const rawCookieStorage = new CookieStorage<CookieStorageKey>({
  secure: !isDevelopmentEnv(),
  expires: ONE_YEAR_IN_SECONDS,
})

export const localStorage = new BaseStorage<LocalStorageKey>(
  window.localStorage,
  KEY_PREFIX,
)
