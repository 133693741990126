import { FC } from 'react'

import { Trade } from '../../../core-api'
import { DetailsSideContent } from '../../../core-ui'
import { useTradeDetails } from '../../hooks'
import { LinkedOrder } from './LinkedOrder'

interface TradeDetailsSideContentProps {
  trade: Trade
}

export const TradeDetailsSideContent: FC<TradeDetailsSideContentProps> = ({ trade }) => {
  const items = useTradeDetails({ trade })

  return (
    <>
      <DetailsSideContent items={items} />
      {trade.orderId && <LinkedOrder orderId={trade.orderId} />}
    </>
  )
}
