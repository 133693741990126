import { Box, Item, Tooltip, useTooltip } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { DEFAULT_CURRENCY, ExtendedPocket, PocketState } from '../../../core-api'
import { AssetAvatar, CurrencyName, useHumanizeCurrency } from '../../../core-shared'
import { Money } from '../../../core-ui'

type PocketsGroupItemProps = {
  pocket: ExtendedPocket
  onClick: VoidFunction
}

export const PocketsGroupItem: FC<PocketsGroupItemProps> = ({ pocket, onClick }) => {
  const tooltip = useTooltip()

  const { humanizeCurrency } = useHumanizeCurrency()

  const humanizedCurrency = humanizeCurrency(pocket.currency)

  const isInactive = pocket.state === PocketState.Inactive

  const item = (
    <Item use="button" onClick={onClick} disabled={isInactive}>
      <Item.Avatar>
        <AssetAvatar variant="brand" asset={pocket.currency} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{humanizedCurrency}</Item.Title>
        <Item.Description>
          <CurrencyName currency={pocket.currency} />
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <Money
            amount={pocket.balance}
            currency={pocket.currency}
            type="money-fractional"
          />
        </Item.Value>

        {!isInactive && pocket.currency !== DEFAULT_CURRENCY && (
          <Item.Value variant="secondary">
            <Money amount={pocket.amount} currency={DEFAULT_CURRENCY} />
          </Item.Value>
        )}

        {isInactive && (
          <Item.Value variant="secondary">
            <FormattedMessage
              id="portfolio.pockets.group.inactive.title"
              defaultMessage="Inactive"
            />
          </Item.Value>
        )}
      </Item.Side>
    </Item>
  )

  if (isInactive) {
    return (
      <>
        <Box {...tooltip.getAnchorProps()}>{item}</Box>

        <Tooltip {...tooltip.getTargetProps()} maxWidth={240}>
          <FormattedMessage
            id="portfolio.pockets.group.inactive.tooltip"
            defaultMessage="This account is inactive. You can activate it in Revolut app."
          />
        </Tooltip>
      </>
    )
  }

  return item
}
