import { autoUpdate, useFloating } from '@floating-ui/react'
import { Action, Avatar, Box, HStack, Portal, Spacer, Text, Tip } from '@revolut/ui-kit'
import { FC } from 'react'

import { Z_INDICES } from '../../../core-ui'
import { useSpotlightStyles } from '../../hooks'
import { IntlMessages, WelcomeJourneyTip } from '../../types'
import { getTipMargins } from '../../utils'
import { TipArrow } from './TipArrow'

interface StepProps extends WelcomeJourneyTip {
  currentStep: string
  onNextStepClick: VoidFunction
  isLastStep: boolean
  open: boolean
  messages: IntlMessages
  onClose: VoidFunction
}

export const Step: FC<StepProps> = ({
  open,
  target,
  description,
  icon,
  title,
  placement,
  currentStep,
  onNextStepClick,
  isLastStep,
  spotlightCustomStyles,
  messages,
  onClose,
}) => {
  const spotlightStyles = useSpotlightStyles({ target })

  const { refs, floatingStyles } = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
  })

  if (!open) return null

  return (
    <>
      <Box
        ref={refs.setReference}
        style={{ ...spotlightStyles, ...spotlightCustomStyles }}
      />

      <Portal>
        <Tip
          width={375}
          ref={refs.setFloating}
          style={{ ...floatingStyles, zIndex: Z_INDICES.TIP }}
          onClose={onClose}
          {...getTipMargins(placement)}
        >
          <TipArrow placement={placement} />
          <Tip.Avatar>
            <Avatar useIcon={icon} />
          </Tip.Avatar>
          <Tip.Title>{title}</Tip.Title>
          <Tip.Description>{description}</Tip.Description>

          <Tip.Actions css={{ gridColumn: '1/-1' }}>
            <HStack mt="s-8">
              <Text variant="h6" color="grey-tone-50">
                {currentStep}
              </Text>
              <Spacer />
              <Action onClick={onNextStepClick}>
                {isLastStep ? messages['actions.done'] : messages['actions.next']}
              </Action>
            </HStack>
          </Tip.Actions>
        </Tip>
      </Portal>
    </>
  )
}
