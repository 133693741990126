import { Box, Tooltip, useTooltip } from '@revolut/ui-kit'
import { FC, ReactNode } from 'react'
import { defineMessages } from 'react-intl'

import { UserRestrictionType, useUserRestrictionEnabled } from '../../../core-api'
import { UserRestrictionKey } from '../../../core-api'
import { FormattedEnumMessage } from '../../../core-intl'

type SupportedRestrictionType = Extract<
  UserRestrictionType,
  UserRestrictionType.DEPOSIT | UserRestrictionType.WITHDRAW
>

const SUPPORTED_RESTRICTION_TYPES: UserRestrictionType[] = [
  UserRestrictionType.DEPOSIT,
  UserRestrictionType.WITHDRAW,
]

const isSupportedRestrictionType = (
  type: UserRestrictionType,
): type is SupportedRestrictionType => SUPPORTED_RESTRICTION_TYPES.includes(type)

interface UserRestrictionTooltipProps {
  restrictionKey: UserRestrictionKey
  children: ReactNode
}

const userRestrictionMessages = defineMessages<SupportedRestrictionType>({
  [UserRestrictionType.DEPOSIT]: {
    id: 'shared.user.restriction.maintenance.deposit',
    defaultMessage: 'Deposits are temporarily disabled due to maintenance',
  },
  [UserRestrictionType.WITHDRAW]: {
    id: 'shared.user.restriction.maintenance.withdraw',
    defaultMessage: 'Withdrawals are temporarily disabled due to maintenance',
  },
})

export const UserRestrictionTooltip: FC<UserRestrictionTooltipProps> = ({
  restrictionKey,
  children,
}) => {
  const { restriction } = useUserRestrictionEnabled(restrictionKey)

  const tooltip = useTooltip()

  if (restriction === undefined || !isSupportedRestrictionType(restriction.type)) {
    return <>{children}</>
  }

  return (
    <>
      <Box {...tooltip.getAnchorProps()}>{children}</Box>

      <Tooltip {...tooltip.getTargetProps({ placement: 'bottom-start' })}>
        <FormattedEnumMessage id={restriction.type} messages={userRestrictionMessages} />
      </Tooltip>
    </>
  )
}
