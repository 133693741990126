import { Table, TableColumn } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

import {
  Order,
  OrderSide,
  OrderState,
  OrderType,
  useCurrencyPairRequired,
} from '../../../core-api'
import {
  CurrencyPairLink,
  CurrencyPairMoney,
  TableFilterDateRange,
} from '../../../core-shared'
import { DateTimeTableCell, Money } from '../../../core-ui'
import { OrderSideTableCell } from '../OrderSideTableCell'
import { OrderStatusTableCell } from '../OrderStatusTableCell'
import { OrderTypeMessage } from '../OrderTypeMessage'
import { TableFilterCurrencyPair } from '../TableFilterCurrencyPair'
import { TableFilterOrderSide } from '../TableFilterOrderSide'
import { TableFilterOrderState } from '../TableFilterOrderState'
import { TableFilterOrderType } from '../TableFilterOrderType'

export const COLUMNS: TableColumn<Order>[] = [
  {
    Header: <FormattedMessage id="activity.table.pair.caption" defaultMessage="Pair" />,
    accessor: 'currencyPair',
    Cell: ({ value }) => (
      <Table.Cell>
        <CurrencyPairLink currencyPair={value} variant="small" size={24} />
      </Table.Cell>
    ),
    Filter: ({ column }) => <TableFilterCurrencyPair column={column} />,
  },
  {
    Header: (
      <FormattedMessage
        id="activity.table.timePlaced.caption"
        defaultMessage="Time placed"
      />
    ),
    accessor: 'createdDate',
    Cell: ({ value }) => <DateTimeTableCell date={new Date(value)} />,
    Filter: ({ column }) => <TableFilterDateRange column={column} />,
  },
  {
    Header: (
      <FormattedMessage
        id="activity.table.direction.caption"
        defaultMessage="Direction"
      />
    ),
    accessor: 'side',
    Cell: ({ value }) => <OrderSideTableCell side={value} />,
    Filter: ({ column }) => <TableFilterOrderSide column={column} />,
  },
  {
    id: 'type',
    Header: <FormattedMessage id="activity.table.type.caption" defaultMessage="Type" />,
    accessor: 'orderType',
    Cell: ({ value }) => (
      <Table.Cell>
        <OrderTypeMessage type={value} />
      </Table.Cell>
    ),
    Filter: ({ column }) => <TableFilterOrderType column={column} />,
  },
  {
    Header: (
      <FormattedMessage id="activity.table.status.caption" defaultMessage="Status" />
    ),
    accessor: 'state',
    Cell: ({ value }) => <OrderStatusTableCell status={value} />,
    Filter: ({ column }) => <TableFilterOrderState column={column} />,
  },
  {
    Header: (
      <FormattedMessage id="shared.order.quantity.label" defaultMessage="Quantity" />
    ),
    accessor: 'qty',
    align: 'end',
    Cell: ({ value, row }) => (
      <Table.Cell>
        <CurrencyPairMoney
          variant="base"
          currencyPair={row.original.currencyPair}
          amount={value}
        />
      </Table.Cell>
    ),
  },
  {
    Header: <FormattedMessage id="activity.table.price.caption" defaultMessage="Price" />,
    accessor: 'price',
    align: 'end',
    Cell: ({ value, row }) => {
      if (
        row.original.orderType === OrderType.Market &&
        row.original.state === OrderState.Rejected
      ) {
        return (
          <Table.Cell>
            <Table.EmptyCell />
          </Table.Cell>
        )
      }

      return (
        <Table.Cell>
          <CurrencyPairMoney
            kind="price"
            variant="quote"
            currencyPair={row.original.currencyPair}
            amount={
              row.original.orderType === OrderType.Market ? row.original.avgPrice : value
            }
          />
        </Table.Cell>
      )
    },
  },
  {
    Header: <FormattedMessage id="shared.order.value.label" defaultMessage="Value" />,
    id: 'total',
    accessor: ({ qty, price, side, avgPrice }) => {
      const currentPrice = avgPrice ?? price
      const total = qty * currentPrice

      return side === OrderSide.Buy && total !== 0 ? -total : total
    },
    align: 'end',
    Cell: ({ value, row }) => {
      const pair = useCurrencyPairRequired(row.original.currencyPair)

      if (
        row.original.orderType === OrderType.Market &&
        row.original.state === OrderState.Rejected
      ) {
        return (
          <Table.Cell>
            <Table.EmptyCell />
          </Table.Cell>
        )
      }

      return (
        <Table.Cell>
          <Money kind="total" currency={pair.quoteCurrency} amount={value} />
        </Table.Cell>
      )
    },
  },
]
