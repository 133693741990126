import { ExtraErrorData } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Dedupe } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { matchPath } from 'react-router'

import { ConfigKey, SentryTag, getConfigValue } from './packlets/core-config'
import { getDeviceId, ROUTE_PATHS } from './packlets/core-utils'

const SENTRY_ROUTES = Object.values(ROUTE_PATHS)
  // Put more specific paths first
  .sort((a, b) => b.localeCompare(a))
  .map((path) => ({ path }))

const SentryConfig = {
  dsn: getConfigValue(ConfigKey.SentryDsn),
  release: process.env.REACT_APP_SENTRY_RELEASE_TAG,
  environment: window.location.origin.endsWith('.com') ? 'production' : 'development',
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // React Intl
    /\[@formatjs\/intl Error MISSING_TRANSLATION\] Missing message: "[^"]+" for locale "[^"]+", using default message \([^)]+\) as fallback/,
    // https://stackoverflow.com/a/50387233
    // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
    'ResizeObserver loop limit exceeded',
    // Same as above but mostly for Firefox and some other browsers.
    // https://www.w3.org/TR/resize-observer/#deliver-resize-error
    'ResizeObserver loop completed with undelivered notifications.',
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // https://bugs.webkit.org/show_bug.cgi?id=246010
    /.*@webkit-masked-url.*/,
  ],
  tracesSampleRate: 1,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        window.history,
        SENTRY_ROUTES,
        matchPath,
      ),
      _metricOptions: {
        _reportAllChanges: true,
      },
    }),
    new Dedupe(),
    new ExtraErrorData({ depth: 6 }),
  ],
}

const UNKNOWN_DEVICE_ID = 'unknown'

export function setupSentry() {
  Sentry.init(SentryConfig)

  Sentry.setTags({
    [SentryTag.Source]: 'Exchange',
    [SentryTag.DeviceId]: getDeviceId() ?? UNKNOWN_DEVICE_ID,
  })

  return Sentry
}
