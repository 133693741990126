import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { TokenInfo, refreshToken } from '../../api'
import { QueryKey } from '../../constants'

export const useRefreshToken = (
  options: UseMutationOptions<TokenInfo, AxiosError, void> = {},
) => {
  const queryClient = useQueryClient()

  return useMutation(refreshToken, {
    ...options,
    onSuccess: (tokenInfo, ...args) => {
      queryClient.setQueryData([QueryKey.AuthTokenInfo], tokenInfo)

      options.onSuccess?.(tokenInfo, ...args)
    },
  })
}
