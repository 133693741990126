import { Color, Table, TableFilters } from '@revolut/ui-kit'
import { FC, useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Order, useInfiniteOrdersHistory } from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { mapTableLoadingState } from '../../../core-utils'
import { getOrdersFilterParams } from '../../utils'
import { COLUMNS } from './OrdersHistoryTable.columns'

type OrdersHistoryTableProps = {
  selectedRowId: string | null
  onSelectRow: (id: string) => void
  initialFilters?: TableFilters<Order>
  stickyHeaderTop?: number | string
}

export const OrdersHistoryTable: FC<OrdersHistoryTableProps> = ({
  selectedRowId,
  onSelectRow,
  initialFilters = [],
  stickyHeaderTop = 0,
}) => {
  const [filters, setFilters] = useState<TableFilters<Order>>(initialFilters)

  const filterParams = useMemo(() => getOrdersFilterParams(filters), [filters])

  const { data, status, fetchNextPage, hasNextPage, refetch } =
    useInfiniteOrdersHistory(filterParams)

  const rows = useMemo(() => (data?.pages ?? []).flatMap((page) => page.items), [data])

  const handleRowClick = (item: Order) => {
    onSelectRow(item.orderId)
  }

  const getRowState = useCallback(
    ({ value }) => ({
      bg: value.orderId === selectedRowId ? Color.BLUE_5 : undefined,
    }),
    [selectedRowId],
  )

  if (status === 'error') {
    return <ErrorStatus onRetry={refetch} />
  }

  return (
    <Table
      rowHeight="small"
      stickyHeaderTop={stickyHeaderTop}
      columns={COLUMNS}
      data={rows}
      loadingState={mapTableLoadingState(status, rows)}
      getRowState={getRowState}
      onRowClick={handleRowClick}
      onRequestMore={fetchNextPage}
      onFilter={setFilters}
      hasMore={hasNextPage}
      autoResetFilters={false}
      manualFilters
      disableSortBy
      disableResizing
      initialState={{ filters }}
      labelEmptyState={
        <FormattedMessage
          id="activity.ordersHistory.table.empty.title"
          defaultMessage="No order history"
        />
      }
      labelNoResults={
        <FormattedMessage
          id="activity.ordersHistory.table.noResults.title"
          defaultMessage="No order history found"
        />
      }
    />
  )
}
