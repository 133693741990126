import * as Sentry from '@sentry/react'

import { Observable, timer, Subscription } from 'rxjs'
import { share, map, filter, retry, catchError } from 'rxjs/operators'
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'

import {
  OrderBookDataWS,
  OrderBookMessage,
  SubscriptionArgs,
  WebSocketAction,
  WebSocketChannel,
  WebSocketMessage,
} from '../types'

const DEFAULT_ORDER_BOOK_DEPTH = '20'
const DEFAULT_REFRESH_MS = '500'
const BASE_RECONNECT_INTERVAL_MS = 1000
const MAX_RECONNECT_INTERVAL_MS = 10000
const RECONNECT_ATTEMPTS = 10

class WebSocketService {
  private socket$: WebSocketSubject<WebSocketMessage>

  private channelSubscriptionMap: Map<string, Observable<OrderBookDataWS>> = new Map()

  private url: string = '/api/crypto-exchange/market-data/ws'

  constructor() {
    this.socket$ = webSocket<WebSocketMessage>(this.url)
  }

  public getSocket$(): WebSocketSubject<WebSocketMessage> {
    return this.socket$
  }

  public getChannelSubscriptionMap() {
    return this.channelSubscriptionMap
  }

  private createSubscription(args: SubscriptionArgs): Observable<OrderBookDataWS> {
    const channelKey = this.getChannelKey(args)

    if (this.channelSubscriptionMap.has(channelKey)) {
      return this.channelSubscriptionMap.get(channelKey)!
    }

    const observable = this.socket$
      .multiplex(
        () => ({ action: WebSocketAction.Subscribe, args }),
        () => ({ action: WebSocketAction.Unsubscribe, args }),
        (message) => this.isMessageRelevant(message, args),
      )
      .pipe(
        filter((message): message is OrderBookMessage => 'data' in message),
        map((message) => message.data),
        share(),
        retry({
          count: RECONNECT_ATTEMPTS,
          delay: (_, attemptNumber) => {
            const exponentialReconnectInterval =
              Math.pow(2, attemptNumber) * BASE_RECONNECT_INTERVAL_MS
            const delayDuration = Math.min(
              exponentialReconnectInterval,
              MAX_RECONNECT_INTERVAL_MS,
            )
            return timer(delayDuration)
          },
          resetOnSuccess: true,
        }),
        catchError((error) => {
          Sentry.captureException(error)
          throw error
        }),
      )

    this.channelSubscriptionMap.set(channelKey, observable)

    return observable
  }

  private isMessageRelevant(message: WebSocketMessage, args: SubscriptionArgs): boolean {
    if ('channel' in message && 'currencyPair' in message) {
      return (
        message.channel === args.channel && message.currencyPair === args.currencyPair
      )
    }
    return false
  }

  public subscribeOrderBook(
    {
      currencyPair,
      depth = DEFAULT_ORDER_BOOK_DEPTH,
      refreshMs = DEFAULT_REFRESH_MS,
    }: {
      currencyPair: string
      depth?: string
      refreshMs?: string
    },
    onData: (data: OrderBookDataWS) => void,
  ): Subscription {
    const observable = this.createSubscription({
      channel: WebSocketChannel.OrderBook,
      currencyPair,
      depth,
      refreshMs,
    })

    const subscription = observable.subscribe({
      next: onData,
      error: (error) => {
        Sentry.captureException(error)
      },
    })

    return subscription
  }

  private getChannelKey(args: SubscriptionArgs): string {
    return `${args.channel}-${args.currencyPair}`
  }
}

export const webSocketService = new WebSocketService()
