import axios from 'axios'

import { DocumentLinksReponse } from '../types'

export const getDocumentLinks = async () => {
  const { data } = await axios.get<DocumentLinksReponse>(
    '/api/crypto-exchange/document/links',
  )

  return data
}
