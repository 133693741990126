import { Link, Text, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

export const OrderFormDisclaimer: FC = () => {
  const tooltip = useTooltip()

  return (
    <>
      <Text variant="small" textAlign="center" color={Token.color.greyTone50}>
        <FormattedMessage
          id="trading.orderForm.disclaimer.title"
          defaultMessage="Crypto markets are unique. <Link>Learn more</Link>"
          values={{
            Link: (text) => <Link {...tooltip.getAnchorProps()}>{text}</Link>,
          }}
        />
      </Text>

      <Tooltip {...tooltip.getTargetProps({ placement: 'top-end' })} maxWidth={240}>
        <FormattedMessage
          id="trading.orderForm.disclaimer.description"
          defaultMessage="Crypto is a new asset class and all crypto assets are different. Prices may change frequently. Each asset also has unique features that may affect its value and how you’re able to use it. Be sure to research each asset before trading."
        />
      </Tooltip>
    </>
  )
}
