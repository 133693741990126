import { keyBy, orderBy } from 'lodash'
import { useEffect, useState } from 'react'

export type SortingRule<T> = {
  id: keyof T
  desc?: boolean | undefined
}

const sorter = (value, isDesc = false) => {
  if (value === undefined || value === null) {
    return isDesc ? -Infinity : Infinity
  }
  return value
}

const sortDataBy = <T>(data: T[], sortBy: SortingRule<T>[]) =>
  orderBy(
    data,
    sortBy.map((rule) => (row: T) => sorter(row[rule.id], rule.desc)),
    sortBy.map((item) => (item.desc ? 'desc' : 'asc')),
  )

export const useStableSortedData = <T>(
  data: T[],
  sortBy: SortingRule<T>[],
  getRowId: (row: T) => string,
  dep?: any,
) => {
  const [sortedData, setSortedData] = useState(data)

  useEffect(() => {
    setSortedData((prevSortedData) => {
      if (prevSortedData.length === data.length) {
        const dataById = keyBy(data, getRowId)

        let hasRemovedRows = false

        const nextSortedData = prevSortedData.map((item) => {
          const nextRow = dataById[getRowId(item)]

          if (nextRow === undefined) {
            hasRemovedRows = true

            return item
          }

          return nextRow
        })

        if (!hasRemovedRows) {
          return nextSortedData
        }
      }

      return sortDataBy(data, sortBy)
    })

    // To have stable sort behaviour we need need to skip sortBy deps here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getRowId])

  useEffect(() => {
    setSortedData(sortDataBy(data, sortBy))

    // To have stable sort behaviour we need need to skip data deps here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, dep])

  // If data was not sorted by sortBy rules yet, return original data
  return data.length === sortedData.length ? sortedData : data
}
