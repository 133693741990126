import { Box, Subheader, VStack } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useOrder } from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { OrdersGroupItem } from '../OrdersGroup'

interface LinkedOrderProps {
  orderId: string
}

export const LinkedOrder: FC<LinkedOrderProps> = ({ orderId }) => {
  const { data: linkedOrder, status, refetch } = useOrder(orderId)

  if (status === 'error') {
    return (
      <>
        <Subheader>
          <Subheader.Title>
            <FormattedMessage
              id="activity.details.linkedOrder.title"
              defaultMessage="Linked order"
            />
          </Subheader.Title>
        </Subheader>

        <Box mx="auto">
          <ErrorStatus onRetry={refetch} />
        </Box>
      </>
    )
  }

  if (!linkedOrder) return null

  return (
    <VStack mt="s-16">
      <Subheader>
        <Subheader.Title>
          <FormattedMessage
            id="activity.details.linkedOrder.title"
            defaultMessage="Linked order"
          />
        </Subheader.Title>
      </Subheader>

      <OrdersGroupItem order={linkedOrder} />
    </VStack>
  )
}
