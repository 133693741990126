import { Header, Popup, PopupProps } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { TransferQuotesFeeBreakdown } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { FeeBreakdownDetails } from '../FeeBreakdownDetails'

type FeeBreakdownPopupProps = {
  breakdown: TransferQuotesFeeBreakdown[]
} & Pick<PopupProps, 'open' | 'onClose'>

export const FeeBreakdownPopup: FC<FeeBreakdownPopupProps> = ({ breakdown, ...rest }) => {
  const { formatMessage } = useIntl()

  return (
    <Popup {...rest} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.CloseButton
          aria-label={formatMessage({
            id: 'labels.close',
            defaultMessage: 'Close',
          })}
        />
        <Header.Title>
          <FormattedMessage
            id="portfolio.feeBreakdown.popup.title"
            defaultMessage="Fee breakdown"
          />
        </Header.Title>
      </Header>

      <FeeBreakdownDetails breakdown={breakdown} />
    </Popup>
  )
}
