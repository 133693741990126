import { Popup, PopupProps } from '@revolut/ui-kit'

import { WithdrawPopupContent } from './WithdrawPopupContent'

export type WithdrawPopupProps = Omit<PopupProps, 'variant'> & {
  currency?: string
  onClose: VoidFunction
}

export const WithdrawPopup = ({
  currency,
  open,
  onClose,
  ...props
}: WithdrawPopupProps) => {
  return (
    <Popup
      open={open}
      variant="modal-view"
      onClose={onClose}
      shouldKeepMaxHeight
      {...props}
    >
      <WithdrawPopupContent currency={currency} onClose={onClose} />
    </Popup>
  )
}
