import {
  HStack,
  IconButton,
  Text,
  TextSkeleton,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { OrderType } from '../../../core-api'
import { CondensedDetails } from '../../../core-ui'
import { useOrderEstimatedFee } from '../../hooks'

interface OrderEstimatedFeeProps {
  quoteCurrency: string
  type: OrderType
  amount: number | null
}

export const OrderEstimatedFee: FC<OrderEstimatedFeeProps> = ({
  quoteCurrency,
  type,
  amount,
}) => {
  const tooltip = useTooltip()

  const { feeMessage, feeTooltipMessage, status } = useOrderEstimatedFee({
    type,
    amount,
    quoteCurrency,
  })

  return (
    <>
      <CondensedDetails>
        <CondensedDetails.Title>
          <HStack space="s-8" align="center">
            <Text>
              <FormattedMessage
                id="trading.orderForm.estimatedFee.title"
                defaultMessage="Estimated fee"
              />
            </Text>

            {feeTooltipMessage && (
              <IconButton
                useIcon="16/InfoOutline"
                size={16}
                color="grey-tone-50"
                {...tooltip.getAnchorProps()}
              />
            )}
          </HStack>
        </CondensedDetails.Title>

        <CondensedDetails.Content>
          {status === 'success' ? feeMessage : <TextSkeleton width={32} />}
        </CondensedDetails.Content>
      </CondensedDetails>

      {feeTooltipMessage && (
        <Tooltip
          {...tooltip.getTargetProps({ placement: 'bottom-start' })}
          maxWidth={240}
        >
          {feeTooltipMessage}
        </Tooltip>
      )}
    </>
  )
}
