import { Color, Table, TableFilters } from '@revolut/ui-kit'
import { FC, useCallback, useMemo, useState } from 'react'

import {
  ExtendedCurrencyPair,
  SortingRule,
  useStableSortedCurrencyPairs,
} from '../../../core-api'
import { EmptyStatus, EmptyStatusType, ErrorStatus } from '../../../core-shared'
import { LocalStorageKey, useLocalStorage } from '../../../core-storage'
import { mapTableLoadingState, matchFilterValue } from '../../../core-utils'
import { ALL_PAIRS_FILTER_OPTION } from '../../constants'
import { COLUMNS } from './MarketsTable.columns'

const getRowId = (row: ExtendedCurrencyPair) => row.pair

const DEFAULT_FILTERS: TableFilters<ExtendedCurrencyPair> = []

type MarketsTableProps = {
  search?: string
  quoteCurrency?: string

  selectedCurrencyPair: string
  onSelectCurrencyPair: (currencyPair: string) => void
}

export const MarketsTable: FC<MarketsTableProps> = ({
  search,
  quoteCurrency,
  selectedCurrencyPair,
  onSelectCurrencyPair,
}) => {
  const [filters, setFilters] = useLocalStorage<TableFilters<ExtendedCurrencyPair>>(
    LocalStorageKey.TradeMarketsTableFilters,
    DEFAULT_FILTERS,
  )

  const [sortBy, setSortBy] = useState<SortingRule<ExtendedCurrencyPair>[]>([
    { id: 'marketCap', desc: true },
  ])

  const handleSortBy = useCallback((nextSortBy: SortingRule<any[0]>[]) => {
    setSortBy(nextSortBy)
  }, [])

  const currencyPairList = useStableSortedCurrencyPairs(sortBy)

  const filteredCurrencyPairList = useMemo(
    () =>
      currencyPairList.data?.filter((pair) => {
        let result = matchFilterValue(pair.pair, search)

        if (quoteCurrency !== ALL_PAIRS_FILTER_OPTION.value) {
          result = result && quoteCurrency === pair.quoteCurrency
        }
        return result
      }) ?? [],
    [currencyPairList.data, quoteCurrency, search],
  )

  const getRowState = useCallback(
    ({ value }) => ({
      bg: value === selectedCurrencyPair ? Color.BLUE_5 : undefined,
    }),
    [selectedCurrencyPair],
  )

  if (currencyPairList.status === 'error') {
    return <ErrorStatus onRetry={currencyPairList.refetch} />
  }

  if (
    currencyPairList.status === 'success' &&
    !filteredCurrencyPairList.length &&
    !filters.length
  ) {
    return <EmptyStatus type={EmptyStatusType.CurrencyPairs} />
  }

  return (
    <Table
      initialState={{ sortBy, filters }}
      rowHeight="small"
      stickyHeaderTop={0}
      columns={COLUMNS}
      data={filteredCurrencyPairList}
      loadingState={mapTableLoadingState(
        currencyPairList.status,
        filteredCurrencyPairList,
      )}
      getRowId={getRowId}
      getRowState={getRowState}
      onRowClick={(pair) => onSelectCurrencyPair(pair.pair)}
      onSortBy={handleSortBy}
      onFilter={setFilters}
      manualSortBy
      autoResetSortBy={false}
      autoResetFilters={false}
      disableMultiSort
      disableResizing
    />
  )
}
