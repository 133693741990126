import { FC, ReactNode } from 'react'

import { useCompatibilityCheck } from '../../hooks'
import { CompatibilityCheckerBrowserPlaceholder } from './CompatibilityCheckerBrowserPlaceholder'
import { CompatibilityCheckerScreenSizePlaceholder } from './CompatibilityCheckerScreenSizePlaceholder'

interface CompatibilityCheckerProps {
  children: ReactNode
}

export const CompatibilityChecker: FC<CompatibilityCheckerProps> = ({ children }) => {
  const { isBrowserCompatible, isScreenSizeCompatible } = useCompatibilityCheck()

  if (!isBrowserCompatible) {
    return <CompatibilityCheckerBrowserPlaceholder />
  }

  if (!isScreenSizeCompatible) {
    return <CompatibilityCheckerScreenSizePlaceholder />
  }

  return <>{children}</>
}
