import { IHelpCentreApi, Scope } from '@revolut-internal/chat-widget'

import { ConfigKey, getConfigValue } from '../../core-config'
import { defineChatApiPath } from './utils'

const HELP_CENTRE_URL = getConfigValue(ConfigKey.HelpCentreUrl)
const CHAT_APP_VERSION = getConfigValue(ConfigKey.SupportChatVersion)
const CHAT_QUEUE_TAG = getConfigValue(ConfigKey.SupportChatQueueTag)
const CHAT_HOSTNAME = getConfigValue(ConfigKey.SupportChatHostname)

export const CHAT_CONFIG = {
  chatApiVersion: CHAT_APP_VERSION,
  chatApiPath: defineChatApiPath(),
  hostname: CHAT_HOSTNAME,
  scope: Scope.Retail,
  httpProtocol: 'https',
  wsProtocol: 'wss',
}

export const NEW_CHAT_PAYLOAD = {
  titleKey: null,
  specialisationQueue: CHAT_QUEUE_TAG,
  entrySuggestions: [],
  context: null,
  faqPath: [],
  messages: [],
}

export const HELP_CENTRE_API: IHelpCentreApi = {
  getFaqItemPath(item: string): string {
    return `${HELP_CENTRE_URL}${item}`
  },
}
