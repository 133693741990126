import { Token } from '@revolut/ui-kit'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

export const Link = styled(RouterLink)`
  color: ${Token.color.foreground};
  text-decoration: none;

  &:hover,
  &:hover span {
    color: ${Token.color.accent};
    text-decoration: underline;
  }
`
