import axios from 'axios'

export const TOKEN_INFO_URL = '/api/crypto-exchange/token/info'

export type TokenInfo = {
  user: {
    userId: string
    deviceId: string
  }
  token: {
    expireAt: number
    lifetime: number
    scopes: string[]
  }
}

export const getTokenInfo = async () => {
  const { data } = await axios.get<TokenInfo>(TOKEN_INFO_URL)

  return data
}
