import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { isNumber } from 'lodash'

import { getTrades } from '../../api'
import { QueryKey } from '../../constants'
import { TradesParams, TradesReponse } from '../../types'

export const useTrades = <T extends unknown = TradesReponse>(
  params: TradesParams = {},
  options: UseQueryOptions<TradesReponse, AxiosError, T> = {},
) =>
  useQuery<TradesReponse, AxiosError, T>(
    [QueryKey.Trades, params],
    () =>
      getTrades(params).then((data) => {
        // TODO: PWCE-1921 Remove once API will accept limit param
        if (isNumber(params.limit)) {
          return {
            ...data,
            items: data.items.slice(0, params.limit),
          }
        }

        return data
      }),
    options,
  )
