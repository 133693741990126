import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getTrades } from '../../api'
import { DEFAULT_PAGE_SIZE, INFINITE_QUERY_KEY, QueryKey } from '../../constants'
import { TradesParams, TradesReponse } from '../../types'

export const useInfiniteTrades = <T extends unknown = TradesReponse>(
  params: Omit<TradesParams, 'cursor'>,
  options: UseInfiniteQueryOptions<TradesReponse, AxiosError, T> = {},
) =>
  useInfiniteQuery<TradesReponse, AxiosError, T>(
    [QueryKey.Trades, INFINITE_QUERY_KEY, params],
    ({ pageParam }) =>
      getTrades({
        ...params,
        cursor: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.items.length < DEFAULT_PAGE_SIZE) {
          return undefined
        }
        return lastPage.cursor !== '' ? lastPage.cursor : undefined
      },
      ...options,
    },
  )
