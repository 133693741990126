import { Table, TableColumn } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

import { Transfer, TransferType } from '../../../core-api'
import { CurrencyLink, TableFilterDateRange, NoFeesLabel } from '../../../core-shared'
import { DateTimeTableCell, Money } from '../../../core-ui'
import { TableFilterCurrency } from '../TableFilterCurrency'
import { TableFilterTransferType } from '../TableFilterTransferType'
import { TransferStatusTableCell } from '../TransferStatusTableCell'
import { TransferTypeMessage } from '../TransferTypeMessage'

export const COLUMNS_ADVANCED: TableColumn<Transfer>[] = [
  {
    Header: <FormattedMessage id="activity.table.asset.caption" defaultMessage="Asset" />,
    id: 'currency',
    accessor: 'assetId',
    align: 'start',
    Cell: ({ value }) => (
      <Table.Cell>
        <CurrencyLink currency={value} variant="small" size={24} />
      </Table.Cell>
    ),
    Filter: ({ column }) => <TableFilterCurrency column={column} />,
  },
  {
    Header: <FormattedMessage id="activity.table.time.caption" defaultMessage="Time" />,
    accessor: 'createdDate',
    Cell: ({ value }) => <DateTimeTableCell date={new Date(value)} />,
    Filter: ({ column }) => <TableFilterDateRange column={column} />,
  },

  {
    Header: (
      <FormattedMessage
        id="activity.table.direction.caption"
        defaultMessage="Direction"
      />
    ),
    accessor: 'type',
    Cell: ({ value }) => (
      <Table.Cell>
        <TransferTypeMessage type={value} />
      </Table.Cell>
    ),
    Filter: ({ column }) => <TableFilterTransferType column={column} />,
  },
  {
    Header: (
      <FormattedMessage id="activity.table.status.caption" defaultMessage="Status" />
    ),
    accessor: 'state',
    Cell: ({ value }) => <TransferStatusTableCell status={value} />,
  },
  {
    Header: (
      <FormattedMessage id="activity.table.amount.caption" defaultMessage="Amount" />
    ),
    accessor: 'amount',
    align: 'end',
    Cell: ({ value, row }) => {
      const transferSign = row.original.type === TransferType.WITHDRAWAL ? -1 : 1

      return (
        <Table.Cell>
          <Money
            amount={value * transferSign}
            currency={row.original.assetId}
            showSign
            type="money-fractional"
          />
        </Table.Cell>
      )
    },
  },
  {
    Header: <FormattedMessage id="activity.table.fee.caption" defaultMessage="Fee" />,
    accessor: 'fee',
    align: 'end',
    Cell: () => (
      <Table.Cell>
        <NoFeesLabel />
      </Table.Cell>
    ),
  },
]
