import { Box, Flex, StatusWidget } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Asset3dImageName, getImageAssetSrcProps } from '../../../core-utils'

export const CompatibilityCheckerBrowserPlaceholder: FC = () => (
  <Flex height="100vh">
    <Box m="auto" px="s-16" maxWidth={350}>
      <StatusWidget>
        <StatusWidget.Image {...getImageAssetSrcProps(Asset3dImageName.Trading)} />
        <StatusWidget.Title>
          <FormattedMessage
            id="shared.compatibility.browser.placeholder.title"
            defaultMessage="Browser not supported"
          />
        </StatusWidget.Title>
        <StatusWidget.Description>
          <FormattedMessage
            id="shared.compatibility.browser.placeholder.subtitle"
            defaultMessage="For optimal performance and security please update your browser to the latest version"
          />
        </StatusWidget.Description>
      </StatusWidget>
    </Box>
  </Flex>
)
