import 'normalize.css'

import { createRoot } from 'react-dom/client'

import { App } from './bootstrap'
import './index.css'
import { setupAxios } from './packlets/core-api'
import { checkRequired, getRouteUrl, RoutePath } from './packlets/core-utils'
import { setupSentry } from './setupSentry'

setupSentry()
setupAxios({
  signInUrl: getRouteUrl({ path: RoutePath.AuthSignIn }),
  signedOutUrl: getRouteUrl({ path: RoutePath.AuthSignedOut }),
})

const container = document.getElementById('mount')

const root = createRoot(checkRequired(container))

root.render(<App />)
