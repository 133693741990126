import { useIntl } from 'react-intl'

import { Order, OrderType, useCurrencyPairRequired } from '../../core-api'
import { Feature, useFeatures } from '../../core-features'
import { CurrencyPairMoney } from '../../core-shared'
import { DetailsCellGroupItems, Money } from '../../core-ui'
import { formatPercents } from '../../core-utils'
import { OrderExecutionMessage, OrderSideMessage, OrderTypeMessage } from '../components'

export const useOrderDetails = ({ order }: { order: Order }) => {
  const { formatMessage } = useIntl()

  const features = useFeatures()

  const isPostOnlyEnabled = features.isFeatureEnabled(Feature.LimitOrderPostOnly)

  const { price, qty, orderId, side, currencyPair, orderType, leavesQty, postOnly } =
    order

  const pair = useCurrencyPairRequired(currencyPair)

  const orderDetails: DetailsCellGroupItems = [
    {
      title: formatMessage({
        id: 'activity.details.filled.label',
        defaultMessage: 'Filled',
      }),
      content: formatPercents(qty === 0 ? 0 : (qty - leavesQty) / qty, {
        format: 'rounded',
      }),
    },
    [
      {
        title: formatMessage({
          id: 'activity.details.direction.label',
          defaultMessage: 'Direction',
        }),
        content: <OrderSideMessage side={side} />,
      },
      {
        title: formatMessage({
          id: 'activity.details.type.label',
          defaultMessage: 'Type',
        }),
        content: <OrderTypeMessage type={orderType} />,
      },
      {
        title: formatMessage({
          id: 'activity.details.execution.label',
          defaultMessage: 'Execution',
        }),
        content: <OrderExecutionMessage postOnly={Boolean(postOnly)} />,
        visible: isPostOnlyEnabled && orderType === OrderType.Limit,
      },
    ],
    [
      {
        title: formatMessage({
          id: 'shared.order.quantity.label',
          defaultMessage: 'Quantity',
        }),
        content: (
          <CurrencyPairMoney variant="base" currencyPair={currencyPair} amount={qty} />
        ),
      },
      {
        title: formatMessage({
          id: 'activity.details.price.label',
          defaultMessage: 'Price',
        }),
        content: (
          <CurrencyPairMoney
            kind="price"
            variant="quote"
            currencyPair={currencyPair}
            amount={price}
          />
        ),
      },
      {
        title: formatMessage({
          id: 'shared.order.value.label',
          defaultMessage: 'Value',
        }),
        content: (
          <Money kind="total" currency={pair.quoteCurrency} amount={qty * price} />
        ),
      },
    ],
    {
      title: formatMessage({
        id: 'activity.details.orderId.label',
        defaultMessage: 'Order ID',
      }),
      content: orderId,
    },
  ]

  return orderDetails
}
