import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getDocumentLinks } from '../../api'
import { QueryKey } from '../../constants'
import { DocumentLinksReponse } from '../../types'

export const useDocumentLinks = <T extends unknown = DocumentLinksReponse>(
  options: UseQueryOptions<DocumentLinksReponse, AxiosError, T> = {},
) =>
  useQuery<DocumentLinksReponse, AxiosError, T>(
    [QueryKey.DocumentLinks],
    () => getDocumentLinks(),
    { ...options, staleTime: Infinity },
  )
