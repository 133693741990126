import { CurrencyPairsResponse } from '../../types'
import { useCurrencyPairs } from '../useCurrencyPairs'

const selectCurrencyPair = (currencyPair: string) => (data: CurrencyPairsResponse) =>
  data[currencyPair]

export const useCurrencyPair = (currencyPair: string) => {
  const { data } = useCurrencyPairs({ select: selectCurrencyPair(currencyPair) })

  return data
}
