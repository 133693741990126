import { Flex, Group, Header, Image, Item, Link, Token, VStack } from '@revolut/ui-kit'
import { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import { useIntl } from '../../../core-intl'
import { Asset3dImageName, getImageAssetSrcProps } from '../../../core-utils'

interface VerificationPopupContentProps {
  variant?: 'legacy' | 'next'
  title: ReactNode
  subtitle: ReactNode
  verificationLink?: string | null
  onClose: VoidFunction
}

export const VerificationPopupContent: FC<VerificationPopupContentProps> = ({
  variant = 'next',
  title,
  subtitle,
  verificationLink,
  onClose,
}) => {
  const { formatMessage } = useIntl()

  return (
    <VStack height="100%">
      <Header variant="item">
        <Header.CloseButton
          aria-label={formatMessage({
            id: 'labels.close',
            defaultMessage: 'Close',
          })}
          onClick={onClose}
        />
        <Header.Title>{title}</Header.Title>
        <Header.Subtitle>{subtitle}</Header.Subtitle>
      </Header>

      {variant === 'next' && verificationLink && (
        <Link variant="caption" href={verificationLink} target="_blank">
          {title}
        </Link>
      )}

      <Flex flex={1} justifyContent="center" alignItems="center">
        <Image size={250} {...getImageAssetSrcProps(Asset3dImageName.Verification)} />
      </Flex>

      {variant === 'legacy' && (
        <Group>
          <Item useIcon="ScanQr" iconColor={Token.color.foreground}>
            <Item.Content>
              <Item.Title>
                <FormattedMessage
                  id="portfolio.verification.scanQR.label"
                  defaultMessage="Scan QR code with your mobile device"
                />
              </Item.Title>
            </Item.Content>
          </Item>
          <Item useIcon="LogoRevolut" iconColor={Token.color.foreground}>
            <Item.Content>
              <Item.Title>
                <FormattedMessage
                  id="portfolio.verification.completeOnboardingInApp.label"
                  defaultMessage="Tap ‘Authorise’ on the prompt on your Revolut app"
                />
              </Item.Title>
            </Item.Content>
          </Item>
        </Group>
      )}
    </VStack>
  )
}
