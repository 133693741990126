import { Color } from '@revolut/ui-kit'
import { FC, useMemo } from 'react'
import styled from 'styled-components'

import { OrderBookProgressSide } from '../../constants'

const OrderBookProgressTag = styled.div<{
  side: OrderBookProgressSide
  color: Color
  progress: number
}>`
  position: absolute;
  top: 0;
  ${({ side }) => (side === OrderBookProgressSide.Left ? { left: 0 } : { right: 0 })};
  height: 100%;
  width: 100%;
  background-color: ${({ color }) => color};

  transform: translateX(
    ${({ progress, side }) =>
      (side === OrderBookProgressSide.Left ? -1 : 1) * (100 - progress)}%
  );
`

export interface OrderBookProgressProps {
  amount: number
  maxAmount: number
  progressRange: number
  color: string
  side: OrderBookProgressSide
}

export const getProgress = ({
  amount,
  maxAmount,
  range,
}: {
  amount: number
  maxAmount: number
  range: number
}): number => {
  return (amount / maxAmount) * range
}

export const OrderBookProgress: FC<OrderBookProgressProps> = ({
  amount,
  maxAmount,
  progressRange,
  color,
  side,
}) => {
  const progress = useMemo(
    () => getProgress({ amount, maxAmount, range: progressRange }),
    [amount, maxAmount, progressRange],
  )

  return <OrderBookProgressTag progress={progress} side={side} color={color} />
}
