import { GridLayoutTemplateArea } from '../types'
import { isGridLayoutResizerArea } from './isGridLayoutResizerArea'

export const getGridLayoutAreaName = <T extends string>(
  area: GridLayoutTemplateArea<T>,
): string => {
  if (isGridLayoutResizerArea(area)) {
    return `${area.name}_${area.targetIndex}`
  }

  return area.name
}
