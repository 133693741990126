import { Experiment } from '../core-api'

export enum Feature {
  Exchange,
  MobileDevices,
  NextPortfolio,
  AmountHero,
  WebSockets,
  HideRealisedPnL,
  LimitOrderPostOnly,
}

export type FeatureParams = {
  experiments: Experiment[]
}

export type Features = {
  [K in Feature]: (args: FeatureParams) => boolean
}
