export enum EmptyStatusType {
  Orders = 'ORDERS',
  OrderHistory = 'ORDER_HISTORY',
  Trades = 'TRADES',
  Transfers = 'TRANSFERS',
  Balances = 'BALANCES',
  CurrencyPairs = 'CURRENCY_PAIRS',
}

export const BROWSER_CHECK_TREE: Bowser.Parser.checkTree = {
  chrome: '>=90',
  chromium: '>=90',
  safari: '>=15.4',
  firefox: '>=88',
  edge: '>=90',
  opera: '>=76',
}

export enum WelcomeJourneyTarget {
  Home = 'welcome-journey-home',
  Trade = 'welcome-journey-trade',
  SelectCurrencyPair = 'welcome-journey-select-currency-pair',
  BuyAndSellCrypto = 'welcome-journey-buy-and-sell-crypto',
  Portfolio = 'welcome-journey-portfolio',
  DepositAndWithdraw = 'welcome-journey-deposit-and-withdraw',
  Activity = 'welcome-journey-activity',
}
