import { keyBy } from 'lodash'
import { useCallback } from 'react'

import { useCurrenciesDetailsRequired } from '../../../core-api'

export const useHumanizeCurrency = () => {
  const currenciesDetails = useCurrenciesDetailsRequired()

  const unhumanizeCurrency = useCallback(
    (ticker: string) => {
      const currenciesDetailsByTicker = keyBy(currenciesDetails, 'ticker')

      const currencyDetails =
        currenciesDetailsByTicker[ticker] ?? currenciesDetails[ticker]

      return currencyDetails?.code
    },
    [currenciesDetails],
  )

  const humanizeCurrency = useCallback(
    (code: string) => currenciesDetails[code]?.ticker ?? code,
    [currenciesDetails],
  )

  return { humanizeCurrency, unhumanizeCurrency }
}
