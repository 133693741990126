import { FC, Suspense, lazy, useEffect } from 'react'

import { useCurrentUser } from '../../../core-api'
import { useAuthContext } from '../../../core-auth'
import { ConfigKey, getConfigValue } from '../../../core-config'
import { useIntlContext } from '../../../core-intl'
import { LocalStorageKey, useLocalStorage } from '../../../core-storage'
import { eventTracker } from '../../utils'

const EVENT_TRACKER_DEBUGGER_ENABLED = getConfigValue(
  ConfigKey.EventTrackerDebuggerEnabled,
)

const EventTrackerDebugger = lazy(() =>
  import(
    /* webpackChunkName: 'event-debugger' */ '@revolut-internal/event-tracker/debugger'
  ).then(({ EventDebuggerDev }) => ({
    default: EventDebuggerDev,
  })),
)

interface EventTrackerProps {}

export const EventTracker: FC<EventTrackerProps> = () => {
  const { locale } = useIntlContext()

  useEffect(() => {
    eventTracker.setLocale(locale)
  }, [locale])

  const { status } = useAuthContext()

  // We use local storage to store the Registered Identity Id to make it available for the event tracking immediately after page reload
  const [registeredIdentityId, setRegisteredIdentityId] = useLocalStorage<string | null>(
    LocalStorageKey.RegisteredIdentityId,
    null,
  )

  // We should user Registered Identity Id as user Id for the event tracking
  const { data: userId = registeredIdentityId } = useCurrentUser({
    select: (user) => user.id,
    enabled: status === 'authorized',
  })

  useEffect(() => {
    if (userId !== null) {
      eventTracker.setUserInfo({ identityId: userId })

      setRegisteredIdentityId(userId)
    }
  }, [userId, setRegisteredIdentityId])

  useEffect(() => {
    eventTracker.start()

    return () => {
      eventTracker.stop()
    }
  }, [])

  if (!EVENT_TRACKER_DEBUGGER_ENABLED) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <EventTrackerDebugger tracker={eventTracker} />
    </Suspense>
  )
}
