import { Fixed, TransitionSlide, useOnClickAway } from '@revolut/ui-kit'
import { useRef } from 'react'

import { FeedbackPromptTile, FeedbackPromptTileProps } from './FeedbackPromptTile'

export type FeedbackPromptProps = {
  isOpen: boolean
  onClickAway: () => void
} & FeedbackPromptTileProps

export const FeedbackPrompt = ({ isOpen, onClickAway, ...rest }: FeedbackPromptProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useOnClickAway({ callback: onClickAway, targets: [ref] })

  return (
    <Fixed right="s-32" bottom="s-32">
      <TransitionSlide in={isOpen} offsetX={-24}>
        <FeedbackPromptTile ref={ref} {...rest} />
      </TransitionSlide>
    </Fixed>
  )
}
