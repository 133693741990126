import { HStack, useTooltip, Icon, Tooltip, Spacer, VStack } from '@revolut/ui-kit'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  Order,
  OrderSide,
  OrderState,
  OrderType,
  useCurrencyPairRequired,
} from '../../core-api'
import { CurrencyPairMoney } from '../../core-shared'
import { DetailsCellGroupItems, EmptyValue, Money } from '../../core-ui'
import { OrderSideMessage, OrderStatusMessage, OrderTypeMessage } from '../components'

export const useOrderHistoryDetails = ({ order }: { order: Order }) => {
  const { formatMessage } = useIntl()

  const averageFilledPriceTooltip = useTooltip()
  const filledValueTooltip = useTooltip()

  const {
    state,
    price,
    qty,
    orderId,
    side,
    currencyPair,
    orderType,
    declineReason,
    avgPrice,
    leavesQty,
    totalFee,
    feeCurrency,
  } = order

  const pair = useCurrencyPairRequired(currencyPair)

  const isOrderRejected = state === OrderState.Rejected
  const isOrderCancelled = state === OrderState.Canceled

  const currentPrice = avgPrice ?? price

  const orderDetails: DetailsCellGroupItems = [
    {
      title: formatMessage({
        id: 'activity.details.status.label',
        defaultMessage: 'Status',
      }),
      content: <OrderStatusMessage state={state} declineReason={declineReason} />,
    },
    [
      {
        title: formatMessage({
          id: 'activity.details.direction.label',
          defaultMessage: 'Direction',
        }),
        content: <OrderSideMessage side={side} />,
      },
      {
        title: formatMessage({
          id: 'activity.details.type.label',
          defaultMessage: 'Type',
        }),
        content: <OrderTypeMessage type={orderType} />,
      },
    ],
    [
      {
        title: formatMessage({
          id: 'shared.order.quantity.label',
          defaultMessage: 'Quantity',
        }),
        content: (
          <CurrencyPairMoney
            variant="base"
            currencyPair={currencyPair}
            amount={qty}
            showSign={false}
          />
        ),
      },
      {
        title: (
          <HStack space="s-8" align="center">
            <FormattedMessage
              id="activity.details.averageFilledPrice.label"
              defaultMessage="Average filled price"
            />
            {orderType === OrderType.Limit && (
              <>
                <Icon
                  name="InfoOutline"
                  size={16}
                  {...averageFilledPriceTooltip.getAnchorProps()}
                />
                <Tooltip {...averageFilledPriceTooltip.getTargetProps()}>
                  <VStack space="s-12" maxWidth={240}>
                    <FormattedMessage
                      id="activity.details.averageFilledPrice.tooltip.label"
                      defaultMessage="Weighted average of the prices of all trades filled by this order. It may differ from the original order price."
                    />
                    <Spacer />
                    <FormattedMessage
                      id="activity.details.averageFilledPrice.orderPrice.tooltip.label"
                      values={{
                        price: (
                          <CurrencyPairMoney
                            kind="price"
                            variant="quote"
                            currencyPair={currencyPair}
                            amount={price}
                          />
                        ),
                      }}
                      defaultMessage="Order price: {price}"
                    />
                  </VStack>
                </Tooltip>
              </>
            )}
          </HStack>
        ),
        content: (
          <CurrencyPairMoney
            kind="price"
            variant="quote"
            currencyPair={currencyPair}
            amount={avgPrice}
          />
        ),
        visible: !isOrderRejected && !isOrderCancelled,
      },
      {
        title: formatMessage({
          id: 'activity.details.price.label',
          defaultMessage: 'Price',
        }),
        content: (
          <CurrencyPairMoney
            kind="price"
            variant="quote"
            currencyPair={currencyPair}
            amount={price}
          />
        ),
        visible: isOrderRejected || isOrderCancelled,
      },
      {
        title: (
          <HStack space="s-8" align="center">
            <FormattedMessage
              id="activity.details.filledValue.label"
              defaultMessage="Filled value"
            />
            <Icon name="InfoOutline" size={16} {...filledValueTooltip.getAnchorProps()} />
            <Tooltip {...filledValueTooltip.getTargetProps()}>
              <VStack space="s-12" maxWidth={240}>
                <FormattedMessage
                  id="activity.details.filledValue.tooltip.label"
                  defaultMessage="Value of the quantity traded at the moment of execution. It may differ from the original order value."
                />
                <Spacer />
                <FormattedMessage
                  id="activity.details.filledValue.orderValue.tooltip.label"
                  values={{
                    value: (
                      <Money
                        amount={(qty - leavesQty) * price}
                        currency={pair.quoteCurrency}
                        kind="total"
                      />
                    ),
                  }}
                  defaultMessage="Order value: {value}"
                />
              </VStack>
            </Tooltip>
          </HStack>
        ),
        content: (
          <Money
            amount={(qty - leavesQty) * currentPrice}
            currency={pair.quoteCurrency}
            kind="total"
          />
        ),
        visible: !isOrderRejected && !isOrderCancelled,
      },
      {
        title: formatMessage({
          id: 'shared.order.value.label',
          defaultMessage: 'Value',
        }),
        content: (
          <Money amount={qty * price} currency={pair.quoteCurrency} kind="total" />
        ),
        visible: isOrderRejected || isOrderCancelled,
      },
      {
        title: formatMessage({
          id: 'activity.details.fee.label',
          defaultMessage: 'Fee',
        }),
        content:
          isOrderRejected || totalFee === 0 ? (
            <EmptyValue />
          ) : (
            <Money kind="total" currency={feeCurrency} amount={totalFee} />
          ),
        visible: false,
      },
      {
        title: formatMessage({
          id: 'activity.details.received.label',
          defaultMessage: 'Received',
        }),
        content: (() => {
          if (isOrderRejected || isOrderCancelled) {
            return <EmptyValue />
          }

          if (side === OrderSide.Buy) {
            return <Money currency={pair.baseCurrency} amount={qty - leavesQty} />
          }

          return (
            <Money
              amount={(qty - leavesQty) * currentPrice}
              currency={pair.quoteCurrency}
              kind="total"
            />
          )
        })(),
        visible: false, // TODO: remove flag once fee is correctly returned by BE
      },
    ],
    {
      title: formatMessage({
        id: 'activity.details.orderId.label',
        defaultMessage: 'Order ID',
      }),
      content: orderId,
    },
  ]

  return orderDetails
}
