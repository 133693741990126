import { isNil } from 'lodash'

export const matchFilterValue = (
  value: string | null | undefined,
  filterValue: string | null | undefined,
) => {
  if (isNil(filterValue)) {
    return true
  }

  if (isNil(value)) {
    return false
  }

  return value.toLowerCase().includes(filterValue.toLowerCase())
}
