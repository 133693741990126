import { OrderSide, OrderType } from '../../../core-api'

export enum OrderValueType {
  Amount = 'AMOUNT',
  Quantity = 'QUANTITY',
}

export interface ExchangeOrderState {
  orderId: string | null

  side: OrderSide
  type: OrderType

  valueType: OrderValueType

  quantity: number | null
  amount: number | null

  postOnly: boolean

  roundedQuantity: number | null
  roundedAmount: number | null

  price: number | null
  estimatedPrice: number | null

  sizeRatio: number | null
  touched: boolean
}

export type InitialOrderState = Partial<
  Omit<
    ExchangeOrderState,
    'touched' | 'sizeRatio' | 'estimatedPrice' | 'roundedQuantity' | 'roundedAmount'
  >
>

export interface ExchangeOrderInputsReturn {
  state: ExchangeOrderState

  baseCurrencyInvalid: boolean
  baseCurrencyMessage: string | null
  baseCurrencyErrorMessage: string | null
  baseCurrencyErrorTooltipMessage?: string | null
  baseCurrencyBalanceMessage: string | null

  quoteCurrencyInvalid: boolean
  quoteCurrencyMessage: string | null
  quoteCurrencyErrorMessage: string | null
  quoteCurrencyBalanceMessage: string | null

  disabled: boolean
  loading: boolean

  depositRequired: boolean
  depositCurrency: string

  handleChangeSide: (value: OrderSide) => void
  handleChangeType: (value: OrderType) => void
  handleToggleValueType: () => void
  handleChangeQuantity: (value: number | null) => void
  handleChangeAmount: (value: number | null) => void
  handleChangePostOnly: (value: boolean) => void
  handleChangePrice: (value: number | null) => void
  handleChangeSize: (sizeRatio: number) => void
  handleReset: () => void
  handleClear: () => void
  handlePrefill: (price: number | null) => void
}
