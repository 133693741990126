import {
  Action,
  Box,
  HStack,
  Icon,
  RadioSelect,
  Spacer,
  Text,
  Token,
  useToggle,
} from '@revolut/ui-kit'
import { FC, useCallback, useMemo } from 'react'

import { useIntl } from '../../../core-intl'
import { OrderExecutionMessage } from '../../../feature-activity'

type OrderExecutionSelectProps = {
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
}

export const OrderExecutionSelect: FC<OrderExecutionSelectProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const { formatMessage } = useIntl()

  const [open, toggleOpen] = useToggle()

  const options = useMemo(
    () => [
      {
        key: 'allow-taker',
        value: false,
        label: <OrderExecutionMessage postOnly={false} />,
      },
      {
        key: 'post-only',
        value: true,
        label: <OrderExecutionMessage postOnly />,
      },
    ],
    [],
  )

  const handleChange = useCallback(
    (nextValue: boolean | null) => {
      if (nextValue !== null) {
        onChange(nextValue)
      }
    },
    [onChange],
  )

  return (
    <Box flex={1}>
      <Action
        onClick={() => toggleOpen(true)}
        disabled={disabled}
        useIcon="16/ChevronDown"
        aria-label={formatMessage({
          id: 'labels.execution',
          defaultMessage: 'Execution',
        })}
      >
        <OrderExecutionMessage postOnly={value} />
      </Action>

      <RadioSelect
        positionStrategy="fixed"
        open={open}
        onClose={() => toggleOpen(false)}
        options={options}
        value={value}
        onChange={handleChange}
        labelList={formatMessage({
          id: 'labels.executions',
          defaultMessage: 'Executions',
        })}
        indicatorStyle="highlight"
        autoClose
      >
        {(option) => (
          <HStack>
            <Text>{option.label}</Text>
            <Spacer />
            {option.value === value && <Icon name="Check" color={Token.color.accent} />}
          </HStack>
        )}
      </RadioSelect>
    </Box>
  )
}
