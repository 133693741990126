import { FC } from 'react'

import { ChartType } from '../../constants'
import { DepthChart } from '../DepthChart'
import { PriceChartWidget } from '../PriceChartWidget'

interface ChartProps {
  type: ChartType
  currencyPair: string
}

export const Chart: FC<ChartProps> = ({ type, currencyPair }) => {
  const isPriceChartVisible = type === ChartType.Price

  return (
    <>
      <PriceChartWidget currencyPair={currencyPair} visible={isPriceChartVisible} />
      <DepthChart currencyPair={currencyPair} visible={!isPriceChartVisible} />
    </>
  )
}
