export const getTradesTotal = ({
  quoteCurrency,
  feeCurrency,
  amount,
  fee,
  price,
}: {
  quoteCurrency: string
  feeCurrency: string
  amount: number
  fee: number
  price: number
}) => {
  if (quoteCurrency === feeCurrency) {
    return amount - fee
  }

  const feeInQuoteCurrency = fee * price

  return amount - feeInQuoteCurrency
}
