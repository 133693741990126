import { usePockets } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { useHumanizeCurrency } from '../../../core-shared'
import { useThemePalette } from '../../../core-ui'
import { useOnboardingButtonProps } from '../../../feature-onboarding'

export interface ExchangeOrderButtonPropsParams {
  isBuy: boolean
  depositRequired: boolean
  baseCurrency: string
  quoteCurrency: string
  disabled: boolean
  submitting: boolean
  loading: boolean
  handleSubmit: VoidFunction
  handleDepositFundsClick: VoidFunction
}

export const useExchangeOrderButtonProps = ({
  depositRequired,
  isBuy,
  baseCurrency,
  quoteCurrency,
  disabled,
  submitting,
  loading,
  handleSubmit,
  handleDepositFundsClick,
}: ExchangeOrderButtonPropsParams) => {
  const { palette } = useThemePalette()

  const { formatMessage } = useIntl()

  const { humanizeCurrency } = useHumanizeCurrency()

  const humanizedBaseCurrency = humanizeCurrency(baseCurrency)
  const humanizedQuoteCurrency = humanizeCurrency(quoteCurrency)

  const tradeButtonText = isBuy
    ? formatMessage(
        {
          id: 'trading.orderForm.buyButton.title',
          defaultMessage: 'Buy {baseCurrency} with {quoteCurrency}',
        },
        {
          baseCurrency: humanizedBaseCurrency,
          quoteCurrency: humanizedQuoteCurrency,
        },
      )
    : formatMessage(
        {
          id: 'trading.orderForm.sellButton.title',
          defaultMessage: 'Sell {baseCurrency} for {quoteCurrency}',
        },
        {
          baseCurrency: humanizedBaseCurrency,
          quoteCurrency: humanizedQuoteCurrency,
        },
      )

  const depositButtonText = isBuy
    ? formatMessage(
        {
          id: 'trading.orderForm.depositFundsToBuyButton.title',
          defaultMessage: 'Deposit {currency} to buy',
        },
        {
          currency: humanizedQuoteCurrency,
        },
      )
    : formatMessage(
        {
          id: 'trading.orderForm.depositFundsToSellButton.title',
          defaultMessage: 'Deposit {currency} to sell',
        },
        {
          currency: humanizedBaseCurrency,
        },
      )

  const pockets = usePockets({
    enabled: depositRequired,
  })

  const hasBalanceToDeposit = pockets.data?.some(
    (pocket) =>
      pocket.currency === (isBuy ? quoteCurrency : baseCurrency) && pocket.balance > 0,
  )

  const buttonProps = useOnboardingButtonProps(
    depositRequired && hasBalanceToDeposit
      ? {
          children: depositButtonText,
          onClick: handleDepositFundsClick,
        }
      : {
          children: tradeButtonText,
          bg: isBuy ? palette.upColor : palette.downColor,
          disabled,
          pending: submitting || loading,
          onClick: handleSubmit,
        },
  )

  return buttonProps
}
