import {
  Money,
  useMoneyFormat as useMoneyFormatInitial,
  UseMoneyFormatOptions as UseMoneyFormatOptionsInitial,
} from '@revolut/ui-kit'
import { useCallback } from 'react'

import { clearCurrencySymbol } from '../../../core-utils'

type MoneyFormatKind = 'price' | 'total' | 'amount'

const FORMAT_OPTIONS_BY_KIND: Record<
  MoneyFormatKind,
  Omit<UseMoneyFormatOptions, 'kind'>
> = {
  price: {
    showTrailingZeros: true,
  },
  total: {
    showTrailingZeros: true,
    maximumFractionDigits: 2,
  },
  amount: {
    showTrailingZeros: false,
  },
}

export interface UseMoneyFormatOptions extends UseMoneyFormatOptionsInitial {
  kind?: MoneyFormatKind
  showTrailingZeros?: boolean
  clearSymbol?: boolean
}

export const useMoneyFormat = (options: UseMoneyFormatOptions = {}) => {
  const { kind = 'amount', ...rest } = options

  const {
    showTrailingZeros = false,
    clearSymbol = false,
    ...kindOptions
  } = {
    ...FORMAT_OPTIONS_BY_KIND[kind],
    ...rest,
  }

  const formatMoneyInitial = useMoneyFormatInitial({
    ...kindOptions,
    minimumFractionDigits: showTrailingZeros ? undefined : 0,
  })

  const formatMoney = useCallback(
    (value: number | Money): string => {
      let result = formatMoneyInitial(value)

      if (clearSymbol) {
        result = clearCurrencySymbol(result)
      }

      return result
    },
    [clearSymbol, formatMoneyInitial],
  )

  return formatMoney
}
