import { Button, Flex, Header, Image, Popup } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useAcceptOnboardingMarketingConsent } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { useStatusPopup } from '../../../core-ui'
import { Asset3dImageName, getImageAssetSrcProps } from '../../../core-utils'
import { useOnboardingMarketingConsentBanner } from '../../hooks'

interface OnboardingPopupMarketingConsentContentProps {
  onClose: VoidFunction
}

export const OnboardingPopupMarketingConsentContent: FC<
  OnboardingPopupMarketingConsentContentProps
> = ({ onClose }) => {
  const { formatMessage } = useIntl()

  const statusPopup = useStatusPopup()

  const [, setOnboardingMarketingConsentBanner] = useOnboardingMarketingConsentBanner()

  const { mutate, isLoading } = useAcceptOnboardingMarketingConsent({
    onSuccess: () => {
      setOnboardingMarketingConsentBanner(false)

      onClose()
    },
    onError: () => {
      statusPopup.showError({
        title: (
          <FormattedMessage
            id="errors.general.title"
            defaultMessage="Something went wrong"
          />
        ),
      })
    },
  })

  const handleClose = () => {
    setOnboardingMarketingConsentBanner(true)

    onClose()
  }

  return (
    <>
      <Header variant="item">
        <Header.CloseButton
          aria-label={formatMessage({
            id: 'labels.close',
            defaultMessage: 'Close',
          })}
          onClick={handleClose}
        />
        <Header.Title>
          <FormattedMessage
            id="onboarding.marketingConsent.title"
            defaultMessage="Make the most of Revolut X"
          />
        </Header.Title>
        <Header.Subtitle>
          <FormattedMessage
            id="onboarding.marketingConsent.subtitle"
            defaultMessage="Allow us to send you updates about exciting Revolut X offers, products and services. You can opt out at any time"
          />
        </Header.Subtitle>
      </Header>

      <Flex justifyContent="center">
        <Image size={250} {...getImageAssetSrcProps(Asset3dImageName.Gift)} />
      </Flex>

      <Popup.Actions>
        <Button onClick={() => mutate(true)} pending={isLoading} elevated>
          <FormattedMessage id="actions.yesPlease" defaultMessage="Yes, please" />
        </Button>

        <Button variant="secondary" onClick={() => mutate(false)} pending={isLoading}>
          <FormattedMessage id="actions.noThanks" defaultMessage="No, thanks" />
        </Button>
      </Popup.Actions>
    </>
  )
}
