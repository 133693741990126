import { FC } from 'react'

import { useTicker } from '../../../core-api'
import { Percents, PercentsProps } from '../../../core-ui'

interface TickerPerformanceProps extends Omit<PercentsProps, 'value' | 'loading'> {
  currencyPair: string
}

export const TickerPerformance: FC<TickerPerformanceProps> = ({
  currencyPair,
  ...rest
}) => {
  const { data, status } = useTicker(currencyPair)

  const value =
    status === 'success' && data?.percentageChange24h !== undefined
      ? Number(data.percentageChange24h)
      : undefined

  return <Percents value={value} loading={status === 'loading'} {...rest} />
}
