export const formatMarketData = (data: {
  asks: Array<{ price: string; amount: string }>
  bids: Array<{ price: string; amount: string }>
}) => {
  const mapOrders = (orders: Array<{ price: string; amount: string }>) =>
    orders
      .map((order: { price: string; amount: string }) => ({
        price: parseFloat(order.price),
        amount: parseFloat(order.amount),
      }))
      .reverse()

  return {
    asks: mapOrders(data.asks),
    bids: mapOrders(data.bids),
  }
}
