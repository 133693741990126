import { useEffect } from 'react'

import { browser, useQueryParams } from '../../../core-utils'
import { BroadcastChannelMessageType, useBroadcastChannel } from '../useBroadcastChannel'

export const useSsoVerificationEffect = () => {
  const [queryParams] = useQueryParams<{ code?: string }>()

  const { sendMessage } = useBroadcastChannel<string>(
    BroadcastChannelMessageType.Verification,
  )

  useEffect(() => {
    if (queryParams.code) {
      sendMessage(queryParams.code)

      browser.close()
    }
  }, [queryParams.code, sendMessage])
}
