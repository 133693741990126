import { VStack, Group, Subheader, useToggle, Action, Button } from '@revolut/ui-kit'
import { FC, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  useStableSortedBalances,
  CurrencyAssetType,
  ExtendedBalance,
} from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { GroupSkeleton } from '../../../core-ui'
import { REDUCED_ITEMS_COUNT } from '../../constants'
import { useFilteredItems } from '../../hooks'
import { BalancesGroupItem } from '../BalancesGroup/BalancesGroupItem'

interface PortfolioGroupProps {
  onClickItem: (balance: ExtendedBalance) => void
  expandable?: boolean
}

export const PortfolioGroup: FC<PortfolioGroupProps> = ({
  onClickItem,
  expandable = true,
}) => {
  const balances = useStableSortedBalances()
  const [showMoreButton, toggleShowMoreButton] = useToggle({ defaultState: expandable })

  const items = useMemo(() => balances.data ?? [], [balances.data])

  const { fiatItems, cryptoItems } = useFilteredItems({
    items,
    showMoreButton,
    reducedItemsCount: REDUCED_ITEMS_COUNT,
  })

  if (balances.status === 'loading') {
    return <GroupSkeleton />
  }

  if (balances.status === 'error') {
    return <ErrorStatus onRetry={balances.refetch} />
  }

  return (
    <VStack space="s-16">
      <Group>
        {fiatItems.length > 0 && (
          <>
            <Subheader>
              <Subheader.Title>
                <FormattedMessage
                  id="portfolio.balances.table.fiat.caption"
                  defaultMessage="Fiat"
                />
              </Subheader.Title>
            </Subheader>
            {fiatItems.map((item) => (
              <BalancesGroupItem
                key={item.currency}
                balance={item}
                onClick={onClickItem}
                variant="performance"
              />
            ))}
          </>
        )}
        {cryptoItems.length > 0 && (
          <>
            <Subheader>
              <Subheader.Title>
                <FormattedMessage
                  id="portfolio.balances.table.crypto.caption"
                  defaultMessage="Crypto"
                />
              </Subheader.Title>
            </Subheader>
            {cryptoItems.map((item) => (
              <BalancesGroupItem
                key={item.currency}
                balance={item}
                onClick={onClickItem}
                variant="performance"
              />
            ))}
          </>
        )}
        {items.filter((item) => item.assetType === CurrencyAssetType.Crypto).length >
          REDUCED_ITEMS_COUNT && (
          <Button>
            <Action
              onClick={() =>
                showMoreButton ? toggleShowMoreButton(false) : toggleShowMoreButton(true)
              }
            >
              {showMoreButton ? (
                <FormattedMessage id="actions.showMore" defaultMessage="Show more" />
              ) : (
                <FormattedMessage id="actions.showLess" defaultMessage="Show less" />
              )}
            </Action>
          </Button>
        )}
      </Group>
    </VStack>
  )
}
