import {
  CurrenciesResponse,
  CurrencyAssetType,
  CurrencyDetails,
  CurrencyStatus,
  Pocket,
  PocketState,
  PocketType,
} from '../types'

export const selectPockets = (
  pockets: Pocket[],
  currencies: CurrenciesResponse,
  currenciesDetails: Record<string, CurrencyDetails>,
) =>
  pockets.filter((pocket) => {
    if (pocket.type !== PocketType.Current) {
      return false
    }

    if (![PocketState.Inactive, PocketState.Active].includes(pocket.state)) {
      return false
    }

    if (pocket.balance <= 0) {
      return false
    }

    const currency = currencies[pocket.currency]

    const currencyDetails = currenciesDetails[pocket.currency]

    // Ignore pockets with unknown currency details
    if (!currencyDetails) {
      return false
    }

    if (currency) {
      // Ignore pockets with inactive crypto currencies
      if (
        currency.assetType === CurrencyAssetType.Crypto &&
        currency.status !== CurrencyStatus.Active
      ) {
        return false
      }
      // Ignore pockets with unknown for Revolut X crypto currencies
    } else if (currencyDetails.type === CurrencyAssetType.Crypto) {
      return false
    }

    return true
  })
