import { useContext } from 'react'

import { WelcomeJourneyContext } from '../../providers'

export const useWelcomeJourneyContext = () => {
  const ctx = useContext(WelcomeJourneyContext)

  if (ctx === null) {
    throw new Error(
      'You can access context only in components inside "WelcomeJourneyProvider" component',
    )
  }

  return ctx
}
