import { Ask, Bid } from '../types'

export const getOrderBookMaxAmount = ({
  bids,
  asks,
}: {
  bids: Array<Bid>
  asks: Array<Ask>
}): number => {
  return Math.max(
    ...asks.map(({ amount }) => amount),
    ...bids.map(({ amount }) => amount),
  )
}
