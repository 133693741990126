import { TableLoadingState } from '@revolut/ui-kit'
import { QueryStatus } from '@tanstack/react-query'

import { notReachable } from './notReachable'

export const mapTableLoadingState = (
  status: QueryStatus,
  data?: Array<unknown>,
): TableLoadingState => {
  switch (status) {
    case 'loading':
      return TableLoadingState.PENDING
    case 'error':
      return TableLoadingState.FAILED
    case 'success': {
      if (data?.length === 0) {
        return TableLoadingState.NO_RESULTS
      }

      return TableLoadingState.READY
    }

    default:
      return notReachable(status)
  }
}
