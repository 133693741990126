import { Avatar, Header, Text, useDateTimeFormat } from '@revolut/ui-kit'
import { FC } from 'react'

import {
  OrderSide,
  OrderState,
  OrderType,
  useCurrencyPairRequired,
} from '../../../core-api'
import { AssetAvatar, CurrencyPairMoney } from '../../../core-shared'
import { OrderSubtitleMessage } from './OrderSubtitleMessage'

interface OrderDetailsSideHeaderProps {
  currencyPair: string
  qty: number
  side: OrderSide
  date: number
  orderType: OrderType
  state: OrderState
}

export const OrderDetailsSideHeader: FC<OrderDetailsSideHeaderProps> = ({
  side,
  qty,
  orderType,
  date,
  currencyPair,
  state,
}) => {
  const pair = useCurrencyPairRequired(currencyPair)

  const dateTimeFormat = useDateTimeFormat({
    style: 'precise',
  })

  const baseCurrencySign = side === OrderSide.Sell ? -1 : 1

  return (
    <Header variant="item">
      <Header.CloseButton />

      <Header.Avatar>
        <AssetAvatar asset={pair.baseCurrency}>
          {state === OrderState.Rejected && (
            <Avatar.Badge useIcon="Cross" backgroundColor="danger" />
          )}
          {state === OrderState.Canceled && (
            <Avatar.Badge useIcon="16/Canceled" backgroundColor="grey-tone-50" />
          )}
        </AssetAvatar>
      </Header.Avatar>

      <Header.Title>
        <Text
          textDecoration={
            state === OrderState.Rejected || state === OrderState.Canceled
              ? 'line-through'
              : 'none'
          }
        >
          <CurrencyPairMoney
            variant="base"
            currencyPair={currencyPair}
            amount={qty * baseCurrencySign}
            showSign
          />
        </Text>
      </Header.Title>

      <Header.Subtitle>
        <OrderSubtitleMessage
          variant="link"
          side={side}
          orderType={orderType}
          currencyPair={currencyPair}
        />
      </Header.Subtitle>

      <Header.Description>{dateTimeFormat(date)}</Header.Description>
    </Header>
  )
}
