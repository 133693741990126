import { Box, Layout, StatusWidget } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'

import { EventTrackerHiddenHeader } from '../../../../packlets/core-event-tracker'
import {
  Asset3dImageName,
  RestrictedAccessType,
  RoutePath,
  getImageAssetSrcProps,
  getRouteUrl,
} from '../../../../packlets/core-utils'
import { RestrictedAccessTitleMessage } from './RestrictedAccessTitleMessage'

export const RestrictedAccessPage: FC = () => {
  const history = useHistory()

  const { type } = useParams<{ type: RestrictedAccessType }>()

  const restrictedAccessType = Object.values(RestrictedAccessType).includes(type)
    ? type
    : RestrictedAccessType.Account

  return (
    <Layout>
      <Layout.Main>
        <EventTrackerHiddenHeader screen="authRestrictedAccess" />

        <Box my="auto" mx="auto" maxWidth={343}>
          <StatusWidget>
            <StatusWidget.Image {...getImageAssetSrcProps(Asset3dImageName.Pending)} />
            <StatusWidget.Title>
              <RestrictedAccessTitleMessage restrictedAccessType={restrictedAccessType} />
            </StatusWidget.Title>
            <StatusWidget.Description>
              <FormattedMessage
                id="auth.restrictedAccess.subtitle"
                defaultMessage="We’ll let you know once it becomes available"
              />
            </StatusWidget.Description>

            <StatusWidget.Action
              onClick={() =>
                history.replace(getRouteUrl({ path: RoutePath.AuthSignOut }))
              }
            >
              <FormattedMessage id="actions.logOut" defaultMessage="Log out" />
            </StatusWidget.Action>
          </StatusWidget>
        </Box>
      </Layout.Main>
    </Layout>
  )
}
