import axios from 'axios'

import { TradesParams, TradesReponse } from '../types'

export const getTrades = async (params: TradesParams) => {
  const { data } = await axios.get<TradesReponse>('/api/crypto-exchange/trades/', {
    params,
  })

  return data
}
