import { GridLayoutTemplateColumn } from '../types'

export const resizeGridLayoutColumns = (
  columns: GridLayoutTemplateColumn[],
  leftIndex: number,
  rightIndex: number,
  dx: number,
): GridLayoutTemplateColumn[] => {
  const leftColumn = columns[leftIndex]
  const rightColumn = columns[rightIndex]

  if (!leftColumn || !rightColumn) {
    return columns
  }

  let adjustedDx = dx

  if (leftColumn.type === 'FIXED') {
    if (adjustedDx < 0) {
      adjustedDx = Math.max(leftColumn.width - leftColumn.maxWidth, adjustedDx)
    } else {
      adjustedDx = Math.min(leftColumn.width - leftColumn.minWidth, adjustedDx)
    }
  }

  if (rightColumn.type === 'FIXED') {
    if (adjustedDx > 0) {
      adjustedDx = Math.min(rightColumn.maxWidth - rightColumn.width, adjustedDx)
    } else {
      adjustedDx = Math.max(rightColumn.minWidth - rightColumn.width, adjustedDx)
    }
  }

  return columns.map((column, index) => {
    if (index === leftIndex) {
      if (column.type === 'FIXED') {
        return {
          ...column,
          width: Math.min(
            Math.max(column.width - adjustedDx, column.minWidth),
            column.maxWidth,
          ),
        }
      }
    }

    if (index === rightIndex) {
      if (column.type === 'FIXED') {
        return {
          ...column,
          width: Math.min(
            Math.max(column.width + adjustedDx, column.minWidth),
            column.maxWidth,
          ),
        }
      }
    }

    return column
  })
}
