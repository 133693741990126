import { VStack, Subheader, Action } from '@revolut/ui-kit'
import { FC, PropsWithChildren, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface GroupWidgetProps extends PropsWithChildren {
  title: string
  to?: string
  textLink?: ReactNode
}

export const GroupWidget: FC<GroupWidgetProps> = ({ children, title, to, textLink }) => {
  return (
    <VStack>
      <Subheader>
        <Subheader.Title>{title}</Subheader.Title>
        <Subheader.Side>
          {to && textLink && (
            <Action use={Link} to={to}>
              {textLink}
            </Action>
          )}
        </Subheader.Side>
      </Subheader>

      {children}
    </VStack>
  )
}
