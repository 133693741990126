import { TabBar, TabProps } from '@revolut/ui-kit'
import { forwardRef } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

export type TabBarLinkProps = {
  to: string
  exact?: boolean
  replace?: boolean
  keepSearch?: boolean
} & TabProps

export const TabBarLink = forwardRef<HTMLAnchorElement, TabBarLinkProps>(
  ({ to, exact, replace, keepSearch = false, ...rest }, ref) => {
    const history = useHistory()

    const location = useLocation()

    const handleClick = () => {
      const nextLocation = {
        pathname: to,
        search: keepSearch ? location.search : undefined,
      }

      if (replace) {
        history.replace(nextLocation)
      } else {
        history.push(nextLocation)
      }
    }

    return (
      <TabBar.Item
        {...rest}
        ref={ref}
        aria-selected={Boolean(useRouteMatch({ path: to, exact }))}
        onClick={handleClick}
      />
    )
  },
)
