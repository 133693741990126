import { useCallback } from 'react'
import { MessageDescriptor, useIntl as useReactIntl } from 'react-intl'

import { isKnownMessageKey } from '../../utils'

export const useIntl = () => {
  const { formatMessage, ...rest } = useReactIntl()

  const formatEnumMessage = useCallback(
    <T extends string | number>(
      id: T,
      messages: Record<T, MessageDescriptor>,
      fallback: string = '',
    ) => {
      if (!isKnownMessageKey(id, messages)) {
        return fallback
      }

      const message = messages[id]

      return formatMessage(message)
    },
    [formatMessage],
  )

  return {
    ...rest,
    formatMessage,
    formatEnumMessage,
  }
}
