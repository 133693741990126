import { ErrorResponse } from './error'

export interface WithdrawResponse {
  status: string
  transferId: string
}

export enum WithdrawType {
  Amount = 'AMOUNT',
}

export type WithdrawData = {
  type: string
  amount: number
  op: string
  code?: string
  codeVerifier?: string
} & (
  | {
      sourcePocketId: string
    }
  | {
      currency: string
    }
)

export interface WithdrawErrorResponse extends ErrorResponse {
  state: string
  acceptedLevel: string
}
