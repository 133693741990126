import { useMemo } from 'react'

import { useCurrencyPairsRequired } from '../../../core-api'
import { ALL_PAIRS_FILTER_OPTION } from '../../constants'
import { getUniqueQuoteCurrencies } from '../../utils'

export const useQuoteCurrenciesFilterOptions = () => {
  const currencyPairs = useCurrencyPairsRequired()
  const quoteCurrencies = getUniqueQuoteCurrencies(currencyPairs)

  const formattedFiatCurrenciesList = useMemo(() => {
    const formattedList = quoteCurrencies.map((currency) => ({
      label: currency,
      value: currency,
      key: currency,
    }))

    return [ALL_PAIRS_FILTER_OPTION, ...formattedList]
  }, [quoteCurrencies])

  return formattedFiatCurrenciesList
}
