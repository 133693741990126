import * as Sentry from '@sentry/react'
import { matchPath } from 'react-router-dom'

import { EventTrackerOptions } from '@revolut-internal/event-tracker'

import { ROUTE_PATHS } from '../../core-utils'

const PATHS = Object.values(ROUTE_PATHS)

type PathResolver = Required<EventTrackerOptions<'cryptoExchange'>>['pathResolver']

export const pathResolver: PathResolver = (pathname: string) => {
  const path = PATHS.find((item) =>
    matchPath(pathname, { path: item, exact: true, strict: false }),
  )

  if (path === undefined) {
    Sentry.captureException(new Error(`Unknown path for the pathname: ${pathname}`))
  }

  return path ?? pathname
}
