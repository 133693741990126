import * as Bowser from 'bowser'
import { stubTrue } from 'lodash'
import { useEffect } from 'react'

import hotkeys from 'hotkeys-js'

import { formatHotkey } from '../../utils/formatHotkey'
import { translateMacOsHotkey } from '../../utils/translateMacOsHotkey'

interface HotkeyEffectParams {
  hotkey: string
  onHotkey: () => void
}

export const useHotkeyEffect = (params: HotkeyEffectParams) => {
  const { hotkey, onHotkey } = params

  const bowser = Bowser.getParser(window.navigator.userAgent)

  const os = bowser.getOSName(true)

  const translatedHotkey = translateMacOsHotkey(hotkey, os)

  useEffect(() => {
    hotkeys.filter = stubTrue

    hotkeys(translatedHotkey, onHotkey)

    return () => hotkeys.unbind(translatedHotkey)
  }, [onHotkey, translatedHotkey])

  const formattedHotkey = formatHotkey(translatedHotkey, os)

  return {
    hotkey: formattedHotkey,
  }
}
