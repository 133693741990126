import { Table, TableCellProps, Text, Token } from '@revolut/ui-kit'
import { FC } from 'react'

import { OrderSide } from '../../../core-api'
import { OrderSideMessage } from '../OrderSideMessage'

interface OrderSideTableCellProps extends TableCellProps {
  side: OrderSide
}

export const OrderSideTableCell: FC<OrderSideTableCellProps> = ({ side, ...rest }) => {
  const color = side === OrderSide.Buy ? Token.color.up : Token.color.down

  return (
    <Table.Cell {...rest}>
      <Text color={color}>
        <OrderSideMessage side={side} />
      </Text>
    </Table.Cell>
  )
}
