import * as Sentry from '@sentry/react'
import { isFunction } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { BaseStorage } from '../../utils'

/**
 * The storage event fires when a storage area has been modified in
 * the context of another document. That is why we need to trigger it manually
 * (to be handled in the context of the current document).
 *
 * Please see https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
 */
const fireStorageChangeEvent = (key: string, newValue: unknown) => {
  const event = new StorageEvent('storage', {
    key,
    newValue: JSON.stringify(newValue),
  })

  event.initEvent('storage', true, true)

  window.dispatchEvent(event)
}

type StorageValue<T> = ((prevValue: T) => T) | T

type UseStorageReturnType<T> = [T, (value: StorageValue<T>) => void]

export const useStorage = <T = string>(
  storage: BaseStorage<string>,
  key: string,
  defaultValue: T,
): UseStorageReturnType<T> => {
  const [value, setValue] = useState<T>(storage.getItem<T>(key) ?? defaultValue)

  const updateValue = useCallback(
    (nextValue: StorageValue<T>) => {
      const prevValue = storage.getItem<T>(key) ?? defaultValue

      const newValue = isFunction(nextValue) ? nextValue(prevValue) : nextValue

      if (newValue === null) {
        storage.removeItem(key)
      } else {
        storage.setItem(key, newValue)
      }

      fireStorageChangeEvent(key, newValue)
    },
    [storage, key, defaultValue],
  )

  // Sync internal state and a storage
  const onStorageChange = useCallback(
    (event: StorageEvent) => {
      if (event.key !== key || !event.newValue) {
        return
      }

      try {
        setValue(JSON.parse(event.newValue))
      } catch (e) {
        Sentry.captureException(e)
      }
    },
    [key, setValue],
  )

  useEffect(() => {
    window.addEventListener('storage', onStorageChange)

    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [onStorageChange])

  return [value, updateValue]
}
