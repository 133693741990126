import { LineData } from 'lightweight-charts'

import { AnalyticsResponse, ValuePoint } from '../../core-api'
import { Time } from '../../core-utils'
import { getChartTime } from './getChartTime'

export const getTotalValueChartData = (
  data: AnalyticsResponse<ValuePoint>,
  lastTotalValue: number,
) => {
  const result: LineData[] = data.points.map((point) => ({
    value: parseFloat(point.balance),
    time: getChartTime(point.timestamp),
  }))

  if (result.length > 0) {
    // In rare cases, BE can returns data where last point a little bit in the future
    const lastTimestamp = Math.max(Date.now(), data.to ?? 0) + Time.Second

    result.push({
      value: lastTotalValue,
      time: getChartTime(lastTimestamp),
    })
  }

  return result
}
