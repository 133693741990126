import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { TokenInfo, getTokenInfo } from '../../api'
import { QueryKey } from '../../constants'

export const useTokenInfo = <T extends unknown = TokenInfo>(
  options: UseQueryOptions<TokenInfo, AxiosError, T> = {},
) =>
  useQuery<TokenInfo, AxiosError, T>([QueryKey.AuthTokenInfo], () => getTokenInfo(), {
    ...options,
    staleTime: 0,
    cacheTime: 0,
    retry: false,
  })
