import { useCallback } from 'react'

import { useEventEmitterContext } from '../../providers'
import { WidgetEventMap } from '../../types'

export const useWidgetEvent = () => {
  const { eventEmitter } = useEventEmitterContext<WidgetEventMap>()

  const subscribe = useCallback(
    <K extends keyof WidgetEventMap>(
      eventName: K,
      listener: (payload: WidgetEventMap[K]) => void,
    ) => {
      eventEmitter.on(eventName, listener)

      return () => eventEmitter.off(eventName, listener)
    },
    [eventEmitter],
  )

  const dispatch = useCallback(
    <K extends keyof WidgetEventMap>(eventName: K, payload: WidgetEventMap[K]) => {
      eventEmitter.emit(eventName, payload)
    },
    [eventEmitter],
  )

  return { subscribe, dispatch }
}
