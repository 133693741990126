import { Box, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

export const AmountHeroWrapper = styled(Box)<{ length?: 'm' | 'l' }>`
  & span[class^='InputAutosize__AutosizeGrid'] {
    transition: font-size 0.4s ease;
    line-height: 52px;
    font-size: ${Token.fontSize.display2};

    & input {
      text-align: right;
    }

    ${({ length }) => {
      if (length === 'm') {
        return `
          font-size: ${Token.fontSize.heading1};
        `
      }

      if (length === 'l') {
        return `
          font-size: ${Token.fontSize.heading2};
        `
      }

      return ''
    }}
  }
`
