import { Action, useToggle } from '@revolut/ui-kit'
import { FC, useMemo } from 'react'

import { TransferQuotes } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import {
  DetailsCellGroup,
  DetailsCellGroupItemProps,
  useMoneyFormat,
} from '../../../core-ui'
import { FeeBreakdownPopup } from '../FeeBreakdownPopup'
import { FormattedRate } from '../FormattedRate'

export interface ExchangeBreakdownDetailsProps {
  toCurrency: string
  fromCurrency: string | undefined
  amount: number | null
  quotes: TransferQuotes | undefined
}

export const ExchangeBreakdownDetails: FC<ExchangeBreakdownDetailsProps> = ({
  toCurrency,
  fromCurrency,
  amount,
  quotes,
}) => {
  const { formatMessage } = useIntl()

  const moneyFormat = useMoneyFormat({ type: 'money-fractional' })

  const [isFeeBreakdownOpen, toggleFeeBreakdownOpen] = useToggle()

  const items = useMemo(() => {
    let result: DetailsCellGroupItemProps[] = []

    if (fromCurrency !== toCurrency && quotes) {
      const totalAmount = quotes.fromAmount.value + quotes.fee.total.value

      result = [
        {
          title: formatMessage({
            id: 'portfolio.exchangeBreakdown.details.amount.title',
            defaultMessage: 'Amount',
          }),
          content: moneyFormat({
            amount: quotes.toAmount.value,
            currency: quotes.toAmount.symbol,
          }),
        },
        {
          title: formatMessage({
            id: 'portfolio.exchangeBreakdown.details.price.title',
            defaultMessage: 'Price',
          }),
          content: (
            <FormattedRate
              fromCurrency={quotes.fromAmount.symbol}
              toCurrency={quotes.toAmount.symbol}
              rate={quotes.rate}
            />
          ),
        },
        {
          title: formatMessage({
            id: 'portfolio.exchangeBreakdown.details.exchanged.title',
            defaultMessage: 'Exchanged',
          }),
          content: moneyFormat({
            amount: quotes.fromAmount.value,
            currency: quotes.fromAmount.symbol,
          }),
        },
        {
          title: formatMessage({
            id: 'portfolio.exchangeBreakdown.details.fees.title',
            defaultMessage: 'Fees',
          }),
          content:
            quotes.fee.total.value === 0 ? (
              formatMessage({
                id: 'shared.orders.noFees.label',
                defaultMessage: 'No fees',
              })
            ) : (
              <Action
                useIcon="InfoOutline"
                onClick={() => toggleFeeBreakdownOpen(true)}
                aria-label={formatMessage({
                  id: 'portfolio.exchangeBreakdown.details.openFeeBreakdown.label',
                  defaultMessage: 'Open fee breakdown',
                })}
              >
                {moneyFormat({
                  amount: quotes.fee.total.value,
                  currency: quotes.fee.total.symbol,
                })}
              </Action>
            ),
        },
        {
          title: formatMessage({
            id: 'portfolio.exchangeBreakdown.details.estimatedTotalCost.title',
            defaultMessage: 'Estimated total cost',
          }),
          content: moneyFormat({
            amount: totalAmount,
            currency: quotes.fromAmount.symbol,
          }),
        },
      ]
    } else {
      result = [
        {
          title: formatMessage({
            id: 'portfolio.exchangeBreakdown.details.amount.title',
            defaultMessage: 'Amount',
          }),
          content: moneyFormat({
            amount: amount ?? 0,
            currency: toCurrency,
          }),
        },
        {
          title: formatMessage({
            id: 'portfolio.exchangeBreakdown.details.fees.title',
            defaultMessage: 'Fees',
          }),
          content: formatMessage({
            id: 'shared.orders.noFees.label',
            defaultMessage: 'No fees',
          }),
        },
      ]
    }

    return result
  }, [
    quotes,
    fromCurrency,
    toCurrency,
    amount,
    formatMessage,
    moneyFormat,
    toggleFeeBreakdownOpen,
  ])

  return (
    <>
      <DetailsCellGroup items={items} />

      {quotes && (
        <FeeBreakdownPopup
          open={isFeeBreakdownOpen}
          onClose={toggleFeeBreakdownOpen}
          breakdown={quotes.fee.breakdown}
        />
      )}
    </>
  )
}
