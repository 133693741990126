import {
  AreaStyleOptions,
  DeepPartial,
  HistogramData,
  HistogramStyleOptions,
  LineData,
  SeriesOptionsCommon,
} from 'lightweight-charts'

export const LightweightChartType = {
  Area: 'Area',
  Histogram: 'Histogram',
} as const

export type LightweightChartData =
  | {
      type: typeof LightweightChartType.Area
      data: LineData[]
      options?: DeepPartial<AreaStyleOptions & SeriesOptionsCommon>
      onFetchMore?: VoidFunction
      onCrosshairMove?: (value?: LineData) => void
    }
  | {
      type: typeof LightweightChartType.Histogram
      data: HistogramData[]
      options?: DeepPartial<HistogramStyleOptions & SeriesOptionsCommon>
      onFetchMore?: VoidFunction
      onCrosshairMove?: (value?: HistogramData) => void
    }
