export enum RoutePath {
  Root,
  Auth,
  AuthSignIn,
  AuthSignInError,
  AuthSignOut,
  AuthSignedOut,
  AuthLimitedAccess,
  AuthVerification,
  AuthRestrictedAccess,
  Error,
  Activity,
  ActivityOpenOrders,
  ActivityOrderHistory,
  ActivityTrades,
  ActivityTransfers,
  Trade,
  Portfolio,
  Home,
  Profile,
  NotificationSettings,
  ProfileAppearance,
}

export enum RestrictedAccessType {
  Region = 'region',
  Account = 'account',
}

export const ROUTE_PATHS: Record<RoutePath, string> = {
  [RoutePath.Root]: '/',
  [RoutePath.Auth]: '/auth',
  [RoutePath.AuthSignIn]: '/auth/signin',
  [RoutePath.AuthSignInError]: '/auth/signin-error',
  [RoutePath.AuthSignOut]: '/auth/signout',
  [RoutePath.AuthSignedOut]: '/auth/signed-out',
  [RoutePath.AuthLimitedAccess]: '/auth/limited-access',
  [RoutePath.AuthVerification]: '/auth/verification',
  [RoutePath.AuthRestrictedAccess]: '/auth/restricted-access/:type',
  [RoutePath.Error]: '/error',
  [RoutePath.Activity]: '/activity',
  [RoutePath.ActivityOpenOrders]: '/activity/open-orders/:id?',
  [RoutePath.ActivityOrderHistory]: '/activity/order-history/:id?',
  [RoutePath.ActivityTrades]: '/activity/trades/:id?',
  [RoutePath.ActivityTransfers]: '/activity/transfers/:id?',
  [RoutePath.Trade]: '/trade/:currencyPair?',
  [RoutePath.Portfolio]: '/portfolio/:currency?',
  [RoutePath.Home]: '/home',
  [RoutePath.Profile]: '/profile',
  [RoutePath.NotificationSettings]: '/profile/notification-settings',
  [RoutePath.ProfileAppearance]: '/profile/appearance',
}
