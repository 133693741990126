import axios from 'axios'

import { WatchlistData } from '../types'

export const updateWatchlist = async (data: WatchlistData) => {
  const { data: response } = await axios.post(
    '/api/crypto-exchange/watchlist/items',
    data,
  )

  return response
}
