import { ConfigKey, Locale, getConfigValue } from '../../core-config'

const SUPPORTED_LOCALES = new Set(
  getConfigValue<Locale[]>(ConfigKey.SupportedLocales).map((item) => item.locale),
)

export const getSupportedLocale = (...locales: (string | null)[]): string | undefined => {
  for (const item of locales) {
    if (item) {
      const normalizedLocale = item.replace('-', '_')

      if (SUPPORTED_LOCALES.has(normalizedLocale)) {
        return normalizedLocale
      }

      const language = normalizedLocale.split('_')[0]

      if (language && SUPPORTED_LOCALES.has(language)) {
        return language
      }
    }
  }

  return undefined
}
