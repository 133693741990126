import { useState, useLayoutEffect } from 'react'

export const useClientRect = (element: HTMLElement | null) => {
  const [rect, setRect] = useState<DOMRect | undefined>()

  useLayoutEffect(() => {
    const tempRect = element?.getBoundingClientRect()
    setRect(tempRect)
  }, [element])

  return rect
}
