import { Token } from '@revolut/ui-kit'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { getRouteUrl, RoutePath } from '../../../core-utils'
import { IntlMessages, WelcomeJourneyTip } from '../../../core-welcome-journey'
import { WelcomeJourneyTarget } from '../../constants'

export const useWelcomeJourney = () => {
  const { formatMessage } = useIntl()

  const tips: WelcomeJourneyTip[] = useMemo(
    () => [
      {
        title: formatMessage({
          id: 'welcomeJourney.home.title',
          defaultMessage: 'Home',
        }),
        description: formatMessage({
          id: 'welcomeJourney.home.description',
          defaultMessage: 'Provides a summary of your portfolio, activity and markets',
        }),

        target: `.${WelcomeJourneyTarget.Home}`,
        placement: 'right-start',
        icon: 'LogoRevolut',
        route: getRouteUrl({ path: RoutePath.Home }),
      },
      {
        title: formatMessage({
          id: 'welcomeJourney.trade.title',
          defaultMessage: 'Trade',
        }),
        description: formatMessage({
          id: 'welcomeJourney.trade.description',
          defaultMessage:
            'Use the trading workspace to trade with advanced trading tools',
        }),
        target: `.${WelcomeJourneyTarget.Trade}`,
        placement: 'right-start',
        icon: 'ArrowRates',
        route: getRouteUrl({ path: RoutePath.Trade }),
      },
      {
        title: formatMessage({
          id: 'welcomeJourney.selectCurrencyPair.title',
          defaultMessage: 'Select currency pair',
        }),
        description: formatMessage({
          id: 'welcomeJourney.selectCurrencyPair.description.v2',
          defaultMessage: 'Switch between currency pairs available to trade',
        }),
        target: `.${WelcomeJourneyTarget.SelectCurrencyPair}`,
        placement: 'bottom-start',
        icon: 'RatesConverter',
        spotlightCustomStyles: {
          borderRadius: Token.radius.round,
        },
      },
      {
        title: formatMessage({
          id: 'welcomeJourey.buyAndSellCrypto.title',
          defaultMessage: 'Buy and sell crypto',
        }),
        description: formatMessage({
          id: 'welcomeJourney.buyAndSellCrypto.description',
          defaultMessage: 'Use the order form to place your limit and market orders',
        }),
        target: `.${WelcomeJourneyTarget.BuyAndSellCrypto}`,
        placement: 'left-start',
        icon: 'Form',
      },
      {
        title: formatMessage({
          id: 'welcomeJourney.portfolio.title',
          defaultMessage: 'Portfolio',
        }),
        description: formatMessage({
          id: 'welcomeJourney.portfolio.description',
          defaultMessage: 'View detailed investment breakdown and analytics',
        }),
        target: `.${WelcomeJourneyTarget.Portfolio}`,
        placement: 'right-start',
        icon: 'Coins',
        route: getRouteUrl({ path: RoutePath.Portfolio }),
      },
      {
        title: formatMessage({
          id: 'welcomeJourney.depositAndWithdraw.title',
          defaultMessage: 'Deposit and withdraw',
        }),
        description: formatMessage({
          id: 'welcomeJourney.depositAndWithdraw.description',
          defaultMessage: 'Deposit and withdraw fiat and crypto to and from Revolut X',
        }),
        target: `.${WelcomeJourneyTarget.DepositAndWithdraw}`,
        placement: 'left-start',
        icon: 'ArrowTopDown',
        spotlightCustomStyles: {
          borderRadius: Token.radius.round,
        },
      },
      {
        title: formatMessage({
          id: 'welcomeJourney.activity.title',
          defaultMessage: 'Activity',
        }),
        description: formatMessage({
          id: 'welcomeJourney.activity.description',
          defaultMessage:
            'Keep track of all your orders, trades and transfers in one place',
        }),
        target: `.${WelcomeJourneyTarget.Activity}`,
        placement: 'right-start',
        icon: 'ArrowTopDown',
        route: getRouteUrl({ path: RoutePath.Activity }),
      },
      {
        title: formatMessage({
          id: 'welcomeJourney.thatsIt.title',
          defaultMessage: "That's it!",
        }),
        description: formatMessage({
          id: 'welcomeJourney.thatsIt.description',
          defaultMessage: 'You can rewatch this tutorial at any time from your profile',
        }),
        target: `[data-rui-part="top-nav-profile"]`,
        placement: 'left-start',
        icon: 'Cleaning',
        route: getRouteUrl({ path: RoutePath.Home }),
      },
    ],
    [formatMessage],
  )

  const messages: IntlMessages = useMemo(
    () => ({
      'actions.done': formatMessage({
        id: 'actions.done',
        defaultMessage: 'Done',
      }),
      'actions.next': formatMessage({
        id: 'actions.next',
        defaultMessage: 'Next',
      }),
    }),
    [formatMessage],
  )

  return { tips, messages }
}
