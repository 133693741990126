import { textChain } from '@revolut/ui-kit'
import { FC, useMemo } from 'react'
import { Helmet, HelmetProps } from 'react-helmet'

import { formatCurrencyPair, useCurrencyPairRequired } from '../../../core-api'
import {
  CurrencyPairProvider,
  useHumanizeCurrency,
  useTickerPrice,
} from '../../../core-shared'
import { useMoneyFormat } from '../../../core-ui'

interface CurrencyPairHelmetInnerProps extends HelmetProps {
  currencyPair: string
}

const CurrencyPairHelmetInner: FC<CurrencyPairHelmetInnerProps> = ({
  currencyPair,
  ...rest
}) => {
  const { data: price, status } = useTickerPrice(currencyPair)

  const { baseCurrency, quoteCurrency } = useCurrencyPairRequired(currencyPair)

  const { humanizeCurrency } = useHumanizeCurrency()

  const humanizedBaseCurrency = humanizeCurrency(baseCurrency)
  const humanizedQuoteCurrency = humanizeCurrency(quoteCurrency)

  const formattedCurrencyPair = formatCurrencyPair(
    {
      baseCurrency: humanizedBaseCurrency,
      quoteCurrency: humanizedQuoteCurrency,
    },
    '-',
  )

  const formatMoney = useMoneyFormat({
    kind: 'price',
    currency: quoteCurrency,
    clearSymbol: true,
  })

  const title = useMemo(() => {
    const parts = [formattedCurrencyPair]

    if (status === 'success' && price !== undefined) {
      parts.unshift(formatMoney(price))
    }

    return textChain(...parts)
  }, [formattedCurrencyPair, formatMoney, price, status])

  return <Helmet title={title} {...rest} defer={false} />
}

interface CurrencyPairHelmetProps extends CurrencyPairHelmetInnerProps {}

export const CurrencyPairHelmet: FC<CurrencyPairHelmetProps> = ({ currencyPair }) => {
  return (
    <CurrencyPairProvider currencyPair={currencyPair}>
      <CurrencyPairHelmetInner currencyPair={currencyPair} />
    </CurrencyPairProvider>
  )
}
