import { Flex } from '@revolut/ui-kit'
import { FC, useMemo } from 'react'

import { useOrderBook } from '../../../core-api'
import { Feature, useFeatures } from '../../../core-features'
import { CurrencyPairProvider, ErrorStatus } from '../../../core-shared'
import { OrderBookListPosition, OrderBookSide } from '../../constants'
import { useOrderBookSteps } from '../../hooks'
import { formatOrderBook } from '../../utils'
import { OrderBookEmptyCaption } from './OrderBookEmptyCaption'
import { OrderBookHeader } from './OrderBookHeader'
import { OrderBookList } from './OrderBookList'
import { OrderBookMidPrice } from './OrderBookMidPrice'

interface OrderBookProps {
  currencyPair: string
  onSelectPrice?: (price: number) => void
}

export const OrderBook: FC<OrderBookProps> = ({ currencyPair, onSelectPrice }) => {
  const orderBookSteps = useOrderBookSteps({ currencyPair })

  const { isFeatureEnabled } = useFeatures()
  const ws = isFeatureEnabled(Feature.WebSockets)

  const orderBook = useOrderBook(
    {
      currencyPair,
    },
    { ws },
  )

  const { asks, bids } = useMemo(() => {
    if (orderBook.status === 'success' && orderBookSteps.currentStep !== undefined) {
      return formatOrderBook(orderBook.data, orderBookSteps.currentStep)
    }

    return { asks: [], bids: [] }
  }, [orderBook.data, orderBook.status, orderBookSteps.currentStep])

  if (!orderBook.data || orderBook.isLoading) {
    return null
  }

  if (orderBook.status === 'error') {
    return <ErrorStatus onRetry={orderBook.refetch} />
  }

  return (
    <CurrencyPairProvider currencyPair={currencyPair}>
      <Flex flexDirection="column" height="100%" overflow="auto">
        <OrderBookHeader currencyPair={currencyPair} />

        {orderBook.data.empty ? (
          <OrderBookEmptyCaption />
        ) : (
          <>
            <OrderBookList
              currencyPair={currencyPair}
              side={OrderBookSide.Ask}
              data={asks}
              maxAmount={orderBook.data.maxAmount}
              position={OrderBookListPosition.Top}
              onSelectPrice={onSelectPrice}
            />

            <OrderBookMidPrice
              midPrice={orderBook.data.midPrice}
              spread={orderBook.data.spread}
              currencyPair={currencyPair}
              orderBookSteps={orderBookSteps}
            />

            <OrderBookList
              currencyPair={currencyPair}
              side={OrderBookSide.Bid}
              data={bids}
              maxAmount={orderBook.data.maxAmount}
              position={OrderBookListPosition.Bottom}
              onSelectPrice={onSelectPrice}
            />
          </>
        )}
      </Flex>
    </CurrencyPairProvider>
  )
}
