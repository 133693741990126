import {
  CurrencyProviderProps,
  CurrencyProvider as UICurrencyProvider,
} from '@revolut/ui-kit'
import { FC, PropsWithChildren } from 'react'
import { Redirect } from 'react-router-dom'

import {
  CurrencyAssetType,
  CurrencyDetails,
  useCurrencies,
  useCurrenciesDetails,
  useCurrencyPairs,
} from '../../../core-api'
import { useAuthContext } from '../../../core-auth'
import { GlobalSpinner } from '../../../core-ui'
import { RoutePath, getRouteUrl } from '../../../core-utils'

const selectCurrencyDefinitions = (
  data: Record<string, CurrencyDetails>,
): CurrencyProviderProps['currencies'] =>
  Object.values(data).map(({ code, type, symbol, decimals }) => ({
    code,
    symbol,
    fractionalPart: Math.max(decimals, 0),
    crypto: type === CurrencyAssetType.Crypto,
  }))

export const CurrencyProvider: FC<PropsWithChildren> = ({ children }) => {
  const { status } = useAuthContext()

  const currencies = useCurrencies({
    enabled: status === 'authorized',
  })

  const currencyDefinitions = useCurrenciesDetails({
    select: selectCurrencyDefinitions,
    enabled: status === 'authorized',
  })

  const currencyPairs = useCurrencyPairs({
    enabled: status === 'authorized',
  })

  if (status !== 'authorized') {
    return <>{children}</>
  }

  if (
    currencies.status === 'loading' ||
    currencyDefinitions.status === 'loading' ||
    currencyPairs.status === 'loading'
  ) {
    return <GlobalSpinner />
  }

  if (
    currencies.status === 'error' ||
    currencyDefinitions.status === 'error' ||
    currencyPairs.status === 'error'
  ) {
    return <Redirect to={getRouteUrl({ path: RoutePath.Error })} />
  }

  return (
    <UICurrencyProvider currencies={currencyDefinitions.data}>
      {children}
    </UICurrencyProvider>
  )
}
