import { add, isAfter, parseISO } from 'date-fns'
import { useMemo } from 'react'

import { PROMPT_FREQUENT_USER_PREVIOUS_SESSION } from '../config'
import { useKeepPreviousSessionDate } from './useKeepPreviousSessionDate'

export const useIsUserFrequent = () => {
  const previousSessionDate = useKeepPreviousSessionDate()

  const isFrequent = useMemo(() => {
    if (!previousSessionDate) {
      return false
    }

    return isAfter(
      add(parseISO(previousSessionDate), PROMPT_FREQUENT_USER_PREVIOUS_SESSION),
      new Date(),
    )
  }, [previousSessionDate])

  return isFrequent
}
