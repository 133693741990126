import { Flex, FlexProps, Link, Text, Token } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { ConfigKey, getConfigValue } from '../../../core-config'
import { useLayoutSize } from '../../hooks'

const StyledLink = styled(Link)`
  &:hover,
  &:focus {
    color: ${Token.color.accent};
  }
`

export const Disclaimer: FC<FlexProps> = (props) => {
  const layoutSize = useLayoutSize()

  return (
    <Flex
      p="s-16"
      py="s-8"
      bg="grey-tone-10"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Text variant={layoutSize === 'narrow' ? 'tiny' : 'caption'} textAlign="center">
        <FormattedMessage
          id="terminal.disclaimer.fca.title"
          defaultMessage="Don't invest unless you're prepared to lose all the money you invest. This is a
        high-risk investment and you should not expect to be protected if something goes
        wrong."
        />
        &nbsp;
        <StyledLink
          href={getConfigValue(ConfigKey.FCADisclaimerUrl)}
          target="_blank"
          color="foreground"
          fontWeight="bold"
        >
          <FormattedMessage
            id="terminal.disclaimer.fca.link.title"
            defaultMessage="Take 2 mins to learn more."
          />
        </StyledLink>
      </Text>
    </Flex>
  )
}
