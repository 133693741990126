import {
  Image,
  ActionButton,
  Box,
  Grid,
  Text,
  VStack,
  IconButton,
  Token,
} from '@revolut/ui-kit'
import { ForwardedRef, forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { useThemeMode } from '../../../core-ui'
import { AssetProject, getImageAssetSrcProps } from '../../../core-utils'

export type FeedbackPromptTileProps = {
  onConfirm: () => void
  onClose: () => void
}

const BG_GRADIENT =
  'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(80deg, rgba(0, 0, 0, 0.70) 11.84%, rgba(0, 0, 0, 0.00) 80.6%);'

export const FeedbackPromptTile = forwardRef(
  (
    { onClose, onConfirm }: FeedbackPromptTileProps,
    ref?: ForwardedRef<HTMLDivElement>,
  ) => {
    const { resolvedThemeMode: themeMode } = useThemeMode()

    return (
      <Grid
        ref={ref}
        height="186px"
        width="343px"
        radius="widget"
        overflow="hidden"
        backgroundColor="black"
        color={Token.color.white}
      >
        <Image
          gridArea="1 / 1"
          variant="round"
          scale="cover"
          height="186px"
          width="343px"
          {...getImageAssetSrcProps('feedback/prompt-bg', AssetProject.Terminal)}
        />

        <Box gridArea="1 / 1" css={{ background: BG_GRADIENT }} />

        <VStack
          gridArea="1 / 1"
          gap="s-12"
          maxWidth="343px"
          placeSelf="end start"
          padding="s-16"
        >
          <Box maxWidth="220px">
            <Text use="p" variant="primary">
              <FormattedMessage
                id="feedback.prompt.header.title"
                defaultMessage="Help us to improve"
              />
            </Text>

            <Text use="p" variant="caption">
              <FormattedMessage
                id="feedback.prompt.header.description"
                defaultMessage="Your feedback will be helpful to make Revolut X better"
              />
            </Text>
          </Box>

          <ActionButton
            onClick={onConfirm}
            variant={themeMode === 'light' ? 'white' : 'black'}
          >
            <FormattedMessage id="actions.giveFeedback" defaultMessage="Give feedback" />
          </ActionButton>
        </VStack>

        <Box gridArea="1 / 1" opacity={0.4} placeSelf="start end" padding="s-12">
          <IconButton
            useIcon="Cross"
            onClick={onClose}
            color={Token.color.grey5}
            size={16}
          />
        </Box>
      </Grid>
    )
  },
)
