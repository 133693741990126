import { TableFilters } from '@revolut/ui-kit'

import { Order, OrdersFilterParams } from '../../core-api'

export const getOrdersFilterParams = (filters: TableFilters<Order>) =>
  filters.reduce<OrdersFilterParams>((result, filter) => {
    if (filter.id === 'createdDate' && filter.value) {
      result.createdFrom = filter.value.from.getTime()
      result.createdTo = filter.value.to.getTime()
    } else {
      result[filter.id] = filter.value
    }

    return result
  }, {})
