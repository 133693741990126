import { ConfigKey, getConfigValue } from '../../core-config'
import defaultLocaleMessages from '../locales/en.json'

export const importMessages = (locale: string) => {
  const defaultLocale = getConfigValue(ConfigKey.DefaultLocale)

  if (locale === defaultLocale) {
    return Promise.resolve(defaultLocaleMessages)
  }

  return import(
    /* webpackChunkName: "locale-[request]" */
    `../locales/${locale}`
  ).then(({ default: messages }) => messages)
}
