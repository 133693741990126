import {
  Button,
  StatusPopup,
  StatusPopupProps,
  useStatusPopup as useStatusPopupUI,
} from '@revolut/ui-kit'
import { ReactNode, useCallback } from 'react'

type ShowStatusPopupParams = {
  title: ReactNode
  description?: ReactNode
} & Pick<StatusPopupProps, 'onClose'>

export const useStatusPopup = () => {
  const statusPopup = useStatusPopupUI()

  const showLoading = useCallback(() => {
    statusPopup.show(<StatusPopup variant="loading" />)
  }, [statusPopup])

  const showSuccess = ({ title, description, onClose }: ShowStatusPopupParams) => {
    statusPopup.show(
      <StatusPopup variant="success" onClose={onClose}>
        <StatusPopup.Title>{title}</StatusPopup.Title>
        {description && <StatusPopup.Description>{description}</StatusPopup.Description>}
      </StatusPopup>,
    )
  }

  const showError = useCallback(
    ({
      title,
      description,
      buttonText,
      onClose,
    }: ShowStatusPopupParams & { buttonText?: ReactNode }) => {
      statusPopup.show(
        <StatusPopup variant="error" onClose={onClose}>
          <StatusPopup.Title>{title}</StatusPopup.Title>

          {description && (
            <StatusPopup.Description>{description}</StatusPopup.Description>
          )}

          {buttonText && (
            <StatusPopup.Actions>
              <Button
                onClick={() => {
                  statusPopup.hide()

                  onClose?.()
                }}
                elevated
              >
                {buttonText}
              </Button>
            </StatusPopup.Actions>
          )}
        </StatusPopup>,
      )
    },
    [statusPopup],
  )

  return {
    showLoading,
    showSuccess,
    showError,
  }
}
