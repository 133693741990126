import axios from 'axios'

import { NotificationSettings } from '../types'

export const updateUserNotificationSettings = async (
  id: string,
  settings: NotificationSettings,
) => {
  const { data: response } = await axios.patch(
    `/api/crypto-exchange/user/settings/notifications/${id}`,
    settings,
  )

  return response
}
