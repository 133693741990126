import { VStack, Text, TextSkeleton } from '@revolut/ui-kit'
import { last } from 'lodash'
import { FC, useMemo, useState } from 'react'

import { DEFAULT_CURRENCY, TimeSpan, useAnalyticsProfitAndLoss } from '../../../core-api'
import { ErrorStatus, ResolutionMessage } from '../../../core-shared'
import {
  LightweightChart,
  LightweightChartData,
  LightweightChartType,
  useMoneyFormat,
} from '../../../core-ui'
import { getChartTime, getVisibleRange } from '../../utils'
import { TimeSpanTabs } from '../TimeSpanTabs'

export const AnalyticsProfitAndLoss: FC = () => {
  const [timeSpan, setTimeSpan] = useState(TimeSpan['1m'])
  const moneyFormat = useMoneyFormat({ kind: 'total', currency: DEFAULT_CURRENCY })

  const {
    data: profitAndLoss,
    isLoading,
    refetch,
    status,
  } = useAnalyticsProfitAndLoss({
    timeSpan,
  })

  const chartData: LightweightChartData[] = useMemo(
    () => [
      {
        type: LightweightChartType.Area,
        data:
          profitAndLoss?.points.map((point) => ({
            value: parseFloat(point.pnl),
            time: getChartTime(point.timestamp),
          })) ?? [],
      },
    ],
    [profitAndLoss],
  )

  const chartOptions = useMemo(
    () => ({
      localization: {
        priceFormatter: (value: number) => moneyFormat(value),
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
      },
    }),
    [moneyFormat],
  )

  if (status === 'error') {
    return <ErrorStatus onRetry={refetch} />
  }

  const visibleRange = getVisibleRange(chartData)

  return (
    <VStack space="s-16" height="100%" px="s-16" pb="s-16" pt={{ all: 's-16', md: '0' }}>
      <VStack>
        {isLoading ? (
          <TextSkeleton variant="h2" size={8} />
        ) : (
          <Text variant="h2">{moneyFormat(last(chartData[0]?.data)?.value ?? 0)}</Text>
        )}

        <Text variant="caption" color="grey-tone-50" mb="s-16">
          <ResolutionMessage timeSpan={timeSpan} />
        </Text>
      </VStack>

      <LightweightChart
        data={chartData}
        options={chartOptions}
        loading={isLoading}
        visibleRange={visibleRange}
      />

      <TimeSpanTabs value={timeSpan} onChange={setTimeSpan} />
    </VStack>
  )
}
