import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getCurrentUserPicture } from '../../api'
import { QueryKey } from '../../constants'

export const useCurrentUserPicture = <T extends unknown = string>(
  options: UseQueryOptions<string, AxiosError, T> = {},
) =>
  useQuery<string, AxiosError, T>(
    [QueryKey.CurrentUserPicture],
    () => getCurrentUserPicture(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      ...options,
    },
  )
