import { FC } from 'react'

import { useTransfer } from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { SideHeaderSkeleton } from '../../../core-ui'
import { TransferDetailsSideContent } from './TransferDetailsSideContent'
import { TransferDetailsSideHeader } from './TransferDetailsSideHeader'

interface TransferDetailsSideProps {
  transferId: string
}

export const TransferDetailsSide: FC<TransferDetailsSideProps> = ({ transferId }) => {
  const { data: transfer, status, refetch } = useTransfer(transferId)

  if (status === 'loading') {
    return <SideHeaderSkeleton />
  }

  if (status === 'error') {
    return <ErrorStatus onRetry={refetch} />
  }

  return (
    <>
      <TransferDetailsSideHeader
        amount={transfer.amount}
        currency={transfer.assetId}
        date={transfer.createdDate}
        status={transfer.state}
        type={transfer.type}
      />

      <TransferDetailsSideContent transfer={transfer} />
    </>
  )
}
