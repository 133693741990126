import { useCallback } from 'react'

import { checkRequired } from '../../../core-utils'
import { useCurrenciesDetailsRequired } from '../useCurrenciesDetailsRequired'
import { useCurrenciesRequired } from '../useCurrenciesRequired'

export const useCurrencyUnitsConvert = () => {
  const currencies = useCurrenciesRequired()
  const currenciesDetails = useCurrenciesDetailsRequired()

  return useCallback(
    (amount: number, currency: string, unit: 'major' | 'minor') => {
      const scale = checkRequired(
        currencies[currency]?.scale ?? currenciesDetails[currency]?.decimals,
        `Unknown currency ${currency}`,
      )

      const scaleFactor = 10 ** scale

      return unit === 'major' ? amount / scaleFactor : amount * scaleFactor
    },
    [currencies, currenciesDetails],
  )
}
