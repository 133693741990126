import { Redirect, Route, Switch } from 'react-router-dom'

import { getRoutePath, RoutePath } from '../../packlets/core-utils'
import { LimitedAccessPage } from './pages/limited-access'
import { RestrictedAccessPage } from './pages/restricted-access'
import { SignedOutPage } from './pages/signed-out'
import { SignInPage } from './pages/signin'
import { SignInErrorPage } from './pages/signin-error'
import { SignOutPage } from './pages/signout'
import { VerificationPage } from './pages/verification'

export const AuthPages = () => {
  return (
    <Switch>
      <Route path={getRoutePath(RoutePath.AuthSignIn)} component={SignInPage} />
      <Route path={getRoutePath(RoutePath.AuthSignInError)} component={SignInErrorPage} />
      <Route path={getRoutePath(RoutePath.AuthSignOut)} component={SignOutPage} />
      <Route path={getRoutePath(RoutePath.AuthSignedOut)} component={SignedOutPage} />
      <Route
        path={getRoutePath(RoutePath.AuthLimitedAccess)}
        component={LimitedAccessPage}
      />
      <Route
        path={getRoutePath(RoutePath.AuthVerification)}
        component={VerificationPage}
      />
      <Route
        path={getRoutePath(RoutePath.AuthRestrictedAccess)}
        component={RestrictedAccessPage}
      />

      <Redirect to={getRoutePath(RoutePath.Root)} />
    </Switch>
  )
}
