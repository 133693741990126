import { HStack } from '@revolut/ui-kit'
import { FC, PropsWithChildren } from 'react'

import { WidgetTabBarItem } from './WidgetTabBarItem'

const WidgetTabBarRoot: FC<PropsWithChildren> = ({ children }) => {
  return (
    <HStack space="s-16" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
      {children}
    </HStack>
  )
}

export const WidgetTabBar = Object.assign(WidgetTabBarRoot, {
  Item: WidgetTabBarItem,
})
