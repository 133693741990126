import {
  RadioSelect,
  RadioSelectProps,
  SelectOptionItemType,
  SelectType,
  Table,
  TableData,
  TableFilterProps,
} from '@revolut/ui-kit'

import { useIntl } from '../../../core-intl'

export interface TableOptionFilterProps<T extends TableData, Value>
  extends Pick<
    RadioSelectProps<Value, SelectOptionItemType<Value>>,
    'options' | 'searchable'
  > {
  column: TableFilterProps<T>['column']
}

export const TableFilterRadioSelect = <T extends TableData, Value>({
  column,
  options,
  searchable,
  ...rest
}: TableOptionFilterProps<T, Value>) => {
  const { formatMessage } = useIntl()

  return (
    <Table.Filter column={column} aria-haspopup="dialog" {...rest}>
      {(filter) => {
        const filterProps = filter.getRadioSelectProps({
          type: SelectType.CONFIRM,
          options,
          searchable,
          labelList: formatMessage({
            id: 'actions.select',
            defaultMessage: 'Select',
          }),
          labelApply: formatMessage({
            id: 'actions.apply',
            defaultMessage: 'Apply',
          }),
          labelClear: formatMessage({
            id: 'actions.clear',
            defaultMessage: 'Clear',
          }),
          labelNoResults: formatMessage({
            id: 'labels.noResult',
            defaultMessage: 'No results found',
          }),
        })

        return <RadioSelect {...filterProps} />
      }}
    </Table.Filter>
  )
}
