import { TextProps, Text } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface NoFeesLabelProps extends TextProps {}

export const NoFeesLabel: FC<NoFeesLabelProps> = (props) => {
  return (
    <Text {...props}>
      <FormattedMessage id="shared.orders.noFees.label" defaultMessage="No fees" />
    </Text>
  )
}
