import axios from 'axios'

import { CurrencyPairChartParams, CurrencyPairChartResponse } from '../types'

const EXCHANGE_CHART_HISTORY_SOURCE = 'keepr-exchange'

// This api call accepts currency pairs in format
// baseCurrency-quoteCurrency
// i.e. BTC-USD
export const getChartHistory = async ({ pair, ...params }: CurrencyPairChartParams) => {
  const formattedPair = pair.replace('/', '-')

  const { data } = await axios.get<CurrencyPairChartResponse>(
    `/api/crypto-exchange/currencies/${formattedPair}/chart/history`,
    {
      params: {
        source: EXCHANGE_CHART_HISTORY_SOURCE,
        type: 'candlestick',
        ...params,
      },
    },
  )

  // TODO: Temporary fix until https://revolut.atlassian.net/browse/PWCE-1442 will be implemented
  if (data.t.length === 0) {
    throw new Error('End of the chart data')
  }

  return data
}
