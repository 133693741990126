import { TextSkeleton } from '@revolut/ui-kit'
import { isNil } from 'lodash'
import { FC } from 'react'

import { UseMoneyFormatOptions, useMoneyFormat } from '../../hooks'
import { EmptyValue } from '../EmptyValue'

export type MoneyProps = {
  amount: number | undefined | null
  loading?: boolean
} & UseMoneyFormatOptions

export const Money: FC<MoneyProps> = ({ amount, loading, ...rest }) => {
  const formatMoney = useMoneyFormat(rest)

  if (isNil(amount)) {
    return <EmptyValue />
  }

  if (loading) {
    return <TextSkeleton />
  }

  return <>{formatMoney(amount)}</>
}
