import { useIntl } from 'react-intl'

import { Transfer, TransferType } from '../../core-api'
import { DetailsCellGroupItems, Money } from '../../core-ui'
import { TransferStateMessage } from '../components'

export const useTransferDetails = ({ transfer }: { transfer: Transfer }) => {
  const { formatMessage } = useIntl()

  const { state, amount, assetId, type } = transfer

  const transferSign = type === TransferType.WITHDRAWAL ? -1 : 1

  const transferDetails: DetailsCellGroupItems = [
    [
      {
        title: formatMessage({
          id: 'activity.details.status.label',
          defaultMessage: 'Status',
        }),
        content: <TransferStateMessage state={state} />,
      },
    ],
    [
      {
        title: formatMessage({
          id: 'activity.details.amount.label',
          defaultMessage: 'Amount',
        }),
        content: (
          <Money
            amount={amount * transferSign}
            currency={assetId}
            showSign
            type="money-fractional"
          />
        ),
      },
    ],
  ]

  return transferDetails
}
