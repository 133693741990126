import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { FormattedEnumMessage } from '../../../core-intl'
import { ThemePaletteType } from '../../../core-ui'

type ThemePaletteTypeMessageeProps = {
  type: ThemePaletteType
}

const themePaletteTypeMessagees = defineMessages<ThemePaletteType>({
  [ThemePaletteType.Default]: {
    id: 'profile.appearance.palette.default.title',
    defaultMessage: 'Default',
  },
  [ThemePaletteType.Revolut]: {
    id: 'profile.appearance.palette.revolut.tile',
    defaultMessage: 'Revolut classic',
  },
})

export const ThemePaletteTypeMessage: FC<ThemePaletteTypeMessageeProps> = ({ type }) => (
  <FormattedEnumMessage id={type} messages={themePaletteTypeMessagees} />
)
