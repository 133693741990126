import { useState } from 'react'

import { LocalStorageKey, localStorage } from '../../../core-storage'
import { browser } from '../../../core-utils'
import { SsoType } from '../../types'
import {
  FlowType,
  GenerateSsoUrlParams,
  PromptType,
  generateCodeVerifier,
  generateSsoUrl,
} from '../../utils'
import { useAuthContext } from '../useAuthContext'
import { BroadcastChannelMessageType, useBroadcastChannel } from '../useBroadcastChannel'

const POPUP_NAME = 'revolut_exchnage_popup'

const POPUP_WIDTH = 440
const POPUP_HEIGHT = 680

type VerifyParams = {
  state: string
  context: string
  onClose: VoidFunction
}

export const useSsoVerification = () => {
  const [verificationLink, setVerificationLink] = useState<string | null>(null)

  const { ssoUrl, ssoClientId, ssoBusinessClientId, verificationUrl } = useAuthContext()

  const { receiveMessage } = useBroadcastChannel<string>(
    BroadcastChannelMessageType.Verification,
  )

  const verify = async (
    params: VerifyParams,
  ): Promise<{
    code: string
    codeVerifier: string
  }> => {
    const { state, context, onClose } = params

    const codeVerifier = generateCodeVerifier()

    localStorage.setItem(LocalStorageKey.SsoCodeVerifier, codeVerifier)

    const ssoType = localStorage.getItem<SsoType>(LocalStorageKey.SsoType)

    const ssoUrlParams: GenerateSsoUrlParams = {
      clientId: ssoType === 'business' ? ssoBusinessClientId : ssoClientId,
      url: ssoUrl,
      codeVerifier,
      redirectUri: verificationUrl,
      flow: FlowType.SignInOnly,
      prompt: PromptType.Silent,
      state,
      acrValues: context,
    }

    const link = generateSsoUrl(ssoUrlParams)

    setVerificationLink(link)

    browser.openPopup(
      link,
      POPUP_NAME,
      {
        width: POPUP_WIDTH,
        height: POPUP_HEIGHT,
      },
      {
        onClose,
      },
    )

    return receiveMessage()
      .then((code) => {
        localStorage.setItem(LocalStorageKey.SsoCodeVerifier, '')

        return {
          code,
          codeVerifier,
        }
      })
      .finally(() => {
        setVerificationLink(null)
      })
  }

  return { verify, verificationLink }
}
