import axios from 'axios'
import Big from 'big.js'
import qs from 'qs'

import { Ticker } from '../types'

export const getTickers = async (currencyPairs?: string[]) => {
  const { data } = await axios.get<Omit<Ticker, 'mid'>[]>(
    '/api/crypto-exchange/tickers',
    {
      params: {
        pair: currencyPairs,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  )

  const tickers: Ticker[] = data.map((ticker) => ({
    ...ticker,
    mid: Big(ticker.ask).plus(ticker.bid).div(2).toString(),
  }))

  return tickers
}
