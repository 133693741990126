import {
  Action,
  ActionButton,
  Bar,
  Box,
  Header,
  Subheader,
  TabBar,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import {
  CurrencyAssetType,
  DEFAULT_CURRENCY,
  formatCurrencyPair,
  useStableSortedBalances,
} from '../../../core-api'
import { useIntl } from '../../../core-intl'
import {
  AssetAvatar,
  CurrencyName,
  ErrorStatus,
  useHumanizeCurrency,
  useHumanizeCurrencyPair,
} from '../../../core-shared'
import { GroupWidget, SideHeaderSkeleton } from '../../../core-ui'
import { RoutePath, getRouteUrl } from '../../../core-utils'
import {
  ActivityType,
  OrderHistoryGroup,
  OrdersGroup,
  TradesGroup,
  TransfersGroup,
} from '../../../feature-activity'
import { BalanceDetails } from '../BalanceDetails'
import { DepositButton } from '../DepositButton'
import { WithdrawButton } from '../WithdrawButton'

const ITEM_LIMIT = 3

interface BalanceDetailsSideProps {
  currency: string
  onClose: VoidFunction
}

export const BalanceDetailsSide: FC<BalanceDetailsSideProps> = ({
  currency,
  onClose,
}) => {
  const { formatMessage } = useIntl()

  const { humanizeCurrency } = useHumanizeCurrency()

  const humanizedCurrency = humanizeCurrency(currency)

  const { humanizeCurrencyPair } = useHumanizeCurrencyPair()

  const balances = useStableSortedBalances()

  const [activityType, setActivityType] = useState<
    ActivityType.OpenOrders | ActivityType.OrdersHistory
  >(ActivityType.OpenOrders)

  const balance = balances.data?.find((b) => b.currency === currency)

  useEffect(() => {
    if (balances.status === 'success' && balance === undefined) {
      onClose()
    }
  }, [balances.status, balance, onClose])

  if (balances.status === 'loading' || !balance) {
    return <SideHeaderSkeleton />
  }

  if (balances.status === 'error') {
    return <ErrorStatus onRetry={balances.refetch} />
  }

  const isCrypto = balance.assetType === CurrencyAssetType.Crypto

  const currencyPair = formatCurrencyPair(
    {
      baseCurrency: currency,
      quoteCurrency: DEFAULT_CURRENCY,
    },
    '/',
  )

  const humanizedCurrencyPair = humanizeCurrencyPair(currencyPair)

  const tradeUrl = getRouteUrl({
    path: RoutePath.Trade,
    params: {
      currencyPair: humanizedCurrencyPair,
    },
  })

  return (
    <>
      <Header variant="item">
        <Header.CloseButton onClick={onClose} />

        <Header.Avatar>
          <AssetAvatar asset={currency} />
        </Header.Avatar>

        <Header.Title>
          <CurrencyName currency={currency} />
        </Header.Title>

        <Header.Subtitle>{humanizedCurrency}</Header.Subtitle>
      </Header>

      <Bar>
        {isCrypto && (
          <ActionButton variant="accent" useIcon="ArrowRates" use={Link} to={tradeUrl}>
            <FormattedMessage id="actions.trade" defaultMessage="Trade" />
          </ActionButton>
        )}

        <DepositButton currency={currency} />

        <WithdrawButton currency={currency} />
      </Bar>

      <VStack mt="s-24" space="s-16">
        <BalanceDetails px="s-16" balance={balance} />

        {isCrypto && (
          <VStack>
            <Subheader>
              <Subheader.Title>
                <FormattedMessage
                  id="portfolio.balance.side.orders.title"
                  defaultMessage="Orders"
                />
              </Subheader.Title>
              <Subheader.Side>
                <Action
                  use={Link}
                  to={getRouteUrl({
                    path:
                      activityType === ActivityType.OpenOrders
                        ? RoutePath.ActivityOpenOrders
                        : RoutePath.ActivityOrderHistory,
                    queryParams: { currencyPair: humanizedCurrencyPair },
                  })}
                >
                  <FormattedMessage id="actions.seeAll" defaultMessage="See all" />
                </Action>
              </Subheader.Side>
            </Subheader>

            <Widget>
              <Box px="s-16" pt="s-16" pb="s-8">
                <TabBar variant="segmented fit" width="100%">
                  <TabBar.Item
                    use="button"
                    aria-selected={activityType === ActivityType.OpenOrders}
                    onClick={() => setActivityType(ActivityType.OpenOrders)}
                  >
                    <FormattedMessage
                      id="portfolio.balance.side.orders.open.title"
                      defaultMessage="Open"
                    />
                  </TabBar.Item>
                  <TabBar.Item
                    use="button"
                    aria-selected={activityType === ActivityType.OrdersHistory}
                    onClick={() => setActivityType(ActivityType.OrdersHistory)}
                  >
                    <FormattedMessage
                      id="portfolio.balance.side.orders.history.title"
                      defaultMessage="History"
                    />
                  </TabBar.Item>
                </TabBar>
              </Box>

              {activityType === ActivityType.OpenOrders && (
                <OrdersGroup currencyPair={currencyPair} limit={ITEM_LIMIT} />
              )}

              {activityType === ActivityType.OrdersHistory && (
                <OrderHistoryGroup currencyPair={currencyPair} limit={ITEM_LIMIT} />
              )}
            </Widget>
          </VStack>
        )}

        {isCrypto && (
          <GroupWidget
            title={formatMessage({
              id: 'portfolio.balance.side.trades.title',
              defaultMessage: 'Trades',
            })}
            to={getRouteUrl({
              path: RoutePath.ActivityTrades,
              queryParams: { currencyPair: humanizedCurrencyPair },
            })}
            textLink={<FormattedMessage id="actions.seeAll" defaultMessage="See all" />}
          >
            <TradesGroup currencyPair={currencyPair} limit={ITEM_LIMIT} />
          </GroupWidget>
        )}

        <GroupWidget
          title={formatMessage({
            defaultMessage: 'Transfers',
            id: 'portfolio.balance.side.transfers.title',
          })}
          to={getRouteUrl({
            path: RoutePath.ActivityTransfers,
            queryParams: { currency: humanizedCurrency },
          })}
          textLink={<FormattedMessage id="actions.seeAll" defaultMessage="See all" />}
        >
          <TransfersGroup currency={currency} limit={ITEM_LIMIT} />
        </GroupWidget>
      </VStack>
    </>
  )
}
