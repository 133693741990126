import { v4 as uuid } from 'uuid'

import { ConfigKey, getConfigValue } from '../../core-config'
import { CookieStorageKey, rawCookieStorage } from '../../core-storage'

const generateNewDeviceId = () => uuid()

const setDeviceId = (value: string) =>
  rawCookieStorage.setItem(CookieStorageKey.DeviceId, value, {
    domain: getConfigValue(ConfigKey.SharedCookieDomain),
  })

export const getDeviceId = () => {
  let deviceId = rawCookieStorage.getItem(CookieStorageKey.DeviceId)

  if (!deviceId) {
    deviceId = generateNewDeviceId()

    setDeviceId(deviceId)
  }

  return deviceId
}
