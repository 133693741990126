import { Placement } from '@floating-ui/react'

export function getTipMargins(placement: Placement) {
  switch (placement) {
    case 'bottom':
    case 'bottom-end':
    case 'bottom-start':
      return {
        mx: '-s-8',
        mt: 's-16',
      }
    case 'right':
    case 'right-end':
    case 'right-start':
      return {
        mx: 's-16',
        mt: '-s-8',
      }
    case 'left':
    case 'left-end':
    case 'left-start':
      return {
        mx: '-s-16',
        mt: '-s-8',
      }
    case 'top':
    case 'top-end':
    case 'top-start':
      return {
        mx: '-s-8',
        mt: '-s-16',
      }
    default:
      return {
        mt: 'auto',
        mx: 'auto',
      }
  }
}
