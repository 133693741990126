import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getMostOwnedCurrencyPairs } from '../../api'
import { QueryKey } from '../../constants'

export const useMostOwnedCurrencyPairs = <T extends unknown = string[]>(
  options: UseQueryOptions<string[], AxiosError, T> = {},
) =>
  useQuery<string[], AxiosError, T>(
    [QueryKey.MostOwnedCurrencyPairs],
    () => getMostOwnedCurrencyPairs(),
    options,
  )
