import { useContext } from 'react'

import { AuthContext } from '../../providers'

export const useAuthContext = () => {
  const ctx = useContext(AuthContext)

  if (ctx === null) {
    throw new Error(
      'You can access context only in components inside "AuthProvider" component',
    )
  }

  return ctx
}
