import { useState, useEffect, FC } from 'react'
import { createGlobalStyle } from 'styled-components'

const DisableTransitionsStyle = createGlobalStyle`
  * {
    transition: none !important;
  }
`

type DisableTransitionsProps = {
  time: number
  disableKey: string
}

export const DisableTransitions: FC<DisableTransitionsProps> = ({
  time = 0,
  disableKey,
}) => {
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setDisabled(true)

    const timer = setTimeout(() => setDisabled(false), time)

    return () => clearTimeout(timer)
  }, [time, disableKey])

  return disabled ? <DisableTransitionsStyle /> : null
}
