import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getExperiments } from '../../api'
import { QueryKey } from '../../constants'
import { Experiment } from '../../types'

export const useExperiments = <T extends unknown = Experiment[]>(
  options: UseQueryOptions<Experiment[], AxiosError, T> = {},
) =>
  useQuery<Experiment[], AxiosError, T>([QueryKey.Experiments], () => getExperiments(), {
    ...options,
    staleTime: Infinity,
  })
