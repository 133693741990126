import { ChartState } from '@revolut-internal/trading-charts'
import { IDBPDatabase, openDB } from 'idb'
import { useState, useEffect, useCallback } from 'react'

import { checkRequired } from '../../../core-utils'

const DATABASE_NAME = 'Terminal'
const DATABASE_VERSION = 1
const DATABASE_STORE_NAME = 'Charts'

export const useChartState = (id: string) => {
  const [database, setDatabase] = useState<IDBPDatabase | null>(null)

  const [chartState, setChartState] = useState<ChartState>()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    openDB(DATABASE_NAME, DATABASE_VERSION, {
      upgrade(db) {
        db.createObjectStore(DATABASE_STORE_NAME)
      },
    }).then((db) => {
      setDatabase(db)
    })
  }, [])

  useEffect(() => {
    if (!database) {
      return
    }

    const tx = database.transaction(DATABASE_STORE_NAME, 'readonly')

    setLoading(true)

    tx.store
      .get(id)
      .then((state) => {
        setChartState(state)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [database, id])

  const handleChartStateSave = useCallback(
    async (state: ChartState) => {
      const db = checkRequired(database, 'Database is not initialized')

      const tx = db.transaction(DATABASE_STORE_NAME, 'readwrite')

      await tx.store.put(state, id)
    },
    [database, id],
  )

  return {
    chartState,
    loading,
    onChartStateSave: handleChartStateSave,
  }
}
