import { useMemo } from 'react'

import {
  CurrencyPairStatus,
  ExtendedCurrencyPair,
  Pocket,
  StableSortedReturn,
} from '../../types'
import { formatCurrencyPair } from '../../utils'
import { useCurrencyPairsRequired } from '../useCurrencyPairsRequired'
import { useStableSortedData } from '../useStableSortedData'
import { SortingRule } from '../useStableSortedData/useStableSortedData'
import { useTickers } from '../useTickers'
import { useWatchlist } from '../useWatchlist'
import { MARKET_CAP_BY_CURRENCY } from './constants'

export const getPocketsPairs = (pockets: Pocket[], quoteCurrency: string) =>
  pockets.reduce<string[]>((result, pocket) => {
    if (pocket.currency !== quoteCurrency) {
      return result.concat(
        formatCurrencyPair(
          {
            baseCurrency: pocket.currency,
            quoteCurrency,
          },
          '/',
        ),
      )
    }
    return result
  }, [])

const getCurrencyPairId = (item: ExtendedCurrencyPair) => item.pair

export const useStableSortedCurrencyPairs = (
  sortBy: SortingRule<ExtendedCurrencyPair>[],
): StableSortedReturn<Array<ExtendedCurrencyPair>> => {
  const currencyPairs = useCurrencyPairsRequired({ status: CurrencyPairStatus.Active })

  const tickers = useTickers()

  const watchlist = useWatchlist()

  const extentedCurrencyPairs: Array<ExtendedCurrencyPair> = useMemo(() => {
    if (tickers.status === 'success') {
      const watchlistedByPair = watchlist.data?.reduce<Record<string, boolean>>(
        (result, pair) => {
          result[pair.currencyPair] = true

          return result
        },
        {},
      )

      return Object.entries(currencyPairs).map(([currencyPair, pair]) => {
        const ticker = tickers.data[currencyPair]

        const midPrice =
          ticker?.ask && ticker?.bid
            ? (Number(ticker.ask) + Number(ticker.bid)) / 2
            : undefined

        const percentageChange24h = ticker?.percentageChange24h
          ? Number(ticker.percentageChange24h)
          : undefined

        const watchlisted = watchlistedByPair?.[currencyPair] ?? false

        return {
          pair: currencyPair,
          midPrice,
          percentageChange24h,
          watchlisted,
          // TODO: Remove it once we have 24h volume https://revolut.atlassian.net/browse/PWCE-1592
          marketCap: MARKET_CAP_BY_CURRENCY[pair.baseCurrency],
          ...pair,
        }
      })
    }

    return []
  }, [currencyPairs, tickers.data, tickers.status, watchlist.data])

  const data = useStableSortedData<ExtendedCurrencyPair>(
    extentedCurrencyPairs,
    sortBy,
    getCurrencyPairId,
  )

  const status = tickers.status

  const refetch = () => {
    tickers.refetch()
  }

  if (status === 'success') {
    return {
      data,
      status,
    }
  }

  if (status === 'loading') {
    return {
      data: undefined,
      status,
    }
  }

  return {
    data: undefined,
    status,
    refetch,
  }
}
