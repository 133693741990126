import { Page, vh } from '@revolut/ui-kit'
import { FC } from 'react'

import { UserRestrictionType, useUserRestrictionEnabled } from '../../../core-api'
import { MaintenancePlaceholer, useLayoutSize } from '../../../core-shared'
import { EventEmitterProvider } from '../../providers'
import { TerminalHeader } from '../TerminalHeader'
import { TerminalNarrow } from './Terminal.narrow'
import { TerminalWide } from './Terminal.wide'
import { TerminalProps } from './types'

export const Terminal: FC<TerminalProps> = ({ currencyPair }) => {
  const layoutSize = useLayoutSize()

  const { enabled: createOrderDisabled } = useUserRestrictionEnabled({
    type: UserRestrictionType.CREATE_ORDER,
  })

  return (
    <>
      <Page.Header>
        {!createOrderDisabled && <TerminalHeader currencyPair={currencyPair} />}
      </Page.Header>

      <Page.Main
        style={
          layoutSize === 'wide'
            ? { height: `calc(${vh(100)} - ${Page.STICKY_OFFSET})`, isolation: 'unset' }
            : {}
        }
      >
        {createOrderDisabled ? (
          <MaintenancePlaceholer type="trading" />
        ) : (
          <>
            <EventEmitterProvider>
              {layoutSize === 'wide' ? (
                <TerminalWide currencyPair={currencyPair} />
              ) : (
                <TerminalNarrow currencyPair={currencyPair} />
              )}
            </EventEmitterProvider>
          </>
        )}
      </Page.Main>

      <Page.Side behaviour="cover" />
    </>
  )
}
