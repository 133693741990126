import { useCallback } from 'react'

import { FormattedOrderBookItem } from '../utils'

export const useAccumulatedDataFormat = ({
  data,
  isReversed,
}: {
  data: FormattedOrderBookItem[]
  isReversed: boolean
}) =>
  useCallback(
    (index: number) => {
      const accumulatedDataIndex = isReversed ? data.length - 1 - index : index
      const accumulatedDataArray = isReversed ? [...data].reverse() : [...data]

      return accumulatedDataArray.slice(0, accumulatedDataIndex + 1)
    },
    [data, isReversed],
  )
