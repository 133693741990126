import scrollParent from 'scrollparent'

import { scrollDocument } from './scrollDocument'

export function getScrollParent(element: HTMLElement | null) {
  if (!element) {
    return scrollDocument()
  }

  const parent = scrollParent(element) as HTMLElement

  if (parent) {
    if (parent.isSameNode(scrollDocument())) {
      return scrollDocument()
    }

    const hasScrolling = parent.scrollHeight > parent.offsetHeight

    if (!hasScrolling) {
      return scrollDocument()
    }
  }

  return parent
}
