import { Text, VStack, VStackProps } from '@revolut/ui-kit'
import Big from 'big.js'
import { isNumber } from 'lodash'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useCurrencyPairRequired } from '../../../core-api'
import { CurrencyPairMoney } from '../../../core-shared'
import { CondensedDetails, Money, useMoneyFormat } from '../../../core-ui'
import { ExchangeOrderState } from '../../hooks'
import { OrderEstimatedFee } from '../OrderEstimatedFee'

interface OrderSummaryProps extends VStackProps {
  currencyPair: string
  state: ExchangeOrderState
}

const shouldRoundQuantity = (quantity: number | null, lotSize: number) => {
  if (quantity === null) {
    return false
  }

  const bigQuantity = Big(quantity)

  return !bigQuantity.mod(lotSize).eq(0)
}

export const OrderSummary: FC<OrderSummaryProps> = ({ currencyPair, state, px }) => {
  const { quoteCurrency, baseLotSize, baseCurrency } =
    useCurrencyPairRequired(currencyPair)

  const formatMoney = useMoneyFormat({
    clearSymbol: true,
  })

  return (
    <VStack px={px}>
      <CondensedDetails>
        <CondensedDetails.Title>
          <FormattedMessage id="shared.order.quantity.label" defaultMessage="Quantity" />
        </CondensedDetails.Title>
        <CondensedDetails.Content>
          <CurrencyPairMoney
            variant="base"
            amount={state.roundedQuantity ?? 0}
            currencyPair={currencyPair}
          />
        </CondensedDetails.Content>

        {shouldRoundQuantity(state.quantity, baseLotSize) && (
          <CondensedDetails.Note>
            <Text variant="small" color="warning">
              <FormattedMessage
                id="trading.orderForm.summary.amount.rounded.title"
                defaultMessage="Amount rounded to a multiple of {lotSize}"
                values={{
                  lotSize: formatMoney({ amount: baseLotSize, currency: baseCurrency }),
                }}
              />
            </Text>
          </CondensedDetails.Note>
        )}
      </CondensedDetails>

      {isNumber(state.estimatedPrice) && (
        <CondensedDetails>
          <CondensedDetails.Title>
            <FormattedMessage
              id="trading.orderForm.summary.estimatedPrice.title"
              defaultMessage="Estimated price"
            />
          </CondensedDetails.Title>
          <CondensedDetails.Content>
            <CurrencyPairMoney
              kind="price"
              variant="quote"
              amount={state.estimatedPrice}
              currencyPair={currencyPair}
            />
          </CondensedDetails.Content>
        </CondensedDetails>
      )}

      <OrderEstimatedFee
        quoteCurrency={quoteCurrency}
        type={state.type}
        amount={state.amount}
      />

      <CondensedDetails>
        <CondensedDetails.Title>
          <FormattedMessage id="shared.order.value.label" defaultMessage="Value" />
        </CondensedDetails.Title>
        <CondensedDetails.Content>
          <Money
            kind="total"
            currency={quoteCurrency}
            amount={state.roundedAmount ?? 0}
          />
        </CondensedDetails.Content>
      </CondensedDetails>
    </VStack>
  )
}
