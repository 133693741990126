import { useThemePalette } from '../useThemePalette'

export const useProfitAndLossColor = (value: number) => {
  const { palette } = useThemePalette()

  if (value === 0) {
    return palette.zeroColor
  }

  const isNegative = value < 0

  return isNegative ? palette.downColor : palette.upColor
}
