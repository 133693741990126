import axios from 'axios'

type SignInPayload = {
  authCode: string
  codeVerifier: string
}

export const SIGN_IN_URL = '/api/crypto-exchange/sign-in'

export const signIn = async (payload: SignInPayload) => {
  const { data } = await axios.post<void>(SIGN_IN_URL, payload)

  return data
}
