import { GridLayoutTemplateRow } from '../types'

export const resizeGridLayoutRows = (
  rows: GridLayoutTemplateRow[],
  topIndex: number,
  bottomIndex: number,
  dy: number,
): GridLayoutTemplateRow[] => {
  const topRow = rows[topIndex]
  const bottomRow = rows[bottomIndex]

  if (!topRow || !bottomRow) {
    return rows
  }

  let adjustedDy = dy

  if (topRow.type === 'FIXED') {
    if (adjustedDy < 0) {
      adjustedDy = Math.max(topRow.height - topRow.maxHeight, adjustedDy)
    } else {
      adjustedDy = Math.min(topRow.height - topRow.minHeight, adjustedDy)
    }
  }

  if (bottomRow.type === 'FIXED') {
    if (adjustedDy > 0) {
      adjustedDy = Math.min(bottomRow.maxHeight - bottomRow.height, adjustedDy)
    } else {
      adjustedDy = Math.max(bottomRow.minHeight - bottomRow.height, adjustedDy)
    }
  }

  return rows.map((row, index) => {
    if (index === topIndex) {
      if (row.type === 'FIXED') {
        return {
          ...row,
          height: Math.min(
            Math.max(row.height - adjustedDy, row.minHeight),
            row.maxHeight,
          ),
        }
      }
    }

    if (index === bottomIndex) {
      if (row.type === 'FIXED') {
        return {
          ...row,
          height: Math.min(
            Math.max(row.height + adjustedDy, row.minHeight),
            row.maxHeight,
          ),
        }
      }
    }

    return row
  })
}
