import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { AuthSignOutCause } from '../../types'
import { useAuthContext } from '../useAuthContext'

type SsoSignOutOptions = {
  cause: AuthSignOutCause
}

export const useSignOutEffect = ({ cause }: SsoSignOutOptions) => {
  const history = useHistory()

  const { signOut, signedOutUrl, signInUrl } = useAuthContext()

  useEffect(() => {
    signOut(cause).then(() => {
      if (cause === 'user') {
        history.replace(signInUrl)
      } else {
        history.replace(signedOutUrl)
      }
    })
  }, [signOut, cause, signedOutUrl, history, signInUrl])
}
