import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { TransferType } from '../../../core-api'
import { FormattedEnumMessage } from '../../../core-intl'

type TransferTypeMessageProps =
  | {
      variant?: 'default'
      type: TransferType
    }
  | {
      variant: 'currency'
      type: TransferType
      currency: string
    }

const transferTypeMessages = defineMessages<TransferType>({
  [TransferType.DEPOSIT]: {
    id: 'activity.transfer.type.deposit.title',
    defaultMessage: 'Deposit',
  },
  [TransferType.WITHDRAWAL]: {
    id: 'activity.transfer.type.withdrawal.title',
    defaultMessage: 'Withdraw',
  },
})

const transferCurrencyTypeMessages = defineMessages<TransferType>({
  [TransferType.DEPOSIT]: {
    id: 'activity.transfer.type.depositCurrency.title',
    defaultMessage: 'Deposit {currency}',
  },
  [TransferType.WITHDRAWAL]: {
    id: 'activity.transfer.type.withdrawalCurrency.title',
    defaultMessage: 'Withdraw {currency}',
  },
})

export const TransferTypeMessage: FC<TransferTypeMessageProps> = ({
  variant = 'default',
  type,
  ...rest
}) => {
  if (variant === 'currency') {
    return (
      <FormattedEnumMessage
        id={type}
        messages={transferCurrencyTypeMessages}
        values={rest}
      />
    )
  }

  return <FormattedEnumMessage id={type} messages={transferTypeMessages} />
}
