import { useIntl } from 'react-intl'

import { Trade, OrderSide, useCurrencyPairRequired } from '../../core-api'
import { CurrencyPairMoney, NoFeesLabel } from '../../core-shared'
import { DetailsCellGroupItems, Money } from '../../core-ui'
import { getTradesTotal } from '../utils'

export const useTradeDetails = ({ trade }: { trade: Trade }) => {
  const { formatMessage } = useIntl()

  const { currencyPair, price, qty, side, amount, fee, feeCurrency } = trade

  const pair = useCurrencyPairRequired(currencyPair)

  const total = getTradesTotal({
    quoteCurrency: pair.quoteCurrency,
    feeCurrency,
    fee,
    amount,
    price,
  })

  const tradeDetails: DetailsCellGroupItems = [
    [
      {
        title: formatMessage({
          id: 'shared.order.quantity.label',
          defaultMessage: 'Quantity',
        }),
        content: (
          <CurrencyPairMoney variant="base" currencyPair={currencyPair} amount={qty} />
        ),
      },
      {
        title: formatMessage({
          id: 'activity.details.price.label',
          defaultMessage: 'Price',
        }),
        content: (
          <CurrencyPairMoney
            kind="price"
            variant="quote"
            currencyPair={currencyPair}
            amount={price}
          />
        ),
      },
      {
        title: formatMessage({
          id: 'shared.order.value.label',
          defaultMessage: 'Value',
        }),
        content: (
          <Money amount={qty * price} currency={pair.quoteCurrency} kind="total" />
        ),
      },
      {
        title: formatMessage({
          id: 'activity.details.fee.label',
          defaultMessage: 'Fee',
        }),
        content:
          fee === 0 ? <NoFeesLabel /> : <Money currency={feeCurrency} amount={fee} />,
      },
      {
        title: formatMessage({
          id: 'activity.details.received.label',
          defaultMessage: 'Received',
        }),
        content:
          side === OrderSide.Sell ? (
            <Money currency={pair.quoteCurrency} amount={total} kind="total" />
          ) : (
            <Money currency={pair.baseCurrency} amount={qty - fee} />
          ),
      },
    ],
    {
      title: formatMessage({
        id: 'activity.details.tradeId.label',
        defaultMessage: 'Trade ID',
      }),
      content: trade.execId,
    },
  ]

  return tradeDetails
}
