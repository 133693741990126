import { FC } from 'react'

import { useTrade } from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { SideHeaderSkeleton } from '../../../core-ui'
import { TradeDetailsSideContent } from './TradeDetailsSideContent'
import { TradeDetailsSideHeader } from './TradeDetailsSideHeader'

interface TradeDetailsSideProps {
  tradeId: string
}

export const TradeDetailsSide: FC<TradeDetailsSideProps> = ({ tradeId }) => {
  const { data: trade, status, refetch } = useTrade(tradeId)

  if (status === 'loading') {
    return <SideHeaderSkeleton />
  }

  if (status === 'error') {
    return <ErrorStatus onRetry={refetch} />
  }

  const { currencyPair, execDate, side, qty } = trade

  return (
    <>
      <TradeDetailsSideHeader
        currencyPair={currencyPair}
        date={execDate}
        side={side}
        qty={qty}
      />
      <TradeDetailsSideContent trade={trade} />
    </>
  )
}
