import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { replaceOrder } from '../../api'
import { QueryKey, REFETCH_INTERVAL } from '../../constants'
import {
  ReplaceOrderData,
  ReplaceOrderErrorResponse,
  ReplaceOrderResponse,
} from '../../types'

export const useReplaceOrder = (
  options: UseMutationOptions<
    ReplaceOrderResponse,
    AxiosError<ReplaceOrderErrorResponse, ReplaceOrderData>,
    ReplaceOrderData
  > = {},
) => {
  const queryClient = useQueryClient()

  return useMutation<
    ReplaceOrderResponse,
    AxiosError<ReplaceOrderErrorResponse, ReplaceOrderData>,
    ReplaceOrderData
  >(replaceOrder, {
    ...options,
    onSuccess: (...args) => {
      // TODO: Temporary solution until we have a proper WS implementation https://revolut.atlassian.net/browse/PWCE-707
      setTimeout(() => {
        queryClient.invalidateQueries([QueryKey.Balances])
        queryClient.invalidateQueries([QueryKey.Orders])
      }, REFETCH_INTERVAL)

      options.onSuccess?.(...args)
    },
  })
}
