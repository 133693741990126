import { Header, Popup, PopupProps } from '@revolut/ui-kit'
import { FC } from 'react'

import { OrderForm, OrderFormProps } from '../OrderForm'

interface OrderFormPopupProps extends PopupProps, OrderFormProps {
  onClose: VoidFunction
}

export const OrderFormPopup: FC<OrderFormPopupProps> = ({
  initialState,
  currencyPair,
  orderId,
  onResetOrderReplace,
  open,
  onClose,
}) => (
  <Popup open={open} onClose={onClose}>
    <Header variant="compact">
      <Header.CloseButton
        onClick={() => {
          onResetOrderReplace()

          onClose()
        }}
      />
    </Header>

    <OrderForm
      initialState={initialState}
      currencyPair={currencyPair}
      orderId={orderId}
      pb={0}
      px={0}
      style={{ flex: 1 }}
      onResetOrderReplace={onResetOrderReplace}
      onCreateSuccess={onClose}
    />
  </Popup>
)
