import { PaginatedResponse, PaginationParams } from './common'

export enum TransferType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
}

export enum TransferState {
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
}

export type Transfer = {
  id: string
  state: TransferState
  createdDate: number
  updatedDate: number
  clientId: string
  type: TransferType
  assetId: string
  amount: number
  initDate: number
  fee?: number
}

export type TransfersFilterParams = {
  currency?: string
  createdFrom?: number
  createdTo?: number
  type?: TransferType
}

export type TransfersParams = PaginationParams & TransfersFilterParams

export type TransfersReponse = PaginatedResponse<Transfer>
