import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { updateUserNotificationSettings } from '../../api'
import { MARKETING_NOTIFICATION_SETTINGS_KEY, QueryKey } from '../../constants'
import { NotificationSettings } from '../../types'

export const useUpdateUserMarketingNotificationSettings = (
  options: UseMutationOptions<void, AxiosError, NotificationSettings> = {},
) => {
  const queryClient = useQueryClient()

  return useMutation<void, AxiosError, NotificationSettings>(
    async (settings: NotificationSettings) => {
      await updateUserNotificationSettings(MARKETING_NOTIFICATION_SETTINGS_KEY, settings)
    },
    {
      ...options,
      onMutate: async (settings: NotificationSettings) => {
        await queryClient.cancelQueries([QueryKey.UserNotificationSettings])
        queryClient.setQueryData([QueryKey.UserNotificationSettings], () => [
          { id: MARKETING_NOTIFICATION_SETTINGS_KEY, ...settings },
        ])
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKey.UserNotificationSettings])
      },
    },
  )
}
