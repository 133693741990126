export enum Asset3dImageName {
  Shield = '3d-images/3D174',
  NotFound = '3d-images/3D086',
  Checklist = '3d-images/3D021',
  Trading = '3d-images-v2/3D270',
  Orders = '3d-images-v2/3D285',
  OrderHistory = '3d-images-v2/3D303',
  Trades = '3d-images-v2/3D128',
  Transfers = '3d-images-v2/3D095',
  Error = '3d-images-v2/3D018',
  UserSearch = '3d-images-v2/3D028',
  Pending = '3d-images-v2/3D007',
  Verification = '3d-images-v2/3D002',
  Watchlist = '3d-images-v2/3D241',
  Revolut = '3d-images-v2/3D122',
  Gift = '3d-images-v2/3D042',
}
