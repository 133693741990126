import { first } from 'lodash'
import { useMemo } from 'react'

import { TimeSpan } from '../../types'
import { calcTotalBalance, combineStatuses } from '../../utils'
import { useAnalyticsTotalValue } from '../useAnalyticsTotalValue'
import { useStableSortedBalances } from '../useStableSortedBalances'

export const useTotalBalance = () => {
  const balances = useStableSortedBalances()

  const previousTotalValue = useAnalyticsTotalValue(
    {
      timeSpan: TimeSpan['1d'],
    },
    {
      select: (data) => first(data.points),
    },
  )

  const { total, totalProfitAndLoss24h, totalPerformance24h, available, reserved } =
    useMemo(() => {
      if (
        balances.status === 'success' &&
        previousTotalValue.status === 'success' &&
        previousTotalValue.data
      ) {
        return calcTotalBalance(balances.data, previousTotalValue.data)
      }

      return {
        total: 0,
        totalProfitAndLoss24h: 0,
        totalPerformance24h: 0,
        available: 0,
        reserved: 0,
      }
    }, [
      balances.data,
      balances.status,
      previousTotalValue.data,
      previousTotalValue.status,
    ])

  const status = combineStatuses(balances.status, previousTotalValue.status)

  return {
    total,
    available,
    reserved,

    totalProfitAndLoss24h,
    totalPerformance24h,

    status,
  }
}
