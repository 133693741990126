export type DateRange = {
  from: Date
  to: Date
}

export const toUTCDateRange = (range: DateRange): DateRange => {
  const { from, to } = range

  const formattedFrom = new Date(from)

  formattedFrom.setUTCHours(0, 0, 0, 0)

  const formattedTo = new Date(to)

  formattedTo.setUTCHours(23, 59, 59, 999)

  return {
    from: formattedFrom,
    to: formattedTo,
  }
}
