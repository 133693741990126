import { Avatar, Header, Text, useDateTimeFormat, Token } from '@revolut/ui-kit'
import { FC } from 'react'

import { TransferState, TransferType } from '../../../core-api'
import { AssetAvatar } from '../../../core-shared'
import { Money } from '../../../core-ui'
import { TransferSubtitleMessage } from './TransferSubtitleMessage'

interface TransferDetailsSideHeaderProps {
  currency: string
  amount: number
  date: number
  status: TransferState
  type: TransferType
}

export const TransferDetailsSideHeader: FC<TransferDetailsSideHeaderProps> = ({
  amount,
  currency,
  type,
  date,
  status,
}) => {
  const dateTimeFormat = useDateTimeFormat({
    style: 'precise',
  })

  const currencySign = type === TransferType.WITHDRAWAL ? -1 : 1

  return (
    <Header variant="item">
      <Header.CloseButton />

      <Header.Avatar>
        <AssetAvatar asset={currency}>
          {status === TransferState.PENDING && (
            <Avatar.Badge useIcon="16/StatusClockArrows" />
          )}
          {status === TransferState.REJECTED && (
            <Avatar.Badge backgroundColor={Token.color.red} useIcon="Cross" />
          )}
        </AssetAvatar>
      </Header.Avatar>

      <Header.Title>
        {status === TransferState.REJECTED ? (
          <Text textDecoration="line-through">
            <Money
              amount={amount * currencySign}
              currency={currency}
              showSign
              type="money-fractional"
            />
          </Text>
        ) : (
          <Money
            amount={amount * currencySign}
            currency={currency}
            showSign
            type="money-fractional"
          />
        )}
      </Header.Title>

      <Header.Subtitle>
        <TransferSubtitleMessage type={type} currency={currency} />
      </Header.Subtitle>

      <Header.Description>{dateTimeFormat(date)}</Header.Description>
    </Header>
  )
}
