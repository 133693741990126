import { Group, ItemSkeleton } from '@revolut/ui-kit'
import { FC } from 'react'

export const GroupSkeleton: FC = () => (
  <Group>
    <ItemSkeleton />
    <ItemSkeleton />
    <ItemSkeleton />
  </Group>
)
