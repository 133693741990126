export enum ActivityType {
  OpenOrders = 'OpenOrders',
  OrdersHistory = 'OrdersHistory',
  Trades = 'Trades',
  Transfers = 'Transfers',
}

export const ACTIVITY_TYPE_ORDER = [
  ActivityType.OpenOrders,
  ActivityType.OrdersHistory,
  ActivityType.Trades,
  ActivityType.Transfers,
]
