import { Avatar, Group, Item, Token } from '@revolut/ui-kit'
import { isEmpty } from 'lodash'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useTrades } from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { GroupSkeleton } from '../../../core-ui'
import { getImageAssetSrcProps, Asset3dImageName } from '../../../core-utils'
import { TradesGroupItem } from './TradesGroupItem'

interface TradesGroupProps {
  currencyPair?: string
  limit?: number
  onItemClick?: (id: string) => void
}

export const TradesGroup: FC<TradesGroupProps> = ({
  currencyPair,
  limit,
  onItemClick,
}) => {
  const trades = useTrades({
    currencyPair,
    limit,
  })

  if (trades.status === 'loading') {
    return <GroupSkeleton />
  }

  if (trades.status === 'error') {
    return <ErrorStatus onRetry={trades.refetch} />
  }

  if (isEmpty(trades.data.items)) {
    return (
      <Item>
        <Item.Avatar>
          <Avatar
            variant="brand"
            bg={Token.color.widgetBackground}
            size={40}
            image={getImageAssetSrcProps(Asset3dImageName.Trades).src}
          />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="activity.trades.group.empty.title"
              defaultMessage="Your trades will appear here"
            />
          </Item.Title>
        </Item.Content>
      </Item>
    )
  }

  return (
    <Group>
      {trades.data.items.map((item) => (
        <TradesGroupItem key={item.execId} trade={item} onClick={onItemClick} />
      ))}
    </Group>
  )
}
