import { ErrorResponse } from './error'

export interface DepositResponse {
  status: string
  transferId: string
}

export enum DepositType {
  Amount = 'AMOUNT',
}

export interface DepositData {
  type: DepositType
  amount: number
  op: string
  sourcePocketId: string
  code?: string
  codeVerifier?: string
  currency?: string
}

export interface DepositErrorResponse extends ErrorResponse {
  state: string
  acceptedLevel: string
}
