import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getBalancesStats } from '../../api'
import { QueryKey } from '../../constants'
import { BalancesStats } from '../../types'

export const useBalancesStats = <T extends unknown = BalancesStats>(
  options: UseQueryOptions<BalancesStats, AxiosError, T> = {},
) =>
  useQuery<BalancesStats, AxiosError, T>(
    [QueryKey.BalancesStats],
    () => getBalancesStats(),
    options,
  )
