import { SelectOptionItemType, TableData, TableFilterProps } from '@revolut/ui-kit'

import { TransferType } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { TableFilterRadioSelect } from '../../../core-shared'
import { TransferTypeMessage } from '../TransferTypeMessage'

interface TableFilterTransferTypeProps<T extends TableData> {
  column: TableFilterProps<T>['column']
}

const OPTIONS_ORDER: TransferType[] = [TransferType.DEPOSIT, TransferType.WITHDRAWAL]

const OPTIONS: SelectOptionItemType<TransferType>[] = OPTIONS_ORDER.map((type) => ({
  key: type,
  label: <TransferTypeMessage type={type} />,
  value: type,
}))

export const TableFilterTransferType = <T extends TableData>({
  column,
}: TableFilterTransferTypeProps<T>) => {
  const { formatMessage } = useIntl()

  return (
    <TableFilterRadioSelect
      aria-label={formatMessage({
        id: 'labels.filterByTransferType',
        defaultMessage: 'Filter by transfer type',
      })}
      column={column}
      options={OPTIONS}
    />
  )
}
