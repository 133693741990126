export enum UserRestrictionType {
  READ = 'READ',
  DEPOSIT = 'DEPOSIT',
  DEPOSIT_CURRENCY = 'DEPOSIT_CURRENCY',
  WITHDRAW = 'WITHDRAW',
  WITHDRAW_CURRENCY = 'WITHDRAW_CURRENCY',
  CREATE_ORDER = 'CREATE_ORDER',
  REPLACE_ORDER = 'REPLACE_ORDER',
  CANCEL_ORDER = 'CANCEL_ORDER',
  CREATE_ORDER_CURRENCY_PAIR = 'CREATE_ORDER_CURRENCY_PAIR',
  REPLACE_ORDER_CURRENCY_PAIR = 'REPLACE_ORDER_CURRENCY_PAIR',
  CANCEL_ORDER_CURRENCY_PAIR = 'CANCEL_ORDER_CURRENCY_PAIR',
}

export type UserRestriction =
  | {
      type:
        | UserRestrictionType.READ
        | UserRestrictionType.DEPOSIT
        | UserRestrictionType.WITHDRAW
        | UserRestrictionType.CREATE_ORDER
        | UserRestrictionType.REPLACE_ORDER
        | UserRestrictionType.CANCEL_ORDER
    }
  | {
      type: UserRestrictionType.DEPOSIT_CURRENCY | UserRestrictionType.WITHDRAW_CURRENCY
      currencies: string[]
    }
  | {
      type:
        | UserRestrictionType.CREATE_ORDER_CURRENCY_PAIR
        | UserRestrictionType.REPLACE_ORDER_CURRENCY_PAIR
        | UserRestrictionType.CANCEL_ORDER_CURRENCY_PAIR
      currencyPairs: string[]
    }
