import { RootProviderProps, TopNav } from '@revolut/ui-kit'
import { AppSwitcher } from '@revolut-internal/app-switcher'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'

import { Disclaimer, WelcomeJourneyTarget } from '../../../packlets/core-shared'
import { RoutePath, getRoutePath, getRouteUrl } from '../../../packlets/core-utils'
import { TopNavProfile } from '../../../packlets/feature-profile'

export const topNav: RootProviderProps['topNav'] = {
  title: 'Revolut X',
  banner: <Disclaimer />,
  logo: (
    <TopNav.Logo
      use={NavLink}
      bg="deep-purple"
      image="https://assets.revolut.com/assets/rev-apps/crypto-exchange.png"
      to={getRoutePath(RoutePath.Home)}
    />
  ),
  profile: <TopNavProfile />,
  appSwitcher: <AppSwitcher />,
  menu: (
    <TopNav.Menu
      collapse="force"
      items={[
        {
          id: RoutePath.Home,
          use: NavLink,
          icon: 'Utilities',
          to: getRouteUrl({ path: RoutePath.Home }),
          title: (
            <FormattedMessage id="shared.sideNav.home.title" defaultMessage="Home" />
          ),
          // @ts-expect-error
          className: WelcomeJourneyTarget.Home,
        },
        {
          id: RoutePath.Trade,
          use: NavLink,
          icon: 'ArrowRates',
          to: getRouteUrl({ path: RoutePath.Trade }),
          title: (
            <FormattedMessage id="shared.sideNav.trade.title" defaultMessage="Trade" />
          ),
          // @ts-expect-error
          className: WelcomeJourneyTarget.Trade,
        },
        {
          id: RoutePath.Portfolio,
          use: NavLink,
          icon: 'Coins',
          to: getRouteUrl({ path: RoutePath.Portfolio }),
          title: (
            <FormattedMessage
              id="shared.sideNav.portfolio.title"
              defaultMessage="Portfolio"
            />
          ),
          // @ts-expect-error
          className: WelcomeJourneyTarget.Portfolio,
        },
        {
          id: RoutePath.Activity,
          use: NavLink,
          icon: 'ArrowTopDown',
          to: getRouteUrl({ path: RoutePath.Activity }),
          title: (
            <FormattedMessage
              id="shared.sideNav.activity.title"
              defaultMessage="Activity"
            />
          ),
          // @ts-expect-error
          className: WelcomeJourneyTarget.Activity,
        },
      ]}
    />
  ),
}
