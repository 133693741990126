import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { FormattedEnumMessage } from '../../../core-intl'
import { WidgetType } from '../../constants'

interface WidgetTypeMessageProps {
  type: WidgetType
}

const widgetTypeMessages = defineMessages<WidgetType>({
  [WidgetType.PriceChart]: {
    id: 'terminal.widgetType.priceChart.title',
    defaultMessage: 'Price chart',
  },
  [WidgetType.OrderBook]: {
    id: 'terminal.widgetType.orderBook.title',
    defaultMessage: 'Order book',
  },
  [WidgetType.OrderForm]: {
    id: 'terminal.widgetType.orderForm.title',
    defaultMessage: 'Order form',
  },
  [WidgetType.Activity]: {
    id: 'terminal.widgetType.activity.title',
    defaultMessage: 'Activity',
  },
})

export const WidgetTypeMessage: FC<WidgetTypeMessageProps> = ({ type }) => (
  <FormattedEnumMessage id={type} messages={widgetTypeMessages} />
)
