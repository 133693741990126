import { ConfigKey } from '../constants'
import { Locale } from '../types'
import { getConfigValue } from './getConfigValue'

const SUPPORTED_LOCALES = getConfigValue<Locale[]>(ConfigKey.SupportedLocales)

export const getLocaleNativeName = (locale: string) => {
  const supportedLocale = SUPPORTED_LOCALES.find((item) => item.locale === locale)

  return supportedLocale?.nativeName ?? locale
}
