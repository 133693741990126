export enum CurrencyAssetType {
  Fiat = 'FIAT',
  Crypto = 'CRYPTO',
}

export enum CurrencyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface Currency {
  scale: number
  assetType: CurrencyAssetType
  status: CurrencyStatus
  symbol: string
  name: string
  code: string
}

export interface CurrenciesResponse<T = Currency> {
  [currency: string]: T
}
