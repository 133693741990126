import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { updateWatchlist } from '../../api'
import { QueryKey } from '../../constants'
import {
  CurrencyAssetType,
  WatchlistData,
  WatchlistItem,
  WatchlistOperationType,
} from '../../types'

export const useUpdateWatchlist = (
  options: UseMutationOptions<void, AxiosError, WatchlistData> = {},
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: updateWatchlist,
    onMutate: async (watchlistData: WatchlistData) => {
      await queryClient.cancelQueries({ queryKey: [QueryKey.Watchlist] })

      const previousWatchlistItems = queryClient.getQueryData([QueryKey.Watchlist])

      const firstOperation = watchlistData.operations[0]

      if (firstOperation && firstOperation.type === WatchlistOperationType.Remove) {
        queryClient.setQueryData(
          [QueryKey.Watchlist],
          (prevWatchlistItems: WatchlistItem[] | undefined) =>
            prevWatchlistItems?.filter(
              (item) => item.currencyPair !== firstOperation.currencyPair,
            ),
        )
      } else {
        queryClient.setQueryData(
          [QueryKey.Watchlist],
          (prevWatchlistItems: WatchlistItem[] | undefined) => {
            const newWatchlistItems = watchlistData.operations.map((operation) => ({
              assetType: CurrencyAssetType.Crypto,
              createdDate: new Date().toISOString(),
              currencyPair: operation.currencyPair,
            }))

            return [...newWatchlistItems.reverse(), ...(prevWatchlistItems ?? [])]
          },
        )
      }

      return { previousWatchlistItems }
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKey.Watchlist])
    },
  })
}
