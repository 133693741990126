import { FC } from 'react'

import { Order, OrderState } from '../../../core-api'
import { DetailsSideContent } from '../../../core-ui'
import { useOrderHistoryDetails } from '../../hooks'
import { LinkedTrades } from './LinkedTrades'

interface OrderHistoryDetailsSideContentProps {
  order: Order
}

export const OrderHistoryDetailsSideContent: FC<OrderHistoryDetailsSideContentProps> = ({
  order,
}) => {
  const items = useOrderHistoryDetails({ order })

  const hasLinkedTrades = order.state !== OrderState.Rejected

  return (
    <>
      <DetailsSideContent items={items} />
      {hasLinkedTrades && <LinkedTrades orderId={order.orderId} />}
    </>
  )
}
