import { Color, GetRowState, Table, TableFilters } from '@revolut/ui-kit'
import { FC, useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Order, useInfiniteOrders } from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { mapTableLoadingState } from '../../../core-utils'
import { getOrdersFilterParams } from '../../utils'
import { OrderActions } from '../OrderActions'
import { COLUMNS_ADVANCED } from './OpenOrdersTable.advanced.columns'
import { COLUMNS_DEFAULT } from './OpenOrdersTable.default.columns'

type OpenOrdersTableProps = {
  maxRows?: number
  selectedRowId?: string | null
  onSelectRow?: (id: string) => void
  variant?: 'default' | 'advanced'
  initialFilters?: TableFilters<Order>
  stickyHeaderTop?: number | string
}

export const OpenOrdersTable: FC<OpenOrdersTableProps> = ({
  maxRows,
  selectedRowId,
  onSelectRow,
  variant,
  initialFilters = [],
  stickyHeaderTop = 0,
}) => {
  const [filters, setFilters] = useState<TableFilters<Order>>(initialFilters)

  const filterParams = useMemo(() => getOrdersFilterParams(filters), [filters])

  const { data, status, fetchNextPage, hasNextPage, refetch } =
    useInfiniteOrders(filterParams)

  const rows = useMemo(
    () => (data?.pages ?? []).flatMap((page) => page.items).slice(0, maxRows),
    [data, maxRows],
  )

  const getRowState: GetRowState<Order> = useCallback(
    ({ value, getContextMenuProps }) => ({
      contextMenu: <OrderActions order={value} {...getContextMenuProps()} />,
      bg: value.orderId === selectedRowId ? Color.BLUE_5 : undefined,
    }),
    [selectedRowId],
  )

  const handleRowClick = (item: Order) => {
    onSelectRow && onSelectRow(item.orderId)
  }

  if (status === 'error') {
    return <ErrorStatus onRetry={refetch} />
  }

  return (
    <Table
      virtual={variant === 'default'}
      rowHeight={variant === 'advanced' ? 'small' : 'large'}
      stickyHeaderTop={stickyHeaderTop}
      lockRightColumnCount={variant === 'advanced' ? 1 : 0}
      columns={variant === 'advanced' ? COLUMNS_ADVANCED : COLUMNS_DEFAULT}
      data={rows}
      loadingState={mapTableLoadingState(status, rows)}
      getRowState={getRowState}
      onRowClick={handleRowClick}
      onRequestMore={fetchNextPage}
      onFilter={setFilters}
      hasMore={hasNextPage}
      autoResetFilters={false}
      manualFilters
      disableSortBy
      disableResizing
      initialState={{ filters }}
      labelEmptyState={
        <FormattedMessage
          id="activity.openOrders.table.empty.title"
          defaultMessage="No open orders"
        />
      }
      labelNoResults={
        <FormattedMessage
          id="activity.openOrders.table.noResults.title"
          defaultMessage="No open orders found"
        />
      }
    />
  )
}
