import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { keyBy } from 'lodash'

import { getQuote } from '../../api'
import { QueryKey } from '../../constants'
import { Quote } from '../../types'
import { formatCurrencyPair } from '../../utils'

export const useQuote = (
  currencyPairs: string[],
  options: UseQueryOptions<Quote[], AxiosError, Record<string, Quote>> = {},
) =>
  useQuery<Quote[], AxiosError, Record<string, Quote>>(
    [QueryKey.Quote, currencyPairs],
    () => getQuote(currencyPairs),
    {
      select: (data) =>
        keyBy(data, (item) =>
          formatCurrencyPair({ baseCurrency: item.from, quoteCurrency: item.to }, '/'),
        ),
      ...options,
    },
  )
