export enum QueryKey {
  AnalyticsPerformance = 'AnalyticsPerformance',
  AnalyticsProfitAndLoss = 'AnalyticsProfitAndLoss',
  AnalyticsValue = 'AnalyticsValue',
  Balances = 'Balances',
  BalancesStats = 'BalancesStats',
  Banners = 'Banners',
  ChartHistory = 'ChartHistory',
  Currencies = 'Currencies',
  CurrenciesDetails = 'CurrenciesDetails',
  CurrencyPairs = 'CurrencyPairs',
  CurrentUser = 'CurrentUser',
  CurrentUserPicture = 'CurrentUserPicture',
  Experiments = 'Experiments',
  Fees = 'Fees',
  MostOwnedCurrencyPairs = 'MostOwnedCurrencyPairs',
  OnboardingStep = 'OnboardingStep',
  Order = 'Order',
  OrderBook = 'OrderBook',
  Orders = 'Orders',
  OrdersHistory = 'OrdersHistory',
  Tickers = 'Tickers',
  Trade = 'Trade',
  Trades = 'Trades',
  TradesByOrderId = 'TradesByOrderId',
  Transfer = 'Transfer',
  Transfers = 'Transfers',
  UserRestrictions = 'UserRestrictions',
  UserNotificationSettings = 'UserNotificationSettings',
  Wallet = 'Wallet',
  Watchlist = 'Watchlist',
  TransferQuotes = 'TransferQuotes',
  Quote = 'Quote',
  DocumentLinks = 'DocumentLinks',
}

export const INFINITE_QUERY_KEY = 'infinite'
