import { PlaceholderImageProps } from '@revolut/ui-kit'

import { ConfigKey, getConfigValue } from '../../core-config'

export enum AssetProject {
  Assets = 'assets',
  Terminal = 'terminal',
  Pigeon = 'published-assets-v3',
}

export const getAssetUrl = (
  name: string,
  project: AssetProject = AssetProject.Assets,
) => {
  return `${getConfigValue(ConfigKey.CdnUrl)}/${project}/${name}`
}

export const getPigeonAssetUrl = (name: string) => {
  return getAssetUrl(name, AssetProject.Pigeon)
}

export const getImageAssetSrcProps = (
  assetName: string,
  assetProject: AssetProject = AssetProject.Assets,
) => {
  const baseAsset = getAssetUrl(assetName, assetProject)

  return {
    src: `${baseAsset}.png`,
    srcSet: `${baseAsset}@2x.png 2x, ${baseAsset}@3x.png 3x`,
  }
}

export const getImageAssetProps = (
  assetName: string,
  assetProject: AssetProject = AssetProject.Assets,
): PlaceholderImageProps['image'] => {
  const baseAsset = getAssetUrl(assetName, assetProject)

  return {
    default: `${baseAsset}.png`,
    '2x': `${baseAsset}@2x.png`,
    '3x': `${baseAsset}@3x.png`,
  }
}
