import { Token, useToggle } from '@revolut/ui-kit'
import { FC, useEffect } from 'react'
import styled from 'styled-components'

import { Widget, WidgetProps } from '../../../core-shared'
import { Z_INDICES } from '../../../core-ui'
import { WidgetType } from '../../constants'
import { useWidgetEvent } from '../../hooks'

const StyledWidget = styled<FC<WidgetProps & { hightlight: boolean }>>(Widget)`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 2px solid;
    border-color: transparent;
    transition: visibility 300ms ease-out, opacity 300ms ease-out,
      border-color 300ms ease-out;
    visibility: ${({ hightlight }) => (hightlight ? 'visible' : 'hidden')};
    opacity: ${({ hightlight }) => (hightlight ? '1' : '0')};
    border-color: ${Token.color.blue};
    z-index: ${Z_INDICES.OVERLAY};
  }
`

const WIDGET_HIGHLIGHT_DURATION = 800

interface TerminalWidgetProps extends WidgetProps {
  type: WidgetType
}

const TerminalWidgetRoot: FC<TerminalWidgetProps> = ({ type, ...rest }) => {
  const { subscribe } = useWidgetEvent()

  const [isHighlighting, toggleIsHighlighting] = useToggle()

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      toggleIsHighlighting(false)
    }, WIDGET_HIGHLIGHT_DURATION)

    const handleHighlightWidget = (widgetType: WidgetType) => {
      if (widgetType === type) {
        clearTimeout(timeoutId)

        toggleIsHighlighting(true)

        timeoutId = setTimeout(() => {
          toggleIsHighlighting(false)
        }, WIDGET_HIGHLIGHT_DURATION)
      }
    }

    const unsubscribe = subscribe('highlightWidget', handleHighlightWidget)

    return () => {
      unsubscribe()

      clearTimeout(timeoutId)
    }
  }, [subscribe, type, toggleIsHighlighting])

  return <StyledWidget gridArea={type} hightlight={isHighlighting} {...rest} />
}

export const TerminalWidget = Object.assign(TerminalWidgetRoot, {
  Header: Widget.Header,
})
