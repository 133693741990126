import { DetailsCell } from '@revolut/ui-kit'
import { FC } from 'react'

export interface DetailsCellGroupItemProps {
  title: string | JSX.Element
  content: string | JSX.Element
  visible?: boolean
}

export const DetailsCellGroupItem: FC<DetailsCellGroupItemProps> = ({
  title,
  content,
  visible = true,
}) => {
  if (!visible) {
    return null
  }

  return (
    <DetailsCell>
      <DetailsCell.Title>{title}</DetailsCell.Title>
      <DetailsCell.Content>{content}</DetailsCell.Content>
    </DetailsCell>
  )
}
