import { FC } from 'react'

import { notReachable } from '../../../core-utils'
import { ActivityType } from '../../constants'
import { OrderDetailsSide } from '../OrderDetailsSide'
import { TradeDetailsSide } from '../TradeDetailsSide'
import { TransferDetailsSide } from '../TransferDetailsSide'

interface ActivityDetailsSideProps {
  type: ActivityType

  id: string
  onClose: VoidFunction
}

export const ActivityDetailsSide: FC<ActivityDetailsSideProps> = ({
  type,
  id,
  onClose,
}) => {
  switch (type) {
    case ActivityType.OpenOrders:
      return <OrderDetailsSide type={type} orderId={id} onClose={onClose} />
    case ActivityType.OrdersHistory:
      return <OrderDetailsSide type={type} orderId={id} onClose={onClose} />
    case ActivityType.Trades:
      return <TradeDetailsSide tradeId={id} />
    case ActivityType.Transfers:
      return <TransferDetailsSide transferId={id} />
    default:
      return notReachable(type)
  }
}
