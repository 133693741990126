import { TableData, TableFilterProps } from '@revolut/ui-kit'
import { useMemo } from 'react'

import { CurrencyPairStatus, useCurrencyPairsRequired } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { TableFilterRadioSelect } from '../../../core-shared'

interface TableFilterCurrencyPairProps<T extends TableData> {
  column: TableFilterProps<T>['column']
  filterCurrencyPairStatus?: CurrencyPairStatus
}

export const TableFilterCurrencyPair = <T extends TableData>({
  column,
  filterCurrencyPairStatus,
}: TableFilterCurrencyPairProps<T>) => {
  const { formatMessage } = useIntl()

  const currencyPairs = useCurrencyPairsRequired({
    status: filterCurrencyPairStatus,
  })

  const options = useMemo(
    () =>
      Object.keys(currencyPairs).map((pair) => ({
        key: pair,
        label: pair,
        value: pair,
      })),
    [currencyPairs],
  )

  return (
    <TableFilterRadioSelect
      aria-label={formatMessage({
        id: 'labels.filterByCurrencyPair',
        defaultMessage: 'Filter by currency pair',
      })}
      column={column}
      options={options}
      searchable
    />
  )
}
