import axios from 'axios'

import { AnalyticsResponse, ValuePoint, AnalyticsParams } from '../types'

export const getAnalyticsValue = async ({ timeSpan }: AnalyticsParams) => {
  const { data } = await axios.get<AnalyticsResponse<ValuePoint>>(
    `/api/crypto-exchange/trading/value/${timeSpan}`,
  )

  return data
}
