import { UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useMemo } from 'react'

import { getOrderBook } from '../../api'
import { QueryKey, REFETCH_INTERVAL } from '../../constants'
import { webSocketService } from '../../services'
import {
  OrderBookDataWS,
  OrderBookExpandedResponse,
  OrderBookParams,
  OrderBookResponse,
} from '../../types'
import { formatMarketData, selectOrderBookExpandedResponse } from '../../utils'

const DEFAULT_ORDER_BOOK_DEPTH = 20

interface OrderBookOptions
  extends Omit<
    UseQueryOptions<OrderBookResponse, AxiosError, OrderBookExpandedResponse>,
    'select'
  > {
  ws: boolean
}

export const useOrderBook = (
  { currencyPair, depth = DEFAULT_ORDER_BOOK_DEPTH }: OrderBookParams,
  { ws = false, ...options }: OrderBookOptions,
) => {
  const queryClient = useQueryClient()

  const memoizedParams = useMemo(
    () => ({
      currencyPair,
      depth,
    }),
    [currencyPair, depth],
  )

  const queryKey = useMemo(() => [QueryKey.OrderBook, memoizedParams], [memoizedParams])

  const query = useQuery<OrderBookResponse, AxiosError, OrderBookExpandedResponse>(
    queryKey,
    () => getOrderBook(memoizedParams),
    {
      ...options,
      select: selectOrderBookExpandedResponse,
      refetchInterval: ws ? false : REFETCH_INTERVAL,
      refetchOnWindowFocus: !ws,
    },
  )

  const updateOrderBookQueryData = useCallback(
    (data: OrderBookDataWS) => {
      queryClient.setQueryData(queryKey, () => {
        return selectOrderBookExpandedResponse(
          formatMarketData({
            asks: data.asks,
            bids: data.bids,
          }),
        )
      })
    },
    [queryClient, queryKey],
  )

  useEffect(() => {
    if (!ws) return undefined

    const subscription = webSocketService.subscribeOrderBook(
      {
        currencyPair: memoizedParams.currencyPair,
        depth: `${memoizedParams.depth}`,
      },
      updateOrderBookQueryData,
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [
    ws,
    memoizedParams.currencyPair,
    memoizedParams.depth,
    queryKey,
    queryClient,
    updateOrderBookQueryData,
  ])

  return query
}
