import { Locale } from '../types'
import { ConfigKey } from './configKey'
import { DEFAULT_ENV, Environment } from './environment'
import supportedLocales from './json/supportedLocales.json'

export type Config = {
  [K in Environment | typeof DEFAULT_ENV]: { [T in ConfigKey]?: any }
}

const SENTRY_DSN =
  'https://a60f7406d9ac45bbad37837c0ded274c@o104379.ingest.sentry.io/4505306887880704'

export const CONFIG: Config = {
  [Environment.Development]: {
    [ConfigKey.SharedCookieDomain]: 'revolut.codes',
    [ConfigKey.SsoClientId]: 'cTlJSmukIUC2YV6yZzst',
    [ConfigKey.SsoBusinessClientId]: 'KQ8uKMcWW3qraSbLjPRU',
    [ConfigKey.SsoUrl]: 'https://sso.revolut.codes',
    [ConfigKey.FeedbackFormId]: 'a7af1694-ab4d-4f5e-ac49-cb21b9a9d116',
    [ConfigKey.FormsUrl]: 'https://forms.revolut.codes',
    [ConfigKey.SupportChatHostname]: 'local.exchange.revolut.codes',

    [ConfigKey.EventTrackerMode]: 'dry-run',
    [ConfigKey.EventTrackerLoggerEnabled]: true,
    [ConfigKey.EventTrackerDebuggerEnabled]: true,
  },
  [Environment.Production]: {
    [ConfigKey.SentryDsn]: SENTRY_DSN,
    [ConfigKey.SharedCookieDomain]: 'revolut.com',
    [ConfigKey.SsoClientId]: 'UcJX8ilz72nJCirUECG0',
    [ConfigKey.SsoBusinessClientId]: 'j6SKtbvwW0J6oZEQkakM',
    [ConfigKey.SsoUrl]: 'https://sso.revolut.com',
    [ConfigKey.FeedbackFormId]: '2d1f2669-305f-414f-8e69-b693d49ff6ef',
    [ConfigKey.FormsUrl]: 'https://forms.revolut.com',
    [ConfigKey.SupportChatHostname]: 'chat.revolut.com',

    [ConfigKey.EventTrackerMode]: 'production',
    [ConfigKey.EventTrackerLoggerEnabled]: false,
    [ConfigKey.EventTrackerDebuggerEnabled]: false,
  },
  [Environment.Test]: {
    [ConfigKey.SentryDsn]: SENTRY_DSN,
    [ConfigKey.SharedCookieDomain]: 'revolut.codes',
    [ConfigKey.SsoClientId]: 'cTlJSmukIUC2YV6yZzst',
    [ConfigKey.SsoBusinessClientId]: 'KQ8uKMcWW3qraSbLjPRU',
    [ConfigKey.SsoUrl]: 'https://sso.revolut.codes',
    [ConfigKey.FeedbackFormId]: 'a7af1694-ab4d-4f5e-ac49-cb21b9a9d116',
    [ConfigKey.FormsUrl]: 'https://forms.revolut.codes',
    [ConfigKey.SupportChatHostname]: 'chat.revolut.codes',

    [ConfigKey.EventTrackerMode]: 'development',
    [ConfigKey.EventTrackerLoggerEnabled]: true,
    [ConfigKey.EventTrackerDebuggerEnabled]: true,
  },
  [DEFAULT_ENV]: {
    [ConfigKey.AuthRefreshTokenInBackground]: true,
    [ConfigKey.AuthRefreshTokenThresholdMilliseconds]: 2.5 * 60 * 1_000,
    [ConfigKey.AuthRefreshTokenTimeoutMilliseconds]: 10 * 1_000,
    [ConfigKey.CdnUrl]: 'https://assets.revolut.com',
    [ConfigKey.ClientVersion]: '100.0',
    [ConfigKey.CookiesPolicyLink]: 'https://www.revolut.com/legal/cookies-policy',
    [ConfigKey.DefaultLocale]: 'en',
    [ConfigKey.FallbackLocale]: 'en',
    [ConfigKey.FCADisclaimerUrl]: 'https://www.revolut.com/legal/crypto-risk-summary/',
    [ConfigKey.HelpCentreUrl]: 'https://help.revolut.com/help/',
    [ConfigKey.SupportChatQueueTag]: 'Category - Crypto',
    [ConfigKey.SupportChatVersion]: '3.6.0',
    [ConfigKey.SupportedLocales]: supportedLocales as Locale[],
    [ConfigKey.TerminalRefetchIntervalMilliseconds]: 3 * 1_000,
  },
}
