import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { FormattedEnumMessage } from '../../../core-intl'
import { EmptyStatusType } from '../../constants'

interface ErrorStatusTitleMessageProps {
  type: EmptyStatusType
}

const errorStatusTitleMessages = defineMessages<EmptyStatusType>({
  [EmptyStatusType.Orders]: {
    id: 'shared.orders.state.error.title',
    defaultMessage: 'No open orders',
  },
  [EmptyStatusType.OrderHistory]: {
    id: 'shared.orderHistory.state.error.title',
    defaultMessage: 'No order history',
  },
  [EmptyStatusType.Trades]: {
    id: 'shared.trades.state.error.title',
    defaultMessage: 'No trades',
  },
  [EmptyStatusType.Transfers]: {
    id: 'shared.transfers.state.error.title',
    defaultMessage: 'No transfers',
  },
  [EmptyStatusType.Balances]: {
    id: 'shared.balances.state.error.title',
    defaultMessage: 'No balances',
  },
  [EmptyStatusType.CurrencyPairs]: {
    id: 'shared.currencyPairs.state.error.title',
    defaultMessage: 'No currency pairs',
  },
})

export const ErrorStatusTitleMessage: FC<ErrorStatusTitleMessageProps> = ({ type }) => {
  return <FormattedEnumMessage id={type} messages={errorStatusTitleMessages} />
}
