import { Overlay, useToggle } from '@revolut/ui-kit'
import * as Sentry from '@sentry/react'
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'

import { Step } from '../components'
import { IntlMessages, WelcomeJourneyContextType, WelcomeJourneyTip } from '../types'
import { formatCurrentStep, waitForElement } from '../utils'

export const WelcomeJourneyContext = createContext<WelcomeJourneyContextType | null>(null)

export type WelcomeJourneyProviderProps = PropsWithChildren<{
  tips: WelcomeJourneyTip[]
  messages: IntlMessages
  elementTimeout?: number
}>

const START_STEP = 0
export const ELEMENT_TIMEOUT_MS = 5000

export const WelcomeJourneyProvider: FC<WelcomeJourneyProviderProps> = ({
  children,
  tips,
  messages,
  elementTimeout = ELEMENT_TIMEOUT_MS,
}) => {
  const history = useHistory()

  const [open, toggleOpen] = useToggle()

  const [currentStep, setCurrentStep] = useState<number>(START_STEP)

  useEffect(
    () =>
      history.listen((_, action) => {
        if (open && action === 'POP') {
          toggleOpen.off()
        }
      }),
    [history, open, toggleOpen],
  )

  const handleStep = useCallback(
    (step: number) => {
      const tip = tips[step]

      if (tip) {
        if (tip.route) {
          history.push(tip.route)
        }

        waitForElement(tip.target, elementTimeout)
          .then(() => {
            setCurrentStep(step)

            if (step === START_STEP) {
              toggleOpen.on()
            }
          })
          .catch(() => {
            toggleOpen.off()

            Sentry.captureException(
              new Error(`Welcome journey target not found: ${tip.target}, step: ${step}`),
            )
          })
      } else {
        toggleOpen.off()

        Sentry.captureException(new Error(`Welcome journey tip not found, step: ${step}`))
      }
    },
    [tips, elementTimeout, history, toggleOpen],
  )

  const handleStart = useCallback(() => {
    handleStep(START_STEP)
  }, [handleStep])

  const handleNextStep = useCallback(() => {
    if (currentStep === tips.length - 1) {
      toggleOpen.off()
    } else {
      handleStep(currentStep + 1)
    }
  }, [currentStep, handleStep, tips, toggleOpen])

  const isLastStep = currentStep === tips.length - 1

  const currentTip = useMemo(() => tips[currentStep], [currentStep, tips])

  const value = useMemo(
    () => ({
      start: handleStart,
      currentTip,
    }),
    [currentTip, handleStart],
  )

  return (
    <WelcomeJourneyContext.Provider value={value}>
      <Overlay
        open={open}
        css={{ mixBlendMode: 'hard-light' }}
        role="presentation"
        overflow="hidden"
        onClose={toggleOpen.off}
        closeOnOverlayClick={false}
        closeOnEsc
      >
        {currentTip && (
          <Step
            {...currentTip}
            currentStep={formatCurrentStep(currentStep + 1, tips.length)}
            onNextStepClick={handleNextStep}
            isLastStep={isLastStep}
            open={open}
            onClose={() => toggleOpen.off()}
            messages={messages}
          />
        )}
      </Overlay>

      {children}
    </WelcomeJourneyContext.Provider>
  )
}
