import { Token, VStack } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { CurrencyAssetType, useStableSortedBalances } from '../../../core-api'
import { isProductionEnv } from '../../../core-config'
import {
  EmptyStatus,
  EmptyStatusType,
  ErrorStatus,
  useLayoutSize,
  Widget,
} from '../../../core-shared'
import { GroupWidget } from '../../../core-ui'
import { BalancesTable } from '../BalancesTable'
import { PortfolioGroup } from '../PortfolioGroup'

type PortfolioWidgetProps = {
  variant: 'default' | 'advanced'
  selectedRowId: string | null | undefined
  onSelectRow: (id: string) => void
}

export const PortfolioWidget: FC<PortfolioWidgetProps> = ({
  variant,
  selectedRowId,
  onSelectRow,
}) => {
  const balances = useStableSortedBalances()
  const { formatMessage } = useIntl()
  const layoutSize = useLayoutSize()
  const productionEnv = isProductionEnv()

  if (
    variant === 'default' &&
    balances.status === 'success' &&
    balances.data.length === 0
  ) {
    return null
  }

  if (!productionEnv && layoutSize === 'narrow') {
    return (
      <GroupWidget
        title={formatMessage({
          defaultMessage: 'Portfolio',
          id: 'home.portfolio.title',
        })}
      >
        <PortfolioGroup onClickItem={(balance) => onSelectRow(balance.currency)} />
      </GroupWidget>
    )
  }

  return (
    <Widget px="s-16" pb="s-16">
      <Widget.Header
        color={variant === 'default' ? Token.color.greyTone50 : undefined}
        title={<FormattedMessage id="home.portfolio.title" defaultMessage="Portfolio" />}
        px="none"
      />

      {balances.status === 'error' && <ErrorStatus onRetry={balances.refetch} />}

      {balances.status === 'success' && balances.data.length === 0 && (
        <EmptyStatus type={EmptyStatusType.Balances} />
      )}

      {((balances.status === 'success' && balances.data.length > 0) ||
        balances.status === 'loading') && (
        <VStack space="s-16">
          <BalancesTable
            variant={variant}
            assetType={CurrencyAssetType.Fiat}
            selectedRowId={selectedRowId}
            onSelectRow={onSelectRow}
          />
          <BalancesTable
            variant={variant}
            expandable={variant === 'default'}
            assetType={CurrencyAssetType.Crypto}
            selectedRowId={selectedRowId}
            onSelectRow={onSelectRow}
          />
        </VStack>
      )}
    </Widget>
  )
}
