import { Box, Layout, StatusWidget } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { EventTrackerHiddenHeader } from '../../../../packlets/core-event-tracker'
import {
  getImageAssetSrcProps,
  Asset3dImageName,
  getRouteUrl,
  RoutePath,
} from '../../../../packlets/core-utils'

export const SignedOutPage = () => {
  const history = useHistory()

  return (
    <Layout>
      <Layout.Main>
        <EventTrackerHiddenHeader screen="authSignedOut" />

        <Box my="auto">
          <StatusWidget>
            <StatusWidget.Image {...getImageAssetSrcProps(Asset3dImageName.Shield)} />
            <StatusWidget.Title>
              <FormattedMessage
                id="auth.signedOut.title"
                defaultMessage="You have been logged out"
              />
            </StatusWidget.Title>
            <StatusWidget.Description>
              <FormattedMessage
                id="auth.signedOut.subtitle"
                defaultMessage="We've logged you out due to inactivity. This is for your security as it will
                protect your account from others accessing it in case you left your computer
                unattended."
              />
            </StatusWidget.Description>

            <StatusWidget.Action
              onClick={() => history.replace(getRouteUrl({ path: RoutePath.AuthSignIn }))}
            >
              <FormattedMessage id="actions.logIn" defaultMessage="Log In" />
            </StatusWidget.Action>
          </StatusWidget>
        </Box>
      </Layout.Main>
    </Layout>
  )
}
