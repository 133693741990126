import { Flex, Link, TextSkeleton, Token, chain } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

import { useDocumentLinks } from '../../../core-api'

export const TopNavProfileFooter = () => {
  const { data, status } = useDocumentLinks()

  return (
    <Flex flexDirection="row" justifyContent="center">
      {status === 'success' ? (
        chain(
          <Link
            color={Token.color.greyTone50}
            href={data.TERMS_AND_CONDITIONS}
            target="_blank"
          >
            <FormattedMessage id="actions.terms" defaultMessage="Terms" />
          </Link>,
          <Link color={Token.color.greyTone50} href={data.TRADING_RULES} target="_blank">
            <FormattedMessage id="actions.tradingRules" defaultMessage="Trading rules" />
          </Link>,
        )
      ) : (
        <TextSkeleton width="s-72" />
      )}
    </Flex>
  )
}
