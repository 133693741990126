import { useContext } from 'react'

import { ChatContext } from '../providers'

export const useChatContext = () => {
  const ctx = useContext(ChatContext)

  if (ctx === null) {
    throw new Error(
      'You can access context only in components inside "ChatProvider" component',
    )
  }

  return ctx
}
