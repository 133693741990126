import { TableData, TableFilterProps } from '@revolut/ui-kit'
import { useMemo } from 'react'

import { useCurrenciesRequired } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { TableFilterRadioSelect, useHumanizeCurrency } from '../../../core-shared'

interface TableFilterCurrencyProps<T extends TableData> {
  column: TableFilterProps<T>['column']
}

export const TableFilterCurrency = <T extends TableData>({
  column,
}: TableFilterCurrencyProps<T>) => {
  const { formatMessage } = useIntl()

  const currencyPairs = useCurrenciesRequired()

  const { humanizeCurrency } = useHumanizeCurrency()

  const options = useMemo(
    () =>
      Object.keys(currencyPairs).map((currency) => ({
        key: currency,
        label: humanizeCurrency(currency),
        value: currency,
      })),
    [humanizeCurrency, currencyPairs],
  )

  return (
    <TableFilterRadioSelect
      aria-label={formatMessage({
        id: 'labels.filterByCurrency',
        defaultMessage: 'Filter by currency',
      })}
      column={column}
      options={options}
      searchable
    />
  )
}
