const randomString = (length: number) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charsetLength = charset.length
  let result = ''

  for (let i = 0; i < length; i++) {
    result += charset[Math.floor(Math.random() * charsetLength)]
  }

  return result
}

export const generateCodeVerifier = () => randomString(64)
