import Big from 'big.js'
import { useMemo } from 'react'

import { DEFAULT_CURRENCY } from '../../constants'
import { Pocket, StableSortedReturn } from '../../types'
import { combineStatuses, formatCurrencyPair } from '../../utils'
import { useCurrencyUnitsConvert } from '../useCurrencyUnitsConvert'
import { usePockets } from '../usePockets'
import { useQuote } from '../useQuote'
import { SortingRule, useStableSortedData } from '../useStableSortedData'

export const getPocketsPairs = (pockets: Pocket[], quoteCurrency: string) =>
  pockets.reduce<string[]>((result, pocket) => {
    if (pocket.currency !== quoteCurrency) {
      return result.concat(
        formatCurrencyPair(
          {
            baseCurrency: pocket.currency,
            quoteCurrency,
          },
          '/',
        ),
      )
    }
    return result
  }, [])

export type ExtendedPocket = Pocket & { amount: number | undefined }

const POCKETS_SORT_BY: SortingRule<ExtendedPocket>[] = [{ id: 'amount', desc: true }]

const getPocketId = (item: Pocket) => item.id

export const useStableSortedPockets = (): StableSortedReturn<Array<ExtendedPocket>> => {
  const pockets = usePockets()

  const convertTo = useCurrencyUnitsConvert()

  const currencyPairs = useMemo(() => {
    if (pockets.status === 'success') {
      return getPocketsPairs(pockets.data, DEFAULT_CURRENCY)
    }

    return []
  }, [pockets.data, pockets.status])

  const quoteEnabled = currencyPairs.length > 0 && pockets.status === 'success'

  const quote = useQuote(currencyPairs, {
    enabled: quoteEnabled,
  })

  const extentedPockets: Array<ExtendedPocket> = useMemo(() => {
    if (
      pockets.status === 'success' &&
      (quoteEnabled ? quote.status === 'success' : true)
    ) {
      return pockets.data.map((pocket) => {
        const pair = formatCurrencyPair(
          {
            baseCurrency: pocket.currency,
            quoteCurrency: DEFAULT_CURRENCY,
          },
          '/',
        )

        const midRate = quote.data?.[pair]?.midRate

        const balanceInMajorUnits = convertTo(pocket.balance, pocket.currency, 'major')

        let amount

        if (midRate !== undefined) {
          amount = Big(midRate).times(balanceInMajorUnits).toNumber()
        }

        if (pocket.currency === DEFAULT_CURRENCY) {
          amount = balanceInMajorUnits
        }

        return {
          amount,
          ...pocket,
        }
      })
    }

    return []
  }, [pockets.data, pockets.status, quote.status, quote.data, convertTo, quoteEnabled])

  const data = useStableSortedData(extentedPockets, POCKETS_SORT_BY, getPocketId)

  const status = quoteEnabled
    ? combineStatuses(pockets.status, quote.status)
    : pockets.status

  const refetch = () => {
    pockets.refetch()
    quote.refetch()
  }

  if (status === 'success') {
    return {
      data,
      status,
    }
  }

  if (status === 'loading') {
    return {
      data: undefined,
      status,
    }
  }

  return {
    data: undefined,
    status,
    refetch,
  }
}
