import { Action, Spacer, Text, VStack } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface ErrorStatusProps {
  onRetry?: VoidFunction
}

export const ErrorStatus: FC<ErrorStatusProps> = ({ onRetry }) => {
  return (
    <VStack space="s-16" align="center" height="100%">
      <Spacer />

      <Text variant="tile" textAlign="center">
        <FormattedMessage
          id="shared.errorStatus.title"
          defaultMessage="Something went wrong"
        />
      </Text>

      {onRetry && (
        <Action onClick={onRetry}>
          <FormattedMessage id="actions.tryAgain" defaultMessage="Try again" />
        </Action>
      )}

      <Spacer />
    </VStack>
  )
}
