import { useContext } from 'react'

import { OnboardingContext } from '../../providers'

export const useOnboardingContext = () => {
  const ctx = useContext(OnboardingContext)

  if (ctx === null) {
    throw new Error(
      'You can access context only in components inside "OnboardingProvider" component',
    )
  }

  return ctx
}
