import { useMutation } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { localStorage, LocalStorageKey } from '../../../core-storage'
import { browser, useQueryParams } from '../../../core-utils'
import { signIn } from '../../api'
import { SsoType } from '../../types'
import { FlowType, generateCodeVerifier, generateSsoUrl, PromptType } from '../../utils'
import { useAuthContext } from '../useAuthContext'

type SsoSignInOptions = {
  redirectUrl: string
  successRedirectUrl: string
  errorRedirectUrl: string
}

const getSsoCodeVerifierKey = (requestId: string) =>
  `${LocalStorageKey.SsoCodeVerifier}-${requestId}` as LocalStorageKey

export const useSsoSignInEffect = ({
  redirectUrl,
  successRedirectUrl,
  errorRedirectUrl,
}: SsoSignInOptions) => {
  const [{ code, requestId, type = 'retail' }] = useQueryParams<{
    code?: string
    requestId?: string
    type?: SsoType
  }>()

  const history = useHistory()

  const { ssoClientId, ssoBusinessClientId, ssoUrl, setStatus } = useAuthContext()

  const handleSignInSuccess = useCallback(() => {
    setStatus('authorized')

    history.replace(successRedirectUrl)
  }, [setStatus, history, successRedirectUrl])

  const handleSignInError = useCallback(() => {
    history.replace(errorRedirectUrl)
  }, [history, errorRedirectUrl])

  const { mutate: signInMutate } = useMutation(signIn, {
    onSuccess: handleSignInSuccess,
    onError: handleSignInError,
  })

  useEffect(() => {
    if (code && requestId) {
      const codeVerifier = localStorage.getItem(getSsoCodeVerifierKey(requestId))

      if (codeVerifier === null) {
        handleSignInError()

        return
      }

      signInMutate({ authCode: code, codeVerifier })
    } else {
      const newRequestId = uuid()

      const codeVerifier = generateCodeVerifier()

      localStorage.setItem(getSsoCodeVerifierKey(newRequestId), codeVerifier)

      localStorage.setItem(LocalStorageKey.SsoType, type)

      browser.navigateTo(
        generateSsoUrl({
          clientId: type === 'business' ? ssoBusinessClientId : ssoClientId,
          url: ssoUrl,
          codeVerifier,
          redirectUri: `${redirectUrl}?requestId=${newRequestId}`,
          flow: FlowType.SignIn,
          prompt: PromptType.SelectAccount,
          acrValues: 'rev:app',
          uiColorScheme: type === 'business' ? 'dark' : undefined,
        }),
      )
    }
  }, [
    code,
    requestId,
    signInMutate,
    handleSignInError,
    ssoUrl,
    redirectUrl,
    type,
    ssoClientId,
    ssoBusinessClientId,
  ])
}
