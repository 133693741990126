import axios from 'axios'

import { OrdersParams, OrdersReponse } from '../types'

export const getOrders = async (params: OrdersParams) => {
  const { data } = await axios.get<OrdersReponse>('/api/crypto-exchange/orders/page', {
    params,
  })

  return data
}
