import { FC } from 'react'

import { Order } from '../../../core-api'
import { DetailsSideContent } from '../../../core-ui'
import { useOrderDetails } from '../../hooks'

interface OpenOrderDetailsSideContentProps {
  order: Order
}

export const OpenOrderDetailsSideContent: FC<OpenOrderDetailsSideContentProps> = ({
  order,
}) => {
  const items = useOrderDetails({ order })

  return <DetailsSideContent items={items} />
}
