import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Widget } from '../../../core-shared'
import { notReachable } from '../../../core-utils'
import { AnalyticsWidgetType } from '../../constants'
import { AnalyticsPerformance } from '../AnalyticsPerformance'
import { AnalyticsProfitAndLoss } from '../AnalyticsProfitAndLoss'
import { AnalyticsTotalValue } from '../AnalyticsTotalValue'

interface AnalyticsWidgetProps {
  type: AnalyticsWidgetType
  height?: number
}

export const AnalyticsWidget: FC<AnalyticsWidgetProps> = ({ type, height = 440 }) => {
  const widgetContent = (() => {
    switch (type) {
      case AnalyticsWidgetType.TotalValue:
        return {
          title: (
            <FormattedMessage
              id="portfolio.analytics.totalValue.title"
              defaultMessage="Total value"
            />
          ),
          tooltip: (
            <FormattedMessage
              id="portfolio.analytics.totalValue.description"
              defaultMessage="The data shows your total portfolio value taking into account all transactions including deposits and withdrawals. Past performance is not a reliable indicator of future results. Data may be subject to delays."
            />
          ),
          content: <AnalyticsTotalValue />,
        }
      case AnalyticsWidgetType.UnrealisedPerformance:
        return {
          title: (
            <FormattedMessage
              id="portfolio.analytics.performance.title"
              defaultMessage="Unrealised performance"
            />
          ),
          tooltip: (
            <FormattedMessage
              id="portfolio.analytics.performance.description"
              defaultMessage="This chart shows historical unrealised performance of your portfolio excluding deposits and withdrawals. Data may be subject to delays."
            />
          ),
          content: <AnalyticsPerformance />,
        }
      case AnalyticsWidgetType.RealisedProfitAndLoss:
        return {
          title: (
            <FormattedMessage
              id="portfolio.analytics.profitAndLoss.title"
              defaultMessage="Realised profit and loss"
            />
          ),
          tooltip: (
            <FormattedMessage
              id="portfolio.analytics.profitAndLoss.description"
              defaultMessage="This chart shows historical realised profits and losses of your trades excluding deposits and withdrawals. Data may be subject to delays."
            />
          ),
          content: <AnalyticsProfitAndLoss />,
        }
      default:
        return notReachable(type)
    }
  })()

  return (
    <Widget flex={1} height={height}>
      <Widget.Header
        title={widgetContent.title}
        tooltip={widgetContent.tooltip}
        display={{ sm: 'none', md: 'flex', all: 'none' }}
      />
      {widgetContent.content}
    </Widget>
  )
}
