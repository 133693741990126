import { Icon, IconProps, Text, Tooltip, TooltipProps, useTooltip } from '@revolut/ui-kit'
import { isNil } from 'lodash'
import { FC, ReactNode } from 'react'

interface InfoTextProps {
  text: ReactNode
  description: ReactNode
  iconSize?: 12 | 16
  iconPosition?: 'left' | 'right'
  iconColor?: IconProps['color']
  tooltipPlacement?: TooltipProps['placement']
}

export const InfoText: FC<InfoTextProps> = ({
  text,
  description,
  iconSize = 12,
  iconPosition = 'left',
  iconColor,
  tooltipPlacement = 'bottom',
}) => {
  const tooltip = useTooltip()

  if (isNil(text)) {
    return null
  }

  if (isNil(description)) {
    return <Text>{text}</Text>
  }

  const margin = iconSize === 12 ? 's-4' : 's-8'

  const icon = (
    <Icon
      name="16/InfoOutline"
      mr={iconPosition === 'left' ? margin : undefined}
      ml={iconPosition === 'right' ? margin : undefined}
      css={{ display: 'inline-flex', verticalAlign: 'middle' }}
      size={iconSize}
      color={iconColor}
      {...tooltip.getAnchorProps()}
    />
  )

  return (
    <>
      {iconPosition === 'left' && icon}

      <Text css={{ verticalAlign: 'middle' }}>{text}</Text>

      {iconPosition === 'right' && icon}

      <Tooltip
        {...tooltip.getTargetProps({ placement: tooltipPlacement })}
        maxWidth={240}
      >
        {description}
      </Tooltip>
    </>
  )
}
