import {
  Box,
  FilterButton,
  HStack,
  RadioSelect,
  Spacer,
  useResizeObserver,
  useToggle,
  Text,
  Token,
  Icon,
} from '@revolut/ui-kit'
import { useCallback, useEffect, useRef } from 'react'
import { FC } from 'react'
import { useIntl } from 'react-intl'

import { DEFAULT_CURRENCY } from '../../../core-api'
import { LocalStorageKey, useLocalStorage } from '../../../core-storage'
import { ALL_PAIRS_FILTER_OPTION } from '../../constants'
import { useQuoteCurrenciesFilterOptions } from '../../hooks'

interface QuoteCurrencyFilterProps {
  onChange: (currency: string) => void
}

export const QuoteCurrencyFilter: FC<QuoteCurrencyFilterProps> = ({ onChange }) => {
  const { formatMessage } = useIntl()

  const [open, toggleOpen] = useToggle()
  const anchorRef = useRef<HTMLButtonElement>(null)
  const size = useResizeObserver(anchorRef)
  const options = useQuoteCurrenciesFilterOptions()

  const [selectedCurrency, setSelectedCurrency] = useLocalStorage(
    LocalStorageKey.TradeMarketsQuoteCurrencyFilter,
    DEFAULT_CURRENCY,
  )

  useEffect(() => {
    onChange(selectedCurrency)
  }, [onChange, selectedCurrency])

  useEffect(() => {
    const isActiveCurrency = options.some((option) => option.value === selectedCurrency)

    if (!isActiveCurrency) {
      setSelectedCurrency(ALL_PAIRS_FILTER_OPTION.value)
    }
  }, [options, selectedCurrency, setSelectedCurrency])

  const handleCurrencyChange = useCallback(
    (currency: string) => {
      const currencyItem = options.find((item) => item.value === currency)

      if (currencyItem) {
        setSelectedCurrency(currencyItem.value)
      }
    },
    [options, setSelectedCurrency],
  )

  const selectedCurrencyLabel =
    selectedCurrency === ALL_PAIRS_FILTER_OPTION.value
      ? formatMessage({ id: 'labels.allPairs', defaultMessage: 'All pairs' })
      : formatMessage(
          {
            id: 'labels.quoteCurrencyPairs',
            defaultMessage: '{currency} pairs',
          },
          {
            currency: selectedCurrency,
          },
        )

  const getOptionLabel = useCallback(
    (value: string) => {
      if (value === ALL_PAIRS_FILTER_OPTION.value) {
        return formatMessage({ defaultMessage: 'All', id: 'labels.all' })
      }

      return value
    },
    [formatMessage],
  )

  if (options.length <= 2) {
    return null
  }

  return (
    <Box flex={1}>
      <FilterButton
        ref={anchorRef}
        onClick={() => toggleOpen(true)}
        width="100%"
        useEndIcon="16/ChevronDownSmall"
        aria-label={formatMessage({
          id: 'labels.quoteCurrency',
          defaultMessage: 'Quote currency',
        })}
      >
        {selectedCurrencyLabel}
      </FilterButton>

      <RadioSelect
        positionStrategy="fixed"
        anchorRef={anchorRef}
        open={open}
        onClose={() => toggleOpen(false)}
        options={options}
        value={selectedCurrency}
        onChange={(value) => value && handleCurrencyChange(value)}
        labelList={formatMessage({
          id: 'labels.quoteCurrencies',
          defaultMessage: 'Quote currencies',
        })}
        indicatorStyle="highlight"
        minWidth={size.width}
        autoClose
      >
        {(option) => {
          return (
            <HStack>
              <Text>{getOptionLabel(option.value)}</Text>
              <Spacer />
              {option.value === selectedCurrency && (
                <Icon name="Check" color={Token.color.accent} />
              )}
            </HStack>
          )
        }}
      </RadioSelect>
    </Box>
  )
}
