import axios from 'axios'

import { TradesReponse } from '../types'

export const getTradesByOrderId = async (id: string, params: { cursor: string }) => {
  const { data } = await axios.get<TradesReponse>(
    `/api/crypto-exchange/orders/${id}/trades`,
    { params },
  )

  return data
}
