import { QueryStatus } from '@tanstack/react-query'

import { CurrencyAssetType, Pocket, TransferQuotes } from '../../../core-api'

export enum DepositFlowStep {
  CurrencySelection = 'CurrencySelection',
  EnterAmount = 'EnterAmount',
  Confirmation = 'Confirmation',
  Verification = 'Verification',
}

export type DepositFlowState = {
  step: DepositFlowStep
  assetType: CurrencyAssetType
  currency: string
  pocket: Pocket | undefined
  amount: number | null
  sizeRatio: number | null
}

export type DepositFlow = {
  state: DepositFlowState

  balanceMessage: string | null
  currencyMessage: string | null
  amountMessage: string | null

  verificationLink: string | null

  quotes: TransferQuotes | undefined

  invalid: boolean
  disabled: boolean
  submitting: boolean
  status: QueryStatus

  handleChangeStep: (step: DepositFlowStep) => void
  handleChangeAmount: (nextAmount: number | null) => void
  handleChangeCurrency: (currency: string) => void
  handleChangePocket: (pocket: Pocket) => void
  handleChangeSize: (nextSizeRatio: number) => void
  handleChangeAssetType: (assetType: CurrencyAssetType) => void
  handleCancelVerification: () => void
  handleSubmit: () => void
}
