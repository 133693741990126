import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { acceptOnboardingMarketingConsent } from '../../api'
import { QueryKey } from '../../constants'

export const useAcceptOnboardingMarketingConsent = (
  options: UseMutationOptions<void, AxiosError, boolean> = {},
) => {
  const queryClient = useQueryClient()

  return useMutation(acceptOnboardingMarketingConsent, {
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries([QueryKey.OnboardingStep])

      options.onSuccess?.(...args)
    },
  })
}
