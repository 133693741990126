import axios from 'axios'

import { convertArrayBufferToBase64 } from '../../core-utils'

const addBase64Prefix = (imageString: string) => `data:image/png;base64,${imageString}`

export const getCurrentUserPicture = async () => {
  const { data } = await axios.get<ArrayBuffer>(
    '/api/crypto-exchange/user/current/picture',
    {
      responseType: 'arraybuffer',
      headers: {
        pragma: 'no-cache',
        'cache-control':
          'no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate',
      },
    },
  )

  return addBase64Prefix(convertArrayBufferToBase64(data))
}
