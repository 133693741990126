import { first, last } from 'lodash'

import { LightweightChartData } from '../../core-ui'

export const getVisibleRange = (chartData: LightweightChartData[]) => {
  if (chartData[0] === undefined) {
    return undefined
  }

  const from = first(chartData[0].data)?.time

  const to = last(chartData[0].data)?.time

  const visibleRange = from !== undefined && to !== undefined ? { from, to } : undefined

  return visibleRange
}
