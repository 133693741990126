import { Action, Color, Flex, Table, VStack, useToggle } from '@revolut/ui-kit'
import { FC, useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  CurrencyAssetType,
  ExtendedBalance,
  SortingRule,
  useStableSortedBalances,
} from '../../../core-api'
import { EmptyStatus, EmptyStatusType, ErrorStatus } from '../../../core-shared'
import { LocalStorageKey, useLocalStorage } from '../../../core-storage'
import { mapTableLoadingState } from '../../../core-utils'
import { REDUCED_ITEMS_COUNT } from '../../constants'
import { createAdvancedColumns } from './BalancesTable.advanced.columns'
import { createColumns } from './BalancesTable.default.columns'

type BalancesTableProps = {
  variant: 'default' | 'advanced'
  assetType?: CurrencyAssetType
  expandable?: boolean

  selectedRowId?: string | null
  onSelectRow?: (id: string) => void
  stickyHeaderTop?: number | string
}

const getRowId = (row: ExtendedBalance) => row.currency

const DEFAULT_SORT_BY: SortingRule<ExtendedBalance>[] = [{ id: 'value', desc: true }]

export const BalancesTable: FC<BalancesTableProps> = ({
  variant,
  assetType,
  expandable = false,

  selectedRowId,
  onSelectRow,
  stickyHeaderTop = 0,
}) => {
  const [showMoreButton, toggleShowMoreButton] = useToggle({ defaultState: expandable })

  let sortByKey = LocalStorageKey.PortfolioBalancesTableSortingRules

  if (assetType) {
    sortByKey =
      assetType === CurrencyAssetType.Fiat
        ? LocalStorageKey.PortfolioFiatBalancesTableSortingRules
        : LocalStorageKey.PortfolioCryptoBalancesTableSortingRules
  }

  const [sortBy, setSortBy] = useLocalStorage<SortingRule<ExtendedBalance>[]>(
    sortByKey,
    DEFAULT_SORT_BY,
  )

  const handleSortBy = useCallback(
    (nextSortBy) => {
      setSortBy(nextSortBy)
    },
    [setSortBy],
  )

  const isAdvancedVariant = variant === 'advanced'

  const balances = useStableSortedBalances(isAdvancedVariant ? sortBy : DEFAULT_SORT_BY)

  const filteredCurrencyPairList = useMemo(
    () =>
      balances.data
        ?.filter((item) =>
          assetType === undefined ? true : item.assetType === assetType,
        )
        .slice(0, showMoreButton ? REDUCED_ITEMS_COUNT : balances.data.length) ?? [],
    [balances.data, assetType, showMoreButton],
  )

  const getRowState = useCallback(
    ({ value }) => ({
      bg: value.currency === selectedRowId ? Color.BLUE_5 : undefined,
    }),
    [selectedRowId],
  )

  const handleRowClick = (item: ExtendedBalance) => {
    onSelectRow && onSelectRow(item.currency)
  }

  const columns = useMemo(
    () =>
      isAdvancedVariant ? createAdvancedColumns(assetType) : createColumns(assetType),
    [isAdvancedVariant, assetType],
  )

  if (balances.status === 'error') {
    return assetType === undefined ? <ErrorStatus onRetry={balances.refetch} /> : null
  }

  if (balances.status === 'success' && !filteredCurrencyPairList.length) {
    return assetType === undefined ? (
      <EmptyStatus type={EmptyStatusType.Balances} />
    ) : null
  }

  return (
    <VStack space="s-16">
      <Table
        rowHeight={isAdvancedVariant ? 'small' : 'large'}
        stickyHeaderTop={stickyHeaderTop}
        columns={columns}
        data={filteredCurrencyPairList}
        loadingState={mapTableLoadingState(balances.status, filteredCurrencyPairList)}
        getRowId={getRowId}
        onSortBy={handleSortBy}
        disableMultiSort
        manualSortBy
        disableResizing
        disableSortBy={!isAdvancedVariant}
        autoResetSortBy={false}
        virtual={false}
        lockLeftColumnCount={isAdvancedVariant && assetType ? 1 : 0}
        initialState={
          isAdvancedVariant
            ? {
                sortBy,
              }
            : undefined
        }
        getRowState={getRowState}
        onRowClick={handleRowClick}
      />

      {expandable &&
        balances.status === 'success' &&
        balances.data.length > REDUCED_ITEMS_COUNT && (
          <Flex justifyContent="center">
            <Action
              onClick={() =>
                showMoreButton ? toggleShowMoreButton(false) : toggleShowMoreButton(true)
              }
            >
              {showMoreButton ? (
                <FormattedMessage id="actions.showMore" defaultMessage="Show more" />
              ) : (
                <FormattedMessage id="actions.showLess" defaultMessage="Show less" />
              )}
            </Action>
          </Flex>
        )}
    </VStack>
  )
}
