import { Absolute, Relative, Skeleton, useResizeObserver } from '@revolut/ui-kit'
import {
  ChartOptions,
  DeepPartial,
  IChartApi,
  ISeriesApi,
  Range,
  Time,
  createChart,
} from 'lightweight-charts'
import { useEffect, useMemo, useRef } from 'react'

import { Z_INDICES } from '../../constants'
import { useComputedStyleUtils, useThemePalette } from '../../hooks'
import { LightweightChartData } from './types'
import { getChartOptions, updateChart } from './utils'

type LightweightChartProps = {
  height?: number
  data: LightweightChartData[]
  options?: DeepPartial<ChartOptions>
  visibleRange?: Range<Time>
  loading?: boolean
  error?: boolean
  errorWidget?: JSX.Element
}

export const LightweightChart = ({
  data,
  height,
  options = {},
  visibleRange,
  loading,
  error,
  errorWidget,
}: LightweightChartProps) => {
  const wrapperElRef = useRef<HTMLDivElement>(null)
  const chartElRef = useRef<HTMLDivElement>(null)

  const computedStyleUtils = useComputedStyleUtils()

  const { computedPalette } = useThemePalette()

  const chartRef = useRef<IChartApi>()
  const seriesRef = useRef<(ISeriesApi<'Area'> | ISeriesApi<'Histogram'>)[]>([])

  const chartOptions = useMemo(
    () => getChartOptions(computedStyleUtils, computedPalette),
    [computedStyleUtils, computedPalette],
  )

  const size = useResizeObserver(wrapperElRef)

  useEffect(() => {
    if (!chartRef.current && chartElRef.current && wrapperElRef.current) {
      chartRef.current = createChart(chartElRef.current, {
        width: wrapperElRef.current.clientWidth,
        height: height ?? wrapperElRef.current.clientHeight,
        ...chartOptions,
        ...options,
      })
    }
  }, [height, options, chartOptions])

  useEffect(() => {
    if (chartRef.current) {
      updateChart(chartRef.current, seriesRef.current, data, computedPalette)
    }
  }, [data, computedPalette])

  useEffect(() => {
    if (visibleRange && chartRef.current) {
      chartRef.current.timeScale().setVisibleRange(visibleRange)
    }
  }, [visibleRange])

  useEffect(() => {
    if (chartRef.current && size) {
      chartRef.current.resize(size.width, size.height)

      chartRef.current.timeScale().fitContent()
    }
  }, [size])

  return (
    <Relative ref={wrapperElRef} height={height ?? '100%'} zIndex={Z_INDICES.CONTENT}>
      <Absolute ref={chartElRef} opacity={loading || error ? 0 : 1} />
      {loading && <Skeleton borderRadius={8} flex={1} height="100%" />}
      {error && errorWidget}
    </Relative>
  )
}
