import { GridLayoutTemplateRow } from '../types'

export const getGridLayoutRowsStyle = (rows: GridLayoutTemplateRow[]) =>
  rows
    .map((row) => {
      if (row.type === 'FIXED') {
        return `${row.height}px`
      }

      if (row.type === 'FLUID') {
        return `minmax(${row.minHeight}px, ${row.ratio}fr)`
      }

      return `${row.width}px`
    })
    .join(' ')
