import { useMemo } from 'react'

import { ExtendedBalance, CurrencyAssetType } from '../../../core-api'

export const useFilteredItems = ({
  items,
  showMoreButton,
  reducedItemsCount,
}: {
  items: ExtendedBalance[]
  showMoreButton: boolean
  reducedItemsCount: number
}) => {
  const fiatItems = useMemo(
    () => items.filter((item) => item.assetType === CurrencyAssetType.Fiat),
    [items],
  )

  const cryptoItems = useMemo(
    () =>
      items
        .filter((item) => item.assetType === CurrencyAssetType.Crypto)
        .slice(0, showMoreButton ? reducedItemsCount : items.length),
    [items, showMoreButton, reducedItemsCount],
  )

  return { fiatItems, cryptoItems }
}
