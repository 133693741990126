import {
  Avatar,
  HStack,
  Header,
  Popover,
  Spacer,
  TopNav,
  useToggle,
} from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import {
  getCurrentUserInitials,
  useCurrentUser,
  useCurrentUserPicture,
} from '../../../core-api'
import { useLayoutSize } from '../../../core-shared'
import { RoutePath, getRouteUrl } from '../../../core-utils'
import { useWelcomeJourneyContext } from '../../../core-welcome-journey'
import { useFeedbackContext } from '../../../feature-feedback'
import { useChatContext } from '../../../feature-support'
import { LanguagePopup } from '../LanguagePopup'
import { TopNavProfileFooter } from './TopNavProfileFooter'

export const TopNavProfile = () => {
  const { start: startWelcomeJourney } = useWelcomeJourneyContext()

  const { openChat } = useChatContext()

  const { open: openFeedbackForm } = useFeedbackContext()

  const layoutSize = useLayoutSize()

  const [languagePopupOpen, toggleLanguagePopupOpen] = useToggle()

  const currentUser = useCurrentUser()

  const hasProfilePicture =
    currentUser.status === 'success' && currentUser.data.hasProfilePicture

  const currentUserPicture = useCurrentUserPicture({
    enabled: hasProfilePicture,
  })

  const initials = currentUser.data && getCurrentUserInitials(currentUser.data)

  const displayName = [currentUser.data?.firstName, currentUser.data?.lastName]
    .filter(Boolean)
    .join(' ')

  const handleClose = () => {
    TopNav.closePopover()
  }

  const handleHelpClick = () => {
    handleClose()
    openChat()
  }

  const handleRevolutXTutorialClick = () => {
    handleClose()
    startWelcomeJourney()
  }

  const handleFeedbackClick = () => {
    handleClose()
    openFeedbackForm()
  }

  const handleLanguageClick = () => {
    handleClose()
    toggleLanguagePopupOpen(true)
  }

  return (
    <>
      <TopNav.Profile
        uuid={currentUser.data?.id}
        image={currentUserPicture.data}
        label={initials}
        footer={<TopNavProfileFooter />}
      >
        <Header variant="item">
          <Header.Title>{displayName}</Header.Title>
          <Header.Subtitle>@{currentUser.data?.username}</Header.Subtitle>
          <Header.Avatar>
            <Avatar
              size={56}
              uuid={currentUser.data?.id}
              image={currentUserPicture.data}
              label={initials}
            />
          </Header.Avatar>
        </Header>

        <Popover.Group>
          <Popover.Item use="button" useIcon="Question" onClick={handleHelpClick}>
            <FormattedMessage id="actions.help" defaultMessage="Help" />
          </Popover.Item>

          <Popover.Item use="button" useIcon="Globe" onClick={handleLanguageClick}>
            <FormattedMessage id="actions.language" defaultMessage="Language" />
          </Popover.Item>

          <Popover.Item
            useIcon="Palette"
            use={Link}
            to={getRouteUrl({ path: RoutePath.ProfileAppearance })}
            onClick={handleClose}
          >
            <FormattedMessage id="profile.appearance.title" defaultMessage="Appearance" />
          </Popover.Item>

          <Popover.Item useIcon="Document" disabled>
            <HStack>
              <span>
                <FormattedMessage
                  id="profile.accountStatement.title"
                  defaultMessage="Account statement"
                />
              </span>
              <Spacer />
              <span>
                <FormattedMessage id="actions.comingSoon" defaultMessage="Coming soon" />
              </span>
            </HStack>
          </Popover.Item>

          <Popover.Item useIcon="Percent" disabled>
            <HStack>
              <span>
                <FormattedMessage
                  id="profile.taxReport.title"
                  defaultMessage="Tax report"
                />
              </span>
              <Spacer />
              <span>
                <FormattedMessage id="actions.comingSoon" defaultMessage="Coming soon" />
              </span>
            </HStack>
          </Popover.Item>

          <Popover.Item
            useIcon="Bell"
            use={Link}
            to={getRouteUrl({ path: RoutePath.NotificationSettings })}
            onClick={handleClose}
          >
            <FormattedMessage
              id="actions.notificationSettings"
              defaultMessage="Notification settings"
            />
          </Popover.Item>

          {layoutSize === 'wide' && (
            <Popover.Item
              use="button"
              useIcon="Library"
              onClick={handleRevolutXTutorialClick}
            >
              <FormattedMessage
                id="actions.revolutXTutorial.title"
                defaultMessage="Revolut X tutorial"
              />
            </Popover.Item>
          )}
        </Popover.Group>

        <Popover.Group>
          <Popover.Item use="button" useIcon="Megaphone" onClick={handleFeedbackClick}>
            <FormattedMessage
              id="actions.feedback.title"
              defaultMessage="Give feedback"
            />
          </Popover.Item>

          <Popover.Item
            useIcon="LogoutDoor"
            color="danger"
            use={Link}
            to={getRouteUrl({ path: RoutePath.AuthSignOut })}
            onClick={handleClose}
          >
            <FormattedMessage id="actions.logOut" defaultMessage="Log out" />
          </Popover.Item>
        </Popover.Group>
      </TopNav.Profile>

      <LanguagePopup
        open={languagePopupOpen}
        onClose={() => toggleLanguagePopupOpen(false)}
      />
    </>
  )
}
