import axios from 'axios'

import { NotificationSettingsResponse } from '../types'

export const getUserNotificationSettings = async () => {
  const { data } = await axios.get<NotificationSettingsResponse>(
    '/api/crypto-exchange/user/settings/notifications',
  )

  return data
}
