import { Table, TableColumn } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

import { Trade, useCurrencyPairRequired } from '../../../core-api'
import { CurrencyPairLink, CurrencyPairMoney } from '../../../core-shared'
import { DateTimeTableCell, Money } from '../../../core-ui'
import { OrderSideTableCell } from '../OrderSideTableCell'

export const COLUMNS_DEFAULT: TableColumn<Trade>[] = [
  {
    Header: <FormattedMessage id="activity.table.asset.caption" defaultMessage="Asset" />,
    accessor: 'currencyPair',
    Cell: ({ value }) => (
      <Table.Cell>
        <CurrencyPairLink currencyPair={value} />
      </Table.Cell>
    ),
  },
  {
    Header: (
      <FormattedMessage
        id="activity.table.timeExecuted.caption"
        defaultMessage="Time executed"
      />
    ),
    accessor: 'execDate',
    Cell: ({ value }) => <DateTimeTableCell date={new Date(value)} />,
  },
  {
    Header: (
      <FormattedMessage
        id="activity.table.direction.caption"
        defaultMessage="Direction"
      />
    ),
    accessor: 'side',
    Cell: ({ value }) => <OrderSideTableCell side={value} />,
  },
  {
    Header: <FormattedMessage id="activity.table.price.caption" defaultMessage="Price" />,
    accessor: 'price',
    Cell: ({ value, row }) => (
      <Table.Cell>
        <CurrencyPairMoney
          kind="price"
          variant="quote"
          currencyPair={row.original.currencyPair}
          amount={value}
        />
      </Table.Cell>
    ),
  },
  {
    Header: (
      <FormattedMessage id="shared.order.quantity.label" defaultMessage="Quantity" />
    ),
    accessor: 'qty',
    align: 'end',
    Cell: ({ value, row }) => (
      <Table.Cell>
        <CurrencyPairMoney
          variant="base"
          currencyPair={row.original.currencyPair}
          amount={value}
        />
      </Table.Cell>
    ),
  },
  {
    Header: <FormattedMessage id="shared.order.value.label" defaultMessage="Value" />,
    id: 'total',
    accessor: ({ amount, fee }) => amount - fee,
    align: 'end',
    Cell: ({ value, row }) => {
      const pair = useCurrencyPairRequired(row.original.currencyPair)

      return (
        <Table.Cell>
          <Money kind="total" currency={pair.quoteCurrency} amount={value} />
        </Table.Cell>
      )
    },
  },
]
