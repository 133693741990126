import { FormattedOrderBookItem } from '../../feature-markets'
import { AccumulatedOrderBookItem } from '../types'

export const getAccumulatedAsks = (asks: FormattedOrderBookItem[]) => {
  return asks
    .sort((a, b) => a.price - b.price)
    .reduce(
      (
        acc: AccumulatedOrderBookItem[],
        current: FormattedOrderBookItem,
        index: number,
      ) => {
        const accumulatedAmount =
          current.amount + (acc[index - 1]?.accumulatedAmount ?? 0)

        acc.push({ ...current, accumulatedAmount })

        return acc
      },
      [],
    )
}
