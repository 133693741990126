import { DetailsCell, HStack, Icon, Tooltip, useTooltip } from '@revolut/ui-kit'
import { FC } from 'react'

import { Money } from '../../../core-ui'

type FeeBreakdownDetailsItemProps = {
  title: string
  amount: number
  currency: string
  description: string
}

export const FeeBreakdownDetailsItem: FC<FeeBreakdownDetailsItemProps> = ({
  title,
  amount,
  currency,
  description,
}) => {
  const tooltipRef = useTooltip()

  return (
    <>
      <DetailsCell>
        <DetailsCell.Title>
          <HStack align="center" gap="s-8">
            <span>{title}</span>
            <Icon name="16/InfoOutline" size={16} {...tooltipRef.getAnchorProps()} />
          </HStack>
        </DetailsCell.Title>
        <DetailsCell.Content>
          <Money type="money-fractional" amount={amount} currency={currency} />
        </DetailsCell.Content>
      </DetailsCell>

      <Tooltip {...tooltipRef.getTargetProps()} width={240} placement="bottom-end">
        {description}
      </Tooltip>
    </>
  )
}
