import { Box, Flex, Token } from '@revolut/ui-kit'
import { FC, useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import { useScrollDetect } from '../../../core-ui'
import { OrderBookListPosition, OrderBookSide } from '../../constants'
import { useAccumulatedDataFormat } from '../../hooks'
import { FormattedOrderBookItem } from '../../utils'
import { OrderBookListItem } from './OrderBookListItem'

interface OrderBookListProps {
  currencyPair: string
  side: OrderBookSide
  data: FormattedOrderBookItem[]
  maxAmount: number
  position: OrderBookListPosition
  onSelectPrice?: (price: number) => void
}

const StyledBox = styled(Box)`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledFlex = styled(Flex)`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const OrderBookList: FC<OrderBookListProps> = ({
  currencyPair,
  side,
  data,
  maxAmount,
  position,
  onSelectPrice,
}) => {
  const elementRef = useRef(null)
  const isScrolling = useScrollDetect({ elementRef })

  const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null)

  const isReversed = position === OrderBookListPosition.Top

  const formatAccumulatedData = useAccumulatedDataFormat({
    data,
    isReversed,
  })

  const getBackgroundColor = useCallback(
    (currentItemIndex: number) => {
      if (hoveredItemIndex === null) {
        return null
      }

      if (currentItemIndex === hoveredItemIndex) {
        return Token.color.greyTone2
      }

      return (
        isReversed
          ? currentItemIndex < hoveredItemIndex
          : currentItemIndex > hoveredItemIndex
      )
        ? null
        : Token.color.greyTone2
    },
    [isReversed, hoveredItemIndex],
  )

  return (
    <StyledBox flex="1 1 0%" overflow="auto">
      <StyledFlex
        height="100%"
        overflow="auto"
        flexDirection={isReversed ? 'column-reverse' : 'column'}
        ref={elementRef}
      >
        <Box mt={isReversed ? 'auto' : 0}>
          {data.map(({ amount, price, total }, index) => (
            <OrderBookListItem
              key={price}
              currencyPair={currencyPair}
              side={side}
              price={price}
              amount={amount}
              total={total}
              maxAmount={maxAmount}
              currentIndex={index}
              onHover={setHoveredItemIndex}
              backgroundColor={getBackgroundColor(index)}
              accumulatedData={formatAccumulatedData(index)}
              isScrolling={isScrolling}
              onSelectPrice={onSelectPrice}
            />
          ))}
        </Box>
      </StyledFlex>
    </StyledBox>
  )
}
