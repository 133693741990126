import { Box, Group, Subheader, VStack } from '@revolut/ui-kit'
import { isEmpty } from 'lodash'
import { FC, useCallback, useMemo, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { useInfiniteTradesByOrderId } from '../../../core-api'
import { ErrorStatus } from '../../../core-shared'
import { TradesGroupItem } from '../TradesGroup'

interface LinkedTradesProps {
  orderId: string
}

export const LinkedTrades: FC<LinkedTradesProps> = ({ orderId }) => {
  const { data, hasNextPage, fetchNextPage, status, refetch, isFetching } =
    useInfiniteTradesByOrderId(orderId)

  const linkedTrades = useMemo(
    () => (data?.pages ?? []).flatMap((page) => page.items),
    [data],
  )

  const observer = useRef<IntersectionObserver>()

  const lastItemRef = useCallback(
    (node: HTMLDivElement) => {
      if (status === 'loading') return

      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage()
        }
      })

      if (node) observer.current.observe(node)
    },
    [fetchNextPage, hasNextPage, isFetching, status],
  )

  if (status === 'error') {
    return (
      <>
        <Subheader>
          <Subheader.Title>
            <FormattedMessage
              id="activity.details.linkedTrades.title"
              defaultMessage="Linked trades"
            />
          </Subheader.Title>
        </Subheader>

        <Box mx="auto">
          <ErrorStatus onRetry={refetch} />
        </Box>
      </>
    )
  }

  if (isEmpty(linkedTrades)) return null

  return (
    <VStack mt="s-16">
      <Subheader>
        <Subheader.Title>
          <FormattedMessage
            id="activity.details.linkedTrades.title"
            defaultMessage="Linked trades"
          />
        </Subheader.Title>
      </Subheader>

      <Group>
        {linkedTrades.map((item) => (
          <TradesGroupItem trade={item} ref={lastItemRef} />
        ))}
      </Group>
    </VStack>
  )
}
