import { type Duration } from 'date-fns'

/** Duration that is required for current session to pass to show feedback prompt */
export const PROMPT_REQUIRED_SESSION_TIME: Duration = { minutes: 5 }

/** Duration that has to pass since last known prompt closing to show feedback prompt */
export const PROMPT_CLOSE_TIMEOUT: Duration = { months: 1 }

/** Duration that has to pass since last known form completion to show feedback prompt  */
export const PROMPT_COMPLETION_TIMEOUT: Duration = { months: 3 }

/** How long ago the previous session has to be to qualify user as not frequent */
export const PROMPT_FREQUENT_USER_PREVIOUS_SESSION: Duration = { weeks: 1 }
