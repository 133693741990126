import { CurrencyAssetType } from '../../../core-api'
import { WithdrawFlowState, WithdrawFlowStep } from './types'

export const INITIAL_STATE: WithdrawFlowState = {
  step: WithdrawFlowStep.CurrencySelection,
  assetType: CurrencyAssetType.Fiat,
  balance: undefined,
  pocket: undefined,
  amount: null,
  sizeRatio: null,
}
