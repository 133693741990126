import { CurrencyAssetType } from './currency'

export enum PocketState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Locked = 'LOCKED',
  Deleted = 'DELETED',
}

export enum PocketType {
  Current = 'CURRENT',
  Savings = 'SAVINGS',
  Staking = 'STAKING',
  StakingProxy = 'STAKING_PROXY',
  Investment = 'INVESTMENT',
  Credit = 'CREDIT',
  Merchant = 'MERCHANT',
  Instalment = 'INSTALMENT',
  Vesting = 'VESTING',
  SalaryAdvance = 'SALARY_ADVANCE',
  Blocking = 'BLOCKING',
  Unknown = 'UNKNOWN',
}

export interface Pocket {
  id: string
  type: PocketType
  state: PocketState
  currency: string
  balance: number
  assetType: CurrencyAssetType
}

export interface Wallet {
  id: string
  pockets: Pocket[]
}
