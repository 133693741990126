import {
  ChartResolution,
  ChartingLibraryFeatureset,
  Overrides,
} from '@revolut-internal/trading-charts'

import { ChartInterval } from '../core-api'

export const CHART_SUPPORTED_RESOLUTIONS = [
  ChartResolution.FiveMinutes,
  ChartResolution.FifteenMinutes,
  ChartResolution.ThirtyMinutes,
  ChartResolution.OneHour,
  ChartResolution.FourHours,
  ChartResolution.OneDay,
  ChartResolution.TwoDays,
  ChartResolution.FourDays,
  ChartResolution.OneWeek,
]

export const NARROW_MAIN_RESOLUTIONS = [
  ChartResolution.FifteenMinutes,
  ChartResolution.OneHour,
]

export const CHART_TIMEFRAMES = {
  [ChartResolution.FiveMinutes]: '1D',
  [ChartResolution.TenMinutes]: '1D',
  [ChartResolution.FifteenMinutes]: '2D',
  [ChartResolution.ThirtyMinutes]: '4D',
  [ChartResolution.OneHour]: '1W',
  [ChartResolution.FourHours]: '1M',
  [ChartResolution.OneDay]: '6M',
  [ChartResolution.TwoDays]: '1Y',
  [ChartResolution.FourDays]: '2Y',
  [ChartResolution.OneWeek]: '3Y',
  [ChartResolution.FiveWeeks]: '5Y',
}

export const RESOLUTION_TO_FX_INTERVAL_MAP: { [key in ChartResolution]?: ChartInterval } =
  {
    [ChartResolution.FiveMinutes]: ChartInterval.FiveMinutes,
    [ChartResolution.FifteenMinutes]: ChartInterval.FifteenMinutes,
    [ChartResolution.ThirtyMinutes]: ChartInterval.ThirtyMinutes,
    [ChartResolution.OneHour]: ChartInterval.OneHour,
    [ChartResolution.FourHours]: ChartInterval.FourHours,
    [ChartResolution.OneDay]: ChartInterval.OneDay,
    [ChartResolution.TwoDays]: ChartInterval.TwoDays,
    [ChartResolution.FourDays]: ChartInterval.FourDays,
    [ChartResolution.OneWeek]: ChartInterval.OneWeek,
  }

export const CONDENSED_WIDTH_LIMIT_PX = 660

export const CHART_DISABLED_FEATURES: ChartingLibraryFeatureset[] = [
  'create_volume_indicator_by_default',
  'header_symbol_search',
  'header_widget',
  'show_object_tree',
  'symbol_search_hot_key',
  'timeframes_toolbar',
  'volume_force_overlay',
  'display_market_status',
]

export const CHART_ENABLED_FEATURES: ChartingLibraryFeatureset[] = [
  'hide_left_toolbar_by_default',
]

export const CHART_CUSTOM_OVERRIDES: Overrides = {
  'paneProperties.legendProperties.showSeriesTitle': false,
}

export enum OrderBookProgressSide {
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum OrderBookSide {
  Ask = 'ASK',
  Bid = 'BID',
}

export enum OrderBookListPosition {
  Top = 'TOP',
  Bottom = 'BOTTOM',
}

export const ORDER_BOOK_STEPS = [1, 5, 10, 50, 100, 500, 1000, 5000]

export const ALL_PAIRS_FILTER_OPTION = {
  label: 'ALL_PAIRS_LABEL',
  value: 'ALL_PAIRS_VALUE',
  key: 'ALL_PAIRS_KEY',
}
