import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getBanners } from '../../api'
import { QueryKey } from '../../constants'
import { BannersResponse } from '../../types'

export const useBanners = <T extends unknown = BannersResponse>(
  options: UseQueryOptions<BannersResponse, AxiosError, T> = {},
) =>
  useQuery<BannersResponse, AxiosError, T>(
    [QueryKey.Banners],
    () => getBanners(),
    options,
  )
