import { OrderSide, OrderType } from '../../../core-api'
import { ExchangeOrderState, OrderValueType } from './types'

export const INITIAL_STATE: ExchangeOrderState = {
  orderId: null,

  side: OrderSide.Buy,
  type: OrderType.Market,
  valueType: OrderValueType.Amount,

  quantity: null,
  amount: null,

  postOnly: false,

  roundedQuantity: null,
  roundedAmount: null,

  price: null,
  estimatedPrice: null,

  sizeRatio: null,
  touched: false,
}
