import { FC, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useIntl } from '../../../core-intl'
import { useHumanizeCurrencyPair, WelcomeJourneyTarget } from '../../../core-shared'
import { getRouteUrl, RoutePath, useQueryParams } from '../../../core-utils'
import { OrderForm } from '../../../feature-trading'
import { WidgetType } from '../../constants'
import { useWidgetEvent } from '../../hooks'
import { WidgetEventMap } from '../../types'
import { TerminalWidget } from '../TerminalWidget'
import { WidgetTypeMessage } from '../WidgetTypeMessage'

interface OrderFormWidgetProps {
  currencyPair: string
}

export const OrderFormWidget: FC<OrderFormWidgetProps> = ({ currencyPair }) => {
  const { formatMessage } = useIntl()

  const { subscribe, dispatch } = useWidgetEvent()

  const history = useHistory()

  const [queryParams] = useQueryParams<{ orderId?: string }>()

  useEffect(() => {
    if (queryParams.orderId) {
      dispatch('highlightWidget', WidgetType.OrderForm)
    }
  }, [queryParams.orderId, dispatch])

  const handleSubscribePrefill = useCallback(
    (handlePrefill: (value: number | null) => void) => {
      const handleOrderFormPrefill = ({ price }: WidgetEventMap['prefillOrder']) => {
        handlePrefill(price)

        dispatch('highlightWidget', WidgetType.OrderForm)
      }

      const unsubscribe = subscribe('prefillOrder', handleOrderFormPrefill)

      return unsubscribe
    },
    [dispatch, subscribe],
  )

  const { humanizeCurrencyPair } = useHumanizeCurrencyPair()

  const handleResetOrderReplace = useCallback(() => {
    history.replace(
      getRouteUrl({
        path: RoutePath.Trade,
        params: { currencyPair: humanizeCurrencyPair(currencyPair) },
      }),
    )
  }, [history, currencyPair, humanizeCurrencyPair])

  return (
    <TerminalWidget
      type={WidgetType.OrderForm}
      aria-label={formatMessage({
        id: 'labels.widget.orderForm',
        defaultMessage: 'Order form',
      })}
      className={WelcomeJourneyTarget.BuyAndSellCrypto}
    >
      <TerminalWidget.Header title={<WidgetTypeMessage type={WidgetType.OrderForm} />} />

      <OrderForm
        px="s-16"
        currencyPair={currencyPair}
        orderId={queryParams.orderId}
        onSubscribePrefill={handleSubscribePrefill}
        onResetOrderReplace={handleResetOrderReplace}
      />
    </TerminalWidget>
  )
}
