import { AuthStatus } from './types'

export const REQUIRE_AUTH_REQUEST_STATUSES: AuthStatus[] = ['unknown']

export const INTERMEDIATE_AUTH_STATUSES: AuthStatus[] = ['unknown', 'requested']

export enum QueryKey {
  AuthTokenInfo = 'AuthTokenInfo',
}

export enum Scope {
  CryptoExchangeAll = 'CRYPTO_EXCHANGE_ALL',
}
