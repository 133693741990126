import { EventTrackerOptions } from '@revolut-internal/event-tracker'
import * as Sentry from '@sentry/react'

import { CryptoExchangeProduct } from '@revolut-internal/aqueduct-schema/types/enums/crypto-exchange/crypto_exchange_product'

import { ROUTE_PATHS, RoutePath } from '../../core-utils'

const PRODUCT_BY_PATH: Record<string, CryptoExchangeProduct> = {
  [ROUTE_PATHS[RoutePath.Root]]: 'Home',
  [ROUTE_PATHS[RoutePath.Auth]]: 'Auth',
  [ROUTE_PATHS[RoutePath.AuthSignIn]]: 'Auth',
  [ROUTE_PATHS[RoutePath.AuthSignInError]]: 'Auth',
  [ROUTE_PATHS[RoutePath.AuthSignOut]]: 'Auth',
  [ROUTE_PATHS[RoutePath.AuthSignedOut]]: 'Auth',
  [ROUTE_PATHS[RoutePath.AuthLimitedAccess]]: 'Auth',
  [ROUTE_PATHS[RoutePath.AuthVerification]]: 'Auth',
  [ROUTE_PATHS[RoutePath.AuthRestrictedAccess]]: 'Auth',
  [ROUTE_PATHS[RoutePath.Error]]: 'Error',
  [ROUTE_PATHS[RoutePath.Activity]]: 'Activity',
  [ROUTE_PATHS[RoutePath.ActivityOpenOrders]]: 'Activity',
  [ROUTE_PATHS[RoutePath.ActivityOrderHistory]]: 'Activity',
  [ROUTE_PATHS[RoutePath.ActivityTrades]]: 'Activity',
  [ROUTE_PATHS[RoutePath.ActivityTransfers]]: 'Activity',
  [ROUTE_PATHS[RoutePath.Trade]]: 'Trade',
  [ROUTE_PATHS[RoutePath.Portfolio]]: 'Portfolio',
  [ROUTE_PATHS[RoutePath.Home]]: 'Home',
  [ROUTE_PATHS[RoutePath.Profile]]: 'Profile',
  [ROUTE_PATHS[RoutePath.NotificationSettings]]: 'Profile',
  [ROUTE_PATHS[RoutePath.ProfileAppearance]]: 'Profile',
}

type ProductResolver = Required<EventTrackerOptions<'cryptoExchange'>>['productResolver']

export const productResolver: ProductResolver = ({ path, url }) => {
  if (path !== undefined) {
    const product = PRODUCT_BY_PATH[path]

    if (product !== undefined) {
      return product
    }
  }

  if (path !== undefined) {
    Sentry.captureException(new Error(`Unknown product for the path: ${path}`), {
      extra: { url },
    })
  }

  return 'ProductUnspecified'
}
