import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getTransfers } from '../../api'
import { DEFAULT_PAGE_SIZE, INFINITE_QUERY_KEY, QueryKey } from '../../constants'
import { TransfersParams, TransfersReponse } from '../../types'

export const useInfiniteTransfers = <T extends unknown = TransfersReponse>(
  params: Omit<TransfersParams, 'cursor'>,
  options: UseInfiniteQueryOptions<TransfersReponse, AxiosError, T> = {},
) =>
  useInfiniteQuery<TransfersReponse, AxiosError, T>(
    [QueryKey.Transfers, INFINITE_QUERY_KEY, params],
    ({ pageParam }) =>
      getTransfers({
        ...params,
        cursor: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.items.length < DEFAULT_PAGE_SIZE) {
          return undefined
        }
        return lastPage.cursor !== '' ? lastPage.cursor : undefined
      },
      ...options,
    },
  )
