import { AxiosError } from 'axios'
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { v4 as uuid } from 'uuid'

import {
  CurrencyAssetType,
  ExtendedBalance,
  Pocket,
  WithdrawData,
  WithdrawErrorResponse,
  WithdrawType,
  combineStatuses,
  transferRequiresSSOVerification,
  usePockets,
  useStableSortedBalances,
  useWithdraw,
} from '../../../core-api'
import { useSsoVerification } from '../../../core-auth'
import { useIntl } from '../../../core-intl'
import { useMoneyFormat, useStatusPopup } from '../../../core-ui'
import { checkRequired } from '../../../core-utils'
import { INITIAL_STATE } from './constants'
import { WithdrawFlow, WithdrawFlowStep } from './types'
import { WithdrawFlowActionType, withdrawFlowReducer } from './withdrawFlowReducer'

interface WithdrawFlowParams {
  currency?: string
  onSuccess: VoidFunction
}

export const useWithdrawFlow = ({
  currency,
  onSuccess,
}: WithdrawFlowParams): WithdrawFlow => {
  const { formatMessage } = useIntl()

  const moneyFormat = useMoneyFormat({ type: 'money-fractional' })

  const { verify, verificationLink } = useSsoVerification()

  const operationRef = useRef(uuid())

  const [state, dispatch] = useReducer(withdrawFlowReducer, INITIAL_STATE)

  const handleChangeStep = (step: WithdrawFlowStep) => {
    dispatch({ type: WithdrawFlowActionType.SetStep, step })
  }

  const handleChangeAmount = (nextAmount: number | null) => {
    dispatch({ type: WithdrawFlowActionType.UpdateAmount, payload: nextAmount })
  }

  const handleChangeBalance = useCallback(
    (balance: ExtendedBalance) => {
      dispatch({ type: WithdrawFlowActionType.UpdateBalance, balance })
    },
    [dispatch],
  )

  const handleChangePocket = useCallback(
    (pocket: Pocket) => {
      dispatch({ type: WithdrawFlowActionType.UpdatePocket, pocket })
    },
    [dispatch],
  )

  const handleChangeSize = (nextSizeRatio: number) => {
    const orderSizeAmount = nextSizeRatio * (state.balance?.amount ?? 0)

    handleChangeAmount(orderSizeAmount)

    dispatch({ type: WithdrawFlowActionType.SetSizeRatio, sizeRatio: nextSizeRatio })
  }

  const handleChangeAssetType = (assetType: CurrencyAssetType) => {
    dispatch({ type: WithdrawFlowActionType.UpdateAssetType, assetType })
  }

  const handleCancelVerification = () => {
    handleChangeStep(WithdrawFlowStep.Confirmation)
  }

  const balances = useStableSortedBalances()

  useEffect(() => {
    if (balances.data && currency) {
      const balance = balances.data.find((item) => item.currency === currency)

      if (balance && state.balance?.currency !== balance.currency) {
        handleChangeBalance(balance)
      }
    }
  }, [balances.data, state.balance?.currency, currency, handleChangeBalance])

  const pockets = usePockets()

  useEffect(() => {
    if (state.pocket === undefined && state.balance && pockets.data) {
      const balanceCurrency = state.balance.currency

      const pocket = pockets.data.find((item) => item.currency === balanceCurrency)

      if (pocket) {
        dispatch({ type: WithdrawFlowActionType.SetPocket, pocket })
      }
    }
  }, [state.pocket, state.balance, pockets, dispatch])

  const errorMessage: string | null = useMemo(() => {
    if (state.amount !== null && state.balance && state.amount > state.balance.amount) {
      return formatMessage({
        id: 'portfolio.withdraw.errors.exceedsBalance.title',
        defaultMessage: 'exceeds balance',
      })
    }

    return null
  }, [state.amount, state.balance, formatMessage])

  const invalid = errorMessage !== null

  const disabled =
    state.amount === null || state.amount === 0 || !state.balance || invalid

  const status = combineStatuses(balances.status, pockets.status)

  const statusPopup = useStatusPopup()

  const {
    mutate,
    mutateAsync,
    isLoading: submitting,
  } = useWithdraw({
    onSuccess: () => {
      if (state.amount !== null && state.balance !== undefined) {
        statusPopup.showSuccess({
          title: (
            <FormattedMessage
              id="portfolio.withdraw.success.title"
              defaultMessage="You’ve withdrawn {amount} of your {currency} holdings"
              values={{
                amount: moneyFormat({
                  amount: state.amount,
                  currency: state.balance.currency,
                }),
                currency: state.balance.currency,
              }}
            />
          ),
          onClose: onSuccess,
        })
      }

      operationRef.current = uuid()
    },
    onError: (error) => {
      const { response } = error

      if (!response || !transferRequiresSSOVerification(response.data, response.status)) {
        statusPopup.showError({
          title: (
            <FormattedMessage
              id="errors.general.title"
              defaultMessage="Something went wrong"
            />
          ),
        })
      }
    },
  })

  const handleSubmit = () => {
    if (disabled || state.amount === null) {
      statusPopup.showError({
        title: (
          <FormattedMessage
            id="errors.general.title"
            defaultMessage="Something went wrong"
          />
        ),
      })

      return
    }

    const withdrawAmount = state.amount

    const withdrawData: WithdrawData = state.pocket
      ? {
          type: WithdrawType.Amount,
          amount: withdrawAmount,
          sourcePocketId: state.pocket.id,
          op: operationRef.current,
        }
      : {
          type: WithdrawType.Amount,
          amount: withdrawAmount,
          currency: checkRequired(state.balance?.currency, 'Currency is required'),
          op: operationRef.current,
        }

    mutateAsync(withdrawData).catch(
      (error: AxiosError<WithdrawErrorResponse, WithdrawData>) => {
        const { response } = error

        if (response && transferRequiresSSOVerification(response.data, response.status)) {
          handleChangeStep(WithdrawFlowStep.Verification)

          const { state: verificationState, acceptedLevel } = response.data

          verify({
            state: verificationState,
            context: acceptedLevel,
            onClose: () => {
              handleChangeStep(WithdrawFlowStep.Confirmation)
            },
          }).then(async ({ code, codeVerifier }) => {
            statusPopup.showLoading()

            mutate({
              ...withdrawData,
              code,
              codeVerifier,
            })
          })
        }
      },
    )
  }

  const currencyMessage = useMemo(() => {
    if (state.balance) {
      return formatMessage(
        {
          id: 'portfolio.balance.title',
          defaultMessage: 'Balance: {balance}',
        },
        {
          balance: moneyFormat({
            amount: state.balance.amount,
            currency: state.balance.currency,
          }),
        },
      )
    }

    return null
  }, [state.balance, formatMessage, moneyFormat])

  return {
    state,

    currencyMessage,
    amountMessage: errorMessage,

    verificationLink,

    invalid,
    disabled,
    submitting,
    status,

    handleChangeStep,
    handleChangeBalance,
    handleChangePocket,
    handleChangeAmount,
    handleChangeSize,
    handleChangeAssetType,
    handleCancelVerification,
    handleSubmit,
  }
}
