import {
  Absolute,
  Amount,
  Box,
  Button,
  Flex,
  FlexProps,
  HStack,
  Icon,
  Input,
  Relative,
  Separator,
  Spacer,
  Text,
  Tooltip,
  VStack,
  useToggle,
  useTooltip,
} from '@revolut/ui-kit'
import { isNil } from 'lodash'
import { FC, ReactNode, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  OrderSide,
  OrderType,
  UserRestrictionType,
  useCurrencyPairRequired,
  useUserRestrictionEnabled,
} from '../../../core-api'
import { Feature, useFeatures } from '../../../core-features'
import { useIntl } from '../../../core-intl'
import {
  AssetAvatar,
  CurrencyPairProvider,
  OrderSizeSuggestions,
  useHumanizeCurrency,
} from '../../../core-shared'
import { CondensedDetails, InfoText, Z_INDICES, useThemePalette } from '../../../core-ui'
import { DepositPopup } from '../../../feature-portfolio'
import {
  InitialOrderState,
  OrderValueType,
  useExchangeOrderButtonProps,
  useExchangeOrderSubmit,
} from '../../hooks'
import { OrderExecutionSelect } from '../OrderExecutionSelect'
import { OrderFormDisclaimer } from '../OrderFormDisclaimer'
import { OrderSideTabs } from '../OrderSideTabs'
import { OrderSummary } from '../OrderSummary'
import { OrderTypeDropdown } from '../OrderTypeDropdown'

const renderAmountInputDescription = (text: ReactNode, description: ReactNode) => {
  if (isNil(text)) {
    return null
  }

  return <InfoText text={text} description={description} />
}

export interface OrderFormProps extends Pick<FlexProps, 'px' | 'pb' | 'style'> {
  initialState?: InitialOrderState
  currencyPair: string
  orderId?: string

  onCreateSuccess?: VoidFunction
  onSubscribePrefill?: (handlePrefill: (value: number | null) => void) => VoidFunction
  onResetOrderReplace: VoidFunction
}

export const OrderForm: FC<OrderFormProps> = ({
  initialState,
  currencyPair,
  orderId,
  onCreateSuccess,
  onSubscribePrefill,
  onResetOrderReplace,
  px,
  ...rest
}) => {
  const { palette } = useThemePalette()

  const [isDepositPopupOpen, toogleIsDepositPopupOpen] = useToggle()

  const { baseCurrency, quoteCurrency } = useCurrencyPairRequired(currencyPair)

  const { humanizeCurrency } = useHumanizeCurrency()

  const humanizedBaseCurrency = humanizeCurrency(baseCurrency)
  const humanizedQuoteCurrency = humanizeCurrency(quoteCurrency)

  const {
    state,
    disabled,
    submitting,
    loading,

    baseCurrencyInvalid,
    baseCurrencyMessage,

    quoteCurrencyInvalid,
    quoteCurrencyMessage,

    baseCurrencyBalanceMessage,
    quoteCurrencyBalanceMessage,

    baseCurrencyErrorMessage,
    baseCurrencyErrorTooltipMessage,
    quoteCurrencyErrorMessage,

    depositRequired,
    depositCurrency,

    handleChangeSide,
    handleChangeType,
    handleToggleValueType,
    handleChangeQuantity,
    handleChangeAmount,
    handleChangePostOnly,
    handleChangePrice,
    handleChangeSize,
    handleSubmit,
    handlePrefill,
  } = useExchangeOrderSubmit({
    initialState,
    currencyPair,
    orderId,

    onCreateSuccess,
    onReplaceSuccess: onResetOrderReplace,
  })

  const { side, type } = state

  const isBuy = side === OrderSide.Buy

  const { formatMessage } = useIntl()

  const priceInputLabel = formatMessage({
    id: 'trading.orderForm.priceInput.title',
    defaultMessage: 'Price',
  })

  const exchangeOrderButtonArgs = useExchangeOrderButtonProps({
    isBuy,
    depositRequired,
    baseCurrency,
    quoteCurrency,
    disabled,
    submitting,
    loading,
    handleSubmit,
    handleDepositFundsClick: () => toogleIsDepositPopupOpen(true),
  })

  useEffect(() => {
    let unsubscribe: VoidFunction

    if (onSubscribePrefill) {
      unsubscribe = onSubscribePrefill(handlePrefill)
    }

    return () => unsubscribe?.()
  }, [onSubscribePrefill, handlePrefill])

  const createOrderTooltip = useTooltip()
  const replaceOrderTooltip = useTooltip()

  const { enabled: createOrderDisabled } = useUserRestrictionEnabled({
    type: UserRestrictionType.CREATE_ORDER_CURRENCY_PAIR,
    currencyPair,
  })

  const { enabled: replaceOrderDisabled } = useUserRestrictionEnabled({
    type: UserRestrictionType.REPLACE_ORDER_CURRENCY_PAIR,
    currencyPair,
  })

  const features = useFeatures()

  const isPostOnlyEnabled = features.isFeatureEnabled(Feature.LimitOrderPostOnly)

  const executionDetails =
    isPostOnlyEnabled && state.type === OrderType.Limit ? (
      <CondensedDetails>
        <CondensedDetails.Title>
          <FormattedMessage
            id="trading.orderForm.execution.title"
            defaultMessage="Execution"
          />
        </CondensedDetails.Title>
        <CondensedDetails.Content>
          <OrderExecutionSelect value={state.postOnly} onChange={handleChangePostOnly} />
        </CondensedDetails.Content>
      </CondensedDetails>
    ) : null

  return (
    <Flex pb="s-16" overflow="auto" flex={1} {...rest}>
      <VStack
        pb={96}
        space="s-8"
        overflow="auto"
        flex={1}
        style={{ position: 'relative' }}
      >
        <HStack px={px}>
          <Box flex={1}>
            <OrderSideTabs
              side={state.side}
              onChangeSide={handleChangeSide}
              disabled={orderId !== undefined}
            />
          </Box>

          <Spacer width="s-8" />

          <OrderTypeDropdown
            type={type}
            onChangeType={handleChangeType}
            disabled={orderId !== undefined}
          />
        </HStack>

        <VStack space="s-16" overflow="auto" flex={1}>
          <VStack px={px} space="s-8" overflow="auto">
            {state.type === OrderType.Limit && (
              <CurrencyPairProvider currencyPair={currencyPair}>
                <Input
                  label={priceInputLabel}
                  type="money"
                  aria-label={formatMessage({
                    id: 'labels.price',
                    defaultMessage: 'Price',
                  })}
                  currency={quoteCurrency}
                  value={state.price}
                  onChange={handleChangePrice}
                />
              </CurrencyPairProvider>
            )}

            {state.valueType === OrderValueType.Amount ? (
              <>
                <Amount
                  use="label"
                  aria-invalid={quoteCurrencyInvalid || baseCurrencyInvalid}
                >
                  <Amount.Currency
                    value={
                      <HStack
                        aria-label={formatMessage({
                          id: 'labels.toggleCurrency',
                          defaultMessage: 'Toggle currency',
                        })}
                        align="center"
                        space="s-4"
                        onClick={() => handleToggleValueType()}
                        css={{ cursor: 'pointer' }}
                      >
                        <AssetAvatar size={20} asset={quoteCurrency} />
                        <Text>{humanizedQuoteCurrency}</Text>
                        <Icon size={24} name="ArrowsSort" />
                      </HStack>
                    }
                  />
                  <Amount.Input
                    type="money"
                    aria-label={formatMessage({
                      id: 'labels.quoteCurrency',
                      defaultMessage: 'Quote currency',
                    })}
                    currency={quoteCurrency}
                    showCurrency={false}
                    value={state.amount}
                    invalid={quoteCurrencyInvalid || baseCurrencyInvalid}
                    description={renderAmountInputDescription(
                      quoteCurrencyMessage ?? baseCurrencyErrorMessage,
                      baseCurrencyErrorTooltipMessage,
                    )}
                    onChange={handleChangeAmount}
                  />
                </Amount>

                <OrderSizeSuggestions
                  sizeRatio={state.sizeRatio}
                  onChangeSize={handleChangeSize}
                />

                <VStack>
                  <CondensedDetails>
                    <CondensedDetails.Title>
                      <FormattedMessage
                        id="trading.orderForm.availableBalance.title"
                        defaultMessage="Available balance"
                      />
                    </CondensedDetails.Title>
                    <CondensedDetails.Content>
                      {state.side === OrderSide.Buy
                        ? quoteCurrencyBalanceMessage
                        : baseCurrencyBalanceMessage}
                    </CondensedDetails.Content>
                  </CondensedDetails>

                  {executionDetails}
                </VStack>
              </>
            ) : (
              <CurrencyPairProvider currencyPair={currencyPair}>
                <>
                  <Amount
                    use="label"
                    aria-invalid={baseCurrencyInvalid || quoteCurrencyInvalid}
                  >
                    <Amount.Currency
                      value={
                        <HStack
                          aria-label={formatMessage({
                            id: 'labels.toggleCurrency',
                            defaultMessage: 'Toggle currency',
                          })}
                          align="center"
                          space="s-4"
                          onClick={() => handleToggleValueType()}
                          css={{ cursor: 'pointer' }}
                        >
                          <AssetAvatar size={20} asset={baseCurrency} />
                          <Text>{humanizedBaseCurrency}</Text>
                          <Icon size={24} name="ArrowsSort" />
                        </HStack>
                      }
                    />
                    <Amount.Input
                      type="money"
                      aria-label={formatMessage({
                        id: 'labels.baseCurrency',
                        defaultMessage: 'Base currency',
                      })}
                      currency={baseCurrency}
                      showCurrency={false}
                      value={state.quantity}
                      invalid={baseCurrencyInvalid || quoteCurrencyInvalid}
                      description={renderAmountInputDescription(
                        baseCurrencyMessage ?? quoteCurrencyErrorMessage,
                        baseCurrencyErrorTooltipMessage,
                      )}
                      onChange={handleChangeQuantity}
                    />
                  </Amount>

                  <OrderSizeSuggestions
                    sizeRatio={state.sizeRatio}
                    onChangeSize={handleChangeSize}
                  />

                  <VStack>
                    <CondensedDetails>
                      <CondensedDetails.Title>
                        <FormattedMessage
                          id="trading.orderForm.availableBalance.title"
                          defaultMessage="Available balance"
                        />
                      </CondensedDetails.Title>
                      <CondensedDetails.Content>
                        {state.side === OrderSide.Buy
                          ? quoteCurrencyBalanceMessage
                          : baseCurrencyBalanceMessage}
                      </CondensedDetails.Content>
                    </CondensedDetails>

                    {executionDetails}
                  </VStack>
                </>
              </CurrencyPairProvider>
            )}
          </VStack>
        </VStack>

        <Absolute px={px} left={0} bottom={0} width="100%">
          <Relative zIndex={Z_INDICES.CONTENT} pb="s-8">
            <Separator bg={palette.separatorColor} />

            <OrderSummary currencyPair={currencyPair} state={state} />
          </Relative>

          <VStack space="s-16">
            {orderId ? (
              <HStack space="s-16">
                <Box flex={1}>
                  <Button
                    variant="secondary"
                    onClick={onResetOrderReplace}
                    aria-label={formatMessage({
                      id: 'labels.cancelOrder',
                      defaultMessage: 'Cancel order',
                    })}
                    disabled={submitting}
                  >
                    <FormattedMessage id="actions.cancel" defaultMessage="Cancel" />
                  </Button>
                </Box>
                <Box flex={1} {...replaceOrderTooltip.getAnchorProps()}>
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    aria-label={formatMessage({
                      id: 'labels.saveOrder',
                      defaultMessage: 'Save order',
                    })}
                    pending={submitting}
                    disabled={replaceOrderDisabled}
                  >
                    <FormattedMessage id="actions.save" defaultMessage="Save" />
                  </Button>
                </Box>
              </HStack>
            ) : (
              <Box {...createOrderTooltip.getAnchorProps()}>
                <Button
                  variant="primary"
                  aria-label={formatMessage({
                    id: 'labels.submitOrder',
                    defaultMessage: 'Submit order',
                  })}
                  {...exchangeOrderButtonArgs}
                  disabled={exchangeOrderButtonArgs.disabled || createOrderDisabled}
                />
              </Box>
            )}

            {createOrderDisabled && (
              <Tooltip
                width={240}
                {...createOrderTooltip.getTargetProps({ placement: 'top' })}
              >
                <FormattedMessage
                  id="trading.orderForm.createOrderDisabled.tooltip.title"
                  defaultMessage="Trading for this pair is temporarily disabled due to maintenance works"
                />
              </Tooltip>
            )}

            {replaceOrderDisabled && (
              <Tooltip
                width={240}
                {...replaceOrderTooltip.getTargetProps({ placement: 'top-end' })}
              >
                <FormattedMessage
                  id="trading.orderForm.replaceOrderDisabled.tooltip.title"
                  defaultMessage="Order editing for this pair is temporarily disabled. You can still cancel your order"
                />
              </Tooltip>
            )}

            <OrderFormDisclaimer />
          </VStack>
        </Absolute>

        <DepositPopup
          currency={depositCurrency}
          open={isDepositPopupOpen}
          onClose={() => toogleIsDepositPopupOpen(false)}
        />
      </VStack>
    </Flex>
  )
}
