import axios from 'axios'
import { omit } from 'lodash'

import { ReplaceOrderData, ReplaceOrderResponse } from '../types'

export const replaceOrder = async (data: ReplaceOrderData) => {
  const { data: response } = await axios.put<ReplaceOrderResponse>(
    `/api/crypto-exchange/orders/${data.id}`,
    omit(data, ['id']),
  )

  return response
}
