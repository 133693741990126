import {
  Box,
  HStack,
  Header,
  Overlay,
  OverlayProps,
  Popup,
  Search,
  Token,
  TransitionSlide,
  VStack,
} from '@revolut/ui-kit'
import { FC, useRef, useState } from 'react'

import { useIntl } from '../../../core-intl'
import { useLayoutSize } from '../../../core-shared'
import { TRANSITION_OFFSET_Y } from '../../../core-ui'
import { MarketsGroup } from '../MarketsGroup'
import { MarketsTable } from '../MarketsTable'
import { QuoteCurrencyFilter } from '../QuoteCurrencyFilter'

interface CurrencyPairSelectorPopupProps extends OverlayProps {
  currencyPair: string
  onSelectCurrencyPair: (currencyPair: string) => void
}

export const CurrencyPairSelectorPopup: FC<CurrencyPairSelectorPopupProps> = ({
  currencyPair,
  onSelectCurrencyPair,
  open,
  onClose,
  ...rest
}) => {
  const layoutSize = useLayoutSize()
  const { formatMessage } = useIntl()

  const nodeRef = useRef(null)

  const [search, setSearch] = useState('')
  const [quoteCurrency, setQuoteCurrency] = useState('')

  if (layoutSize === 'narrow') {
    return (
      <Popup
        open={open}
        onClose={onClose}
        closeOnEsc
        closeOnOverlayClick
        shouldKeepMaxHeight
        focusTrap={false}
      >
        <Header>
          <Header.CloseButton
            onClick={onClose}
            aria-label={formatMessage({
              id: 'labels.close',
              defaultMessage: 'Close',
            })}
          />

          <Header.Sticky>
            <VStack space="s-16" align="start">
              <Search
                placeholder={formatMessage({
                  id: 'labels.search',
                  defaultMessage: 'Search',
                })}
                value={search}
                onChange={setSearch}
                autoFocus
              />

              <QuoteCurrencyFilter onChange={setQuoteCurrency} />
            </VStack>
          </Header.Sticky>
        </Header>

        <MarketsGroup
          search={search}
          quoteCurrency={quoteCurrency}
          selectedCurrencyPair={currencyPair}
          onSelectCurrencyPair={onSelectCurrencyPair}
        />
      </Popup>
    )
  }

  return (
    <Overlay
      display="flex"
      open={open}
      onClose={onClose}
      closeOnEsc
      closeOnOverlayClick
      focusTrap={false}
      {...rest}
    >
      <TransitionSlide nodeRef={nodeRef} in={open} offsetY={TRANSITION_OFFSET_Y}>
        <VStack
          ref={nodeRef}
          width="min(640px, 100%)"
          height="min(560px, 100%)"
          space="s-16"
          bg="grey-tone-5"
          radius="popup"
          my="auto"
          mx="auto"
          overflow="hidden"
          border={`1px solid ${Token.color.greyTone8}`}
          role="dialog"
        >
          <HStack px="s-16" pt="s-16" align="center" space="s-16">
            <Search
              placeholder={formatMessage({
                id: 'labels.search',
                defaultMessage: 'Search',
              })}
              value={search}
              onChange={setSearch}
              autoFocus
            />
            <QuoteCurrencyFilter onChange={setQuoteCurrency} />
          </HStack>

          <Box flex={1} overflow="auto">
            <MarketsTable
              search={search}
              quoteCurrency={quoteCurrency}
              selectedCurrencyPair={currencyPair}
              onSelectCurrencyPair={onSelectCurrencyPair}
            />
          </Box>
        </VStack>
      </TransitionSlide>

      <Overlay.CloseButton
        aria-label={formatMessage({
          id: 'labels.close',
          defaultMessage: 'Close',
        })}
        onClick={onClose}
      />
    </Overlay>
  )
}
