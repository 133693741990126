import { HStack, Table, TableColumn, TextSkeleton } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

import {
  CurrencyAssetType,
  DEFAULT_CURRENCY,
  ExtendedBalance,
  formatCurrencyPair,
} from '../../../core-api'
import { Currency, CurrencyPairMoney } from '../../../core-shared'
import { Money, Percents } from '../../../core-ui'
import { formatPercents } from '../../../core-utils'

const LEGACY_COLUMNS: TableColumn<ExtendedBalance>[] = [
  {
    Header: (
      <FormattedMessage
        id="portfolio.balances.table.asset.caption"
        defaultMessage="Asset"
      />
    ),
    accessor: 'currency',
    Cell: ({ value }) => (
      <Table.Cell>
        <Currency currency={value} size={24} variant="small" />
      </Table.Cell>
    ),
  },
  {
    Header: (
      <FormattedMessage
        id="portfolio.balances.table.total.caption"
        defaultMessage="Total"
      />
    ),
    accessor: 'totalMajor',
    align: 'end',
    Cell: ({ row, value }) => (
      <Table.Cell>
        <Money currency={row.original.currency} amount={value} />
      </Table.Cell>
    ),
  },
  {
    Header: (
      <FormattedMessage
        id="portfolio.balances.table.available.caption"
        defaultMessage="Available"
      />
    ),
    accessor: 'amountMajor',
    align: 'end',
    Cell: ({ row, value }) => (
      <Table.Cell>
        <Money currency={row.original.currency} amount={value} />
      </Table.Cell>
    ),
  },
  {
    accessor: 'price',
    Header: (
      <FormattedMessage
        id="portfolio.markets.table.price.caption"
        defaultMessage="Price"
      />
    ),
    align: 'end',
    Cell: ({ row, value }) => {
      return row.original.assetType === CurrencyAssetType.Fiat ? (
        <Table.Cell>
          {value === undefined ? (
            <TextSkeleton width="s-48" />
          ) : (
            <Money kind="price" type="money" currency={DEFAULT_CURRENCY} amount={value} />
          )}
        </Table.Cell>
      ) : (
        <Table.Cell>
          <CurrencyPairMoney
            kind="price"
            variant="quote"
            amount={value}
            currencyPair={formatCurrencyPair(
              {
                baseCurrency: row.original.currency,
                quoteCurrency: DEFAULT_CURRENCY,
              },
              '/',
            )}
          />
        </Table.Cell>
      )
    },
  },
  {
    Header: (
      <FormattedMessage
        id="portfolio.balances.table.averageEntry.caption"
        defaultMessage="Avg. entry"
      />
    ),
    accessor: 'averageEntryPrice',
    align: 'end',
    Cell: ({ row, value }) => {
      if (value === undefined) {
        return <TextSkeleton width="s-24" />
      }

      return row.original.assetType === CurrencyAssetType.Fiat ? (
        <Table.Cell>
          <Table.EmptyCell />
        </Table.Cell>
      ) : (
        <Table.Cell>
          <CurrencyPairMoney
            kind="price"
            variant="quote"
            amount={value}
            currencyPair={formatCurrencyPair(
              {
                baseCurrency: row.original.currency,
                quoteCurrency: DEFAULT_CURRENCY,
              },
              '/',
            )}
          />
        </Table.Cell>
      )
    },
  },
  {
    Header: (
      <FormattedMessage
        id="portfolio.balances.table.value.caption"
        defaultMessage="Value"
      />
    ),
    accessor: 'value',
    align: 'end',
    Cell: ({ value }) => (
      <Table.Cell>
        {value === undefined ? (
          <TextSkeleton width="s-48" />
        ) : (
          <Money currency={DEFAULT_CURRENCY} amount={value} />
        )}
      </Table.Cell>
    ),
  },
  {
    Header: (
      <FormattedMessage
        id="portfolio.balances.table.return.caption"
        defaultMessage="Return"
      />
    ),
    accessor: 'return',
    align: 'end',
    Cell: ({ value, row }) => {
      if (row.original.assetType === CurrencyAssetType.Fiat) {
        return (
          <Table.Cell>
            <Table.EmptyCell />
          </Table.Cell>
        )
      }

      return (
        <Table.Cell>
          {value === undefined ? (
            <TextSkeleton width="s-48" />
          ) : (
            <Money kind="total" currency={DEFAULT_CURRENCY} amount={value} />
          )}
        </Table.Cell>
      )
    },
  },
  {
    Header: (
      <FormattedMessage
        id="portfolio.balances.table.performance.caption"
        defaultMessage="Performance"
      />
    ),
    accessor: 'performance',
    align: 'end',
    Cell: ({ value, row }) => {
      if (row.original.assetType === CurrencyAssetType.Fiat) {
        return (
          <Table.Cell>
            <Table.EmptyCell />
          </Table.Cell>
        )
      }

      return (
        <Table.Cell>
          {value === undefined ? (
            <TextSkeleton width="s-48" />
          ) : (
            <Percents value={value} variant="body3" />
          )}
        </Table.Cell>
      )
    },
  },
  {
    Header: (
      <FormattedMessage
        id="portfolio.balances.table.allocation.caption"
        defaultMessage="Allocation"
      />
    ),
    accessor: 'allocation',
    align: 'end',
    Cell: ({ value }) => <Table.Cell>{formatPercents(value)}</Table.Cell>,
  },
]

export const createAdvancedColumns = (
  assetType: CurrencyAssetType | undefined,
): TableColumn<ExtendedBalance>[] => {
  if (assetType === undefined) {
    return LEGACY_COLUMNS
  }

  return [
    {
      Header:
        assetType === CurrencyAssetType.Fiat ? (
          <FormattedMessage
            id="portfolio.balances.table.fiat.caption"
            defaultMessage="Fiat"
          />
        ) : (
          <FormattedMessage
            id="portfolio.balances.table.crypto.caption"
            defaultMessage="Crypto"
          />
        ),
      accessor: 'currency',
      Cell: ({ value }) => (
        <Table.Cell>
          <Currency currency={value} size={24} variant="small" />
        </Table.Cell>
      ),
    },
    {
      Header: (
        <FormattedMessage
          id="portfolio.balances.table.total.caption"
          defaultMessage="Total"
        />
      ),
      accessor: 'totalMajor',
      align: 'end',
      Cell: ({ row, value }) => (
        <Table.Cell>
          <Money currency={row.original.currency} amount={value} />
        </Table.Cell>
      ),
    },
    {
      Header: (
        <FormattedMessage
          id="portfolio.balances.table.available.caption"
          defaultMessage="Available"
        />
      ),
      accessor: 'amountMajor',
      align: 'end',
      Cell: ({ row, value }) => (
        <Table.Cell>
          <Money currency={row.original.currency} amount={value} />
        </Table.Cell>
      ),
    },
    {
      accessor: 'price',
      Header: (
        <FormattedMessage
          id="portfolio.markets.table.price.caption"
          defaultMessage="Price"
        />
      ),
      align: 'end',
      Cell: ({ row, value }) => {
        return row.original.assetType === CurrencyAssetType.Fiat ? (
          <Table.Cell>
            {value === undefined ? (
              <TextSkeleton width="s-48" />
            ) : (
              <Money
                kind="price"
                type="money"
                currency={DEFAULT_CURRENCY}
                amount={value}
              />
            )}
          </Table.Cell>
        ) : (
          <Table.Cell>
            <CurrencyPairMoney
              kind="price"
              variant="quote"
              amount={value}
              currencyPair={formatCurrencyPair(
                {
                  baseCurrency: row.original.currency,
                  quoteCurrency: DEFAULT_CURRENCY,
                },
                '/',
              )}
            />
          </Table.Cell>
        )
      },
    },
    {
      Header: (
        <FormattedMessage
          id="portfolio.balances.table.averageEntry.caption"
          defaultMessage="Avg. entry"
        />
      ),
      accessor: 'averageEntryPrice',
      align: 'end',
      Cell: ({ row, value }) => {
        if (value === undefined) {
          return <TextSkeleton width="s-24" />
        }

        return row.original.assetType === CurrencyAssetType.Fiat ? (
          <Table.Cell>
            <Table.EmptyCell />
          </Table.Cell>
        ) : (
          <Table.Cell>
            <CurrencyPairMoney
              kind="price"
              variant="quote"
              amount={value}
              currencyPair={formatCurrencyPair(
                {
                  baseCurrency: row.original.currency,
                  quoteCurrency: DEFAULT_CURRENCY,
                },
                '/',
              )}
            />
          </Table.Cell>
        )
      },
      disableSortBy: assetType === CurrencyAssetType.Fiat,
    },
    {
      Header: (
        <FormattedMessage
          id="portfolio.balances.table.value.caption"
          defaultMessage="Value"
        />
      ),
      accessor: 'value',
      align: 'end',
      Cell: ({ value }) => (
        <Table.Cell>
          {value === undefined ? (
            <TextSkeleton width="s-48" />
          ) : (
            <Money currency={DEFAULT_CURRENCY} amount={value} />
          )}
        </Table.Cell>
      ),
    },
    {
      Header: (
        <FormattedMessage
          id="portfolio.balances.table.unrealisedPnl.caption"
          defaultMessage="Unrealised P&L"
        />
      ),
      accessor: 'return',
      align: 'end',
      Cell: ({ row }) => {
        if (row.original.assetType === CurrencyAssetType.Fiat) {
          return (
            <Table.Cell>
              <Table.EmptyCell />
            </Table.Cell>
          )
        }

        return (
          <Table.Cell>
            <HStack space="s-8" align="center">
              {row.original.return === undefined ? (
                <TextSkeleton width="s-32" />
              ) : (
                <Money
                  kind="total"
                  currency={DEFAULT_CURRENCY}
                  amount={row.original.return}
                />
              )}
              {row.original.performance === undefined ? (
                <TextSkeleton width="s-32" />
              ) : (
                <Percents value={row.original.performance} variant="body3" />
              )}
            </HStack>
          </Table.Cell>
        )
      },
    },
    {
      Header: (
        <FormattedMessage
          id="portfolio.balances.table.allocation.caption"
          defaultMessage="Allocation"
        />
      ),
      accessor: 'allocation',
      align: 'end',
      Cell: ({ value }) => <Table.Cell>{formatPercents(value)}</Table.Cell>,
    },
  ]
}
