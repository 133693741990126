import { VStack, Text, TextSkeleton } from '@revolut/ui-kit'
import { last } from 'lodash'
import { FC, useMemo, useState } from 'react'

import { TimeSpan, useAnalyticsPerformance } from '../../../core-api'
import { ErrorStatus, ResolutionMessage } from '../../../core-shared'
import {
  LightweightChart,
  LightweightChartData,
  LightweightChartType,
} from '../../../core-ui'
import { formatPercents } from '../../../core-utils'
import { getChartTime, getVisibleRange } from '../../utils'
import { TimeSpanTabs } from '../TimeSpanTabs'

export const AnalyticsPerformance: FC = () => {
  const [timeSpan, setTimeSpan] = useState(TimeSpan['1m'])

  const {
    data: performance,
    isLoading,
    refetch,
    status,
  } = useAnalyticsPerformance({
    timeSpan,
  })

  const chartData: LightweightChartData[] = useMemo(
    () => [
      {
        type: LightweightChartType.Area,
        data:
          performance?.points.map((point) => ({
            value: parseFloat(point.change),
            time: getChartTime(point.timestamp),
          })) ?? [],
      },
    ],
    [performance],
  )

  const chartOptions = useMemo(
    () => ({
      localization: {
        priceFormatter: (value: number) => formatPercents(value, { type: 'percent' }),
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
      },
    }),
    [],
  )

  if (status === 'error') {
    return <ErrorStatus onRetry={refetch} />
  }

  const visibleRange = getVisibleRange(chartData)

  return (
    <VStack space="s-16" height="100%" px="s-16" pb="s-16" pt={{ all: 's-16', md: '0' }}>
      <VStack>
        {isLoading ? (
          <TextSkeleton variant="h2" size={8} />
        ) : (
          <Text variant="h2">
            {formatPercents(last(chartData[0]?.data)?.value ?? 0, { type: 'percent' })}
          </Text>
        )}

        <Text variant="caption" color="grey-tone-50" mb="s-16">
          <ResolutionMessage timeSpan={timeSpan} />
        </Text>
      </VStack>

      <LightweightChart
        data={chartData}
        options={chartOptions}
        loading={isLoading}
        visibleRange={visibleRange}
      />

      <TimeSpanTabs value={timeSpan} onChange={setTimeSpan} />
    </VStack>
  )
}
