import { TableFilters } from '@revolut/ui-kit'

import { Trade, TradesFilterParams } from '../../core-api'

export const getTradesFilterParams = (filters: TableFilters<Trade>) =>
  filters.reduce<TradesFilterParams>((result, filter) => {
    if (filter.id === 'execDate' && filter.value) {
      result.execFrom = filter.value.from.getTime()
      result.execTo = filter.value.to.getTime()
    } else {
      result[filter.id] = filter.value
    }

    return result
  }, {})
