export enum CurrencyPairStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface CurrencyPair {
  baseCurrency: string
  baseLotSize: number
  baseLotSizePennies: number
  baseLotSizeScale: number
  maxTradeSize: number
  minTradeSize: number
  quoteCurrency: string
  quoteStep: number
  quoteStepScale: number
  status: CurrencyPairStatus
}

export interface CurrencyPairsResponse {
  [currencyPair: string]: CurrencyPair
}

export type ExtendedCurrencyPair = CurrencyPair & {
  pair: string
  midPrice: number | undefined
  percentageChange24h: number | undefined
  watchlisted: boolean
  // TODO: Remove it once we have 24h volume https://revolut.atlassian.net/browse/PWCE-1592
  marketCap: number | undefined
}
