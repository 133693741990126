import { BalancesResponse } from '../../types'
import { useBalances } from '../useBalances'

const selectBalance = (currency: string) => (balances: BalancesResponse) =>
  balances[currency]

export const useBalance = (currency: string) =>
  useBalances({
    select: selectBalance(currency),
  })
