import { OrderBookExpandedResponse, OrderSide } from '../../core-api'
import { checkRequired } from '../../core-utils'

export type CalcOrderQuantityByOrderBookParams = {
  amount: number
  side: OrderSide
  orderBook: Pick<OrderBookExpandedResponse, 'asks' | 'bids'>
}

export const calcOrderQuantityByOrderBook = (
  params: CalcOrderQuantityByOrderBookParams,
) => {
  const { amount, side, orderBook } = params

  const { asks, bids } = orderBook

  let quantity: number | null = null

  const orders = side === OrderSide.Buy ? asks : bids

  if (orders.length > 0) {
    let restAmount = amount
    let orderIndex = 0

    quantity = 0

    while (restAmount > 0 && orderIndex < orders.length) {
      const order = checkRequired(orders[orderIndex], 'Order should be defined')

      const orderValue = order.amount * order.price

      if (orderValue >= restAmount || orderIndex === orders.length - 1) {
        quantity += restAmount / order.price
      } else {
        quantity += order.amount
      }

      restAmount -= orderValue
      orderIndex += 1
    }
  }

  return quantity
}
