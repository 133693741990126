import axios from 'axios'

import { TransfersParams, TransfersReponse } from '../types'

export const getTransfers = async (params: TransfersParams = {}) => {
  const { data } = await axios.get<TransfersReponse>(
    '/api/crypto-exchange/transfers/page',
    {
      params,
    },
  )

  return data
}
