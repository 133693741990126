import { Item } from '@revolut/ui-kit'
import { FC } from 'react'

import { ExtendedCurrencyPair } from '../../../core-api'
import {
  AssetAvatar,
  CurrencyName,
  CurrencyPair,
  CurrencyPairMoney,
} from '../../../core-shared'
import { Percents } from '../../../core-ui'

interface MarketsGroupItemProps {
  currencyPair: ExtendedCurrencyPair
  selectedCurrencyPair: string
  onSelectCurrencyPair: (currencyPair: string) => void
}

export const MarketsGroupItem: FC<MarketsGroupItemProps> = ({
  currencyPair,
  selectedCurrencyPair,
  onSelectCurrencyPair,
}) => (
  <Item
    use="button"
    aria-pressed={selectedCurrencyPair === currencyPair.pair}
    onClick={() => onSelectCurrencyPair(currencyPair.pair)}
  >
    <Item.Avatar>
      <AssetAvatar
        asset={currencyPair.baseCurrency}
        badgeAsset={currencyPair.quoteCurrency}
      />
    </Item.Avatar>

    <Item.Content>
      <Item.Title>
        <CurrencyPair type="compact" currencyPair={currencyPair.pair} />
      </Item.Title>
      <Item.Description>
        <CurrencyName currency={currencyPair.baseCurrency} />
      </Item.Description>
    </Item.Content>
    <Item.Side>
      <Item.Value>
        <CurrencyPairMoney
          amount={currencyPair.midPrice}
          currencyPair={currencyPair.pair}
          variant="quote"
        />
      </Item.Value>
      <Item.Description>
        <Percents value={currencyPair.percentageChange24h} />
      </Item.Description>
    </Item.Side>
  </Item>
)
