import { PaginatedResponse, PaginationParams } from './common'
import { ErrorResponse } from './error'

export enum OrderSide {
  Buy = 'BUY',
  Sell = 'SELL',
}

export enum OrderType {
  Market = 'MARKET',
  Limit = 'LIMIT',
}

export enum OrderState {
  New = 'NEW',
  PartiallyFilled = 'PARTIALLY_FILLED',
  Filled = 'FILLED',
  Canceled = 'CANCELLED',
  Replaced = 'REPLACED',
  Rejected = 'REJECTED',
}

export enum OrderParty {
  Maker = 'MAKER',
  Taker = 'TAKER',
}

export enum OrderDeclineReason {
  None = 'NONE',
  InvalidQty = 'INVALID_QTY',
  InvalidPrice = 'INVALID_PRICE',
  InvalidLockedFunds = 'INVALID_LOCKED_FUNDS',
  NotEnoughLiquidity = 'NOT_ENOUGH_LIQUIDITY',
  NotEnoughLockedFunds = 'NOT_ENOUGH_LOCKED_FUNDS',
  NotFound = 'NOT_FOUND',
  NotReplaceable = 'NOT_REPLACEABLE',
  InvalidCurrencyPair = 'INVALID_CURRENCY_PAIR',
  DisabledCurrencyPair = 'DISABLED_CURRENCY_PAIR',
  NotEnoughBalance = 'NOT_ENOUGH_BALANCE',
  EmptyReserveAmount = 'EMPTY_RESERVE_AMOUNT',
  SelfMatchingProtection = 'SELF_MATCHING_PROTECTION',
  PriceLimitProtection = 'PRICE_LIMIT_PROTECTION',
  InvalidOrderType = 'INVALID_ORDER_TYPE',
  PrevOrderNotFound = 'PREV_ORDER_NOT_FOUND',
  PrevOrderAnotherSide = 'PREV_ORDER_ANOTHER_SIDE',
  ExchangeClosed = 'EXCHANGE_CLOSED',
}

export interface CreateOrderData {
  clientOrderId: string
  currencyPair: string
  price?: number
  qty?: number
  amount?: number
  side: OrderSide
  orderType: OrderType
  postOnly?: boolean
}

export interface CreateOrderResponse {
  clientId: string
  clientOrderId: string
  currencyPair: string
  orderId: string
  orderType: OrderType
  price: number
  qty: number
  side: OrderSide
  startedDate?: number
  state: string
  postOnly?: boolean
}

export interface CreateOrderErrorResponse extends ErrorResponse {}

export interface ReplaceOrderData extends Omit<CreateOrderData, 'orderType' | 'price'> {
  id: string
  orderType: OrderType.Limit
  price: number
}

export interface ReplaceOrderResponse extends CreateOrderResponse {}

export interface ReplaceOrderErrorResponse extends ErrorResponse {}

export type Order = {
  currencyPair: string
  orderId: string
  clientOrderId: string
  clientId: string
  side: OrderSide
  qty: number
  amount: number
  price: number
  avgPrice?: number
  feeCurrency: string
  state: OrderState
  startedDate: number
  updatedDate: number
  createdDate: number
  completedDate: number
  orderType: OrderType
  leavesQty: number
  totalFee: number
  declineReason: OrderDeclineReason
  postOnly?: boolean
}

export type OrdersFilterParams = {
  currencyPair?: string
  createdFrom?: number
  createdTo?: number
  side?: OrderSide
  type?: OrderType
  state?: OrderState
}

export type OrdersParams = PaginationParams & OrdersFilterParams

export type OrdersReponse = PaginatedResponse<Order>
