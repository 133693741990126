import axios from 'axios'

import { CurrenciesResponse, Currency } from '../types'

export const getCurrencies = async () => {
  const { data } = await axios.get<CurrenciesResponse<Omit<Currency, 'code'>>>(
    '/api/crypto-exchange/config/currencies',
  )

  const currencies: CurrenciesResponse = Object.entries(data).reduce<CurrenciesResponse>(
    (acc, [code, item]) => {
      acc[code] = {
        ...item,
        code,
      }

      return acc
    },
    {},
  )

  return currencies
}
