import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export const useGoBack = (fallbackPath: string) => {
  const history = useHistory()

  const handleGoBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack()
    } else {
      history.push(fallbackPath)
    }
  }, [history, fallbackPath])

  return handleGoBack
}
