import axios from 'axios'

import { HttpHeader } from '../constants'
import { WithdrawData, WithdrawResponse } from '../types'

export const withdraw = async ({ code, codeVerifier, op, ...data }: WithdrawData) => {
  const { data: response } = await axios.put<WithdrawResponse>(
    '/api/crypto-exchange/transfers/withdraw',
    data,
    {
      headers: {
        [HttpHeader.XAuthCode]: code,
        [HttpHeader.XAuthCodeVerifier]: codeVerifier,
        [HttpHeader.XIdempotencyKey]: op,
      },
    },
  )

  return response
}
