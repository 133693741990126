import {
  AccountSwitcherProps,
  AccountSwitcher as UIAccountSwitcher,
} from '@revolut/ui-kit'
import { FC } from 'react'
import styled from 'styled-components'

// TODO: PWCE-2150 Remove once UI kit will provide ability to hide chevron
export const AccountSwitcher = styled<
  FC<
    AccountSwitcherProps & {
      hasChevron?: boolean
    }
  >
>(UIAccountSwitcher)`
  max-width: none;

  ${({ hasChevron }) => {
    if (!hasChevron) {
      return `
        [class*='ButtonLayout__IconWrapper'] {
            display: none;
        }
      `
    }

    return {}
  }}
`
