import { HStack, Separator, Spacer, Text } from '@revolut/ui-kit'
import { FC } from 'react'
import { useIntl } from 'react-intl'

import { useCurrencyPairRequired } from '../../../core-api'
import { useMoneyFormat, useThemePalette } from '../../../core-ui'
import { OrderBookSteps } from '../../hooks'
import { OrderBookStepCounter } from './OrderBookStepCounter'

interface OrderBookMidPriceProps {
  midPrice: number | undefined
  spread: number | undefined
  currencyPair: string
  orderBookSteps: OrderBookSteps
}

export const OrderBookMidPrice: FC<OrderBookMidPriceProps> = ({
  midPrice,
  spread,
  currencyPair,
  orderBookSteps,
}) => {
  const { palette } = useThemePalette()

  const { quoteCurrency } = useCurrencyPairRequired(currencyPair)

  const moneyFormat = useMoneyFormat({
    kind: 'price',
    currency: quoteCurrency,
    clearSymbol: true,
  })

  const { formatMessage } = useIntl()

  if (midPrice === undefined) {
    return null
  }

  return (
    <>
      <Separator bg={palette.separatorColor} />
      <HStack py="s-4" px="s-16" align="center">
        {spread ? (
          <Text variant="small-tile">
            {`${moneyFormat(midPrice)} (${moneyFormat(spread)} ${formatMessage({
              id: 'markets.orderBook.spread.label',
              defaultMessage: 'spread',
            })})`}
          </Text>
        ) : (
          <Text variant="small-tile">{moneyFormat(midPrice)}</Text>
        )}

        <Spacer />

        <OrderBookStepCounter {...orderBookSteps} />
      </HStack>
      <Separator bg={palette.separatorColor} />
    </>
  )
}
