import axios from 'axios'

import { HttpHeader } from '../constants'
import { DepositData, DepositResponse } from '../types'

export const deposit = async ({ code, codeVerifier, op, ...data }: DepositData) => {
  const { data: response } = await axios.put<DepositResponse>(
    '/api/crypto-exchange/transfers/deposit',
    data,
    {
      headers: {
        [HttpHeader.XAuthCode]: code,
        [HttpHeader.XAuthCodeVerifier]: codeVerifier,
        [HttpHeader.XIdempotencyKey]: op,
      },
    },
  )

  return response
}
