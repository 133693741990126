import { FC } from 'react'

import { useCurrencyDetailsRequired } from '../../../core-api'

interface CurrencyNameProps {
  currency: string
}

export const CurrencyName: FC<CurrencyNameProps> = ({ currency }) => {
  const { genericLocalizedName } = useCurrencyDetailsRequired(currency)

  return <>{genericLocalizedName}</>
}
