import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { FormattedEnumMessage } from '../../../core-intl'
import { ChartType } from '../../constants'

interface ChartTypeMessageProps {
  type: ChartType
}

const chartTypeMessages = defineMessages<ChartType>({
  [ChartType.Price]: {
    id: 'terminal.chartType.price.title',
    defaultMessage: 'Price chart',
  },
  [ChartType.Depth]: {
    id: 'terminal.chartType.depth.title',
    defaultMessage: 'Depth chart',
  },
})

export const ChartTypeMessage: FC<ChartTypeMessageProps> = ({ type }) => (
  <FormattedEnumMessage id={type} messages={chartTypeMessages} />
)
