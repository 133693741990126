import { QueryStatus } from '@tanstack/react-query'

export const combineStatuses = (...statuses: QueryStatus[]): QueryStatus => {
  if (statuses.length === 0) {
    throw new Error('Status list is empty')
  }

  if (statuses.includes('error')) {
    return 'error'
  }
  if (statuses.includes('loading')) {
    return 'loading'
  }

  return 'success'
}
