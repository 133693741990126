import { ColorMode, useMatchMedia } from '@revolut/ui-kit'

import { LocalStorageKey, useLocalStorage } from '../../../core-storage'

export type ThemeMode = ColorMode | 'auto'

const resolveThemeMode = (themeMode: ThemeMode, prefersDark: boolean) => {
  if (themeMode === 'auto') {
    return prefersDark ? ColorMode.DARK : ColorMode.LIGHT
  }

  return themeMode
}

export const useThemeMode = () => {
  const [themeMode, setThemeMode] = useLocalStorage<ThemeMode>(
    LocalStorageKey.ThemeMode,
    ColorMode.DARK,
  )

  const prefersDark = useMatchMedia(
    themeMode === 'auto' ? '(prefers-color-scheme: dark)' : null,
  )

  const resolvedThemeMode = resolveThemeMode(themeMode, prefersDark)

  return { themeMode, resolvedThemeMode, setThemeMode }
}
