import { FC, useCallback } from 'react'

import { GridLayout, GridLayoutTemplate } from '../../../core-grid-layout'
import { LocalStorageKey, useLocalStorage } from '../../../core-storage'
import { notReachable } from '../../../core-utils'
import { ActivityWidget } from '../ActivityWidget'
import { ChartWidget } from '../ChartWidget'
import { OrderBookWidget } from '../OrderBookWidget'
import { OrderFormWidget } from '../OrderFormWidget'
import { TerminalProps } from './types'

type GridLayoutTemplateArea = 'PRICE_CHART' | 'ORDER_BOOK' | 'ORDER_FORM' | 'ACTIVITY'

const GRID_TEMPLATE: GridLayoutTemplate<GridLayoutTemplateArea> = {
  rows: [
    {
      type: 'FLUID',
      minHeight: 300,
      ratio: 1,
    },
    {
      type: 'FIXED',
      minHeight: 250,
      maxHeight: 500,
      height: 300,
    },
  ],
  columns: [
    {
      type: 'FLUID',
      minWidth: 550,
      ratio: 1,
    },
    {
      type: 'FIXED',
      width: 315,
      minWidth: 300,
      maxWidth: 400,
      range: 35,
    },
    {
      type: 'FIXED',
      width: 315,
      minWidth: 300,
      maxWidth: 400,
      range: 35,
    },
  ],
  areas: [
    [
      {
        name: 'PRICE_CHART',
      },
      {
        name: 'ORDER_BOOK',
      },
      {
        name: 'ORDER_FORM',
      },
    ],
    [
      {
        name: 'ACTIVITY',
      },
      {
        name: 'ACTIVITY',
      },
      {
        name: 'ORDER_FORM',
      },
    ],
  ],
}

const renderGridLayoutArea = (area: GridLayoutTemplateArea, currencyPair: string) => {
  switch (area) {
    case 'PRICE_CHART':
      return <ChartWidget currencyPair={currencyPair} />
    case 'ORDER_BOOK':
      return <OrderBookWidget currencyPair={currencyPair} />
    case 'ORDER_FORM':
      return <OrderFormWidget currencyPair={currencyPair} />
    case 'ACTIVITY':
      return <ActivityWidget />

    default:
      return notReachable(area)
  }
}

export const TerminalWide: FC<TerminalProps> = ({ currencyPair }) => {
  const [template, setTemplate] = useLocalStorage<
    GridLayoutTemplate<GridLayoutTemplateArea>
  >(LocalStorageKey.TradeGridLayoutTemplate, GRID_TEMPLATE)

  const handleChangeTemplate = useCallback(
    (nextTemplate: GridLayoutTemplate<GridLayoutTemplateArea>) => {
      setTemplate(nextTemplate)
    },
    [setTemplate],
  )

  return (
    <GridLayout template={template} onChange={handleChangeTemplate}>
      {(area: GridLayoutTemplateArea) => renderGridLayoutArea(area, currencyPair)}
    </GridLayout>
  )
}
