import { HeaderSkeleton } from '@revolut/ui-kit'
import { FC } from 'react'

export const SideHeaderSkeleton: FC = () => (
  <HeaderSkeleton variant="item">
    <HeaderSkeleton.CloseButton />
    <HeaderSkeleton.Title />
    <HeaderSkeleton.Subtitle />
    <HeaderSkeleton.Description />
    <HeaderSkeleton.Bar />
    <HeaderSkeleton.Avatar size={56} />
  </HeaderSkeleton>
)
