import { FC } from 'react'

import { RoutePath, getRouteUrl } from '../../../core-utils'
import { Currency, CurrencyProps } from '../Currency/Currency'
import { Link } from '../CurrencyPairLink/styled'

interface CurrencyLinkProps extends CurrencyProps {}

export const CurrencyLink: FC<CurrencyLinkProps> = ({ currency, ...rest }) => {
  const to = getRouteUrl({
    path: RoutePath.Portfolio,
    params: {
      currency,
    },
  })

  return (
    <Currency
      use={Link}
      to={to}
      currency={currency}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    />
  )
}
