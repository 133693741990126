import { useQuery } from '@tanstack/react-query'

import { checkRequired } from '../../../core-utils'
import { getOrder } from '../../api'
import { QueryKey } from '../../constants'

export const useOrder = (id: string | undefined) =>
  useQuery(
    [QueryKey.Order, id],
    () => getOrder(checkRequired(id, 'Order ID should be defined')),
    {
      enabled: !!id,
    },
  )
