import { useToggle } from '@revolut/ui-kit'
import { FC, PropsWithChildren, createContext, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { ErrorCode, HttpCode, OnboardingStep, useOnboardingStep } from '../../../core-api'
import { useAuthContext } from '../../../core-auth'
import { GlobalSpinner } from '../../../core-ui'
import { RestrictedAccessType, RoutePath, getRouteUrl } from '../../../core-utils'
import { OnboardingPopup } from '../../components'
import { useOnboardingMarketingConsentBanner } from '../../hooks'
import { OnboardingContextType } from '../../types'

export const OnboardingContext = createContext<OnboardingContextType | null>(null)

type OnboardingProviderProps = PropsWithChildren<{
  enabled: boolean
}>

export const OnboardingProvider: FC<OnboardingProviderProps> = ({
  enabled,
  children,
}) => {
  const [open, toggleOpen] = useToggle()

  const { status: authStatus } = useAuthContext()

  const history = useHistory()

  const [showOnboardingMarketingConsentBanner] = useOnboardingMarketingConsentBanner()

  const {
    data,
    error,
    status: onboardingStatus,
    refetch,
  } = useOnboardingStep({
    enabled: authStatus === 'authorized',
  })

  useEffect(() => {
    if (error && error.response?.status === HttpCode.UnprocessableEntity) {
      const type =
        error.response?.data?.code === ErrorCode.RegionNotAvailableCode
          ? RestrictedAccessType.Region
          : RestrictedAccessType.Account

      history.replace(
        getRouteUrl({
          path: RoutePath.AuthRestrictedAccess,
          params: {
            type,
          },
        }),
      )
    }
  }, [error, history])

  useEffect(() => {
    if (onboardingStatus === 'success' && authStatus === 'authorized' && enabled) {
      if (data?.nextStep && data.nextStep !== OnboardingStep.None) {
        if (
          data.nextStep === OnboardingStep.MarketingConsent &&
          showOnboardingMarketingConsentBanner
        ) {
          return
        }

        toggleOpen(true)
      } else {
        toggleOpen(false)
      }
    } else {
      toggleOpen(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, enabled])

  const value = useMemo(
    () => ({
      open,
      data,
      toggleOpen,
      shouldShowMarketingConsentBanner:
        showOnboardingMarketingConsentBanner &&
        data?.nextStep === OnboardingStep.MarketingConsent,
      refetch,
    }),
    [data, open, toggleOpen, refetch, showOnboardingMarketingConsentBanner],
  )

  return (
    <OnboardingContext.Provider value={value}>
      <OnboardingPopup />

      {onboardingStatus === 'success' ? children : <GlobalSpinner />}
    </OnboardingContext.Provider>
  )
}
