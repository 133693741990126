import { Box, Page, Side } from '@revolut/ui-kit'
import { useEffect, useState } from 'react'

import { LocalStorageKey, useLocalStorage } from '../../../core-storage'
import { WidgetTabBar } from '../../../core-ui'
import {
  ACTIVITY_TYPE_ORDER,
  ActivityDetailsSide,
  ActivityTable,
  ActivityType,
  ActivityTypeMessage,
} from '../../../feature-activity'
import { WidgetType } from '../../constants'
import { TerminalWidget } from '../TerminalWidget'

export const ActivityWidget = () => {
  const [selectedActivityType, setSelectedActivityType] = useLocalStorage<ActivityType>(
    LocalStorageKey.TradeSelectedActivityType,
    ActivityType.OpenOrders,
  )

  const [selectedRowId, setSelectedRowId] = useState<string | null>(null)

  useEffect(() => {
    setSelectedRowId(null)
  }, [selectedActivityType])

  return (
    <>
      <TerminalWidget type={WidgetType.Activity} overflow="auto" pb="s-16">
        <TerminalWidget.Header>
          <WidgetTabBar>
            {ACTIVITY_TYPE_ORDER.map((type) => (
              <WidgetTabBar.Item
                key={type}
                selected={type === selectedActivityType}
                onClick={() => setSelectedActivityType(type)}
              >
                <ActivityTypeMessage type={type} />
              </WidgetTabBar.Item>
            ))}
          </WidgetTabBar>
        </TerminalWidget.Header>
        <Box px="s-16" height="calc(100% - 44px)" overflow="auto">
          <ActivityTable
            type={selectedActivityType}
            selectedRowId={selectedRowId}
            onSelectRow={setSelectedRowId}
          />
        </Box>
      </TerminalWidget>

      <Page.Side behaviour="cover" usePortal>
        <Side
          open={Boolean(selectedRowId)}
          onClose={() => {
            setSelectedRowId(null)
          }}
          variant="medium"
        >
          {selectedRowId && (
            <ActivityDetailsSide
              type={selectedActivityType}
              id={selectedRowId}
              onClose={() => setSelectedRowId(null)}
            />
          )}
        </Side>
      </Page.Side>
    </>
  )
}
