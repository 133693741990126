import { Group } from '@revolut/ui-kit'
import { FC, useMemo } from 'react'

import {
  ExtendedCurrencyPair,
  SortingRule,
  useStableSortedCurrencyPairs,
} from '../../../core-api'
import { EmptyStatus, EmptyStatusType } from '../../../core-shared'
import { matchFilterValue } from '../../../core-utils'
import { ALL_PAIRS_FILTER_OPTION } from '../../constants'
import { MarketsGroupItem } from './MarketsGroupItem'

interface MarketsGroupProps {
  search?: string
  quoteCurrency?: string

  selectedCurrencyPair: string
  onSelectCurrencyPair: (currencyPair: string) => void
}

const SORT_BY: SortingRule<ExtendedCurrencyPair>[] = [{ id: 'marketCap', desc: true }]

export const MarketsGroup: FC<MarketsGroupProps> = ({
  search,
  quoteCurrency,
  selectedCurrencyPair,
  onSelectCurrencyPair,
}) => {
  const currencyPairList = useStableSortedCurrencyPairs(SORT_BY)

  const filteredCurrencyPairList = useMemo(
    () =>
      currencyPairList.data?.filter((pair) => {
        let result = matchFilterValue(pair.pair, search)

        if (quoteCurrency !== ALL_PAIRS_FILTER_OPTION.value) {
          result = result && quoteCurrency === pair.quoteCurrency
        }
        return result
      }) ?? [],
    [currencyPairList.data, quoteCurrency, search],
  )

  if (filteredCurrencyPairList.length === 0) {
    return (
      <EmptyStatus
        type={EmptyStatusType.CurrencyPairs}
        height="auto"
        style={{ flex: 1 }}
      />
    )
  }

  return (
    <Group>
      {filteredCurrencyPairList.map((item) => (
        <MarketsGroupItem
          key={item.pair}
          currencyPair={item}
          selectedCurrencyPair={selectedCurrencyPair}
          onSelectCurrencyPair={onSelectCurrencyPair}
        />
      ))}
    </Group>
  )
}
