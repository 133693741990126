import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { TimeSpan } from '../../../core-api'
import { FormattedEnumMessage } from '../../../core-intl'

interface ResolutionMessageProps {
  timeSpan: TimeSpan
}

const resolutionMessages = defineMessages<TimeSpan>({
  [TimeSpan['1d']]: {
    id: 'shared.timeSpan.1d.title',
    defaultMessage: 'Today',
  },
  [TimeSpan['1w']]: {
    id: 'shared.timeSpan.1w.title',
    defaultMessage: 'Past week',
  },
  [TimeSpan['1m']]: {
    id: 'shared.timeSpan.1m.title',
    defaultMessage: 'Past month',
  },
  [TimeSpan['6m']]: {
    id: 'shared.timeSpan.6m.title',
    defaultMessage: 'Past 6 months',
  },
  [TimeSpan['1y']]: {
    id: 'shared.timeSpan.1y.title',
    defaultMessage: 'Past year',
  },
  [TimeSpan.All]: {
    id: 'shared.timeSpan.max.title',
    defaultMessage: 'All time',
  },
})

export const ResolutionMessage: FC<ResolutionMessageProps> = ({ timeSpan }) => {
  return <FormattedEnumMessage id={timeSpan} messages={resolutionMessages} />
}
