import { CSSProperties } from 'react'

import { getElement } from '../../utils'
import { useClientRect } from '../useClientRect'
import { useElementPosition } from '../useElementPosition'

export const useSpotlightStyles = ({ target }: { target: HTMLElement | string }) => {
  const element = getElement(target)

  const elementRect = useClientRect(element)
  const top = useElementPosition({
    element,
  })

  const styles: CSSProperties = {
    backgroundColor: 'gray',
    borderRadius: 16,
    position: 'absolute',
    height: Math.round(elementRect?.height ?? 0),
    left: Math.round(elementRect?.left ?? 0),
    opacity: 1,
    top,
    transition: 'opacity 0.2s',
    width: Math.round(elementRect?.width ?? 0),
    transform: 'translate3d(0, 0, 0)',
  }

  return styles
}
