import {
  ActionButton,
  Box,
  Flex,
  IconButton,
  Link,
  Switch,
  Text,
  VStack,
  useToggle,
} from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { ConfigKey, getConfigValue } from '../../../core-config'
import { useIntl } from '../../../core-intl'

interface PreferencesScreenProps {
  isAnalyticsEnabled?: boolean
  onClickBack: () => void
  onClickSave: (isAnalyticsEnabled: boolean) => void
}

export const PreferencesScreen: FC<PreferencesScreenProps> = ({
  isAnalyticsEnabled = true,
  onClickBack,
  onClickSave,
}) => {
  const { formatMessage } = useIntl()

  const [acceptCookies, toggleAcceptCookies] = useToggle({
    defaultState: isAnalyticsEnabled,
  })

  return (
    <VStack space="s-32">
      <Flex>
        <IconButton
          aria-label={formatMessage({
            id: 'labels.back',
            defaultMessage: 'Back',
          })}
          useIcon="BackButtonArrow"
          onClick={onClickBack}
        />

        <Text use="h5" variant="primary" mx="auto" width="100%" textAlign="center">
          <FormattedMessage
            id="shared.cookies.preferences.title"
            defaultMessage="Cookie preferences"
          />
        </Text>
      </Flex>

      <Text use="p" variant="caption" mt="-s-8">
        <FormattedMessage
          id="shared.cookies.preferences.description"
          defaultMessage="We're not talking about the crunchy, tasty kind. These cookies help us keep our website safe, give you a better experience and show more relevant ads. We won't turn on unless you accept. Want to know more or adjust your preferences?"
        />
      </Text>

      <Box>
        <Text use="h6" mb="s-12">
          <FormattedMessage
            id="shared.cookies.preferences.essentialCookies.title"
            defaultMessage="Essential cookies"
          />
        </Text>

        <Text variant="caption">
          <FormattedMessage
            id="shared.cookies.preferences.essentialCookies.description"
            defaultMessage="Essential cookies help you to access the website and each of its web pages and sub domains, by enabling basic functions like cookie consent. They cannot be disabled."
          />
        </Text>
      </Box>

      <Box>
        <Flex use="label" mb="s-12" alignItems="center" justifyContent="space-between">
          <Text use="h6" mr="s-12">
            <FormattedMessage
              id="shared.cookies.preferences.advertisingCookies.title"
              defaultMessage="Analytics and advertising cookies"
            />
          </Text>

          <Switch checked={acceptCookies} onClick={() => toggleAcceptCookies()} />
        </Flex>

        <Text variant="caption">
          <FormattedMessage
            id="shared.cookies.preferences.advertisingCookies.description"
            defaultMessage="These allow us to recognise and count the number of visitors to our website, and see how visitors browse our website, so we can improve it where necessary. These also allow us to see what pages and links you have visited so we can provide more relevant ads. We may share this information with other organisations, such as Google, Facebook and LinkedIn, for the same purpose."
          />
        </Text>
      </Box>

      <Link
        target="_blank"
        rel="noopener noreferrer"
        variant="caption"
        display="inline-block"
        href={getConfigValue(ConfigKey.CookiesPolicyLink)}
      >
        <FormattedMessage
          id="shared.cookies.preferences.policyLink"
          defaultMessage="Read our full cookie policy"
        />
      </Link>

      <ActionButton onClick={() => onClickSave(acceptCookies)} width="100%">
        <FormattedMessage
          id="actions.saveCookieSettings"
          defaultMessage="Save cookie settings"
        />
      </ActionButton>
    </VStack>
  )
}
