import { BoxProps, Widget as UIWidget, VStack } from '@revolut/ui-kit'
import { FC } from 'react'

import { WidgetErrorBoundary } from './WidgetErrorBoundary'
import { WidgetHeader } from './WidgetHeader'

export interface WidgetProps extends BoxProps {}

const WidgetRoot: FC<WidgetProps> = ({ children, ...rest }) => (
  <UIWidget {...rest}>
    <VStack overflow="auto" height="100%">
      <WidgetErrorBoundary>{children}</WidgetErrorBoundary>
    </VStack>
  </UIWidget>
)

export const Widget = Object.assign(WidgetRoot, {
  Header: WidgetHeader,
})
