import { chain } from '@revolut/ui-kit'
import { FC } from 'react'

import { OrderState, OrderDeclineReason } from '../../../core-api'
import { OrderDeclineReasonMessage } from '../OrderDeclineReasonMessage'
import { OrderStateMessage } from '../OrderStateMessage'

interface OrderStatusMessageProps {
  state: OrderState
  declineReason: OrderDeclineReason
}

export const OrderStatusMessage: FC<OrderStatusMessageProps> = ({
  state,
  declineReason,
}) => {
  if (state === OrderState.Rejected) {
    return (
      <>
        {chain(
          <OrderStateMessage state={state} />,
          <OrderDeclineReasonMessage declineReason={declineReason} />,
        )}
      </>
    )
  }

  return <OrderStateMessage state={state} />
}
