import { Bar, FilterButton } from '@revolut/ui-kit'
import { FC } from 'react'

import { Currency } from '../../../core-api'
import { AssetAvatar } from '../../../core-shared'

interface CurrencyBarProps {
  currencies: Currency[]
  currency: string
  onChange: (currency: string) => void
}

export const CurrencyBar: FC<CurrencyBarProps> = ({ currencies, currency, onChange }) => (
  <Bar>
    {currencies.map((item) => (
      <FilterButton
        key={item.code}
        useIcon={() => <AssetAvatar asset={item.code} size={20} />}
        onClick={() => onChange(item.code)}
        active={currency === item.code}
      >
        {item.code}
      </FilterButton>
    ))}
  </Bar>
)
