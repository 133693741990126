import { Table, TableColumn } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

import { Trade, useCurrencyPairRequired } from '../../../core-api'
import {
  CurrencyPairLink,
  CurrencyPairMoney,
  NoFeesLabel,
  TableFilterDateRange,
} from '../../../core-shared'
import { DateTimeTableCell, Money } from '../../../core-ui'
import { OrderSideTableCell } from '../OrderSideTableCell'
import { TableFilterCurrencyPair } from '../TableFilterCurrencyPair'
import { TableFilterOrderSide } from '../TableFilterOrderSide'

export const COLUMNS_ADVANCED: TableColumn<Trade>[] = [
  {
    Header: <FormattedMessage id="activity.table.pair.caption" defaultMessage="Pair" />,
    accessor: 'currencyPair',
    Cell: ({ value }) => (
      <Table.Cell>
        <CurrencyPairLink currencyPair={value} size={24} variant="small" />
      </Table.Cell>
    ),
    Filter: ({ column }) => <TableFilterCurrencyPair column={column} />,
  },
  {
    Header: (
      <FormattedMessage
        id="activity.table.timeExecuted.caption"
        defaultMessage="Time executed"
      />
    ),
    accessor: 'execDate',
    Cell: ({ value }) => <DateTimeTableCell date={new Date(value)} />,
    Filter: ({ column }) => <TableFilterDateRange column={column} />,
  },
  {
    Header: (
      <FormattedMessage
        id="activity.table.direction.caption"
        defaultMessage="Direction"
      />
    ),
    accessor: 'side',
    Cell: ({ value }) => <OrderSideTableCell side={value} />,
    Filter: ({ column }) => <TableFilterOrderSide column={column} />,
  },
  {
    Header: (
      <FormattedMessage id="shared.order.quantity.label" defaultMessage="Quantity" />
    ),
    accessor: 'qty',
    align: 'end',
    Cell: ({ value, row }) => (
      <Table.Cell>
        <CurrencyPairMoney
          variant="base"
          currencyPair={row.original.currencyPair}
          amount={value}
        />
      </Table.Cell>
    ),
  },
  {
    Header: <FormattedMessage id="activity.table.price.caption" defaultMessage="Price" />,
    accessor: 'price',
    align: 'end',
    Cell: ({ value, row }) => (
      <Table.Cell>
        <CurrencyPairMoney
          kind="price"
          variant="quote"
          currencyPair={row.original.currencyPair}
          amount={value}
        />
      </Table.Cell>
    ),
  },
  {
    Header: <FormattedMessage id="shared.order.value.label" defaultMessage="Value" />,
    id: 'total',
    accessor: ({ amount, fee }) => amount - fee,
    align: 'end',
    Cell: ({ value, row }) => {
      const pair = useCurrencyPairRequired(row.original.currencyPair)

      return (
        <Table.Cell>
          <Money kind="total" currency={pair.quoteCurrency} amount={value} />
        </Table.Cell>
      )
    },
  },

  {
    Header: <FormattedMessage id="activity.table.fee.caption" defaultMessage="Fee" />,
    accessor: 'fee',
    align: 'end',
    Cell: ({ value, row }) => (
      <Table.Cell>
        {value === 0 ? (
          <NoFeesLabel />
        ) : (
          <Money currency={row.original.feeCurrency} amount={value} />
        )}
      </Table.Cell>
    ),
  },
]
