import isString from 'lodash/isString'
import keys from 'lodash/keys'

import { StorageItem } from '../types'
import { CookieStorage } from './cookieStorage'

export class BaseStorage<T extends string> {
  storage: Storage

  prefix: string

  constructor(storage: Storage, prefix: string) {
    this.storage = storage
    this.prefix = prefix
  }

  getItemValue<V = string>(key: T): { ts: number; payload: V } | null {
    const value = this.storage.getItem(this.getPrefixedKey(key))

    if (!isString(value)) {
      return null
    }

    try {
      return JSON.parse(value)
    } catch {
      return null
    }
  }

  getItem<V = string>(key: T): V | null {
    return this.getItemValue<V>(key)?.payload ?? null
  }

  setItem(key: T, payload: unknown) {
    const value: StorageItem = {
      ts: Date.now(),
      payload,
    }

    this.storage.setItem(this.getPrefixedKey(key), JSON.stringify(value))
  }

  removeItem(key: T) {
    this.storage.removeItem(this.getPrefixedKey(key))
  }

  getAll() {
    if (this.storage instanceof CookieStorage) {
      return this.storage.getAll()
    }

    throw new Error('Not implemented')
  }

  clear({ exclude }: { exclude: ReadonlyArray<T> } = { exclude: [] }) {
    const prefixedExclude = exclude.map(this.getPrefixedKey)

    keys(this.storage)
      .filter((key) => key.startsWith(this.prefix))
      .filter((key) => !prefixedExclude.includes(key))
      .forEach((key) => {
        this.storage.removeItem(key)
      })
  }

  getPrefixedKey(key: string) {
    return `${this.prefix}${key}`
  }
}
