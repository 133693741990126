import { ReactNode } from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'

import { isKnownMessageKey } from '../../utils'

type FormattedEnumMessageProps<T extends string | number> = {
  id: T
  messages: Record<T, MessageDescriptor>
  fallback?: ReactNode
  values?: Record<string, string | number | JSX.Element>
}

export const FormattedEnumMessage = <T extends string | number>({
  id,
  messages,
  fallback = null,
  values = {},
}: FormattedEnumMessageProps<T>) => {
  if (!isKnownMessageKey(id, messages)) {
    return <>{fallback}</>
  }

  const message = messages[id]

  return <FormattedMessage {...message} values={values} />
}
