import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getWallet } from '../../api'
import { QueryKey } from '../../constants'
import { Wallet } from '../../types'

export const useWallet = <T extends unknown = Wallet>(
  options: UseQueryOptions<Wallet, AxiosError, T> = {},
) => useQuery<Wallet, AxiosError, T>([QueryKey.Wallet], () => getWallet(), options)
