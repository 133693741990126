import axios from 'axios'

import { ChatAccessTokensResponse } from '../types'

export const getChatAccessToken = async (chatAppVersion: string, deviceId: string) => {
  const { data } = await axios.post<ChatAccessTokensResponse>(
    '/api/crypto-exchange/chat/sign-in',
    { chatAppVersion },
    {
      headers: {
        'x-device-id': deviceId,
        'x-browser-application': 'WEB_CLIENT',
        'x-client-version': '100.0',
      },
    },
  )

  return data
}
