import { VStack } from '@revolut/ui-kit'
import { FC } from 'react'

import {
  DetailsCellGroupItems,
  DetailsCellGroup,
  DetailsCellGroupItem,
} from '../DetailsCellGroup'

interface DetailsSideContentProps {
  items: DetailsCellGroupItems
}

export const DetailsSideContent: FC<DetailsSideContentProps> = ({ items }) => {
  return (
    <VStack space="s-16">
      {items.map((item) => {
        if (Array.isArray(item)) {
          return <DetailsCellGroup items={item} />
        }

        return <DetailsCellGroupItem {...item} />
      })}
    </VStack>
  )
}
