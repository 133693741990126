import { Token, useMatchMedia } from '@revolut/ui-kit'
import * as Sentry from '@sentry/react'
import * as Bowser from 'bowser'
import { useEffect } from 'react'

import { Feature, useFeatures } from '../../../core-features'
import { BROWSER_CHECK_TREE } from '../../constants'

type PlatformType = 'desktop' | 'mobile' | 'tablet' | 'tv'

export const useCompatibilityCheck = () => {
  const isScreenSizeCompatible = useMatchMedia(Token.media.md)

  const bowser = Bowser.getParser(window.navigator.userAgent)

  const { isFeatureEnabled } = useFeatures()

  const isMobileDevicesEnabled = isFeatureEnabled(Feature.MobileDevices)

  const platformType = bowser.getPlatformType() as PlatformType

  const isBrowserCompatible =
    platformType === 'desktop' || !isMobileDevicesEnabled
      ? bowser.satisfies(BROWSER_CHECK_TREE)
      : true

  useEffect(() => {
    if (!isBrowserCompatible) {
      Sentry.captureMessage('Browser not compatible')
    }
  }, [isBrowserCompatible])

  return {
    isScreenSizeCompatible: isMobileDevicesEnabled ? true : isScreenSizeCompatible,
    isBrowserCompatible,
    platformType,
  }
}
