import { Avatar, Item, Token, createChain } from '@revolut/ui-kit'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import {
  Order,
  OrderSide,
  OrderState,
  OrderType,
  useCurrencyPairRequired,
} from '../../../core-api'
import { AssetAvatar, CurrencyPairMoney } from '../../../core-shared'
import { Money } from '../../../core-ui'
import { RoutePath, getRouteUrl } from '../../../core-utils'
import { OrderDeclineReasonMessage } from '../OrderDeclineReasonMessage'
import { OrderSubtitleMessage } from '../OrderDetailsSide'

interface OrdersGroupItemProps {
  order: Order
  onClick?: (id: string) => void
}

export const OrdersGroupItem: FC<OrdersGroupItemProps> = ({ order, onClick }) => {
  const {
    currencyPair,
    orderType,
    side,
    qty,
    price,
    avgPrice,
    orderId,
    state,
    declineReason,
  } = order

  const { baseCurrency, quoteCurrency } = useCurrencyPairRequired(currencyPair)

  const isMarketOrder = orderType === OrderType.Market

  const total = isMarketOrder && avgPrice ? qty * avgPrice : qty * price

  const orderLink = getRouteUrl({
    path: RoutePath.ActivityOrderHistory,
    params: { id: orderId },
  })

  const handleItemClick = () => {
    if (onClick) {
      onClick(orderId)
    }
  }

  const itemProps = onClick ? { onClick: handleItemClick } : { use: Link, to: orderLink }

  const valueTextDecoration = [OrderState.Canceled, OrderState.Rejected].includes(state)
    ? 'line-through'
    : undefined

  return (
    <Item use="button" {...itemProps}>
      <Item.Avatar>
        <AssetAvatar variant="brand" asset={baseCurrency} size={40}>
          {state === OrderState.Canceled && (
            <Avatar.Badge bg={Token.color.greyTone50} useIcon="Reverted" />
          )}
          {state === OrderState.Rejected && (
            <Avatar.Badge bg={Token.color.danger} useIcon="Cross" />
          )}
          {state === OrderState.PartiallyFilled && (
            <Avatar.Badge bg={Token.color.orange} useIcon="16/SandWatch" />
          )}
        </AssetAvatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          <OrderSubtitleMessage
            variant="text"
            currencyPair={currencyPair}
            orderType={orderType}
            side={side}
          />
        </Item.Title>
        <Item.Description>
          {createChain(' · ')(
            <CurrencyPairMoney variant="base" currencyPair={currencyPair} amount={qty} />,
            <CurrencyPairMoney
              kind="price"
              variant="quote"
              currencyPair={currencyPair}
              amount={isMarketOrder ? avgPrice ?? price : price}
            />,
            state === OrderState.Rejected ? (
              <OrderDeclineReasonMessage declineReason={declineReason} />
            ) : null,
          )}
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value textDecoration={valueTextDecoration}>
          <Money
            kind="total"
            currency={quoteCurrency}
            amount={side === OrderSide.Buy && total !== 0 ? -total : total}
          />
        </Item.Value>
      </Item.Side>
    </Item>
  )
}
