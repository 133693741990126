import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getCurrentUser } from '../../api'
import { QueryKey } from '../../constants'
import { CurrentUser } from '../../types'

export const useCurrentUser = <T extends unknown = CurrentUser>(
  options: UseQueryOptions<CurrentUser, AxiosError, T> = {},
) =>
  useQuery<CurrentUser, AxiosError, T>(
    [QueryKey.CurrentUser],
    () => getCurrentUser(),
    options,
  )
