import { CurrencyProvider as UICurrencyProvider } from '@revolut/ui-kit'
import { FC, PropsWithChildren, useMemo } from 'react'

import {
  CurrencyAssetType,
  useCurrencyDetailsRequired,
  useCurrencyPairRequired,
} from '../../../core-api'

interface CurrencyPairProviderProps extends PropsWithChildren {
  currencyPair: string
}

export const CurrencyPairProvider: FC<CurrencyPairProviderProps> = ({
  currencyPair,
  children,
}) => {
  const pair = useCurrencyPairRequired(currencyPair)

  const baseCurrency = useCurrencyDetailsRequired(pair.baseCurrency)
  const quoteCurrency = useCurrencyDetailsRequired(pair.quoteCurrency)

  const currencies = useMemo(
    () => [
      {
        code: baseCurrency.code,
        symbol: baseCurrency.symbol,
        fractionalPart: Math.max(pair.baseLotSizeScale, 0),
        crypto: baseCurrency.type === CurrencyAssetType.Crypto,
      },
      {
        code: quoteCurrency.code,
        symbol: quoteCurrency.symbol,
        fractionalPart: Math.max(pair.quoteStepScale, 0),
        crypto: quoteCurrency.type === CurrencyAssetType.Crypto,
      },
    ],
    [pair, baseCurrency, quoteCurrency],
  )

  return <UICurrencyProvider currencies={currencies}>{children}</UICurrencyProvider>
}
