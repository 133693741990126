import { FC } from 'react'

import { EVENT_TRACKER_KEYS } from '../../constants'

interface EventTrackerHiddenHeaderProps {
  screen: keyof typeof EVENT_TRACKER_KEYS.screens
}

export const EventTrackerHiddenHeader: FC<EventTrackerHiddenHeaderProps> = ({
  screen,
}) => {
  return (
    <h1 data-event-key={EVENT_TRACKER_KEYS.screens[screen]} style={{ display: 'none' }}>
      {screen}
    </h1>
  )
}
