import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { TimeSpan } from '../../../core-api'
import { FormattedEnumMessage } from '../../../core-intl'

interface TimeSpanTabsMessageProps {
  timeSpan: TimeSpan
}

const timeSpanMessages = defineMessages<TimeSpan>({
  [TimeSpan['1d']]: {
    id: 'portfolio.analytics.timeSpan.1d.label',
    defaultMessage: '1D',
  },
  [TimeSpan['1w']]: {
    id: 'portfolio.analytics.timeSpan.1w.label',
    defaultMessage: '1W',
  },
  [TimeSpan['1m']]: {
    id: 'portfolio.analytics.timeSpan.1m.label',
    defaultMessage: '1M',
  },
  [TimeSpan['6m']]: {
    id: 'portfolio.analytics.timeSpan.6m.label',
    defaultMessage: '6M',
  },
  [TimeSpan['1y']]: {
    id: 'portfolio.analytics.timeSpan.1y.label',
    defaultMessage: '1Y',
  },
  [TimeSpan.All]: {
    id: 'portfolio.analytics.timeSpan.max.label',
    defaultMessage: 'All',
  },
})

export const TimeSpanTabsMessage: FC<TimeSpanTabsMessageProps> = ({ timeSpan }) => {
  return <FormattedEnumMessage id={timeSpan} messages={timeSpanMessages} />
}
