import { GridLayoutResizerType, GridLayoutTemplate } from '../types'
import { resizeGridLayoutColumns } from './resizeGridLayoutColumns'
import { resizeGridLayoutRows } from './resizeGridLayoutRows'

export const resizeGridLayout = <T extends string>(
  template: GridLayoutTemplate<T>,
  startIndex: number,
  endIndex: number,
  dxy: number,
  type: GridLayoutResizerType,
): GridLayoutTemplate<T> => {
  if (type === 'column') {
    return {
      ...template,
      columns: resizeGridLayoutColumns(template.columns, startIndex, endIndex, dxy),
    }
  }

  return {
    ...template,
    rows: resizeGridLayoutRows(template.rows, startIndex, endIndex, dxy),
  }
}
