import { Page } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useGoBack } from '../../../core-shared'
import { getRouteUrl, RoutePath } from '../../../core-utils'
import { Marketing } from '../Marketing'

export const NotificationSettings: FC = () => {
  const handleGoBack = useGoBack(getRouteUrl({ path: RoutePath.Profile }))

  return (
    <>
      <Page.Header onBack={handleGoBack}>
        <FormattedMessage
          id="profile.notificationSettings.title"
          defaultMessage="Notification settings"
        />
      </Page.Header>

      <Page.Main>
        <Marketing />
      </Page.Main>
    </>
  )
}
