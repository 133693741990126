import { Ask, Bid } from '../types'

export const calcOrderBookSpread = ({
  bids,
  asks,
}: {
  bids: Array<Bid>
  asks: Array<Ask>
}) => {
  const lastAsk = asks[0]
  const firstBid = bids[0]

  if (!lastAsk || !firstBid) return undefined

  const lastAskPrice = lastAsk.price
  const firstBidPrice = firstBid.price

  return Math.abs(lastAskPrice - firstBidPrice)
}
