import { CurrencyPair, CurrencyPairStatus, CurrencyPairsResponse } from '../../core-api'

export const getUniqueQuoteCurrencies = (
  currencyPairs: CurrencyPairsResponse,
): string[] =>
  Array.from(
    new Set(
      Object.values(currencyPairs)
        .filter(
          (currencyPair: CurrencyPair) =>
            currencyPair.status === CurrencyPairStatus.Active,
        )
        .map((currencyPair: CurrencyPair) => currencyPair.quoteCurrency),
    ),
  )
