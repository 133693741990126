import { Icon } from '@revolut/ui-kit'
import { FC, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { WatchlistOperationType, useUpdateWatchlist } from '../../../core-api'
import { useStatusPopup } from '../../../core-ui'

interface WatchlistStarProps {
  currencyPair: string
  checked: boolean
}

export const WatchlistStar: FC<WatchlistStarProps> = ({ currencyPair, checked }) => {
  const statusPopup = useStatusPopup()

  const { mutate } = useUpdateWatchlist({
    onError: () => {
      statusPopup.showError({
        title: (
          <FormattedMessage
            id="errors.general.title"
            defaultMessage="Something went wrong"
          />
        ),
      })
    },
  })

  const handleToggle = useCallback(
    (event) => {
      event.stopPropagation()

      const type = checked ? WatchlistOperationType.Remove : WatchlistOperationType.Add

      mutate({
        operations: [
          {
            type,
            currencyPair,
          },
        ],
      })
    },
    [checked, mutate, currencyPair],
  )

  return (
    <Icon
      color={checked ? 'yellow' : 'grey-tone-50'}
      name={checked ? 'StarFilled' : 'StarEmpty'}
      size={16}
      onClick={handleToggle}
    />
  )
}
