import { SelectOptionItemType, TableData, TableFilterProps } from '@revolut/ui-kit'

import { OrderSide } from '../../../core-api'
import { useIntl } from '../../../core-intl'
import { TableFilterRadioSelect } from '../../../core-shared'
import { OrderSideMessage } from '../OrderSideMessage'

interface TableFilterOrderSideProps<T extends TableData> {
  column: TableFilterProps<T>['column']
}

const OPTIONS_ORDER: OrderSide[] = [OrderSide.Buy, OrderSide.Sell]

const OPTIONS: SelectOptionItemType<OrderSide>[] = OPTIONS_ORDER.map((side) => ({
  key: side,
  label: <OrderSideMessage side={side} />,
  value: side,
}))

export const TableFilterOrderSide = <T extends TableData>({
  column,
}: TableFilterOrderSideProps<T>) => {
  const { formatMessage } = useIntl()

  return (
    <TableFilterRadioSelect
      aria-label={formatMessage({
        id: 'labels.filterByOrderSide',
        defaultMessage: 'Filter by order side',
      })}
      column={column}
      options={OPTIONS}
    />
  )
}
