// TODO: Remove it once we have 24h volume https://revolut.atlassian.net/browse/PWCE-1592
export const MARKET_CAP_BY_CURRENCY = {
  BTC: 1413906916759,
  ETH: 487058838640,
  SOL: 66071141865,
  XRP: 34259089980,
  DOGE: 25359011632,
  MATIC: 11319088291,
  LTC: 7113863734,
  DOT: 14380415657,
  BCH: 8705376699,
  ADA: 26068013324,
  SHIB: 20112384888,
  LINK: 12646319160,
  RNDR: 4733400763,
  XLM: 4196908306,
  GRT: 4211396594,
  AVAX: 17514863660,
  ALGO: 2326487782,
  USDC: 30163922887,
  USDT: 102037142401,
  FET: 2938882034,
  ACH: 168548262,
  FIL: 5918972784,
  ATOM: 5295337066,
  ETC: 5458363781,
  STX: 4654793781,
  MANA: 1455396331,
  QNT: 1730593054,
  ICP: 6642574606,
  DAI: 4569503226,
  MASK: 493493199,
  INJ: 3629974442,
  HBAR: 4373412843,
  AAVE: 2009911687,
  MKR: 2422693841,
  APE: 1416236427,
  LRC: 550408589,
  JASMY: 1081062680,
  GALA: 2703782982,
  UNI: 10780383921,
  LDO: 2989913331,
  SNX: 1475355990,
  XTZ: 1326999671,
  CRV: 960616504,
  EOS: 1356402975,
  APT: 4937830416,
  KAVA: 1163369964,
  OP: 4599806370,
  '1INCH': 738076447,
  COMP: 612343231,
  SAND: 1760807758,
  IMX: 4915067530,
  MINA: 1806266732,
  YFI: 339572143,
  CHZ: 1363908317,
  ANKR: 522704349,
  NEAR: 7206657613,
  FLOW: 2193284457,
  OCEAN: 770686761,
  DASH: 488241204,
  BAT: 546612893,
  FTM: 2377648215,
  ZRX: 866425627,
  SUSHI: 382381893,
  ENJ: 940516631,
  EGLD: 1838495803,
  AXS: 1746042694,
  AMP: 298314992,
  CRO: 4469892987,
  SYN: 294394897,
  RPL: 732088638,
  OMG: 171098475,
  KSM: 481310156,
  CTSI: 293533655,
  FLR: 1421608930,
  HFT: 186449053,
  KNC: 164888369,
  REN: 99458607,
  BIT: 1996476227,
  GMT: 673719240,
  CELO: 639851803,
  RAD: 126275851,
  ENS: 791731139,
  AUDIO: 384978208,
  SKL: 536846814,
  NKN: 135398177,
  BAND: 370671519,
  STG: 166612588,
  UMA: 366628663,
  RLC: 310683467,
  LPT: 661367613,
  STORJ: 126812475,
  BAL: 324329801,
  CELR: 193193333,
  ARPA: 77919145,
  GAL: 498030875,
  IDEX: 70075452,
  AGLD: 141275458,
  TRU: 91426885,
  BICO: 442385009,
  NMR: 291877988,
  COTI: 369585307,
  API3: 406929137,
  SPELL: 188964617,
  PERP: 122211350,
  GTC: 144654217,
  CVC: 119665530,
  POND: 290161622,
  OXT: 96505443,
}
