import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getAnalyticsProfitAndLoss } from '../../api'
import { QueryKey } from '../../constants'
import { AnalyticsResponse, AnalyticsParams, ProfitAndLossPoint } from '../../types'

export const useAnalyticsProfitAndLoss = <
  T extends unknown = AnalyticsResponse<ProfitAndLossPoint>,
>(
  params: AnalyticsParams,
) =>
  useQuery<AnalyticsResponse<ProfitAndLossPoint>, AxiosError, T>(
    [QueryKey.AnalyticsProfitAndLoss, params],
    () => getAnalyticsProfitAndLoss(params),
  )
