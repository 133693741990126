export const waitForElement = (selector: string, timeout = 5000) => {
  const syncElement = document.querySelector(selector)

  if (syncElement) {
    return Promise.resolve(syncElement)
  }

  return new Promise((resolve, reject) => {
    const observer = new MutationObserver((_, obs) => {
      const element = document.querySelector(selector)

      if (element) {
        resolve(element)

        obs.disconnect()

        clearTimeout(timer)
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    const timer = setTimeout(() => {
      observer.disconnect()

      reject(
        new Error(
          `Timeout reached: No element found for selector '${selector}' within ${timeout}ms`,
        ),
      )
    }, timeout)
  })
}
