import { Text, VStack, ActionButton, HStack } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface ConsentScreenProps {
  onClickAllow: VoidFunction
  onClickReject: VoidFunction
  onClickManage: VoidFunction
}

export const ConsentScreen: FC<ConsentScreenProps> = ({
  onClickAllow,
  onClickReject,
  onClickManage,
}) => {
  return (
    <VStack space="s-16">
      <Text use="h5" variant="primary">
        <FormattedMessage
          id="shared.cookies.consent.title"
          defaultMessage="Cookies give you a personalised experience"
        />
      </Text>

      <Text use="p" variant="caption">
        <FormattedMessage
          id="shared.cookies.consent.description"
          defaultMessage="We're not talking about the crunchy, tasty kind. These cookies help us keep our website safe, give you a better experience and show more relevant ads. We won't turn them on unless you accept. Want to know more or adjust your preferences?"
        />
      </Text>

      <HStack space="s-16">
        <ActionButton onClick={onClickAllow} variant="accent" css={{ flex: 1 }}>
          <FormattedMessage
            id="actions.allowCookies"
            defaultMessage="Allow all cookies"
          />
        </ActionButton>

        <ActionButton onClick={onClickReject} variant="accent" css={{ flex: 1 }}>
          <FormattedMessage
            id="actions.rejectCookies"
            defaultMessage="Reject all cookies"
          />
        </ActionButton>
      </HStack>

      <ActionButton onClick={onClickManage} width="100%">
        <FormattedMessage id="actions.manageCookies" defaultMessage="Manage cookies" />
      </ActionButton>
    </VStack>
  )
}
