import { last } from 'lodash'

import { Ask, Bid, OrderBookExpandedResponse } from '../../core-api'

const MAX_VOLUME_PERCENTILE = 1

const findPriceByPercentile = (orders: (Bid | Ask)[], volumePercentile: number) => {
  const totalVolume = orders.reduce((sum, order) => sum + order.amount, 0)

  const targetVolume = totalVolume * Math.min(volumePercentile, MAX_VOLUME_PERCENTILE)

  let cumulativeVolume = 0

  for (const order of orders) {
    cumulativeVolume += order.amount

    if (cumulativeVolume >= targetVolume) {
      return order.price
    }
  }

  return last(orders)?.price
}

export type PriceRangeLimits = {
  min?: number
  max?: number
}

const DEFAULT_MIN_PRICE_RANGE_RATIO = 0
const DEFAULT_MAX_PRICE_RANGE_RATIO = 1

export const getOrderBookPriceRangeByPercentile = (
  orderBook: Pick<OrderBookExpandedResponse, 'asks' | 'bids' | 'midPrice'>,
  volumePercentile: number,
  priceRangeLimits: PriceRangeLimits,
) => {
  const { asks, bids, midPrice } = orderBook

  const askPriceByPercentile = findPriceByPercentile(asks, volumePercentile)
  const midPriceByPercentile = findPriceByPercentile(bids, volumePercentile)

  if (midPrice === undefined) {
    return undefined
  }

  const priceRangeRatio =
    Math.max(
      (askPriceByPercentile ?? midPrice) - midPrice,
      midPrice - (midPriceByPercentile ?? midPrice),
    ) / midPrice

  const { min = DEFAULT_MIN_PRICE_RANGE_RATIO, max = DEFAULT_MAX_PRICE_RANGE_RATIO } =
    priceRangeLimits

  return Math.max(min, Math.min(max, priceRangeRatio))
}
