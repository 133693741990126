import { PropsWithChildren, useMemo } from 'react'

import { EventEmitter, EventMap } from '../../utils'
import { EventEmitterContext } from './EventEmitterContext'

export const EventEmitterProvider = <E extends EventMap = EventMap>({
  children,
}: PropsWithChildren) => {
  const contextValue = useMemo(() => ({ eventEmitter: new EventEmitter<E>() }), [])

  return (
    <EventEmitterContext.Provider value={contextValue}>
      {children}
    </EventEmitterContext.Provider>
  )
}
