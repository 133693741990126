import { OrderBookDataWS } from './orderBook'

export enum WebSocketAction {
  Subscribe = 'SUBSCRIBE',
  Unsubscribe = 'UNSUBSCRIBE',
}

export enum WebSocketChannel {
  OrderBook = 'ORDER_BOOK',
}

export interface SubscriptionArgs {
  channel: WebSocketChannel
  currencyPair: string
  depth?: string
  refreshMs?: string
}

export interface WebSocketMessageBase {
  action: WebSocketAction
  args: SubscriptionArgs
}

export interface OrderBookMessage extends WebSocketMessageBase {
  channel: WebSocketChannel.OrderBook
  currencyPair: string
  data: OrderBookDataWS
}

export interface UnsubscribeMessage {
  action: WebSocketAction.Unsubscribe
  args: {
    channel: WebSocketChannel.OrderBook
    currencyPair: string
  }
}

export type WebSocketMessage = OrderBookMessage | UnsubscribeMessage
