import {
  Amount,
  AmountSkeleton,
  Button,
  Flex,
  Header,
  Popup,
  StatusWidget,
  Subheader,
  TabBar,
  VStack,
} from '@revolut/ui-kit'
import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  CurrencyAssetType,
  ExtendedBalance,
  UserRestrictionType,
  useCurrenciesDetailsRequired,
  useCurrenciesRequired,
  useUserRestrictionEnabled,
} from '../../../core-api'
import { Feature, useFeatures } from '../../../core-features'
import { useIntl } from '../../../core-intl'
import { AssetAvatar, OrderSizeSuggestions } from '../../../core-shared'
import {
  AccountSwitcher,
  AmountHero,
  DetailsCellGroup,
  InfoText,
  useMoneyFormat,
} from '../../../core-ui'
import {
  Asset3dImageName,
  checkRequired,
  getImageAssetSrcProps,
  notReachable,
} from '../../../core-utils'
import { WithdrawFlowStep, useWithdrawFlow } from '../../hooks'
import { BalancesGroup } from '../BalancesGroup'
import { PocketsGroup } from '../PocketsGroup'
import { TransferDisclaimer } from '../TransferDisclaimer'
import { VerificationPopupContent } from '../VerificationPopupContent'

export type WithdrawPopupContentProps = {
  currency?: string
  onClose: VoidFunction
}

export const WithdrawPopupContent = ({
  currency,
  onClose,
}: WithdrawPopupContentProps) => {
  const { formatMessage } = useIntl()

  const moneyFormat = useMoneyFormat({ type: 'money-fractional' })

  const currencies = useCurrenciesRequired()

  const currenciesDetails = useCurrenciesDetailsRequired()

  const { restriction } = useUserRestrictionEnabled({
    type: UserRestrictionType.WITHDRAW_CURRENCY,
  })

  const filterRestrictedCurrency = useCallback(
    (code: string) => {
      if (restriction && restriction.type === UserRestrictionType.WITHDRAW_CURRENCY) {
        return !restriction.currencies.includes(code)
      }

      return true
    },
    [restriction],
  )

  const filterRestrictedBalance = useCallback(
    (balance: ExtendedBalance) => filterRestrictedCurrency(balance.currency),
    [filterRestrictedCurrency],
  )

  const {
    state,

    currencyMessage,
    amountMessage,

    verificationLink,

    invalid,
    submitting,
    disabled,
    status,

    handleChangeStep,
    handleChangeBalance,
    handleChangePocket,
    handleChangeAmount,
    handleChangeSize,
    handleChangeAssetType,
    handleCancelVerification,
    handleSubmit,
  } = useWithdrawFlow({
    currency,
    onSuccess: onClose,
  })

  const { step } = state

  const features = useFeatures()

  const isAmountHeroEnabled = features.isFeatureEnabled(Feature.AmountHero)

  switch (step) {
    case WithdrawFlowStep.CurrencySelection:
      return (
        <>
          <Header variant="item" displayMode="inline">
            <Header.CloseButton
              aria-label={formatMessage({
                id: 'labels.close',
                defaultMessage: 'Close',
              })}
              onClick={onClose}
            />

            <Header.Title>
              <FormattedMessage
                id="portfolio.withdraw.currencySelection.title.v2"
                defaultMessage="Select currency"
              />
            </Header.Title>
          </Header>

          <VStack space="s-16">
            <TabBar variant="segmented fit" width="100%">
              <TabBar.Item
                use="button"
                onClick={() => handleChangeAssetType(CurrencyAssetType.Fiat)}
                aria-selected={state.assetType === CurrencyAssetType.Fiat}
              >
                <FormattedMessage
                  id="portfolio.currencies.fiat.title"
                  defaultMessage="Fiat"
                />
              </TabBar.Item>
              <TabBar.Item
                use="button"
                onClick={() => handleChangeAssetType(CurrencyAssetType.Crypto)}
                aria-selected={state.assetType === CurrencyAssetType.Crypto}
              >
                <FormattedMessage
                  id="portfolio.currencies.crypto.title"
                  defaultMessage="Crypto"
                />
              </TabBar.Item>
            </TabBar>

            <BalancesGroup
              subheader={
                <Subheader>
                  <Subheader.Title>
                    <InfoText
                      text={
                        <FormattedMessage
                          id="portfolio.withdraw.currencySelection.availableBalances.title"
                          defaultMessage="Available balances"
                        />
                      }
                      description={
                        <FormattedMessage
                          id="portfolio.withdraw.sourceSelection.availableBalances.description"
                          defaultMessage="These are the balances available to transfer from Revolut X. Balances locked on open orders can’t be withdrawn."
                        />
                      }
                      iconSize={16}
                      iconPosition="right"
                      tooltipPlacement="bottom-start"
                    />
                  </Subheader.Title>
                </Subheader>
              }
              assetType={state.assetType}
              balance={state.balance}
              onClickItem={handleChangeBalance}
              filter={filterRestrictedBalance}
            />
          </VStack>
        </>
      )
    case WithdrawFlowStep.EnterAmount: {
      const requiredBalance = checkRequired(state.balance, 'Balance should be defined')

      const { ticker } = checkRequired(
        currenciesDetails[requiredBalance.currency],
        `Currency details data should be defined: ${requiredBalance.currency}`,
      )

      const showCurrency = requiredBalance.assetType !== CurrencyAssetType.Crypto

      return (
        <>
          {isAmountHeroEnabled ? (
            <Header variant="item" displayMode="inline" displayCollapsedDescription>
              {currency === undefined ? (
                <Header.BackButton
                  aria-label={formatMessage({
                    id: 'labels.back',
                    defaultMessage: 'Back',
                  })}
                  onClick={() => handleChangeStep(WithdrawFlowStep.CurrencySelection)}
                />
              ) : (
                <Header.CloseButton
                  aria-label={formatMessage({
                    id: 'labels.close',
                    defaultMessage: 'Close',
                  })}
                  onClick={onClose}
                />
              )}
              <Header.Title>
                <FormattedMessage
                  id="portfolio.withdraw.enterAmount.title"
                  defaultMessage="Withdraw {currency}"
                  values={{ currency: ticker }}
                />
              </Header.Title>

              <Header.Description>{currencyMessage}</Header.Description>

              <Header.Actions>
                <AssetAvatar asset={requiredBalance.currency} />
              </Header.Actions>
            </Header>
          ) : (
            <Header variant="item">
              {currency === undefined ? (
                <Header.BackButton
                  aria-label={formatMessage({
                    id: 'labels.back',
                    defaultMessage: 'Back',
                  })}
                  onClick={() => handleChangeStep(WithdrawFlowStep.CurrencySelection)}
                />
              ) : (
                <Header.CloseButton
                  aria-label={formatMessage({
                    id: 'labels.close',
                    defaultMessage: 'Close',
                  })}
                  onClick={onClose}
                />
              )}
              <Header.Title>
                <FormattedMessage
                  id="portfolio.withdraw.enterAmount.title"
                  defaultMessage="Withdraw {currency}"
                  values={{ currency: ticker }}
                />
              </Header.Title>

              <Header.Avatar>
                <AssetAvatar asset={requiredBalance.currency} />
              </Header.Avatar>
            </Header>
          )}

          {status === 'success' ? (
            <>
              {isAmountHeroEnabled ? (
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ flex: 1 }}
                >
                  <VStack align="center" space="s-16" mb="s-8">
                    <AmountHero
                      autoFocus
                      name="amount"
                      type="money-fractional"
                      currency={requiredBalance.currency}
                      value={state.amount}
                      onChange={handleChangeAmount}
                      invalid={invalid}
                      description={amountMessage}
                    />

                    <AccountSwitcher
                      code={ticker}
                      image="https://assets.revolut.com/assets/banks/Revolut.svg"
                    >
                      <FormattedMessage
                        id="portfolio.withdraw.enterAmount.toRevolut.label"
                        defaultMessage="To Revolut"
                      />
                    </AccountSwitcher>
                  </VStack>
                </Flex>
              ) : (
                <Amount use="label" mb="s-8" aria-invalid={invalid}>
                  <Amount.Currency
                    value={ticker}
                    description={currencyMessage}
                    invalid={invalid}
                  />
                  <Amount.Input
                    name="amount"
                    type="money-fractional"
                    value={state.amount}
                    currency={requiredBalance.currency}
                    description={amountMessage}
                    invalid={invalid}
                    showCurrency={showCurrency}
                    onChange={handleChangeAmount}
                  />
                </Amount>
              )}
            </>
          ) : (
            <AmountSkeleton />
          )}

          <OrderSizeSuggestions
            sizeRatio={state.sizeRatio}
            onChangeSize={handleChangeSize}
          />

          <Popup.Actions>
            <Button
              elevated
              disabled={disabled}
              pending={submitting}
              onClick={() => handleChangeStep(WithdrawFlowStep.Confirmation)}
            >
              <FormattedMessage id="actions.review" defaultMessage="Review" />
            </Button>
          </Popup.Actions>
        </>
      )
    }

    case WithdrawFlowStep.SourceSelection: {
      const requiredBalance = checkRequired(state.balance, 'Balance should be defined')

      const { assetType } = checkRequired(
        currencies?.[requiredBalance.currency],
        `Currency data should be defined: ${requiredBalance.currency}`,
      )

      const isCryptoCurrency = assetType === CurrencyAssetType.Crypto

      return (
        <>
          <Header variant="item" displayMode="inline">
            <Header.CloseButton
              aria-label={formatMessage({
                id: 'labels.close',
                defaultMessage: 'Close',
              })}
              onClick={() => handleChangeStep(WithdrawFlowStep.EnterAmount)}
            />
            <Header.Title>
              <FormattedMessage
                id="portfolio.withdraw.sourceSelection.title"
                defaultMessage="Withdraw to"
              />
            </Header.Title>
          </Header>

          <VStack space="s-16">
            <PocketsGroup
              currency={requiredBalance.currency}
              onItemClick={handleChangePocket}
            />

            {isCryptoCurrency && (
              <StatusWidget>
                <StatusWidget.Image
                  {...getImageAssetSrcProps(Asset3dImageName.Pending)}
                />
                <StatusWidget.Title>
                  <FormattedMessage
                    id="portfolio.withdraw.sourceSelection.externaWithdraw.pending.title"
                    defaultMessage="External withdrawals coming soon"
                  />
                </StatusWidget.Title>
                <StatusWidget.Description>
                  <FormattedMessage
                    id="portfolio.withdraw.sourceSelection.externaWithdraw.pending.description"
                    defaultMessage="Withdrawing {currency} to external wallets will be supported soon"
                    values={{ currency: requiredBalance.currency }}
                  />
                </StatusWidget.Description>
              </StatusWidget>
            )}
          </VStack>
        </>
      )
    }

    case WithdrawFlowStep.Confirmation: {
      const requiredBalance = checkRequired(state.balance, 'Balance should be defined')

      const { assetType } = checkRequired(
        currencies?.[requiredBalance.currency],
        `Currency data should be defined: ${requiredBalance.currency}`,
      )

      const requiredAmount = checkRequired(state.amount, 'Amount should be defined')

      const withdrawDetails = [
        {
          title: formatMessage({
            id: 'portfolio.withdraw.confirmation.amount.title',
            defaultMessage: 'Amount',
          }),
          content: moneyFormat({
            amount: requiredAmount,
            currency: requiredBalance.currency,
          }),
        },
        {
          title: formatMessage({
            id: 'portfolio.withdraw.confirmation.fees.title',
            defaultMessage: 'Fees',
          }),
          content: formatMessage({
            id: 'shared.orders.noFees.label',
            defaultMessage: 'No fees',
          }),
        },
      ]

      return (
        <>
          <Header variant="item">
            <Header.BackButton
              aria-label={formatMessage({
                id: 'labels.back',
                defaultMessage: 'Back',
              })}
              onClick={() => handleChangeStep(WithdrawFlowStep.EnterAmount)}
            />
            <Header.Title>
              <FormattedMessage
                id="portfolio.transfer.confirmation.title"
                defaultMessage="Review transfer"
              />
            </Header.Title>
          </Header>

          <DetailsCellGroup items={withdrawDetails} />

          <Popup.Actions>
            <TransferDisclaimer assetType={assetType} />

            <Button elevated pending={submitting} onClick={handleSubmit}>
              <FormattedMessage id="actions.confirm" defaultMessage="Confirm" />
            </Button>
          </Popup.Actions>
        </>
      )
    }

    case WithdrawFlowStep.Verification: {
      return (
        <VerificationPopupContent
          title={
            <FormattedMessage
              id="portfolio.transfer.verification.title"
              defaultMessage="Approve transfer"
            />
          }
          subtitle={
            <FormattedMessage
              id="portfolio.transfer.verification.subtitle"
              defaultMessage="You’re being redirected to a new page to approve this transfer. If you’re not redirected automatically, click on the link below."
            />
          }
          verificationLink={verificationLink}
          onClose={handleCancelVerification}
        />
      )
    }
    default:
      return notReachable(step)
  }
}
