import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getBalances } from '../../api'
import { QueryKey } from '../../constants'
import { BalancesResponse } from '../../types'

export const useBalances = <T extends unknown = BalancesResponse>(
  options: UseQueryOptions<BalancesResponse, AxiosError, T> = {},
) =>
  useQuery<BalancesResponse, AxiosError, T>(
    [QueryKey.Balances],
    () => getBalances(),
    options,
  )
