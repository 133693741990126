import {
  ActionButton,
  ActionButtonProps,
  MoreBarActionProps,
  Token,
  useToggle,
} from '@revolut/ui-kit'
import { ComponentType, FC } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import {
  UserRestrictionKey,
  UserRestrictionType,
  useUserRestrictionEnabled,
} from '../../../core-api'
import { UserRestrictionTooltip } from '../../../core-shared'
import { useOnboardingButtonProps } from '../../../feature-onboarding'
import { DepositPopup } from '../DepositPopup'

const RESTRICTION_KEY: UserRestrictionKey = {
  type: UserRestrictionType.DEPOSIT,
}

const WhiteActionButton = styled(ActionButton)`
  background-color: ${Token.color.white};
  color: ${Token.color.black};
`

interface DepositButtonProps {
  use?: ComponentType<ActionButtonProps | MoreBarActionProps>
  variant?: 'primary' | 'white'
  currency?: string
  defaultOpen?: boolean
  onClose?: VoidFunction
}

export const DepositButton: FC<DepositButtonProps> = ({
  use: Button = ActionButton,
  variant = 'primary',
  defaultOpen = false,
  onClose,
  currency,
}) => {
  const [isPopupOpen, toogleIsPopupOpen] = useToggle({ defaultState: defaultOpen })

  const { enabled: depositDisabled } = useUserRestrictionEnabled(
    currency
      ? {
          type: UserRestrictionType.DEPOSIT_CURRENCY,
          currency,
        }
      : RESTRICTION_KEY,
  )

  const buttonProps = useOnboardingButtonProps({
    onClick: () => toogleIsPopupOpen(true),
    disabled: depositDisabled,
    children: <FormattedMessage id="actions.deposit" defaultMessage="Deposit" />,
  })

  const handlePopupClose = () => {
    toogleIsPopupOpen(false)
    onClose && onClose()
  }

  return (
    <>
      <UserRestrictionTooltip restrictionKey={RESTRICTION_KEY}>
        {variant === 'white' ? (
          <WhiteActionButton {...buttonProps} />
        ) : (
          <Button useIcon="ArrowDown" variant="primary" {...buttonProps} />
        )}
      </UserRestrictionTooltip>

      <DepositPopup currency={currency} open={isPopupOpen} onClose={handlePopupClose} />
    </>
  )
}
