const POPUP_CLOSE_CHECK_INTERVAL_MS = 300

export const browser = {
  getOrigin() {
    return window.location.origin
  },

  getHostname() {
    return window.location.hostname
  },

  getPathname() {
    return window.location.pathname
  },

  getSearch() {
    return window.location.search.substring(1)
  },

  getQueryParams<T>() {
    const searchParams = new URLSearchParams(this.getSearch())

    const queryParams = Object.fromEntries(searchParams)

    return queryParams as T
  },

  getUserAgent() {
    return window.navigator.userAgent
  },

  getLanguage() {
    return window.navigator.language
  },

  async openPopup(
    url: string,
    name: string,
    size: { width: number; height: number },
    { onClose }: { onClose?: VoidFunction } = {},
  ) {
    const features = Object.entries({
      left: window.screen.width / 2 - size.width / 2,
      top: window.screen.height / 2 - size.height / 2,
      ...size,
    })
      .map((item) => item.join('='))
      .join(',')

    return new Promise<Window | null>((resolve) =>
      // Safari blocks any window.open calls that are not triggered by user interaction, setTimeout resolves this issue
      setTimeout(() => {
        const popup = window.open(url, name, features) ?? window.open(url, '_blank')

        if (popup !== null) {
          window.addEventListener('beforeunload', () => {
            popup.close()
          })

          if (onClose) {
            const popupInterval = setInterval(() => {
              if (popup.closed) {
                clearInterval(popupInterval)

                onClose()
              }
            }, POPUP_CLOSE_CHECK_INTERVAL_MS)
          }
        }

        resolve(popup)
      }, 0),
    )
  },

  close() {
    window.close()
  },

  navigateTo(url: string) {
    window.location.assign(url)
  },

  reloadLocation() {
    window.location.reload()
  },

  scrollTo(options: ScrollToOptions) {
    return window.scrollTo(options)
  },

  checkIfOnline() {
    return window.navigator.onLine
  },
}
