import { Chat as ChatWidget } from '@revolut-internal/chat-widget'
import { Box } from '@revolut/ui-kit'
import { FC } from 'react'
import styled from 'styled-components'

import { eventTracker } from '../../core-event-tracker'
import { useIntlContext } from '../../core-intl'
import { Z_INDICES } from '../../core-ui'
import { HELP_CENTRE_API } from './constants'
import { useChatContext, useChatMessages } from './hooks'

const ChatWrapper = styled(Box)`
  z-index: ${Z_INDICES.SIDE};

  /* TODO: Should be fixed in chat-widget */
  & [class*='LayoutTopNav'] {
    display: none;
  }

  & [class*='LayoutSide'] {
    position: fixed;
    left: unset;
    right: 0;
    padding: 1.5rem;
  }
`

export const Chat: FC = () => {
  const { locale } = useIntlContext()

  const { chatProviderService } = useChatContext()
  const localeDictionary = useChatMessages()

  return (
    <ChatWrapper>
      <ChatWidget
        locale={locale.replace('_', '-')}
        localeDictionary={localeDictionary}
        helpCentreApi={HELP_CENTRE_API}
        chatProviderService={chatProviderService}
        chatEventTracker={eventTracker}
      />
    </ChatWrapper>
  )
}
