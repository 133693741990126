import Big from 'big.js'
import { isEqual, isNil, sumBy } from 'lodash'
import { useMemo } from 'react'

import { DEFAULT_CURRENCY } from '../../constants'
import { ExtendedBalance, StableSortedReturn } from '../../types'
import { combineStatuses, formatCurrencyPair } from '../../utils'
import { useBalances } from '../useBalances'
import { useBalancesStats } from '../useBalancesStats'
import { useCurrencyUnitsConvert } from '../useCurrencyUnitsConvert'
import { useQuote } from '../useQuote'
import { SortingRule, useStableSortedData } from '../useStableSortedData'
import { useTickers } from '../useTickers'

const BALANCES_SORT_BY: SortingRule<ExtendedBalance>[] = [{ id: 'value', desc: true }]

const getBalanceId = (item: ExtendedBalance) => item.currency

export const useStableSortedBalances = (
  sortBy: SortingRule<ExtendedBalance>[] = BALANCES_SORT_BY,
): StableSortedReturn<ExtendedBalance[]> => {
  const balances = useBalances()

  const balancesStats = useBalancesStats()

  const tickers = useTickers()

  const currencyPairs = useMemo(() => {
    if (balances.status === 'success') {
      return Object.keys(balances.data).map((currency) =>
        formatCurrencyPair(
          {
            baseCurrency: currency,
            quoteCurrency: DEFAULT_CURRENCY,
          },
          '/',
        ),
      )
    }

    return []
  }, [balances.data, balances.status])

  const quote = useQuote(currencyPairs, {
    enabled: currencyPairs.length > 0 && balances.status === 'success',
  })

  const convertTo = useCurrencyUnitsConvert()

  const extentedBalances: ExtendedBalance[] = useMemo(() => {
    if (balances.status === 'success' && tickers.status === 'success') {
      const tempBalances = Object.entries(balances.data).reduce<
        Omit<ExtendedBalance, 'allocation'>[]
      >((result, [balanceCurrency, balance]) => {
        const total = balance.amount + balance.reserved

        if (total === 0) {
          return result
        }

        const pair = formatCurrencyPair(
          {
            baseCurrency: balanceCurrency,
            quoteCurrency: DEFAULT_CURRENCY,
          },
          '/',
        )

        const ticker = tickers.data?.[pair]
        const currencyQuote = quote.data?.[pair]

        const midPrice = ticker?.mid ?? currencyQuote?.midRate

        let midPriceBig = midPrice !== undefined ? Big(midPrice) : undefined

        let value
        let amountValue
        let reservedValue

        const totalInMajorUnits = convertTo(total, balanceCurrency, 'major')
        const amountInMajorUnits = convertTo(balance.amount, balanceCurrency, 'major')
        const reservedInMajorUnits = convertTo(balance.reserved, balanceCurrency, 'major')

        if (!isNil(midPriceBig)) {
          value = midPriceBig.times(totalInMajorUnits).toNumber()
          amountValue = midPriceBig.times(amountInMajorUnits).toNumber()
          reservedValue = midPriceBig.times(reservedInMajorUnits).toNumber()
        }

        if (balanceCurrency === DEFAULT_CURRENCY) {
          midPriceBig = Big(1)

          value = totalInMajorUnits
          amountValue = amountInMajorUnits
          reservedValue = reservedInMajorUnits
        }

        const balanceStats = balancesStats.data?.[balanceCurrency] ?? {}

        return [
          ...result,
          {
            total,
            totalMajor: totalInMajorUnits,
            amountMajor: amountInMajorUnits,
            value,
            currency: balanceCurrency,
            amountValue,
            reservedValue,
            price: midPriceBig?.toNumber(),
            ...balance,
            ...balanceStats,
          },
        ]
      }, [])

      const totalValue = sumBy(tempBalances, 'value')

      return tempBalances.map((balance) => ({
        ...balance,
        allocation: (balance.value ?? 0) / totalValue,
      }))
    }

    return []
  }, [
    balances.data,
    balances.status,
    balancesStats.data,
    tickers.data,
    tickers.status,
    quote.data,
    convertTo,
  ])

  const extentedSortBy: SortingRule<ExtendedBalance>[] = useMemo(
    () =>
      isEqual(sortBy, BALANCES_SORT_BY)
        ? [{ id: 'assetType', desc: true }, ...sortBy]
        : sortBy,
    [sortBy],
  )

  const data = useStableSortedData(
    extentedBalances,
    extentedSortBy,
    getBalanceId,
    balancesStats.isFetched,
  )

  const status = combineStatuses(balances.status, tickers.status)

  const refetch = () => {
    balances.refetch()
    tickers.refetch()
  }

  if (status === 'success') {
    return {
      data,
      status,
    }
  }

  if (status === 'loading') {
    return {
      data: undefined,
      status,
    }
  }

  return {
    data: undefined,
    status,
    refetch,
  }
}
