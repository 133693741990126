import { Box, Fixed } from '@revolut/ui-kit'
import { uniqBy } from 'lodash'
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Z_INDICES } from '../../../core-ui'
import { GridLayoutResizerType, GridLayoutTemplate } from '../../types'
import {
  addGridLayoutResizers,
  getGridLayoutAreaName,
  getGridLayoutColumnsStyle,
  getGridLayoutRowsStyle,
  getGridLayoutStyle,
  isGridLayoutResizerArea,
  resizeGridLayout,
  resizeGridLayoutColumns,
  resizeGridLayoutRows,
} from '../../utils'
import { GridLayoutResizer } from '../GridLayoutResizer'

type ResizingState = {
  type: GridLayoutResizerType
  index: number
  targetIndex: number
  startX: number
  startY: number
}

type GridLayoutProps<T extends string> = {
  template: GridLayoutTemplate<T>
  onChange: (template: GridLayoutTemplate<T>) => void
  children: (area: T) => ReactNode
}

export const GridLayout = <T extends string>({
  template,
  onChange,
  children,
}: GridLayoutProps<T>) => {
  const gridElRef = useRef<HTMLDivElement>(null)

  const resizingRef = useRef<ResizingState | null>(null)

  const [resizing, setResizing] = useState(false)

  const { shadowTemplate, uniqAreas, style } = useMemo(() => {
    const nextShadowTemplate = addGridLayoutResizers(template)

    return {
      shadowTemplate: nextShadowTemplate,
      uniqAreas: uniqBy(nextShadowTemplate.areas.flat(), getGridLayoutAreaName),
      style: getGridLayoutStyle(nextShadowTemplate),
    }
  }, [template])

  const handleMouseDown = useCallback(
    (targetIndex: number, index: number, type: GridLayoutResizerType) => (event) => {
      resizingRef.current = {
        type,
        index,
        targetIndex,
        startX: event.clientX,
        startY: event.clientY,
      }

      setResizing(true)
    },
    [],
  )

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!resizingRef.current || !gridElRef.current) {
        return
      }

      const { index, type } = resizingRef.current

      if (type === 'row') {
        const resizedRows = resizeGridLayoutRows(
          shadowTemplate.rows,
          index - 1,
          index + 1,
          resizingRef.current.startY - event.clientY,
        )

        gridElRef.current.style.gridTemplateRows = getGridLayoutRowsStyle(resizedRows)
      } else {
        const resizedColumns = resizeGridLayoutColumns(
          shadowTemplate.columns,
          index - 1,
          index + 1,
          resizingRef.current.startX - event.clientX,
        )

        gridElRef.current.style.gridTemplateColumns =
          getGridLayoutColumnsStyle(resizedColumns)
      }
    },
    [shadowTemplate],
  )

  const handleMouseUp = useCallback(
    (event) => {
      if (resizingRef.current) {
        const { targetIndex, type } = resizingRef.current

        const dxy =
          type === 'row'
            ? resizingRef.current.startY - event.clientY
            : resizingRef.current.startX - event.clientX

        onChange(resizeGridLayout(template, targetIndex, targetIndex + 1, dxy, type))
      }

      setResizing(false)

      resizingRef.current = null
    },
    [onChange, template],
  )

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [handleMouseMove, handleMouseUp])

  return (
    <>
      {resizing && (
        <Fixed zIndex={Z_INDICES.OVERLAY} width="100%" height="100%" top={0} left={0} />
      )}

      <Box
        ref={gridElRef}
        display="grid"
        overflow="auto"
        style={style}
        flex={1}
        css={{ userSelect: 'none' }}
      >
        {uniqAreas.map((area) => {
          if (isGridLayoutResizerArea(area)) {
            const name = getGridLayoutAreaName(area)

            const reziserType = area.name === 'COLUMN_RESIZER' ? 'column' : 'row'

            return (
              <GridLayoutResizer
                key={name}
                gridArea={name}
                flex={1}
                type={reziserType}
                onMouseDown={handleMouseDown(area.targetIndex, area.index, reziserType)}
              />
            )
          }

          return <Fragment key={area.name}>{children(area.name)}</Fragment>
        })}
      </Box>
    </>
  )
}
