import { Box, Layout, StatusWidget } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { EventTrackerHiddenHeader } from '../../../../packlets/core-event-tracker'
import {
  Asset3dImageName,
  getImageAssetSrcProps,
  getRouteUrl,
  RoutePath,
} from '../../../../packlets/core-utils'

export const LimitedAccessPage = () => {
  const history = useHistory()

  return (
    <Layout>
      <Layout.Main>
        <EventTrackerHiddenHeader screen="authLimitedAccess" />

        <Box my="auto">
          <StatusWidget>
            <StatusWidget.Image {...getImageAssetSrcProps(Asset3dImageName.Shield)} />
            <StatusWidget.Title>
              <FormattedMessage
                id="auth.limitedAccess.title"
                defaultMessage="You have signed in with limited access"
              />
            </StatusWidget.Title>
            <StatusWidget.Description>
              <FormattedMessage
                id="auth.limitedAccess.subtitle"
                defaultMessage="Authorise via push notification to get access to the functionality."
              />
            </StatusWidget.Description>

            <StatusWidget.Action
              onClick={() => history.replace(getRouteUrl({ path: RoutePath.AuthSignIn }))}
            >
              <FormattedMessage id="actions.signIn" defaultMessage="Sign In" />
            </StatusWidget.Action>
          </StatusWidget>
        </Box>
      </Layout.Main>
    </Layout>
  )
}
