import { FC } from 'react'
import { defineMessages } from 'react-intl'

import { OrderSide } from '../../../core-api'
import { FormattedEnumMessage } from '../../../core-intl'

type OrderSideMessageProps = {
  side: OrderSide
}

const orderSideMessages = defineMessages<OrderSide>({
  [OrderSide.Buy]: {
    id: 'activity.order.side.buy.title',
    defaultMessage: 'Buy',
  },
  [OrderSide.Sell]: {
    id: 'activity.order.side.sell.title',
    defaultMessage: 'Sell',
  },
})

export const OrderSideMessage: FC<OrderSideMessageProps> = ({ side }) => {
  return <FormattedEnumMessage id={side} messages={orderSideMessages} />
}
