import { Flex, Text, Tooltip, TooltipState, VStack } from '@revolut/ui-kit'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { CurrencyPairMoney, useHumanizeCurrency } from '../../../core-shared'
import { Money } from '../../../core-ui'

interface OrderBookTooltipProps {
  averagePrice: number
  totalAmount: number
  totalPrice: number
  currencyPair: string
  baseCurrency: string
  quoteCurrency: string
  tooltip: TooltipState<Element>
}

export const OrderBookTooltip: FC<OrderBookTooltipProps> = ({
  currencyPair,
  averagePrice,
  totalAmount,
  totalPrice,
  baseCurrency,
  quoteCurrency,
  tooltip,
}) => {
  const { humanizeCurrency } = useHumanizeCurrency()

  const humanizedBaseCurrency = humanizeCurrency(baseCurrency)
  const humanizedQuoteCurrency = humanizeCurrency(quoteCurrency)

  return (
    <Tooltip {...tooltip.getTargetProps({ placement: 'left' })} bg="foreground">
      <VStack space="s-4" width={160} py="s-8" px="s-4">
        <Flex alignItems="center" justifyContent="space-between">
          <Text variant="small" color="background">
            <FormattedMessage
              id="markets.orderBook.averagePrice.label"
              defaultMessage="Avg. price"
            />
          </Text>
          <Text variant="small" color="background">
            <CurrencyPairMoney
              kind="price"
              variant="quote"
              currencyPair={currencyPair}
              amount={averagePrice}
              clearSymbol
            />
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Text variant="small" color="background">
            <FormattedMessage
              id="markets.orderBook.sumBase.label"
              defaultMessage="Sum {baseCurrency}"
              values={{
                baseCurrency: humanizedBaseCurrency,
              }}
            />
          </Text>
          <Text variant="small" color="background">
            <CurrencyPairMoney
              variant="base"
              currencyPair={currencyPair}
              amount={totalAmount}
              clearSymbol
              compactNotation
            />
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Text variant="small" color="background">
            <FormattedMessage
              id="markets.orderBook.sumQuote.label"
              defaultMessage="Sum {quoteCurrency}"
              values={{
                quoteCurrency: humanizedQuoteCurrency,
              }}
            />
          </Text>
          <Text variant="small" color="background">
            <Money
              kind="total"
              amount={totalPrice}
              currency={quoteCurrency}
              compactNotation
            />
          </Text>
        </Flex>
      </VStack>
    </Tooltip>
  )
}
