import axios from 'axios'

import { OrdersParams, OrdersReponse } from '../types'

export const getOrdersHistory = async (params: OrdersParams) => {
  const { data } = await axios.get<OrdersReponse>(
    '/api/crypto-exchange/orders/history/page',
    {
      params,
    },
  )

  return data
}
